//引用第一步创建的axios.js文件
import request from '../axios.js'
// 获取生产任务
export function workshopInformation(params) {
  return request({
    url: 'minapp/personnelData/workshopInformation',
    method: 'get',
    params
  })
}
export function soonerAndLater(params) {
  return request({
    url: 'minapp/personnelData/soonerAndLater',
    method: 'get',
    params
  })
}
export function personnelState(params) {
  return request({
    url: 'minapp/personnelData/personnelState',
    method: 'get',
    params
  })
}
