<template>
	<a-layout class="layout-content" style="overflow: hidden;" :style="{zoom:screenWidth/1920}" v-if="list.length>0">
		<a-layout-sider style="background: #F0F1F3!important;border-right: none!important;height: 1200px;width: 503px;">
			<div class="header-bg">
				<div class="flex list" :style="{backgroundColor:type==arr.type?'#FFFFFF':''}" v-for="arr in list"
					:key="arr" @click="type=arr.type,title='',getDetail()">
					<div class="tab-box-left-bg" :style="{backgroundImage:arr.iconBackground}">
						<div class="tab-box-left-icon">
							<span class="iconfont" :class="arr.icon"
								:style="{ color: '#FFFFFF', fontSize: '26px', }"></span>
						</div>
					</div>
					<div style="width: 100%;">
						<div class="title">
							{{arr.msgTypeName}}
							<div class="right">{{arr.createTime}}</div>
						</div>
						<div class="desc">
							<div class="content">
								{{arr.subTypeTitle}}
							</div>

							<div class="right">
								<div class="num" v-show="arr.unreadAmount!=null">{{arr.unreadAmount}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-layout-sider>
		<a-layout-content style="background: #F0F1F3!important;overflow-y: hidden;">
			<div class="page" style="overflow: hidden;position: absolute;top: 0;bottom: 0;left: 600px;right: 0;">
				<div class="header flex" v-if="notice.length>0">
					<div class="tab-box-left-bg" :style="{backgroundImage:arr.iconBackground}">
						<div class="tab-box-left-icon">
							<span class="iconfont" :class="arr.icon"
								:style="{ color: '#FFFFFF', fontSize: '26px', }"></span>
						</div>
					</div>
					{{arr.msgTypeName}}
					<a-checkbox class="Pending" v-model="state1" v-if="type==5">仅展示待处理</a-checkbox>
					<a-input v-if="type==5" class="right search" v-model="title" placeholder="搜索" @press-enter="getDetail()" allow-clear>
						<template #prefix>
							<icon-search />
						</template>
					</a-input>
					<div class="right flex" v-if="type!=1&&type!=5">
						<div class="button" :class="{active:state == 0}" @click="state=0,getDetail()">全部</div>
						<div class="button" :class="{active:state == 1}" @click="state=1,getDetail()">我收到的</div>
						<div class="button" :class="{active:state == 2}" @click="state=2,getDetail()">回复我的</div>
					</div>
				</div>
				<div class="content" style="overflow-y: scroll;height: 100%;" v-if="notice.length>0">
					<div class="border" :style="{backgroundColor:(type==5?'#FFFFFF':'')}" v-for="(item,index) in notice"
						v-if="type==5">
						<div class="title">{{item.msgTypeName}}</div>
						<div class="desc">{{item.subTypeTitle}}</div>
						<div class="time">{{item.time}}</div>
					</div>
					<div class="message" :class="{select:select}" v-for="(item,index) in notice" v-if="type!=5">
						<div class="time">{{item.time}}</div>
						<div class="flex" style="align-items: flex-start;position: relative;" @click="choose(index)">
							<div class="tab-box-left-bg" :style="{backgroundImage:arr.iconBackground}">
								<div class="tab-box-left-icon">
									<span class="iconfont" :class="arr.icon"
										:style="{ color: '#FFFFFF', fontSize: '26px', }"></span>
								</div>
							</div>
							<div>
								<div class="name">{{arr.msgTypeName}}</div>
								<div class="card" v-if="type!=1&&item.subType==1">
									<div class="title">{{item.subTypeTitle}}</div>
									<div class="desc" v-if="item.subType==1">
										{{item.content}}
										<div class="right">
											<div class="btn" v-show="type==2" :class="{blue:item.status==0}"
												@click="goPage(item.status,item.outerId)">
												{{item.status==0?'去处理':'已处理'}}
											</div>
											<div class="btn" v-show="type==4||type==3" :class="{blue:item.status==0}"
												@click="goPage(item.status,item.outerId)">
												{{item.status==0?'去阅读':'已读'}}
											</div>
										</div>
									</div>
									<!-- <div class="bottom" v-if="item.subType==2" :style="{color:item.status==0?'#4288E8':'#C6C6C6'}">我知道了</div> -->
								</div>
								<!-- <a-popover title="" position="top" trigger="contextMenu"> -->
								<div class="card" style="height: 213px;" v-if="item.subType==2||type==1"
									@contextmenu.prevent="rightClick(index)">
									<div class="title">{{item.msgTypeName}}</div>
									<div class="desc" style="color: #888888;">{{item.subTypeTitle}}</div>
									<div class="bottom" :style="{color:item.status==0?'#4288E8':'#C6C6C6'}" @click="goPage(item.status,item.outerId)">我知道了</div>
									<div class="icon" v-show="item.all"></div>
									<div class="popover" v-show="item.all">
										<div class="pop" @click="id = item.notificationId,visible = true">删除</div>
										<div class="pop">|</div>
										<div class="pop" @click="select = true,allSelect()">多选</div>
									</div>
								</div>
								<!-- <template #content> -->

								<!-- </template> -->
								<!-- </a-popover> -->

							</div>
							<div class="right switch" :style="{opacity:item.choose==true?1:''}">
								<div style="width: 22px;height: 22px;border-radius: 50%;border: 1px solid #3296FA;display: flex;align-items: center;justify-content: center;"
									v-if="item.choose!=true">

								</div>
								<icon-check-circle-fill style="color: #3296FA;font-size: 28px;"
									v-if="item.choose==true" />
							</div>
						</div>
					</div>
					<div style="height: 120px;width: 100px;"></div>
					<div style="height: 63px;width: 100px;" v-if="select"></div>
				</div>
				
				<div class="center"  v-else>
					<div>
						<img src="../../assets/bg/wl-nodata.png" />
						<div>暂时没有消息</div>
					</div>
				</div>
				<div class="select-bottom" v-if="select">
					<div class="icon" style="background-color: #FFFFFF;" @click="selectDel()">
						<icon-delete :style="{fontSize:'24px'}" />
					</div>
					<div class="icon" @click="select = false,allSelect()">
						<icon-close />
					</div>
				</div>
				<div class="cover" v-if="visible">
					<div class="modal">
						<div class="title">删除</div>
						<div class="desc">删除该消息后将无法找回，是否确认删除该条消息？</div>
						<div class="flex">
							<div class="btn left" @click="delNotice">仍要删除</div>
							<div class="btn" @click="visible = false">取消</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <router-view ref="children"></router-view> -->
		</a-layout-content>
	</a-layout>
	<div class="center" style="background-color: #F0F1F3;" :style="{left:screenWidth/1920*96+'px'}" v-else>
		<div :style="{zoom:screenWidth/1920}">
			<img src="../../assets/bg/wl-nodata.png" />
			<div>暂时没有消息</div>
		</div>
	</div>
</template>
<script>
	import {
		getList,
		getDetailList,
		delNotificationBatch
	} from "../../api/notice.js";
	export default {
		data() {
			return {
				title: '',
				arr: {},
				notice: [],
				state: 0,
				type: 5,
				select: false,
				list: [],
				index: 0,
				id: 0,
				state1: false,
				visible: false,
				screenWidth: document.documentElement.clientWidth, //获取屏幕高度
			};
		},
		//监听路由变化
		$route(path) {
			this.index = path.meta.key;
			// if (path.meta.key != null) {
			// 	localStorage.setItem('key', path.meta.key)
			// 	localStorage.setItem('type', path.meta.type)
			// 	this.type = path.meta.type
			// 	var a = false; //判断当前路由是否加入到标签里面
			// 	for (let i = 0; i < this.data.length; i++) {
			// 		if (path.meta.key == this.data[i].key) {
			// 			a = true;
			// 		}
			// 	}
			// 	if (a == false) {
			// 		var arr = path.meta
			// 		arr.fullPath = path.fullPath
			// 		this.data.push(arr);
			// 		localStorage.setItem('tabs', JSON.stringify(this.data))
			// 		console.log('tabs')
			// 		console.log(this.data)
			// 	}
			// }

		},
		mounted() {
			var that = this
			this.getList()
		},
		methods: {
			allSelect() {
				for (let i = 0; i < this.notice.length; i++) {
					this.notice[i].all = false
					this.notice[i].choose = false
				}
			},
			rightClick(index) {
				if (this.notice[index].status == 0) {
					return
				} else {
					if (this.notice[index].all == null || this.notice[index].all == false) {
						this.notice[index].all = true
					} else {
						this.notice[index].all = false
					}
				}

			},
			getList() {
				getList().then((res) => {
					console.log(res);
					var list = res.data
					for (let i = 0; i < list.length; i++) {
						list[i].iconBackground = list[i].iconBackground.substr(0, list[i].iconBackground.length -
							1)
					}
					this.type = list[0].type
					this.arr = list[0]
					this.getDetail()
					this.list = list
				});
			},
			getDetail() {
				console.log(this.state)
				var state = 0
				if(this.type==5){
					state = this.state1?1:0
				}else{
					state = this.state
				}
				var data = {
					state: state,
					title: this.title,
					type: this.type
				}
				getDetailList(data).then((res) => {
					console.log('getDetail');
					console.log(res.data.records);
					this.notice = res.data.records
				});
			},
			delNotice() {
				var id = this.id
				delNotificationBatch({
					id: id
				}).then((res) => {
					console.log(res);
					this.visible = false
					this.allSelect()
				});
			},
			choose(index) {
				console.log(index)
				if (this.notice[index].status == 0) {
					return
				} else {
					if (this.select == true) {
						if (this.notice[index].choose == null) {
							this.notice[index].choose = true
						} else {
							this.notice[index].choose = !this.notice[index].choose
						}
					}
				}
			},
			selectDel() {
				var id = ''
				for (let i = 0; i < this.notice.length; i++) {
					if (this.notice[i].choose == true) {
						id += this.notice[i].notificationId + ','
					}
				}
				console.log(id)
				id = id.substr(0, id.length - 1)
				console.log(id)
				this.id = id
				this.visible = true
				this.allSelect()
			},
			goPage(status, outerId) {
				if (status == 0) {
					if (this.type == 4) {
						this.$router.push({
							path: "/dashBord/workBench/exceptionReport",
							query: {
								attachmentId: outerId,
								type: 1
							},
						});
					}
					if (this.type == 3) {
						this.$router.push({
							path: "/dashBord/workBench/productionDaily",
							query: {
								dailyId: outerId,
							},
						});
					}
				}
			}
		},
	};
</script>
<style>
	.arco-trigger-position-top .arco-popover-popup-content {
		background-color: #3C3C3C !important;
	}

	.arco-checkbox-icon-check {
		font-size: 14px !important;
	}
</style>
<style scoped lang="less">
	.center{
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 24px;
		font-weight: normal;
		color: rgba(0, 0, 0, 0.30000001192092896);
		line-height: 36px;
		img{
			width: 329px;
			height: 256px;
			margin-bottom: 36px;
		}
	}
	/deep/ .arco-icon {
		zoom: 1.5;
	}

	/deep/ .arco-popover-title {
		display: none !important;
	}

	/deep/ .arco-layout-content {
		background: #F0F1F3;
	}

	.header-bg {
		padding: 15px 0 0;
		border-radius: 10px 0 0 0;
	}

	.list {
		width: 465px;
		height: 85px;
		border-radius: 4px;
		margin: 0 14px;
		display: flex;
		align-items: flex-start;
		padding-left: 11px;
		padding-top: 20px;
		.title {
			font-size: 24px;
			font-weight: normal;
			color: rgba(0, 0, 0, 0.800000011920929);
			line-height: 30px;
			position: relative;
			text-align: start;

			.right {
				font-size: 18px;
				font-weight: normal;
				color: #999999;
				line-height: 30px;
				right: 14px;
			}
		}

		.desc {
			font-size: 18px;
			font-weight: normal;
			color: #999999;
			line-height: 30px;
			position: relative;
			text-align: start;

			.right {
				right: 14px;
			}

			.content {
				font-size: 18px;
				width: 240px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

			}

			.num {
				height: 24px;
				background: #DB707C;
				border-radius: 12px;
				opacity: 1;
				font-size: 15px;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 24px;
				padding: 0 6px;
				margin-top: 5px;

			}
		}
	}

	.button {
		width: 84px;
		padding: 0 10px;
		font-weight: normal;
		text-align: center;
		margin-left: 8px;
		height: 42px;
		background: #FFFFFF;
		border-radius: 6px 6px 6px 6px;
		opacity: 1;
		border: 2px solid #F5F5F5;
		font-size: 21px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #2189F3;
		line-height: 42px;
	}

	.button:hover {
		border: 2px solid #2994FD;
		background: #2994FD10;
	}

	.active {
		border: 2px solid #2994FD;
		background: #2994FD10;
	}

	.right {
		position: absolute;
		right: 16px;
		top: 0;
	}

	.list:hover {

		background: #FFFFFF;
	}

	.active {

		background: rgba(213, 213, 213, 0.20000000298023224);
	}

	.tab-box-left-bg {
		width: 46px;
		margin-right: 9px;
		height: 46px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-top: 3px;

	}

	.page {
		// width: 100%;
		background: #FAFBFC;
		border-radius: 4px;
		border: 1px solid #FFFFFF;
		overflow: hidden;
		padding: 0 !important;
		position: relative;

		.header {
			// background-color: #FAFBFC;
			height: 84px;
			font-size: 24px;
			font-weight: 700;
			color: #333333;
			line-height: 20px;
			padding-left: 22px;
			box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.05);
			z-index: 9;
			position: relative;

			.right {
				top: 21px;
			}

			/deep/ .arco-input-wrapper {
				width: 383px;
				height: 54px;
				font-size: 21px !important;

				.arco-icon {
					width: 21px;
					height: 21px;
				}
			}

			/deep/ .arco-input {
				font-size: 21px !important;
			}
		}

		.flex {
			display: flex;
			align-items: center;
		}

		/deep/ .arco-checkbox-icon {
			zoom: 1.5;
			border-radius: 50%;
		}

		.arco-checkbox {
			font-size: 21px !important;
			margin-left: 30px;
			font-weight: 500;

		}

		/deep/ .arco-checkbox-label {
			font-size: 21px;
			font-weight: normal;
			color: #2189F3;
			line-height: 20px;
		}

		.content {
			text-align: start;
			padding: 1px;
			overflow-y: scroll;
			margin-top: 2px;

			.border {
				border-bottom: 1px solid #F6F7F8;
				// padding-bottom: 14px;
				height: 160px;

				.title {
					font-size: 24px;
					padding: 27px 27px 0;
					font-weight: normal;
					color: #333333;
					line-height: 30px;
				}

				.desc {
					font-size: 21px;
					font-weight: normal;
					color: #999999;
					line-height: 30px;
					margin: 14px 27px 0;
				}

				.time {
					font-size: 18px;
					font-weight: normal;
					color: #D6D6D6;
					line-height: 30px;
					margin: 9px 27px 0;
				}
			}


		}

		.message {
			margin-left: 22px;

			.time {
				width: 108px;
				padding: 0 6px;
				height: 20px;
				// background: #FFFFFF;
				border-radius: 10px;
				font-size: 15px;
				font-weight: normal;
				color: #4A4A4A;
				line-height: 26px;
				margin: 32px auto 8px;
			}

			.name {
				font-size: 18px;
				font-weight: normal;
				color: #787B82;
				line-height: 30px;
				text-align: start;
			}

			.card {
				border: 2px solid #DEE0E3;
				width: 558px;
				min-height: 161px;
				background: #FFFFFF;
				// padding: 16px;
				border-radius: 10px;
				text-align: start;
				position: relative;

				.icon {
					position: absolute;
					top: -30px;
					left: 455px;
					width: 27px;
					height: 27px;
					background-color: #3C3C3C;
					transform: rotate(45deg);
				}

				.title {
					font-size: 24px;
					font-weight: normal;
					color: #1E2327;
					line-height: 30px;
					padding: 24px 24px 0;
				}

				.desc {
					line-height: 30px;
					position: relative;
					margin: 42px 24px 40px;
					font-size: 23px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: rgba(0, 0, 0, 0.8);
					line-height: 40px;

					.right {
						top: -4px;
					}

					.btn {
						width: 99px !important;
						height: 41px;
						border-radius: 21px;
						opacity: 1;
						border: 1px solid #C7C7C7;
						width: 28px;
						font-weight: normal;
						color: #C7C7C7;
						line-height: 41px;
						text-align: center;
					}

					.blue {
						border-color: #4676E4;
						color: #4676E4;
					}
				}
			}

			.bottom {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				font-size: 24px;
				font-weight: normal;
				color: #4288E8;
				line-height: 57px;
				text-align: center;
				height: 57px;
				border-top: 1px solid #F6F7F8;
			}

		}

		.select-bottom {
			// width: 818px;
			height: 96px;
			background: #FCFDFD;
			box-shadow: 0px -3px 3px 2px rgba(50,150,250,0.1);
			border-radius: 1px;
			border: 0px solid #FFFFFF;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.icon {
				width: 74px;
				height: 74px;
				border-radius: 6px;
				margin: 0 15;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

	}

	.popover {
		position: absolute;
		top: -65px;
		left: 383px;
		width: 176px;
		height: 53px;
		background: #3C3C3C;
		border-radius: 12px;
		opacity: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pop {
		text-align: center;
		width: 42px;
		height: 33px;
		font-size: 21px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
	}

	.switch {
		opacity: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.select {
		margin-right: 28px;
		padding-bottom: 20px;
	}

	.select:hover {
		background-color: #EFEFEF;

		.switch {
			opacity: 1;
		}
	}

	.content::-webkit-scrollbar {
		width: 0px;
	}
	.cover{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #00000090;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 9;
		.modal{
			width: 420px;
			height: 270px;
			background: #FFFFFF;
			border-radius: 10px;
			position: relative;
			.title{
				font-size: 26px;
				font-family: PingFang SC-Medium, PingFang SC;
				font-weight: 500;
				color: #1F1F1F;
				line-height: 39px;
				text-align: center;
				margin: 30px auto 18px;
			}
			.desc{
				margin: 0 36px;
				font-size: 21px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: rgba(31, 31, 31, 0.65);
				line-height: 30px;
			}
			.flex{
				border-top: 1px solid #EBEBEB;
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 69px;
				.btn{
					width: 210px;
					text-align: center;
					font-size: 26px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #3296FA;
					line-height: 69px;
				}
				.left{
					border-right: 1px solid #EBEBEB;
				}
			}
		}
	}
	
</style>
