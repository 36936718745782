<template>
  <div
    class="page"
    :style="{ height: screenHeight + 'px' }"
    style="overflow: hidden"
  >
    <div class="per_header">
      产能数据驾驶舱
      <div class="per_header_box">
        <div class="per_header_boxs" v-for="item in factory" :key="item">
          {{ item.structureName }}
        </div>
      </div>
    </div>

    <div class="page-content" :style="{ height: screenHeight - 70 + 'px' }">
      <div class="content-left">
        <div class="left-top">
          <div class="title">
            <div>车间问题占比</div>
            <!-- <div class="bfb">80%</div> -->
          </div>
          <div class="bto">今日处理回复率</div>
          <div
            class="chart"
            id="proportion1"
            style="width: 95%; height: 85%"
          ></div>
        </div>
        <div class="left-bottom">
          <div class="title">生管问题汇总</div>
          <div
            class="chart"
            id="summary1"
            style="width: 100% !important; height: 60%"
          ></div>
          <div class="legend-list">
            <div class="flex legend" v-for="(item, i) in label" :key="i">
              <div
                class="legend-icon"
                :style="{ backgroundColor: color[i] }"
              ></div>
              <div class="legend-name">{{ item.labelName }}</div>
              <span class="count">{{ item.amount }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="search">
          <a-select
            @click="visible = false"
            v-model="form.labelId"
            :default-value="form.labelId"
            placeholder="生管问题"
            style="width: 150px"
            @change="(list = []), (current = 1), getList()"
          >
            <a-optgroup
              :label="item.labelName"
              v-for="item in selectList"
              :key="item"
            >
              <a-option
                :value="arr.labelId"
                v-for="arr in item.children"
                :key="arr"
              >
                {{ arr.labelName }}
              </a-option>
            </a-optgroup>
          </a-select>
          <a-select
            @click="visible = false"
            v-model="form.state"
            @change="(list = []), (current = 1), getList()"
            placeholder="状态"
            style="width: 150px"
          >
            <a-option value="1">待处理</a-option>
            <a-option value="2">已处理</a-option>
          </a-select>
          <a-select
            @click="visible = false"
            v-model="form.structureId"
            :default-value="form.structureId"
            placeholder="车间"
            style="width: 150px"
            @change="(list = []), (current = 1), getList()"
          >
            <a-optgroup
              :label="item.structureName"
              :value="item.structureId"
              v-for="item in factory"
              :key="item"
            >
              <a-option
                :value="arr.structureId"
                v-for="arr in item.children"
                :key="arr"
              >
                {{ arr.structureName }}
              </a-option>
            </a-optgroup>
          </a-select>

          <a-range-picker
            @click="visible = true"
            :popup-visible="visible"
            @change="onChange"
            @select="onSelect"
          />

          <div
            class="isbut"
            @click="
              (form = { labelId: '', state: '', structureId: '' }),
                (list = []),
                (datetime = []),
                (current = 1),
                getList()
            "
          >
            重置
          </div>
        </div>
        <div
          class="scrollbar"
          style="
            overflow-y: scroll;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
          "
          @scroll="scrollEvent"
          :style="{ height: screenHeight - 145 + 'px' }"
        >
          <div
            class="card"
            v-for="(item, i) in list"
            :key="i"
            @click="(visible = true), goDetail(item.rid)"
          >
            <div class="img" style="text-align: center">
              <a-image
                width="100%"
                height="212px"
                v-if="item.url != null && item.url != ''"
                :src="$baseUrl + item.url"
              />
              <img
                v-else
                style="height: 80%; margin: 0 auto"
                src="../../assets/bg/default.png"
              />

              <div
                class="statusRight"
                :class="[
                  { status1: item.state == 1, status2: item.state != 1 },
                ]"
              >
                {{ item.stateCN }}
              </div>
            </div>
            <div class="">
              <div
                class="title"
                style="padding: 10px 10px 0 10px; position: relative"
              >
                <div class="blod">{{ item.labelName }}</div>
                <div class="title-border"></div>
                <div class="ygpx">{{ item.labelSubName }}</div>
                <div class="title-time" style="position: absolute; right: 15px">
                  {{ item.createTime.slice(5, 16) }}
                </div>
              </div>
              <div class="flex-bot">
                <div class="flex-bot-left">
                  责任人：{{
                    item.responsible == null || item.responsible == ""
                      ? "--"
                      : item.responsible
                  }}
                </div>
                <div class="flex-bot-right">
                  提报人：{{ item.createPerson }}
                </div>
              </div>
            </div>
            <!-- <div class="desc" style="height: 45px;">
								{{item.problemDesc==null?'这个家伙很懒，没有对问题进行描述':item.problemDesc}}
								<div class="title">
									<div class="blod">{{item.labelName}}</div>
									<div class="title-border"></div>
									<div>{{item.labelSubName}}</div>
								</div>
							</div> -->
            <!-- <div class="msg">
								<div class="ellipsis">
									<icon-location />
									{{item.structureName}}
								</div>
								<div class="flex">
									<img class="icon-time" src="../../assets/icon/biao.png" />
									<div class="ellipsis">{{item.createTime}}</div>
								</div>
							</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { getSelect, getReportCensus, reportPage } from "../../api/exception.js";
import { getStructure } from "../../api/index.js";
export default {
  setup() {
    return {
      dayjs,
    };
  },
  data() {
    return {
      form: {},
      factory: [], //车间列表
      factoryId: 0, //当前选择工厂的id
      list: [],
      fold: false,
      visible: false,
      datetime: [],
      selectList: [],
      label: [],
      current: 1, //页码
      color: [
        "#3F7EF8",
        "#A5A8FF",
        "#32F0E5",
        "#FFBB37",
        "#61C6FF",
        "#F1D94E",
        "#A2BBD7",
        "#00D1BE",
        "#F8DFF9",
        "#AFB6FB",
      ], //饼状图颜色
      replyRate: "",
      status2: null,
      pages: 0,
      myChart: null,
      summary: null,
      type: false, //1写提报，2详情
      screenHeight: document.documentElement.clientHeight, //获取屏幕高度
    };
  },
  beforeRouteLeave: function () {
    clearInterval(this.status2);
  },
  // beforeRouteEnter(to, from, next) {
  // 	next(vm => { //  这里的vm指的就是vue实例，可以用来当做this使用
  // 		if (from.fullPath != '/' ) {
  // 			vm.$router.go(0);
  // 		}
  // 	})
  // },
  mounted: function () {
    var type = this.$route.query.type;
    var from = localStorage.getItem("form");
    var datatime = localStorage.getItem("datetime");
    if (type) {
      this.form = JSON.parse(from);
    }
    if (type) {
      this.datetime = JSON.parse(datatime);
    }
    this.getFactory(); //获取车间列表
    // this.getCherts()
    // var that = this
    // // 获取页面高度
    // window.screenHeight = document.documentElement.clientHeight
    // that.screenHeight = window.screenHeight;
    // 监听页面高度 watch有时会监听不到
    window.addEventListener(
      "resize",
      () => (this.screenHeight = document.documentElement.clientHeight),
      false
    );
    // window.onresize = () => {
    // 	return (() => {
    // 		console.log(1111111111)
    // 		// window.screenHeight = document.documentElement.clientHeight;
    // 		// that.screenHeight = window.screenHeight;
    // 		// console.log(window.screenHeight);
    // 	})();
    // };
  },

  methods: {
    onChange(e) {
      this.datetime = e;
      this.visible = false;
      this.getList();
    },
    scrollEvent(e) {
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight ==
          e.srcElement.scrollHeight &&
        this.current != this.pages
      ) {
        this.current++;
        this.getList();
      }
      // if(e.srcElement.scrollTop+e.srcElement.offsetHeight>e.srcElement.scrollHeight-(this.fold?this.screenHeight-210:this.screenHeight-119)){
      //   console.log("啦啦啦啦啦触发了")
      //   // this.active=this.active+1;
      //   //加载更多
      // }
      // 		var a = this.fold?this.screenHeight-210:this.screenHeight-119
      // console.log(a)
      // console.log(e.srcElement.scrollTop)
      // console.log(e.srcElement.offsetHeight)
      // console.log(e.srcElement.scrollHeight)
    },
    change() {
      this.current = 1;
      this.pages = 0;
      this.list = [];
      this.getList();
      this.getSelectList();
      this.getCherts();
    },
    // 获取车间信息
    getFactory() {
      getStructure({
        type: 1,
      }).then((res) => {
        this.factory = res.data;
        this.factoryId = res.data[0].structureId;
        this.getList();
        this.getSelectList();
        this.getCherts();
        // this.getList()
      });
    },
    getList() {
      var data1 = this.form;
      var data = JSON.stringify(data1);
      data = JSON.parse(data);
      data.labelId = data.labelId == 0 ? null : data.labelId;
      data.state = data.state == 0 ? null : data.state;
      data.structureId = data.structureId == 0 ? null : data.structureId;
      data.startTime = this.datetime[0];
      data.endTime = this.datetime[1];
      (data.factoryId = this.factoryId), (data.current = this.current);
      reportPage(data).then((res) => {
        this.list = this.list.concat(res.data.records);
        this.pages = res.data.pages;
      });
    },
    getSelectList() {
      getSelect({
        factoryId: this.factoryId,
      }).then((res) => {
        this.selectList = res.data;
      });
    },
    goDetail(rid) {
      localStorage.setItem("form", JSON.stringify(this.form));
      localStorage.setItem("datetime", JSON.stringify(this.datetime));
      this.$router.push({
        path: "/dashBord/workBench/reportFormDetail",
        query: {
          rid: rid,
        },
      });
    },
    // echarts
    getCherts() {
      getReportCensus({
        factoryId: this.factoryId,
      }).then((res) => {
        this.replyRate = res.data.replyRate;
        this.label = res.data.label;
        var label = res.data.label;
        var structure = res.data.structure;
        var name = [],
          value = [],
          indicator = [],
          max = 0,
          maxList = [],
          bgList = [];
        // data = []
        for (let i = 0; i < structure.length; i++) {
          if (i == 0) {
            max = structure[i].amount;
          } else {
            if (structure[i].amount > max) {
              max = structure[i].amount;
            }
          }
          name.push(structure[i].StructureName);
          value.push(structure[i].amount);
        }
        for (let i = 0; i < label.length; i++) {
          indicator.push({
            name: label[i].labelName,
            value: label[i].amount,
          });
          // data.push(label[i].amount)
        }
        for (let i = 0; i < structure.length; i++) {
          maxList.push(max);
          bgList.push(100);
        }
        console.log("indicator");
        console.log(name);
        console.log(value);
        console.log(structure);
        // 柱状图
        var myChart = this.myChart;
        if (this.myChart != null) {
          myChart.dispose();
          this.myChart.dispose();
        }
        myChart = this.$echarts.init(document.getElementById("proportion1"));
        var option;

        option = {
          grid: {
            top: 10,
            left: 10,
            right: 10,
            bottom: 15,
            containLabel: true,
          },
          xAxis: {
            // type: 'category',
            data: name,
            axisLabel: {
              interval: 0,
              rotate: 30,
              formatter: function (value, index) {
                console.log(value);
                if (index % 2 != 0) {
                  return value;
                } else {
                  return value;
                }
              },
              textStyle: {
                color: "#00000030",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
              //x轴刻度相关设置
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#00000030",
              },
            },
          },
          dataZoom: {
            // show: true,
            // realtime: true,
            type: "inside",
            start: 0,
            end: value.length > 5 ? parseInt(10000 / (value.length * 20)) : 100,
            zoomLock: true,
          },
          series: [
            {
              data: value,
              type: "bar",
              barWidth: "20",
              showBackground: false,
              backgroundStyle: {
                color: "#EBEEF6",
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: "{c}" + "%",
                    show: true,
                    position: "top",
                    textStyle: {
                      //数值样式
                      color: "#2189F3",
                      fontSize: 12,
                    },
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 1,
                      color: "#01D1FF",
                    },
                    {
                      offset: 0,
                      color: "#2E9EF8",
                    },
                  ]),
                  opacity: 1,
                  barBorderRadius: [15, 15, 0, 0],
                },
              },
            },
            {
              data: maxList,
              name: "背景",
              type: "bar",
              barWidth: "20",
              barGap: "-100%",
              itemStyle: {
                normal: {
                  color: "#C4C4C410",
                },
              },
              zlevel: 9,
            },
            {
              data: bgList,
              name: "背景",
              type: "bar",
              barWidth: "20",
              barGap: "-100%",
              itemStyle: {
                normal: {
                  color: "#FFFFFF00",
                },
              },
              zlevel: 9,
            },
          ],
        };
        console.log(option);
        option && myChart.setOption(option);

        document
          .getElementById("proportion1")
          .setAttribute("_echarts_instance_", "");
        this.myChart = myChart;
        // setTimeout(function() {
        // 	window.onresize = function() {
        // 		this.myChart.resize();
        // 	}
        // }, 200)
        // 雷达图
        var summary = this.summary;
        if (this.summary != null) {
          summary.dispose();
          this.summary.dispose();
        }
        summary = this.$echarts.init(document.getElementById("summary1"));
        var option1;
        option1 = {
          tooltip: {
            trigger: "item",
            backgroundColor: "#F9F8FB00",
            borderColor: "#FFFFFF00",
            position: ["27%", "36%"],
            color: "#FFFFFF",
            extraCssText: "box-shadow: 0 0 0 #FFFFFF00;", // 额外附加到浮层的 css 样式
            formatter: function (param) {
              //自定义tooltip内容
              var text = "";
              text +=
                '<div style="font-size: 14px;color: #4E5969;text-align:center;width:100px;">' +
                param.name +
                '</div><div style="font-size: 16px;color: #1D2129;font-weight:700;text-align:center;">' +
                param.percent +
                "%</div>";
              return text;
            },
          },
          legend: {
            show: false,
          },
          color: this.color,
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["60%", "75%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: false,
                },
              },
              labelLine: {
                show: false,
              },
              data: indicator,
            },
          ],
        };
        var index = 0;
        setTimeout(() => {
          //高亮当前图形
          summary.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
          //显示tooltip
          summary.dispatchAction({
            type: "showTip",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }, 500);
        this.status2 = setInterval(() => {
          var dataLen = option1.series[0].data.length;
          //取消之前高亮的图形

          if (index + 1 == dataLen) {
            index = 0;
          } else {
            index++;
          }
          summary.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: index == 0 ? dataLen - 1 : index - 1,
          });
          //高亮当前图形
          summary.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: index,
          });
          //显示tooltip
          summary.dispatchAction({
            type: "showTip",
            seriesIndex: 0,
            dataIndex: index,
          });
        }, 5000);
        option1 && summary.setOption(option1);
        document
          .getElementById("summary1")
          .setAttribute("_echarts_instance_", "");
        this.summary = summary;
        window.addEventListener("resize", function () {
          summary.resize();
        });
      });
    },
    setCharts1() {},
  },
};
</script>
<style scoped lang="less">
@import url("../../styles/exceptionReportFrom.less");
@import url("../../styles/all.less");

.search {
  position: relative;
}
</style>


<style scoped lang="scss">
.page-content {
  .content-left {
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #4e5969;
      padding-bottom: 2px !important;

      .bfb {
      }
    }
    .bto {
      font-size: 11px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #050505;
      text-align: right;
      padding: 0 12px;
      opacity: 0.32;
    }
  }
  .left-bottom {
    height: calc(50% - 10px) !important;
    .title {
      font-size: 16px !important;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN !important;
      font-weight: 500 !important;
      color: #4e5969;
	  
    }
    .legend-list {
		margin-top: 15px;
		padding: 0 6px;
		.legend-icon{
			margin-right: 6px;
		}
    }
    .legend {
      width: calc(50% - 27px);

      padding: 5px 14px 5px 7px;
      .legend-name {
        font-family: PingFang SC-Regular, PingFang SC !important;
        color: #5a5a5a;
      }
      .count {
        font-family: Roboto-Regular, Roboto !important;
        font-weight: 400;
        color: #2189f3;
      }
    }
  }
  .scrollbar{
    justify-content:flex-start !important;
	  padding:1px 0px 1px 11px;
    .card{
      margin-right: 11px;
    }
  }
  .card {
	  padding: 8px;
	  width: 275px;
	  .title{
		  margin-top: 10px;
	  }
    .blod {
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN !important;
      font-weight: 500 !important;
      color: #000000;
    }
    .statusRight {
      padding: 3px 8px;
    }
    .flex-bot {
      .flex-bot-left {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #7f90a8;
      }
    }
    .title-border {
      // border: none;
      // width: 1px;
      border-left: 2px solid #00000050;
      // background: rgba(0, 0, 0, 0.5);
      margin-left: 10px;
      height: 17px !important;
    }
    .title-time {
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #4e5969;
    }
    .ygpx {
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
    }
    .title {
      align-items: flex-end;
    }
  }
}
</style>
