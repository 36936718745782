<template>
	<!-- 标签部分 -->
	<a-layout class="layout-content">
		<!-- <div style="height:1px"></div>
		<div class="bg">

			背景渐变色
			<div class="right-BG" v-if="type==2"></div>
			<div class="bottom-BG" v-if="type==2"></div>
			<div class="cover-BG" v-if="type==2"></div>

			主体 -->
			<!-- <a-layout-content class="content-box" style=""> -->
				<keep-alive>
					<router-view ref="children" :key="$route.fullpath" v-if="$route.meta.keepAlive"></router-view>
				</keep-alive>
				<router-view ref="children" :key="$route.fullpath" v-if="!$route.meta.keepAlive"></router-view>
			<!-- </a-layout-content> -->
	<!-- 		底部
		</div> -->
		
	</a-layout>
	<!-- <router-view ref="children"></router-view> -->
</template>
<script>
	import {
		getMinPermission,
		logout
	} from '../../api/index.js'

	import massageCard from '../../common/massageCard.vue'

	export default {
		components: {
			massageCard,
		},
		data() {
			return {
				type: 1, //判断背景颜色
				userinfo: {}, //当前登录的用户信息
				children: [], //所有模块
				key: "", //标签高亮的key
				data: [], //标签列表
			};
		},
		watch: {
			//监听路由变化
			// $route(path) {
			// 	this.key = path.meta.key;
			// 	if (path.meta.key != null) {
			// 		localStorage.setItem('key', path.meta.key)
			// 		localStorage.setItem('type', path.meta.type)
			// 		this.type = path.meta.type
			// 		var a = false; //判断当前路由是否加入到标签里面
			// 		for (let i = 0; i < this.data.length; i++) {
			// 			if (path.meta.key == this.data[i].key) {
			// 				a = true;
			// 			}
			// 		}
			// 		if (a == false) {
			// 			var arr = path.meta
			// 			arr.fullPath = path.fullPath
			// 			this.data.push(arr);
			// 			localStorage.setItem('tabs', JSON.stringify(this.data))
			// 			console.log('tabs')
			// 			console.log(this.data)
			// 		}
			// 	}

			// },
		},
		mounted() {
			// 存储标签列表
			// var data = localStorage.getItem('tabs')
			// this.key = localStorage.getItem('key')
			// // this.type = localStorage.getItem('type')
			// console.log('tabs')
			// console.log(data)
			// // 未打开任何页面时默认打开工作台
			// if (data == null || data == '') {
			// 	this.data.push({
			// 		title: '工作台',
			// 		icon: 'logo.png',
			// 		type: 1,
			// 		key: '01',
			// 		fullPath: '/dashBord/workBench/index'
			// 	});
			// 	localStorage.setItem('tabs', JSON.stringify(this.data))
			// } else {
			// 	this.data = JSON.parse(data)
			// }
			// this.type = this.$route.meta.type
			// var menuList = localStorage.getItem('menuList')
			// console.log(menuList)
			// if (menuList == null) {
			// 	this.getMenu()
			// } else {
			// 	this.menuList = JSON.parse(menuList)
			// 	this.children = this.menuList[0].children
			// }
			// var userinfo = JSON.parse(localStorage.getItem('userinfo'))
			// console.log(userinfo.nickname.substring(userinfo.nickname.length - 2))
			// userinfo.nickname1 = userinfo.nickname.substring(userinfo.nickname.length - 2)
			// this.userinfo = userinfo
		},
		methods: {
			// 搜索出来的列表点击跳转页面
			goNewPage(url, type) {
				console.log(url)
				console.log(url != null)
				if (type != -1 && url != null) {
					this.$router.push('/' + url);
				}
			},
			// 退出登录
			layout() {
				var data = {
					name: '提示',
					msg: '是否退出登录？',
					type: 'Warning'
				}

				this.$refs.massageCard.handleClick(data);
			},
			// 清除缓存，没有一键清除是因为列配置不能清除
			handleOk() {
				logout().then(res => {
					this.$router.replace('/')
					localStorage.removeItem('token');
					localStorage.removeItem('userinfo');
					localStorage.removeItem('menuList');
					localStorage.removeItem('oftenList');
					localStorage.removeItem('key');
					localStorage.removeItem('tabs');
					localStorage.removeItem('type');
				})
			},
			// 获取全部模块（最上方搜索用到）
			getMenu() {
				getMinPermission().then(res => {
					var all = []
					for (let i = 0; i < res.data.length; i++) {
						all = all.concat(res.data[i].children)
					}
					this.children = all
				})
			},
			// 切换 消息/工作台/我的，因为其他两个模块没有所以强制跳转工作台
			onClickMenuItem(e) {
				this.$router.push('/dashBord/workBench');
			},
			// 点击标签跳转页面
			goPage(e) {
				this.data.forEach(element => {
					console.log(element.fullPath != null)
					if (element.key == e && element.fullPath != null) {
						this.$router.push(element.fullPath);
						// window.location.href = "#" +element.fullPath
					}
				});
			},
			// 删除标签，默认打开前一个标签的页面
			handleDelete(key) {
				this.data = this.data.filter((item) => item.key !== key)
				localStorage.setItem('tabs', JSON.stringify(this.data))
				let push = this.data[this.data.length - 1].fullPath
				this.$router.push(push)
			},
		},
	};
</script>
<style>


	.arco-menu-item-tooltip {
		display: none !important;
	}
</style>
<style scoped lang="less">
	@import url("../../styles/dashBord.less");

	.menu-icon {
		height: 26px !important;
		// font-size: 20px!important;
		margin-left: 2px;
	}

	/deep/ .layout-box::-webkit-scrollbar {
		width: 0;
	}

	/deep/ .arco-avatar-text {
		font-size: 14px !important;
	}

	.tabs-list .iconfont {
		color: #2189F3 !important;
		margin-right: 10px;
	}

	/deep/ .arco-tabs-tab {
		color: #1F1F1F80 !important;
	}

	/deep/ .arco-tabs-tab-active {
		color: #1F1F1F !important;
	}
</style>
<style scoped>
	.home-icon-pinglun:before {
		width: 24px !important;
	}

	.tab-icon {
		height: 20px;
		margin-right: 10px;
		margin-bottom: 6px;
		position: relative;
		bottom: -4px;

	}
</style>
