//引用第一步创建的axios.js文件
import request from '../axios.js'
// 获取右侧
export function getWorkmanship(params) {
  return request({
    url: 'minapp/workmanship/getWorkmanship',
    method: 'get',
    params
  })
}
// 获取浏览记录
export function getHistoryList(params) {
  return request({
    url: 'minapp/workmanship/viewHistory',
    method: 'get',
    params
  })
}
// 获取左侧
export function getProduct(params) {
    return request({
      url: 'minapp/workmanship/getProduct',
      method: 'get',
      params
    })
  }
// 查看文件
export function openFiles(data) {
  return request({
    url: 'minapp/workmanship/viewHistory/save',
    method: 'post',
    data:data
  })
}
