<template>
  <div class="list_box">
    <div class="list_header" >
        <div class="checkbox" v-if="checkbox"> <a-checkbox v-model="checkedAll" @change="AllCheck"></a-checkbox></div>
        <div class="list_header_box"  v-for="(item,index) in headerData" :key='index' :style="{width:item.width,display:item.show ? '' : 'none'}" >
            <div :style="{color:textColor(index)}" >{{item.lable}}</div>
            <div v-if="item.filter" style="display:flex;align-items: center;margin-left:10px">
                <img style="width: 15px;height: 15px;"  src="../assets/icon/sx1.png" v-if="item.filterIcon" @click="changeIcon(index,1)"/>
                <img style="width: 15px;height: 15px;" src="../assets/icon/sx2.png" v-else @click="changeIcon(index,2)"/>
            </div>
            <div class="filter_box" v-if="!item.filterIcon">
                <div class="" style="max-height: 150px;  overflow: auto;">
                    <div class="filter_cont" v-for="(i,k) in item.filterList" :key='k'><a-checkbox v-model="i.checked"  @change="changeChecked(index)">{{i.value}}</a-checkbox></div>
                </div>
                <div class="filter_box_bot">
                    <div @click="reset(index)">重置</div>
                    <div @click="sbmint(index,item.param)">确定</div>
                </div>
            </div>
        </div>
    </div>
    <div class="list_content" :style="{height:tabHight + 'px'}">
        <div v-for="(item,index) in content" :key='index' style="display:flex;padding:0 12px" :class="border ? 'border' : '' "  :style="{background:bg(index,item.state)}">
            <div class="checkbox" v-if="checkbox">
                <a-checkbox  v-model="item.checked" @change="changeCheckBox($event,index)"></a-checkbox>
            </div>
            <div v-for="(i,k) in headerData" :key='k' :style="{width:i.width,display:i.show ? '' : 'none'}" class="content_box" >
                <span v-if="i.param == 'index'">{{index + 1}}</span>
                <span v-if="i.param !== 'index' && i.param !== 'completeState' ">{{item[i.param]}}</span>
                <div  v-if="i.param == 'completeState'" :class="item[i.param] == 1 ? 'yes' : 'no'  " ></div>
                <span v-if="i.param == 'but'" style="color:#2189F3" @click="butClick(item.orderId,item)">查看</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'list',
    props:{
        headerData:{
            type:Array
        },
        content:{
            type:Array
        },
        tabHight:{
            type:Number
        },
        oldList:{
            type:Array
        },
        border:{
            type:Boolean,
            default:false
        },
        type:{
            type:Number,
            default:1
        },
        checkbox:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            checkedAll:false
        }
    },
    methods: {
        textColor(index) {
            for (const item of this.headerData[index].filterList) {
                if(item.checked) {
                    return 'rgb(33, 137, 243)'
                }
            }
        },
        changeIcon(index,type) {
            if(type == 1) {
                for (const item of this.headerData) {
                    item.filterIcon = true
                }
            }
            this.headerData[index].filterIcon = !this.headerData[index].filterIcon
        },
        filterFun(name){
            let arr = []
            for (const item of this.content) {
                arr.push(item[name])
            }
            arr= new Set(arr);
            let newArr = []
            arr.forEach(ite=>{newArr.push({checked:false,value:ite})})
            return newArr
        },
        changeChecked(e,i) {
           console.log(this.headerData[e],'123')
        },
        bg(num,state) {
            if(this.type == 1) {
                if(num%2 == 0) {
                    return '#fff'
                }
            }else{
                if(state == 1) {
                    return '#F0FEF9'
                }else{
                    return '#FFF1E4'
                }
            }
        },
        sbmint(index,name) {
            let arr = []
            for (const item of this.headerData[index].filterList) {
                if(item.checked) {
                    for (const i of this.content) {
                        if(i[name] == item.value) {
                            arr.push(i)
                        }
                    }
                }
            }
            if(arr.length == 0) {
                arr = this.oldList
            }
            this.$emit('changeList',arr)
            this.headerData[index].filterIcon = !this.headerData[index].filterIcon
        },
        butClick(orderId,item) {
            
            if(this.type == 1) {
                this.$emit('butClick',orderId)
            }else{
                this.$emit('butClick',item)
            }
        },
        changeCheckBox(e,index) {
            this.content[index].checked = e
            this.$emit('changeList',this.content)
            let arr = []
            for (const item of this.content) {
                if(item.checked) {
                    arr.push(item)
                }
            }
            this.$emit('selectOne',arr)
        },
        reset(index) {
            for (const item of this.headerData[index].filterList) {
                item.checked = false
            }
        },
        AllCheck(e) {
            for (const item of this.content) {
                item.checked = e
            }
            this.$emit('changeList',this.content)
            let arr = []
            for (const item of this.content) {
                if(item.checked) {
                    arr.push(item)
                }
            }
            this.$emit('selectOne',arr)
        }
    }
}
</script>
<style lang="scss" scoped>
.list_box{
    width: 100%;
    background: #fff;
    .checkbox{
        display: flex;
        align-items: center;
        width: 50px;
        justify-content: center;
    }
    .list_header{
        display: flex;
        align-items: center;
        padding: 0 12px;
        
        .list_header_box{
            position: relative;
            height: 56px;
            background: #fff;
            font-size: 16px;
            font-weight: 500;
            color: #4E5969;
            display: flex;
            align-items: center;
            .filter_box{
                width: 100%;
                min-width: 100px;
                background: #fff;
                border-radius: var(--border-radius-medium);
                box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
                position: absolute;
                top: 50px;
                font-size: 13px;
                padding: 0 12px;
                max-height: 200px;
                overflow: auto;
                z-index: 10;
            
                .filter_cont{
                    height: 35px;
                    line-height: 35px;
                }
                .filter_box_bot{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    background: #fff;

                    div{
                        width: 50%;
                        text-align: center;
                        height: 35px;
                        line-height: 35px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .list_content{
        background: #FAFAFB;
        
        overflow: auto;
        .content_box{
            line-height: 53px;
            height: 53px;
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.8);
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
            display: flex;
            align-items: center;
            .list_state1{
                width: 16px;
                height: 16px;
                background: linear-gradient(47deg, #00D1BE 0%, #2EF867 100%);
                box-shadow: 1px 1px 4px 1px rgba(13, 220, 164, 0.4);
                border-radius: 50%;
            }
            .list-state2{
                width: 16px;
                height: 16px;
                background: linear-gradient(47deg, #FFA834 0%, #FF8C16 100%);
                box-shadow: 1px 1px 4px 1px #FF9823;
                opacity: 1;
                border-radius: 50%;
            }
        }
    }
}
.border{
    border-bottom:1px solid #e5e5e5
}
.yes{
    width: 16px;
    height: 16px;
    background: linear-gradient(47deg, #00D1BE 0%, #2EF867 100%);
    box-shadow: 1px 1px 4px 1px rgba(13, 220, 164, 0.4);
    border-radius: 50%;
}
.no{
    width: 16px;
    height: 16px;
    background: linear-gradient(47deg, #FFA834 0%, #FF8C16 100%);
    box-shadow: 1px 1px 4px 1px #FF9823;
    border-radius: 50%;
}
</style>

