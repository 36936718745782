<template>
  <div
    class="page"
    style="
      padding-bottom: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;
    "
    :style="{ height: screenHeight + 'px' }"
  >
    <div class="per_header">
      产能驾驶舱
      <div class="per_header_box">
        <div class="per_header_boxs" v-for="item in factory" :key="item">
          {{ item.structureName }}
        </div>
      </div>
    </div>

    <div class="capacity-content" :style="{ height: screenHeight - 85 + 'px' }">
      <div class="capacity-left">
        <div class="left-top">
          <div class="top-title">产能趋势</div>
          <div
            class="chart"
            id="histogram1"
            style="width: 100%; height: 90%"
          ></div>
        </div>
        <div class="left-right" style="height:calc(50% - 10px)">
          <div class="right-title">人均产能趋势</div>
          <div class="chart" id="aveHistogram" style="height: 90%"></div>
        </div>
      </div>
      <div class="capacity-right" style="height:100%">
        <calender ref="calender1" :factoryId="factoryId" class="calender card">
        </calender>
      </div>
    </div>

    <div class="capacity" v-show="false">
      <div class="capacity-left">
        <div class="charts">
          <div class="title">
            <b>产能趋势</b>
            <a-select
              v-model="shopworkId"
              placeholder="电车车间"
              @change="getCapacity()"
            >
              <a-option
                :value="item.workshopId"
                v-for="(item, index) in list"
                :key="index"
                :style="{ display: index == 0 ? 'none' : '' }"
                >{{ item.workshopName }}</a-option
              >
            </a-select>
            <icon-caret-down class="icon" />
            <div class="right flex">
              <div
                class="dateType pointer"
                :class="{ active: capacityDate == 0 }"
                @click="(capacityDate = 0), getCapacity()"
              >
                周
              </div>
              <div
                class="dateType pointer"
                :class="{ active: capacityDate == 1 }"
                @click="(capacityDate = 1), getCapacity()"
              >
                月
              </div>
            </div>
          </div>
          <div class="chart" :class="{ overflow: capacityDate == 1 }">
            <!-- <div class="chart" id="histogram1" style="width: 100%;"></div> -->
          </div>
        </div>
        <div class="charts">
          <div class="title">
            <b>人均产能趋势</b>
            <a-select
              v-model="shopworkId"
              placeholder="电车车间"
              @change="getAveCapacity()"
            >
              <a-option
                :value="item.workshopId"
                v-for="(item, index) in list"
                :key="index"
                :style="{ display: index == 0 ? 'none' : '' }"
                >{{ item.workshopName }}</a-option
              >
            </a-select>
            <icon-caret-down class="icon icon1" />
            <div class="right flex">
              <div
                class="dateType pointer"
                :class="{ active: aveCapacityDate == 0 }"
                @click="(aveCapacityDate = 0), getAveCapacity()"
              >
                周
              </div>
              <div
                class="dateType pointer"
                :class="{ active: aveCapacityDate == 1 }"
                @click="(aveCapacityDate = 1), getAveCapacity()"
              >
                月
              </div>
            </div>
          </div>
          <div class="chart" :class="{ overflow: aveCapacityDate == 1 }">
            <!-- <div class="chart" id="aveHistogram"></div> -->
          </div>
        </div>
      </div>

      <div class="charts pc" style="overflow-x: hidden">
        <calender
          ref="calender1"
          :factoryId="factoryId"
          @pickPre="pickPre"
          @pickNext="pickNext"
          @getToday="getToday"
          class="card"
        >
        </calender>
      </div>

      <div
        class="charts flex calenderList"
        style="
          position: relative;
          overflow-x: hidden;
          background-color: #ffffff00;
          margin-bottom: 26px;
          box-shadow: none;
        "
        :style="{ left: moveSize + 'px' }"
        @touchstart="touchstartHandle"
        @touchmove="touchmoveHandle"
        @touchend="touchendHandle"
      >
        <!-- <calender ref="calender2" style="margin: -50px 16px 0 0;background-color: #FFFFFF00;" :factoryId="factoryId"
					class="calender card">
				</calender>
				<calender ref="calender" style="margin: -50px 16px 0 0;background-color: #FFFFFF00;" @pickPre="pickPre" @pickNext="pickNext" @getToday="getToday"
					:factoryId="factoryId" class="calender card">
				</calender> -->
        <calender
          ref="calender3"
          style="margin: -50px 16px 0 0; background-color: #ffffff00"
          :factoryId="factoryId"
          class="calender card"
        >
        </calender>
      </div>
      <div
        class="mobile"
        style="box-shadow: 11px 12px 15px 1px rgb(51 107 239 / 16%) !important"
      ></div>
    </div>
  </div>
</template>

<script>
import { getStructure } from "../../api/index.js";
import {
  capacityTrend,
  personAvgTrend,
  capacityDetail,
} from "../../api/capacity.js";
import { workshopInformation } from "../../api/personnel.js";
import calender from "../../common/calender.vue";
export default {
  components: {
    calender,
  },
  data() {
    return {
      screenWidth: document.body.clientWidth, //获取屏幕宽度
      screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
      factoryId: 0, //当前选择工厂的id
      factory: [], //工厂列表
      capacityDate: 0, //产能趋势，1周，2月
      aveCapacityDate: 0, //人均趋势，1周，2月
      shopworkId: "",
      checkName: "",
      fold: false,
      avg: [],
      status1: null,
      status2: null,
      list: [],
      histogram: null, //柱状图
      aveHistogram: null,
      chart1: [],
      start: 0,
      moveSize: 0,
      histogram1: false,
      startTime: "",
      phone: true,
    };
  },
  beforeRouteLeave: function () {
    clearInterval(this.status1);
    clearInterval(this.status2);
  },
  mounted: function () {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (this.flag == null) {
      this.$refs.calender1.getList();
    } else {
      this.$refs.calender.getList();
      this.$refs.calender2.getList();
      this.$refs.calender3.getList();
    }
    this.getFactory();

    window.onresize = () => {
      return (() => {
        window.screenHeight = document.documentElement.clientHeight;
        this.screenHeight = window.screenHeight;
      })();
    };
    var index = 0;
    var that = this;
    // that.getCharts2(that.avg)
    // that.getCharts1(that.chart1)
    that.status1 = setInterval(function () {
      // var length = that.avg.length
      // if(index==length){
      // 	index = 0
      // 	that.checkName = that.avg[index].time
      // }else{
      // 	index++
      // 	that.checkName = that.avg[index].time
      // }
      // that.getCharts2(that.avg)
      // that.getCharts1(that.chart1)
    }, 5000); //30s保存一次
  },
  // beforeRouteEnter(to, from, next) {
  // 	next(vm => { //  这里的vm指的就是vue实例，可以用来当做this使用
  // 		if (from.fullPath != '/' ) {
  // 			vm.$router.go(0);
  // 		}
  // 	})
  // },
  methods: {
    getToday() {
      this.$refs.calender.getToday();
      this.$refs.calender1.getToday();
    },
    pickPre() {
      this.$refs.calender.pickPre();
      this.$refs.calender1.pickPre();
    },
    pickNext() {
      this.$refs.calender.pickNext();
      this.$refs.calender1.pickNext();
    },
    touchstartHandle(e) {
      this.startTime = new Date().getTime();
      this.start = e.changedTouches[0].clientX;
    },
    touchmoveHandle(e) {
      var endTime = new Date().getTime();
      console.log(endTime);
      console.log(this.startTime);
      console.log(endTime - this.startTime);
      console.log("-------------------------------");
      if (endTime - this.startTime > 500) {
        var end = e.changedTouches[0].clientX;
        var move = end - this.start;
        this.moveSize = move;
      }
    },
    touchendHandle(e) {
      console.log("touchendHandle");
      console.log(this.moveSize);
      var end = e.changedTouches[0].clientX;
      if (this.moveSize != 0) {
        if (end - this.start > 100) {
          this.$refs.calender.changeDate(1);
          this.$refs.calender1.changeDate(1);
        }
        if (end - this.start < -100) {
          this.$refs.calender.changeDate(2);
          this.$refs.calender1.changeDate(2);
        }
      }
      this.moveSize = 0;
    },
    // 获取工厂信息
    getFactory() {
      getStructure({
        type: 7,
      }).then((res) => {
        console.log(res);
        this.factory = res.data;
        this.factoryId = res.data[0].structureId;
        console.log(this.factoryId);
        this.getWorkshop();
      });
    },

    // 获取各车间人员在线离线
    getWorkshop() {
      workshopInformation({
        factoryId: this.factoryId,
      }).then((res) => {
        var list = res.data;
        this.list = list;
        this.shopworkId = list[1].workshopId;
        this.getCapacity();
        this.getAveCapacity();
      });
    },
    // 车间产能趋势
    getCapacity() {
      capacityTrend({
        shopworkId: this.shopworkId,
        dataType: this.capacityDate,
      }).then((res) => {
        console.log(res);
        this.chart1 = res.data;
        this.getCharts1(res.data);
      });
    },
    // 车间人均产能趋势
    getAveCapacity() {
      personAvgTrend({
        shopworkId: this.shopworkId,
        dataType: this.aveCapacityDate,
      }).then((res) => {
        console.log(res);
        this.avg = res.data;
        this.checkName = this.avg[0].time;
        clearInterval(this.status2);
        this.getCharts2(res.data);
      });
    },
    getCharts1(arr) {
      var histogram = this.histogram;
      console.log(histogram);
      if (this.histogram != null) {
        histogram.dispose();
      }
      histogram = this.$echarts.init(document.getElementById("histogram1"));
      var time = [],
        value = [];
      for (let i = 0; i < arr.length; i++) {
        time.push(arr[i].time);
        value.push(arr[i].value);
      }
      var that = this;
      var option = {
        title: {
          show: false,
        },
        grid: {
          top: 20,
          left: 10,
          right: 30,
          bottom: 10,
          containLabel: true,
        },
        xAxis: {
          data: time,
          // data: ['02-02','02-03','02-04'],
          type: "value",
          axisLabel: {
            interval: 0,
            rotate: 30,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
          },
        },
        yAxis: {
          type: "category",
        },
        dataZoom: {
          // show: true,
          // realtime: true,
          type: "inside",
          start: 0,
          end: arr.length > 10 ? 20 : 100,
          zoomLock: true,
        },
        series: [
          {
            name: "产能趋势",
            type: "bar",
            data: value,
            // data: [5,2,4],
            barWidth: 10,
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            itemStyle: {
              normal: {
                barBorderRadius: [0, 10, 10, 0],
                color: that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 1,
                    color: "#03C9FD",
                  },
                  {
                    offset: 0,
                    color: "#0A93FB",
                  },
                ]),
                label: {
                  show: true,
                  formatter: "{c}",
                  position: "right",
                  textStyle: {
                    //数值样式
                    color: "#2189F3",
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      };

      document
        .getElementById("histogram1")
        .setAttribute("_echarts_instance_", "");
      option && histogram.setOption(option);
      this.histogram1 = true;
      this.histogram = histogram;
      window.addEventListener("resize", function () {
        histogram.resize();
      });
    },
    getCharts2(arr) {
      // var checkName = this.checkName; //点击柱子的名字//添加在代码最上面
      var aveHistogram = this.aveHistogram;
      console.log(aveHistogram);
      if (this.aveHistogram != null) {
        aveHistogram.dispose();
      }
      aveHistogram = this.$echarts.init(
        document.getElementById("aveHistogram")
      );
      aveHistogram.on("mouseout", function (params) {
        console.log(2222);
        for (let i = 0; i < arr.length; i++) {
          aveHistogram.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: i,
          });
        }

        //点击的柱子的名称
        // checkName = params.name;
        //柱形图重构
        // option && aveHistogram.setOption(option)
      });
      var time = [],
        value = [],
        max = 0;
      for (let i = 0; i < arr.length; i++) {
        if (i == 0) {
          max = arr[i].value;
        } else {
          if (arr[i].value > max) {
            max = arr[i].value;
          }
        }
        time.push(arr[i].time);
        value.push(arr[i].value);
      }
      var maxList = [];
      for (let i = 0; i < arr.length; i++) {
        maxList.push(parseInt(max + 1));
      }
      // max =
      // console.log('max')
      // console.log(max)
      var that = this;
      var option = {
        title: {
          show: false,
        },
        grid: {
          top: 20,
          left: 10,
          right: 10,
          bottom: 10,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          extraCssText: "box-shadow: 0 0 0 #FFFFFF00;", // 额外附加到浮层的 css 样式
          backgroundColor: "#F9F8FB00",
          borderColor: "#FFFFFF00",
          formatter: function (param) {
            //自定义tooltip内容
            // console.log(param)
            var text = "";
            text +=
              '<div style="background: rgba(255, 255, 255, 0.2);box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.16);padding:12px 7px;border-radius: 20px">' +
              '<div style="font-size: 14px;font-weight: 500;color: #00000080;text-align:left;">' +
              param[0].name +
              '</div><div style="font-size: 16px;color: #53B8F9;margin-top:10px;text-align:left;">产能：' +
              param[1].value +
              "（套）</div></div>";
            return text;
          },
        },
        xAxis: {
          data: time,
          // data: ['02-02','02-03','02-04'],
          type: "category",
          axisLabel: {
            interval: 0,
            rotate: 30,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
          },
        },
        yAxis: {
          type: "value",
        },
        dataZoom: {
          // show: true,
          // realtime: true,

          type: "inside",
          start: 0,
          end: arr.length > 10 ? 20 : 100,
          zoomLock: true,
        },
        series: [
          {
            name: "产能趋势",
            type: "bar",
            data: maxList,
            // data: [5,2,4],
            barWidth: 70,
            itemStyle: {
              normal: {
                // showBackground: false,
                color: "#FFFFFF10",
              },
              emphasis: {
                shadowBlur: 1,
                shadowOffsetX: 0,
                shadowColor: "#1b8ff3",
              },
            },
          },
          {
            name: "产能",
            type: "line",
            smooth: 0.6,
            symbol: "none",
            lineStyle: {
              color: "#53B8F9",
              width: 5,
            },
            areaStyle: {
              color: "#FFFFFF00",
            },
            data: value,
          },
        ],
      };
      var dataLen = option.series[0].data.length;
      var index = 0;
      this.status2 = setInterval(() => {
        //取消之前高亮的图形
        if (index + 1 >= dataLen) {
          index = 0;
        } else {
          // console.log(2)
          index++;
          // console.log(1)
        }
        // app.currentIndex = (app.currentIndex + 1) % dataLen;
        for (let i = 0; i < arr.length; i++) {
          aveHistogram.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: i,
          });
        }
        //高亮当前图形
        aveHistogram.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: index,
        });
        //显示tooltip
        aveHistogram.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: index,
        });
      }, 5000);
      option && aveHistogram.setOption(option);
      document
        .getElementById("aveHistogram")
        .setAttribute("_echarts_instance_", "");
      this.aveHistogram = aveHistogram;
      window.addEventListener("resize", function () {
        aveHistogram.resize();
      });
    },
    // getCharts2(arr) {
    // 	var that = this
    // 	// var checkName = ''; //点击柱子的名字//添加在代码最上面
    // 	var myChart = this.myChart
    // 	// if (this.myChart != null) {
    // 	// 	myChart.dispose()
    // 	// }
    // 	var chartDom = document.getElementById('aveHistogram');
    // 	myChart = this.$echarts.init(chartDom);
    // 	// myChart.on('click', function(params) {
    // 	// 	//点击的柱子的名称
    // 	// 	checkName = params.name;
    // 	// 	//柱形图重构
    // 	// 	myChart.setOption(option);
    // 	// })
    // 	var option;
    // 	var time = [],
    // 		data = [],
    // 		value = []
    // 	for (let i = 0; i < arr.length; i++) {
    // 		time.push([arr[i].time, arr[i].value - 0.1 < 0.1 ? 0 : arr[i].value - 0.1])
    // 		value.push([arr[i].time, arr[i].value])
    // 		data.push(arr[i].time)
    // 	}
    // 	option = {
    // 		xAxis: {
    // 			type: 'category',
    // 			// boundaryGap: false,
    // 			data: data
    // 		},
    // 		yAxis: {
    // 			type: 'value',
    // 			// boundaryGap: [0, '80%']
    // 		},
    // 		grid: {
    // 			top: 50,
    // 			left: 10,
    // 			right: 10,
    // 			bottom: 15,
    // 			containLabel: true
    // 		},
    // 		tooltip: {
    // 			trigger: 'axis',
    // 			extraCssText: 'box-shadow: 0 0 0 #FFFFFF00;', // 额外附加到浮层的 css 样式
    // 			backgroundColor: '#F9F8FB00',
    // 			borderColor: '#FFFFFF00',
    // 			formatter: function(param) { //自定义tooltip内容
    // 				var text = '';
    // 				text +=
    // 					'<div style="background: rgba(255, 255, 255, 0.2);box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.16);padding:12px 7px;border-radius: 20px">' +
    // 					'<div style="font-size: 14px;font-weight: 500;color: #00000080;text-align:left;">' +
    // 					param[0].name +
    // 					'</div><div style="font-size: 16px;color: #53B8F9;margin-top:10px;text-align:left;">产能：' +
    // 					param[0].data[1] + '（套）</div></div>'
    // 				return text;
    // 			},
    // 		},
    // 		series: [
    // 			// 	{
    // 			// 	type: 'line',
    // 			// 	smooth: 0.6,
    // 			// 	symbol: 'none',
    // 			// 	lineStyle: {
    // 			// 		color: '#D3D8E480',
    // 			// 		width: 5,
    // 			// 	},
    // 			// 	areaStyle: {
    // 			// 		color: '#FFFFFF00'
    // 			// 	},
    // 			// 	data: time
    // 			// },
    // 			{
    // 				name: '产能',
    // 				type: 'line',
    // 				smooth: 0.6,
    // 				symbol: 'none',
    // 				lineStyle: {
    // 					color: '#53B8F9',
    // 					width: 5,
    // 				},
    // 				areaStyle: {
    // 					color: '#FFFFFF00'
    // 				},
    // 				data: value
    // 			}
    // 		]
    // 	};

    // 	option && myChart.setOption(option);
    // 	this.myChart = myChart
    // 	// window.addEventListener("resize",function(){
    // 	//     myChart.resize();
    // 	// })
    // },
  },
};
</script>

<style scoped lang="less">
@import url("../../styles/capacity.less");
@import url("../../styles/all.less");

.pc {
  @media (min-width: 1400px) {
    margin-bottom: 16px;
  }

  @media (max-width: 1400px) {
    width: 400px;
    background-color: #ffffff00;
    height: 36px;
    overflow: hidden;
    box-shadow: none !important;
    width: 100%;
    margin-left: -16px;
    padding-left: 32px;
    // z-index: 9;
  }
}

.mobile {
  position: absolute;
  z-index: -1;
  border-radius: 20px;
  // bottom: 8px;
  top: 440px;
  margin-top: 16px;

  @media (min-width: 1300px) and (max-width: 1400px) {
    width: 1260px;
    height: 925px;
    right: 10px;
  }

  @media (min-width: 1100px) and (max-width: 1300px) {
    width: 1170px;
    height: 925px;
    right: 16px;
  }

  @media (max-width: 1100px) {
    width: 915px;
    height: 925px;
    right: 16px;
  }
}
</style>

<style scoped lang="scss">
.per_header {
  font-size: 16px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN !important;
  font-weight: 600;
  color: #3b75fd;
}
.capacity-content {
  .top-title {
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN !important;
    font-weight: 600;
    color: #4e5969;
  }
  .right-title {
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #4e5969;
  }
}
</style>