//引用第一步创建的axios.js文件
import request from '../axios.js'
// 发送验证码
export function send(params) {
  return request({
    url: 'minapp/login/mobile/send/'+params,
    method: 'get'
  })
}
// 获取组织信息
export function getStructure(params) {
  return request({
    url: 'minapp/structure/getStructure',
    method: 'get',
    params
  })
}
// 组织小组获取人员
export function getPersonnel(params) {
  return request({
    url: 'minapp/personnel/getPersonnelByStructure',
    method: 'get',
    params
  })
}
//登录
export function login(params) {
  return request({
    url: 'auth/mobile/token/sms',
    method: 'post',
    data:params,
    params

  })          
}
// 获取工作台菜单
export function getMinPermission(params) {
  return request({
    url: 'minapp/permission/getMinPermission',
    method: 'get',
    params
  })
}
// 获取常用菜单
export function getOftenPermission(params) {
  return request({
    url: 'minapp/permission/getOftenPermission',
    method: 'get',
    params
  })
}
//添加常用菜单
export function addMenu(data) {
  return request({
    url: 'minapp/permission/saveOftenPermission',
    method: 'post',
    data:data
  })
}
//删除常用菜单
export function delMenu(data) {
  return request({
    url: 'minapp/permission/deleteOftenPermission',
    method: 'DELETE',
    data:data
  })
}
// 获取二维码
export function getQrcode(data) {
  return request({
    url: 'auth/token/qrcode/gen_login_token',
    method: 'post',
    data:data
  })
}
// 确认用户操作状态
export function getStatus(data) {
  return request({
    url: 'auth/token/qrcode/is_token_login',
    method: 'post',
    data:data
  })
}
// 二维码登录
export function qrcodeLogin(data) {
  return request({
    url: 'auth/mobile/token/QR?mobile=QR@'+ data.token +'&grant_type=mobile',
    method: 'post',
    data:{}
  })
}
// 退出登录
export function logout(data) {
  return request({
    url: 'auth/token/logout',
    method: 'delete',
    data:data
  })
}

// 获取工作台通知
export function notice(params) {
  return request({
    url: 'minapp/advert/list',
    method: 'get',
    params
  })
}
// 发送通知
export function noticeSend(data) {
  return request({
    url: 'minapp/notice/send',
    method: 'post',
    data:data
  })
}

