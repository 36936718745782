<template>
  <div class="page">
    <div class="msg" style="padding-bottom: 90px;height:400px;overflow-y: scroll;">
      <div class="list flex">
        <div class="name center">订单编号:</div>
        <div class="contant">{{ detail.orderNo }}</div>
      </div>
      <div class="list flex">
        <div class="name center">款式编号:</div>
        <div class="contant">{{ detail.styleNo }}</div>
      </div>
      <div class="list flex">
        <div class="name center">销售单号:</div>
        <div class="contant">{{ detail.saleOrderNo }}</div>
      </div>
      <div class="list flex">
        <div class="name center">款式名称:</div>
        <div class="contant">{{ detail.styleName }}</div>
      </div>
      <div class="list flex">
        <div class="name center">客户单号:</div>
        <div class="contant">{{ detail.customerOrderNo }}</div>
      </div>
      <div class="list flex">
        <div class="name center">产品型号:</div>
        <div class="contant">{{ detail.productModel }}</div>
      </div>
      <div class="list flex">
        <div class="name center">生产车间:</div>
        <div class="contant">{{ detail.productionWorkshop }}</div>
      </div>
      <div class="list flex">
        <div class="name center">颜色:</div>
        <div class="contant">{{ detail.colorFeatures }}</div>
      </div>
      <div class="list flex">
        <div class="name center">开始日期:</div>
        <div class="contant">{{ detail.startDate }}</div>
      </div>
      <div class="list flex">
        <div class="name center">数量:</div>
        <div class="contant">{{ detail.orderAmount }}</div>
      </div>
      <div class="list flex">
        <div class="name center">完工日期:</div>
        <div class="contant">{{ detail.sailingDate }}</div>
      </div>
      <div class="list flex">
        <div class="name center">产量:</div>
        <div class="contant">{{ detail.outputAmount }}</div>
      </div>
      <div class="list flex">
        <div class="name center">时段:</div>
        <div class="contant">{{ detail.timeInterval }}</div>
      </div>
      <div class="list" style="width: 100%">
        <div class="name">销售订单说明</div>
        <div class="desc">
          {{ detail.orderRemarks ? detail.orderRemarks : "暂无" }}
        </div>
      </div>
      <div class="list" style="width: 100%" v-if="type.id == 1">
        <div class="name">订单备注说明</div>
        <div class="desc">{{ detail.remarks1 ? detail.remarks1 : "暂无" }}</div>
      </div>
      <div class="list" style="width: 100%" v-if="type.id == 1">
        <div class="name">暂停/报废说明</div>
        <div class="desc">
          {{ detail.lockRemakes ? detail.lockRemakes : "暂无" }}
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import { getOrderDetail } from "../api/task.js";
import { getOrderTaskProcess } from "../api/report.js";
export default {
  name: "data",
  props: {
    type: {},
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
	  
  },
  methods: {
    getReportDetail(data) {
      console.log("详情");
      getOrderTaskProcess(data).then((res) => {
		  console.log(res.data)
        this.detail = res.data[0];
      });
    },
    getDetail(orderId) {
      console.log("详情");
      getOrderDetail({
        orderId: orderId,
      }).then((res) => {
        this.detail = res.data[0];
		console.log(res.data)
      });
    },
  },
};
</script>
<style scoped lang="less">
//   @import url("../styles/productionTask.less");
.page {
  text-align: start;
  position: relative;
  // overflow-x: hidden;
  // width: 1090px;
  padding: 0 !important;
}

	/deep/ .arco-btn-shape-square{
		@media (max-width: 1300px){
			width: 155px!important;
		}
	}
/deep/ .arco-btn-primary {
	width: 155px!important;
	height: 48px;
	background: #2189F3;
	box-shadow: 1px 1px 0px 1px #E5E5E5;
	border-radius: 8px;
	font-size: 18px;
	font-family: Source Han Sans CN-Medium, Source Han Sans CN;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 21px;
}
.title {
  // width: 1066px;
  height: 56px;
  background: #303c56;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 56px;
  padding-left: 24px;

  @media (min-width: 820px) and (max-width: 1180px) {
    width: 800px;
  }

  @media (max-width: 768px) {
    width: 600px;
  }
}

.msg {
  overflow: auto;
  background-color: #ffffff;
  padding: 24px 20px 0;	
  @media (min-width: 820px) and (max-width: 1180px) {
    width: 792px;
  }

  @media (max-width: 768px) {
    width: 591px;
  }
}

.flex {
  display: flex;
  align-items: center;
  width: 50%;
  line-height: 40px;
  height: 40px;

  @media (min-width: 820px) and (max-width: 1180px) {
    width: 350px;
  }

  @media (max-width: 768px) {
    width: 290px;
  }

  div {
    display: inline-block;
  }

  .name {
    width: 85px;
  }
}

.list {
  text-align: start;
  color: #000;
  display: inline-block;

  .name {
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 700;
    color: #000000;
    line-height: 40px;
    height: 40px;
  }

  .contant {
    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 40px;
	min-width: 20px;
  }

  .desc {
    // width: 1004px;
    background: #f8f9fa;
    border-radius: 8px 8px 8px 8px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #000000cc;
    line-height: 19px;
    border: 1px dashed #e5e5e5;
    padding: 20px;

    @media (min-width: 820px) and (max-width: 1180px) {
      width: 750px;
    }

    @media (max-width: 768px) {
      width: 550px;
    }
  }
}

.bottom {
  // width: 1090px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 78px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 1px #336bef29;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 820px) and (max-width: 1180px) {
    width: 824px;
  }

  @media (max-width: 768px) {
    width: 624px;
  }
}
/deep/ .arco-btn-primary{
	box-shadow: none;
}
.bottom /deep/ .arco-btn-primary{
		height: 40px;
		font-size: 14px;
	}
	.bottom /deep/ .arco-btn-secondary{
		height: 40px;
		font-size: 14px;
	}
/deep/ .arco-modal-body{
	padding: 0!important;
}
.bottom /deep/ .arco-btn-size-medium{
		padding: 0!important;
	}
	
	.msg::-webkit-scrollbar {
	  width: 0px;
	  height: 0;
	}
</style>
