<template>
  <div class="container-box" :style="{ height: screenHeight - 99 + 'px' }">
	  <div class="cover">
		<div class="tab-title">
		  <div @click="goBack"><icon-left />返回</div>
		  {{ name }}
		</div>
	</div>
    <div class="detail-box">
      <iframe
        style="width: 100%; position: absolute;top: 0px;left: 0px;right:0px;border: none;" :style="{top:style?'-40px':0,  height: screenHeight - 50 + 'px'}"
        id="iframe"
        :src="src"
      ></iframe>
      <!-- <pdf :src="src"></pdf>	 -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      address: "",
      src: "",
	  style:false,
	screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
    };
  },
		// 监听高度
		watch: {
			screenHeight(val) {
				this.screenHeight = val;
			},
		},
  mounted() {
	  var that = this
	  that.screenHeight = document.documentElement.clientHeight;
	  window.onresize = () => {
	  	return (() => {
	  		window.screenHeight = document.documentElement.clientHeight
	  		that.screenHeight = window.screenHeight;
	  	})();
	  };
    console.log(this.$route.query);
    this.address = this.$route.query.content;
	var file = this.address.split(".")
	console.log(file[1])
	if(file[1]=='pdf'||file[1]=='ppt'){
		this.style = true
	}
    this.name = this.$route.query.name;
    this.populateIframe();
  },
  methods: {
    goBack() {
	  localStorage.setItem('productId',this.$route.query.productId)
	  localStorage.setItem('name',this.$route.query.name)
    localStorage.setItem('activeKey',JSON.stringify(this.$route.query.activeKey))
    this.$router.back();
    },
    populateIframe() {
      let Base64 = require("js-base64").Base64;
      var url = this.$baseUrl + "/minapp" + this.address;
      url = Base64.encode(url);
      url = encodeURIComponent(url);
      this.src = this.$baseUrl +"/view/onlinePreview?url=" + url;
    },
  },
  beforeRouteLeave (to, from, next) {
	  to.meta.keepAlive = true
	  console.log('*****************')
	  next()
  }
};
</script>
<style lang="less" scoped>
.container-box {
  width: 100%;
  position: relative;
}

.tab-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 2px;
  margin: 10px 10px 10px 8px;
  padding: 16px 0;
  text-align: center;
  // position: relative;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  z-index: 1;
}

.tab-title div:first-child {
  color: #2189f3;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #2189f3;
  position: absolute;
  left: 20px;
  cursor: pointer;
}

.detail-box {
  // width: 100%;
  position: relative;
  height: 800px;
  margin: 67px 10px 0;
}
.cover{
	position: absolute;
	left: 2px;
	top: 0;
	right: 0;
	height: 67px;
	background-color: #F8F9FA;
	z-index: 9;
}
</style>
