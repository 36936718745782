<template>
	<div class="page" :style="{height:(screenHeight)+'px'}">
		<a-layout>
			<a-layout>
				<a-layout-sider theme="dark" :style="{height:(screenHeight)+'px'}" style="overflow-y: hidden;">
					<div class="sider-width" :style="{minHeight:(screenHeight-215)+'px'}" v-show="list.length > 0">
						<div class="header_tips">
							<div class="tips-box"  @click="status = 0,maxPage = 1, type = 1,exceptionList = [],getList()">全部
								<div class="selcet" v-if="status == 0"></div>
							</div>
							<div class="tips-box" @click="status = 1,maxPage = 1, type = 1,exceptionList = [], getList()">我收到的
								<div class="selcet" v-if="status == 1"></div>
							</div>
							<div class="tips-box" @click="status = 2,maxPage = 1, type = 1,exceptionList = [],getList()">我收到的
								<div class="selcet" v-if="status == 2"></div>
							</div>
						</div>
						

						<div style="text-align: start; height:36px;display:flex;align-items: center;margin-top:56px">
							<a-checkbox class="Pending" v-model="state"
							@change="current = 1,exceptionList = [],maxPage = 1,getList()">仅展示待处理</a-checkbox>
						</div>
						<div v-for="item in list" :key="item">
							<div class="exceptionReport_date">{{ item.createTime }}</div>
							<div class="exceptionReport_card" v-for="records in item.list" :key="records"
								@click="getDetail(records.rid)">
								<a-image height="74" width="74" style="margin-right:10px;"
									:src="$baseUrl + records.url" />
								<div class="exceptionReport_card-right">
									<div class="card-title flex">
										<span class="name" style="width: 70px; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;">
											{{ records.labelName }}</span>
										<div style=" display: flex; align-items: flex-start; height: 20px;width: 70px; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;">
											{{ records.labelSubName }}
										</div>
										<div class="status" :class="{ status1: records.state == 2 }">
											{{ records.stateCN }}
										</div>
									</div>
									<div class="msg" style="margin-top: 8px">
										<icon-location />
										{{ records.structureName }}
									</div>
									<div class="msg">
										<icon-schedule />
										{{ records.createTime }}
									</div>
								</div>
							</div>
							<div style="height: 1px; background-color: ##f3f4f5"></div>
						</div>
						<div class="bottom">
							<div class="button" @click="current++,getList()">
								{{maxPage==current||maxPage==1?'已加载全部':'查看更多...'}} </div>
						</div>
					</div>
					<div class="sider-width" v-show="list.length == 0" :style="{height:(screenHeight-99)+'px'}">
						<div style="text-align: start;padding: 20px 0 0 20px">
							<a-checkbox class="Pending" v-model="state"
								@change="current = 1,exceptionList = [],maxPage = 1,getList()">仅展示待处理</a-checkbox>
						</div>
						<div class="none">
							<div>
								<div class="noData" style="zoom: 0.6;">
									<img class="noData-Bg" src="../../assets/bg/none-bg.png" />
									<img class="noData-icon" src="../../assets/bg/none.png" />
								</div>
								<div class="tips">
									<div class="bold">什么都没有</div>
									换个条件试试吧
								</div>
							</div>
						</div>
					</div>
				</a-layout-sider>
				<a-layout-content  style="overflow-y: scroll;position: relative;background: #F0F1F3;"
					id="rightScroll">

					<div class="right_header" v-show="type !== 1">
						提报详情

						<div class="right-header-but" @click="goEdit(1)">写提报</div>
					</div>

					<a-button type="primary" v-show="type == 1" @click="goEdit(1)" style="margin:auto">
						写提报</a-button>
					<div class="detail" v-show="type == 2">
						<div class="right flex" style="z-index: 999;">
							<div style="display: block;" @click="goEdit(2)">
								<!-- <a-button type="primary" v-show="detail.isSubmit!=null&&detail.isSubmit == 1"
									style="margin-right: 8px">
									
								</a-button> -->
								<div class="edit_But"><icon-edit />修改</div>
							</div>
							<div style="display: block;" @click="type1 = 1,deleteReport()">
								<!-- <a-button type="primary" v-show="detail.isSubmit!=null&&detail.isSubmit == 1"
									status="warning">
									
								</a-button> -->
								<div class="delete_But"><icon-delete />删除</div>
							</div>

						</div>
						<div class="card-title flex">
							<span class="name">{{ detail.labelName }}</span>
							{{ detail.labelSubName }}
							<div class="status" :class="{ status1: detail.state != 1 }">
								{{ detail.stateCN }}
							</div>
						</div>
						<div class="msg flex" style="margin-top: 16px;font-size:16px;color: rgba(0, 0, 0, 0.8);">
							<div style="margin-right: 50px;">
								<icon-location />
								{{ detail.structureName }}
							</div>
							<div>
								<icon-schedule />
								{{ detail.createTime }}
							</div>
						</div>
						<div class="img flex">
							<a-image width="100%" :src="$baseUrl+detail.url" v-if="detail.url!=null&&detail.url!=''" />
							<!-- </div> -->
							<img class="none" src="../../assets/bg/default.png" v-else />
							<div class="img-title">
								<div>提报人：{{ detail.createPerson }}</div>
								<div>责任人：{{ detail.responsible==null||detail.responsible==''?'--':detail.responsible }}
								</div>
							</div>
						</div>
						<div>
							<div class="desc">
								{{ detail.problemDesc==''||detail.problemDesc==null?'这个家伙很懒,还没有对问题进行描述':detail.problemDesc}}
							</div>
						</div>
						<div class="background flex" style="justify-content: space-between" v-if="detail.state == 1"
							id="target">
							<!-- <input class="input" :style="{ width: '88%',height:'74px' }" ref="formRef"
								@keyup.enter="$refs.formRef.blur(),submit()" v-model="solve"
								:max-length="200" placeholder="提出方案,向优秀更进一步" allow-clear />
							<div class="icon-bg flex">
								<img :src="file" class="icon" style="background-color: #FFFFFF00;width: 40px;right: 8px;"
									@click="$refs.upload.click()" v-show="file!=''&&file!=null" />
								<icon-image class="icon" :style="{ fontSize: '26px' }" v-show="file==''||file==null"
									@click="$refs.upload.click()" />
							</div>
							<input ref="upload" v-show="false" class="upload" @change='beforeUpload' type="file"> -->

							

						</div>
						<div class="background contant" v-else>
							<a-avatar :style="{ backgroundColor: '#3b75fd', width:'40px'}" :size="40" shape="square">
								<img :src="$baseUrl + detail.solvePersonnelHeadImage"
									v-if="detail.solvePersonnelHeadImage!=null&&detail.solvePersonnelHeadImage!=''" />
								{{detail.solvePersonnelHeadImage==null||detail.solvePersonnelHeadImage==''?detail.updatePerson1:''}}
								<!-- {{ detail.updatePerson1 }} -->
							</a-avatar>
							<div style="margin-left: 12px">
								<div class="name">{{ detail.updatePerson }}</div>
								<div class="" style="margin: 8px 0">{{ detail.solve }}</div>
								<img style="margin-top: 16px" width="78"
									v-if="detail.solveUrl!=null&&detail.solveUrl!=''"
									:src="$baseUrl +detail.solveUrl" />
								<div class="time flex">
									{{ detail.updateTime }}
									<div @click="type1 = 2,handleClick()" style="width: 40px;height: 40px">
										<icon-delete
											:style="{ fontSize: '16px', marginLeft: '18px',marginTop:'12px' }" />
									</div>

								</div>
							</div>
						</div>
					</div>
					<div class="exceptionReport_input" v-if="detail.state == 1">
						<input class="exceptionReport_input_box" v-model="solve" placeholder="提出方案，向优秀更近一步" type="text">
						<div class="exceptionReport_input_right">
							<div @click="$refs.upload.click()">
								<img src="../../assets/icon/img_01.png" alt="">
							</div>
							<div class="exceptionReport_input_border"></div>
							<div @click="submit()">
								<img src="../../assets/icon/img_02.png" alt="">
							</div>
						</div>
						<input ref="upload" v-show="false" class="upload" @change='beforeUpload' type="file">
					</div>
				</a-layout-content>
			</a-layout>
		</a-layout>
		<massageCard  @handleOk="handleOk" ref="massageCard"></massageCard>
	</div>
</template>
<script>
	import axios from 'axios'
	import {
		getExceptionList,
		getExceptionProcess,
		delReport,
		submitReply,
		deleteSolve
	} from "../../api/exception.js";
	import massageCard from "../../common/massageCard.vue";
	import * as imageConversion from 'image-conversion';
	export default {
		components: {
			massageCard,
		},
		data() {
			return {
				status: 0, //0全部，1待处理，2我提交
				type: 1, //1写提报，2详情
				detail: {}, //详情
				list: [], //日报列表
				exceptionList: [], //原始数据
				attachmentId: "", //附件id
				loading: false,
				solve: "", //解决描述
				state: false, //仅查看待处理
				type1: 1, //1删除整条异常，2删除评论
				current: 1, //页码
				token: '',
				maxPage: 1,
				file: '',
				screenHeight: document.documentElement.clientHeight, //获取屏幕高度
			};
		},
		watch: {
			/* 监听页面高度 */
			// screenHeight(val) {
			// 	this.screenHeight = val;
			// },
		},
		mounted: function() {
			this.token = localStorage.getItem('token')
			var that = this
			// 获取页面高度
			window.screenHeight = document.documentElement.clientHeight
			that.screenHeight = window.screenHeight;
			// 监听页面高度 watch有时会监听不到
			window.onresize = () => {
				return (() => {
					window.screenHeight = document.documentElement.clientHeight
					that.screenHeight = window.screenHeight;
				})();
			};
			// 由详情页返回过来时获得的异常id
			var attachmentId = this.$route.query.attachmentId
			var type = this.$route.query.type
			// 该情况为取消，讲不展示详情
			if (type == 1) {
				this.type = 2
				this.getDetail(attachmentId)
			}
			this.getList();
		},
		methods: {
			// 滚动到指定位置
			setScroll() {
				// this.$message.info('This is an info message')
				this.$nextTick(() => {
				     this.$refs.DOM.scrollTo(0,1000);
				})
			},
			// 上传图片
			beforeUpload(event) {
				var file = event.target.files[0];
				let type = file.type; //文件的类型，判断是否是图片
				let isLt2M = size < 400 * 1024 // 判定图片大小是否小于4MB
				let size = 0.01
				if (isLt2M) {
					resolve(file)
				} else if (size > 400 * 1024) {
					var file1
					size = 0.01
					imageConversion.compress(file, size).then(res => {
						var uri = ''
						var file1 = new window.File([res], file.name, {
							type: res.type
						})
						let form = new FormData();
						form.append('file', file1, file1.name);
						axios({
							url: this.$baseUrl + '/minapp/onlineView/uploadFile/311',
							method: 'post', //get
							data: form,
							headers: {
								'TENANT-ID': '2',
								'Authorization': 'Bearer ' + this.token
							}
						}).then((res) => {
							this.file = this.$baseUrl + res.data.data.url
							this.attachmentId = res.data.data.id;
							this.$refs.formRef.focus()
						})
					})
				} else {
					var file1
					size = parseFloat(400 * 1024 / file.size)
					imageConversion.compress(file, size).then(res => {
						var uri = ''
						var file1 = new window.File([res], file.name, {
							type: res.type
						})
						let form = new FormData();
						form.append('file', file1, file1.name);
						axios({
							url: this.$baseUrl + '/minapp/onlineView/uploadFile/311',
							method: 'post', //get
							data: form,
							headers: {
								'TENANT-ID': '2',
								'Authorization': 'Bearer ' + this.token
							}
						}).then((res) => {
							this.file = this.$baseUrl + res.data.data.url
							this.attachmentId = res.data.data.id;
							this.$refs.formRef.focus()
						})
			
					})
			
				}
			},
			//   上传成功
			uploadSuccess(e) {
				this.file = this.$baseUrl + e.response.data.url
				this.attachmentId = e.response.data.id;
				this.$refs.formRef.focus()
			},
			//   提交回复
			submit() {
				var data = {
					reportRecordId: this.detail.rid,
					solve: this.solve,
					attachmentId: this.attachmentId,
				};
				submitReply(data).then((res) => {
					this.file = ''
					this.solve = ''
					if (res.code == 0) {
						// this.$message.success(');
						this.list = []
						this.exceptionList = []
						this.current = 1
						this.exceptionList = []
						this.maxPage = 1
						this.getList()
						this.$message.success(res.msg);
						this.getDetail(this.detail.rid);
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//   写日报
			goEdit(type) {
				var data = {
					type: type,
				};
				if (type == 2) {
					data.detail = this.detail.rid;
				}
				this.$router.push({
					path: "/dashBord/workBench/editExceptionReport",
					query: data,
				});
			},

			// 获取列表
			getList() {
				if (this.current == this.maxPage + 1) {
					this.current--
					return
				}
				var data = {
					dataType: this.status,
					state: this.state ? 1 : -1,
					current: this.current,
				};
				// if(this.status==1){
				// 	data.state = this.state
				// }
				getExceptionList(data).then((res) => {
					var list = res.data.records;
					this.exceptionList = this.exceptionList.concat(list)
					this.maxPage = res.data.pages
					if (res.data.pages == 0 && this.current == 1) {
						this.current = 1
						list = []
					} else {
						list = this.mapLoction(this.exceptionList);
					}
					this.list = list
				});
			},
			// 获取详情
			getDetail(dailyId) {
				this.solve = ''
				this.file = ''
				this.attachmentId = ''
				getExceptionProcess({
					rid: dailyId,
				}).then((res) => {
					// this.detail = res.data;
					var detail = res.data
					console.log('res.data.updatePerson')
					console.log(res.data.updatePerson)
					// var str = res.data.updatePerson
					var str = '王世杰'
					detail.updatePerson1 = str.substring(str.length - 2)
					this.detail = detail
					this.type = 2;
				});
			},
			// 处理日期
			mapLoction(arr) {
				var newArr = [];
				var index = -1;
				for (let i = 0; i < arr.length; i++) {
					if (
						arr[i + 1] != null &&
						arr[i].createTime.substring(0, 10) ===
						arr[i + 1].createTime.substring(0, 10)
					) {} else {
						var data = {
							createTime: arr[i].createTime.substring(0, 10),
							list: [],
						};
						index = index + 1;
						newArr.push(data);
						for (let j = 0; j < arr.length; j++) {
							if (
								arr[i].createTime.substring(0, 10) ===
								arr[j].createTime.substring(0, 10)
							) {
								newArr[index].list.push({
									createTime: arr[j].createTime,
									url: arr[j].url,
									labelName: arr[j].labelName,
									labelSubName: arr[j].labelSubName,
									structureName: arr[j].structureName,
									createTime: arr[j].createTime,
									stateCN: arr[j].stateCN,
									state: arr[j].state,
									rid: arr[j].rid,
								});
							}
						}
					}
				}
				return newArr;
			},
			handleClick() {
				var data = {
					name: "删除回复",
					msg: "是否删除该条回复？",
					type: "Error",
				};
				this.$refs.massageCard.handleClick(data);
			},
			// 删除异常
			handleOk() {
				if (this.type1 == 1) {
					delReport({
						rid: this.detail.rid,
					}).then((res) => {
						if (res.code == 1) {
							// this.$message.error(res.msg);
						} else {
							this.type = 1
							this.$message.success(res.msg);
							this.list = []
							this.exceptionList = []
							this.current = 1
							this.exceptionList = []
							this.getList()
						}
					});
				} else {
					deleteSolve({
						rid: this.detail.rid,
					}).then((res) => {
						if (res.code == 1) {
							// this.$message.error(res.msg);
						} else {
							this.list = []
							this.exceptionList = []
							this.current = 1
							this.exceptionList = []
							this.maxPage = 1
							this.getList()
							this.$message.success(res.msg);
							this.getDetail(this.detail.rid);
						}
					});
				}
			},
			deleteReport() {
				var data = {
					name: "删除提报",
					msg: "是否删除该条提报？",
					type: "Error",
				};
				this.$refs.massageCard.handleClick(data);
			},
		},
	};
</script>
<style scoped>


	.sider-width {
		background-color: #FAFBFF;
		width: 100%;
		/* height: 100%; */
		overflow-y: scroll;
	}

	.sider-width::-webkit-scrollbar {
		display: none;
		/* Chrome Safari */
	}

	.bottom {
		height: 60px;
		/* background-color: #FFFFFF; */
		width: 100%;
		padding-top: 10px;
	}

	.bottom .button {
		/* width: 378px !important; */
		height: 48px !important;
		background: #e5e5e500 !important;
		border-radius: 2px !important;
		font-size: 14px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #0000004d;
		margin: 0 5px 0 8px;
		line-height: 48px;
		text-align: start;
		padding-left: 20px;
	}
</style>
<style scoped lang="less">
	@import url("../../styles/exceptionReport.less");

	/deep/ .arco-layout-sider-children {
		background: #F3F4F5 !important;
	}

	.none {
		@media (min-width: 1200px) and (max-width:1400px) {
			height: 200px !important;
			width: auto !important;
		}
	}

	.page {
		.none {
			@media (min-width: 1200px) and (max-width:1400px) {
				left: 0 !important;
				right: 0;
				margin: auto;
			}
		}
	}

	/deep/ .arco-avatar-text {
		font-size: 14px !important;
	}
	/deep/ .arco-upload-wrapper{
		width:32px;
	}
	.input{
		border: none;
		outline: none;
		padding-left: 12px;
	}
	.header_tips{
		height: 56px;
		background: #FFFFFF;
		box-shadow: 0px 4px 4px 1px rgba(59, 117, 253, 0.05);
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		font-weight: 500;
		color: #4E5969;
		
		width: 335px;
		position: fixed;
		z-index: 10;
	}
	.tips-box{
		width: 30%;
		text-align: center;
		position: relative;
	}
	.exceptionReport_input{
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		.exceptionReport_input_box{
			width: 835px;
			height: 48px;
			box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.05);
			border-radius: 4px 4px 4px 4px;
			margin: auto;
			border: 1px solid #BBBFC4;
			outline: none;
			padding-left: 10px;
			background: #F0F1F3;
		}
		.exceptionReport_input_right{
			display: flex;
			align-items: center;
			position: absolute;
			right: 40px;
			z-index: 20;
			.exceptionReport_input_border{
				height: 22px;
				width: 1px;
				background: #BBBFC4;
				margin: 0 14px;
			}
			img{
				width: 19px;
				
			}
		}
	}
</style>
