//vue3中
import {
	createApp
} from 'vue'
import App from '../App.vue';
const app = createApp(App);
// app.use(Mui);
import SvgIcon from '@/components/SvgIcon'
app.component('svg-icon', SvgIcon) //注册svg组件

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./svg', false, /\.svg$/)
requireAll(req)
console.log('req------------------')
console.log(requireAll(req))