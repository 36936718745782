<template>
	<div class="page" :style="{ height: screenHeight + 'px' }" style="overflow-y: scroll;">
		<div class="per_header" >
			生产报工
			<div class="per_header_box">
				<div class="per_header_boxs" v-for="item in factory" :key='item'>{{ item.structureName }}</div>
			</div>
		</div>
		<div class="search-box">
			<div class="search-box-left">
				<div class="search-box-l1">
					<div v-if="show">今日产量</div>
					<div v-else>今日工资</div>
					<div class="num" v-if="show">256</div>
					<div class="num" v-else>602</div>
					<div class="seeW" v-if="show" @click="show = !show">看工资</div>
					<div class="seeW" v-else @click="show = !show">看产量</div>
				</div>
				<div class="search-box-l2">
					<div>任务总数</div>
					<div class="num">150</div>
				</div>
				<div class="search-box-l3">
					<div>完成产量</div>
					<div class="num">200</div>
				</div>
			</div>
			<div class="search-box-right">
				<div class="search-box-r1">
					<div class="search-date" @click="visiblePick = true">{{value}}
						<a-date-picker v-model="value" style="width: 0px;position: absolute;top: 80px;"
						:popup-visible="visiblePick" @change="changeDate" />
					</div>
				</div>
				<div class="search-box-r2" style="padding-right:10px">
					<div style="width: 340px;">
						<a-radio-group name="radioGroup" :default-value="3" @change="changeRadio">
							<a-radio :value="0">未完成</a-radio>
							<a-radio :value="1">已完成</a-radio>
						</a-radio-group>
					</div>
					<input type="text" placeholder="搜索" >
					<div class="but-input" @click="getList()">查询</div>
				</div>
				<div class="search-box-r3">
					<div class="but" @click="getOrderTaskList">报工</div>
				</div>
			</div>
		</div>

		<div class="calendar" v-show="false">
			<div class="date flex">
				<div class="dateCover" @click="visiblePick = true">{{value}}
					<icon-caret-down style="font-size: 24px;margin-left: 10px;" />
				</div>
				
				<div class="tags flex">
					<div :class="[type==1?'tags1':'tags2']" @click="type=1">月
						<div></div>
					</div>
					<div :class="[type==1?'tags2':'tags1']" @click="type=2,changeDate1(value)">周<div></div>
					</div>

				</div>
				<feedback style="z-index: 99;" ref="feedback"></feedback>
				<massageCard style="z-index: 99;" @handleOk="handleOk" ref="massageCard"></massageCard>
				<success style="z-index: 99;" v-if="success" ref="success"></success>
			</div>
			<div class="date-picker">

					<div class="flex" style="width: 294%;position: relative;margin-left: -98%;"  :style="{left:moveSize+'px'}"  @touchstart="touchstartHandle"
				@touchmove="touchmoveHandle" @touchend="touchendHandle">
						<a-date-picker v-model="value" v-if="type == 1" v-model:pickerValue="pickerValue" hide-trigger
							@change="getList()" style="width: 98%; margin-top: -50px;mar" />
						<a-date-picker v-model="value" v-if="type == 1" v-model:pickerValue="pickerValue" hide-trigger
							@change="getList()" style="width: 98%; margin-top: -50px;mar" />
						<a-date-picker v-model="value" v-if="type == 1" v-model:pickerValue="pickerValue" hide-trigger
							@change="getList()" style="width: 98%; margin-top: -50px;mar" />
					</div>
				<!-- </div> -->
				

				<!-- </VueTouch> -->
				<div v-show="type==2" class="flex" style="width: 294%;position: relative;margin-left: -98%;" :style="{left:moveSize+'px'}" @touchstart="touchstartHandle"
				@touchmove="touchmoveHandle" @touchend="touchendHandle1">
					<!-- <VueTouch @swipeleft="weekleft" @swiperight="weekright"> -->
					<weekPicker ref="weekPicker1"  style="width: 98%;" @changeDate="getChangeDate"></weekPicker>
					<weekPicker ref="weekPicker2" style="width: 98%;" @changeDate="getChangeDate"></weekPicker>
					<weekPicker ref="weekPicker3" style="width: 98%;" @changeDate="getChangeDate"></weekPicker>
					<!-- </VueTouch> -->
				</div>
			</div>
			<div class="weekMonth" @click="type=(type==2?1:2),changeDate1(value)"></div>
		</div>
		<div class="task-list">
			<div class="flex" style="justify-content: flex-start;" v-show="false">

				<div class="input-box">
					<a-input v-model="form.saleOrderNo" placeholder="销售单号"
						@press-enter="$refs.inputText1.blur(),$refs.inputText2.blur(),$refs.inputText3.blur(),getList()"
						ref="inputText1" :allow-clear="true">
						<template #prefix>
							<icon-search />
						</template>
					</a-input>
				</div>

				<div class="input-box">
					<a-input v-model="form.styleName" placeholder="款式名称"
						@press-enter="$refs.inputText2.blur(),$refs.inputText3.blur(),getList()" ref="inputText2"
						:allow-clear="true">
						<template #prefix>
							<icon-search />
						</template>
					</a-input>
				</div>

				<div class="input-box">
					<a-input v-model="form.orderNo" placeholder="订单编号" @press-enter="$refs.inputText3.blur(),getList()"
						ref="inputText3" :allow-clear="true">
						<template #prefix>
							<icon-search />
						</template>
					</a-input>
				</div>

				<div class="input-box">
					<a-button size="large" @click="form = {},getList()" class="refresh"
						:style="{backgroundColor:'#00000000'}" style="margin-left:0px;">
						<template #icon>
							<icon-refresh :style="{fontSize:'22px',marginTop:'4px',color:'#2189F3'}" />
						</template>

						<template #default>
							重置
						</template>
					</a-button>
				</div>

				<div class="input-box">
					<a-button size="large" class="refresh group" :style="{backgroundColor:'#00000000',width:'150px'}"
						@click="handleClick">
						<template #icon>
							<icon-settings :style="{fontSize:'22px',marginTop:'4px',color:'#2189F3'}" />
						</template>

						<template #default>
							列配置
						</template>
					</a-button>
				</div>
				<a-drawer :visible="visible" @ok="handleSubmit" @cancel="handleCancel" unmountOnClose>
					<template #title>
						列显隐
					</template>
					<div>
						<a-checkbox default-checked v-model="i.show" v-for="(item,index) in header" :key='index'>{{i.label}}</a-checkbox><br>
					</div>
				</a-drawer>

				<div class="input-box" style="margin-right: 200px;">
					<a-button @click="is_personal=!is_personal,getList()" size="large" class="refresh group"
						style="width: 180px;" :style="{backgroundColor:'#00000000'}">
						<template #icon>
							<icon-swap :style="{fontSize:'22px',marginTop:'4px',color:'#2189F3'}" />
						</template>
						<template #default>
							{{is_personal?'个人订单':'小组订单'}}
						</template>
					</a-button>
				</div>

				<div class="right flex">
						<a-button type="primary" size="large" shape="round" class="refresh"
							style="margin-left:0px;margin-right: 10px;" @click="allReport">一键报工</a-button>
						<a-button type="primary" size="large" shape="round" class="refresh" @click="getOrderTaskList">报工
					</a-button>
				</div>
			</div>

			<div class="table" v-if="orderList.length>0">
				<div class="pagination" v-if="pages>1">
					<icon-up @click="previous()" />
					<a-pagination :total="total" @change="changePage" :current="current" :page-size="size" />
					<icon-down @click="next()" />
				</div>

				<list :checkbox='true' :oldList='oldList' :headerData='header' @selectOne='selectOne' @butClick='butClick' @changeList='changeList' :content='orderList' :tabHight='screenHeight - 200 ' :type='2'/>
			</div>

			<div class="none" v-else>
				<div>
					<div class="noData" style="zoom: 0.7;">
						<img class="noData-Bg" src="../../assets/bg/none-bg.png" />
						<img class="noData-icon" src="../../assets/bg/none.png" />
					</div>
					<div class="tips">
						<div class="bold">什么都没有</div>
						换个条件试试吧
					</div>
				</div>
			</div>
		</div>
		<a-modal :visible="orderDetail" title="任务单工序详情" @ok="handleOk">
			<div class="msg">
				<a-table :data="process" :bordered="true" size="mini" :pagination="false" :scroll="{y:160}">
					<template #columns>
						<a-table-column width="110" title="订单编号" data-index="orderNo">
							<template #cell="{ record }">
								<a-popover trigger="contextMenu" :title="record.orderNo">
									<a-button class="ellipsis" style="width: 100%;">{{record.orderNo}}</a-button>
								</a-popover>
							</template>
						</a-table-column>
						<a-table-column width="110" title="工序编号" data-index="processNo">
							<template #cell="{ record }">
								<a-popover trigger="contextMenu" :title="record.processNo">
									<a-button class="ellipsis" style="width: 100%;">{{record.processNo}}</a-button>
								</a-popover>
							</template>
						</a-table-column>
						<a-table-column width="70" title="工序名" data-index="processName">
							<template #cell="{ record }">
								<a-popover trigger="contextMenu" :title="record.processName">
									<a-button class="ellipsis" style="width: 100%;">{{record.processName}}
									</a-button>
								</a-popover>
							</template>
						</a-table-column>
						<a-table-column width="70" title="部件" data-index="unitName">
							<template #cell="{ record }">
								<a-popover trigger="contextMenu" :title="record.unitName">
									<a-button class="ellipsis" style="width: 100%;">{{record.unitName}}</a-button>
								</a-popover>
							</template>
						</a-table-column>
						<a-table-column width="70" title="任务数" data-index="taskCount">
							<template #cell="{ record }">
								<a-popover trigger="contextMenu" :title="record.taskCount">
									<a-button class="ellipsis" style="width: 100%;">{{record.taskCount}}</a-button>
								</a-popover>
							</template>
						</a-table-column>
						<a-table-column width="70" title="完工数" data-index="completeAmount">
							<template #cell="{ record }">
								<a-popover trigger="contextMenu" :title="record.completeAmount">
									<a-button class="ellipsis" style="width: 100%;">{{record.completeAmount}}
									</a-button>
								</a-popover>
							</template>
						</a-table-column>
					</template>
				</a-table>
			</div>
		</a-modal>
		<!-- <div class="cover flex" style="position: fixed;" v-if="orderDetail">
			<div class="report">
				<div class="title">任务单工序详情
					<icon-close class="right" @click="orderDetail=false" />
				</div>
				<div class="msg">
					<a-table :data="process" :bordered="true" size="mini" :pagination="false" :scroll="{y:160}">
						<template #columns>
							<a-table-column width="110" title="订单编号" data-index="orderNo">
								<template #cell="{ record }">
									<a-popover trigger="contextMenu" :title="record.orderNo">
										<a-button class="ellipsis" style="width: 100%;">{{record.orderNo}}</a-button>
									</a-popover>
								</template>
							</a-table-column>
							<a-table-column width="110" title="工序编号" data-index="processNo">
								<template #cell="{ record }">
									<a-popover trigger="contextMenu" :title="record.processNo">
										<a-button class="ellipsis" style="width: 100%;">{{record.processNo}}</a-button>
									</a-popover>
								</template>
							</a-table-column>
							<a-table-column width="70" title="工序名" data-index="processName">
								<template #cell="{ record }">
									<a-popover trigger="contextMenu" :title="record.processName">
										<a-button class="ellipsis" style="width: 100%;">{{record.processName}}
										</a-button>
									</a-popover>
								</template>
							</a-table-column>
							<a-table-column width="70" title="部件" data-index="unitName">
								<template #cell="{ record }">
									<a-popover trigger="contextMenu" :title="record.unitName">
										<a-button class="ellipsis" style="width: 100%;">{{record.unitName}}</a-button>
									</a-popover>
								</template>
							</a-table-column>
							<a-table-column width="70" title="任务数" data-index="taskCount">
								<template #cell="{ record }">
									<a-popover trigger="contextMenu" :title="record.taskCount">
										<a-button class="ellipsis" style="width: 100%;">{{record.taskCount}}</a-button>
									</a-popover>
								</template>
							</a-table-column>
							<a-table-column width="70" title="完工数" data-index="completeAmount">
								<template #cell="{ record }">
									<a-popover trigger="contextMenu" :title="record.completeAmount">
										<a-button class="ellipsis" style="width: 100%;">{{record.completeAmount}}
										</a-button>
									</a-popover>
								</template>
							</a-table-column>
						</template>
					</a-table>
				</div>
				<div class="bottom">
					<a-button class="primary" type="primary" @click="orderDetail=false">我知道了</a-button>
				</div>
			</div>
		</div> -->
		<div class="cover flex" style="position: fixed;" v-if="report">
			<div class="report report-width">
				<div class="title">报工
					<icon-close class="right" @click="report=false" />
				</div>
				<div class="msg">
					<a-table :data="reportList" :stripe="true" :bordered="false" size="mini" :pagination="false"
						:scroll="{y:250}" style="height:300px;">
						<template #columns>
							<a-table-column width="120" title="订单编号" data-index="orderNo">
								<template #cell="{ record }">
									<a-popover trigger="contextMenu" :title="record.orderNo">
										<a-button class="ellipsis" style="width: 100%;">{{record.orderNo}}</a-button>
									</a-popover>
								</template>
							</a-table-column>
							<a-table-column title="款式名称" data-index="styleName">
								<template #cell="{ record }">
									<a-popover trigger="contextMenu" :title="record.styleName">
										<a-button class="ellipsis" style="width: 100%;">{{record.styleName}}</a-button>
									</a-popover>
								</template>
							</a-table-column>
							<a-table-column width="100" align="center" title="部件" data-index="wayName">
								<template #cell="{ record }">
									<div style="text-align: center;">
										{{record.amount-record.completeCount<0?0:record.amount-record.completeCount}}
									</div>
								</template>
							</a-table-column>
							<a-table-column width="90" align="center" title="未完工数" data-index="orderAmount">
								<template #cell="{ record }">
									<div style="text-align: center;">
										{{record.amount-record.completeCount<0?0:record.amount-record.completeCount}}
									</div>
								</template>
							</a-table-column>
							<a-table-column width="100" align="center" title="完工数">
								<template #cell="{ record }">
									<a-input v-model="record.count"
										:value="record.amount-record.completeCount<0?0:record.amount-record.completeCount"
										@press-enter="$refs.inputText7.blur()" ref="inputText7" :allow-clear="false"
										@blur="getCount(record)"
										:style="{width:'50px',height:'24px',borderRadius:'0px',border:'1px solid #999999'}"
										:placeholder="record.amount-record.completeCount<0?0:record.amount-record.completeCount"
										allow-clear />
								</template>
							</a-table-column>
						</template>
					</a-table>
				</div>
				<div class="bottom">
					<a-button class="cancel" @click="report=false">取消</a-button>
					<a-button class="primary" type="primary" @click="report=false,getMsg()">确定</a-button>
				</div>
			</div>
		</div>
		<a-modal v-model:visible="showDetail" class="add-box" style="padding: 0!important;" :footer="false"
			:width="1100">
			<template #title>
				<div class="model-title">生产任务-订单详情</div>
			</template>
			<div class="scrollbar">
				<detailCard :type="{ id: 2, name: '生产报工-订单详情' }" ref="detailCard" class="card"
					@close="showDetail = false">
				</detailCard>
			</div>

		</a-modal>
	</div>
</template>
<script>
	import {
		getStructure
	} from "../../api/index.js";
	import vueTouch from "vue-touch";
	import {
		getOrderList,
		getOrderTaskProcess,
		proSet
	} from '../../api/report.js'
	import {
		getOrderDetail
	} from '../../api/task.js'
	import feedback from '../../common/feedback.vue'
	import success from '../../common/success.vue'
	import detailCard from '../../common/detailCard.vue'
	import massageCard from '../../common/massageCard.vue'
	import weekPicker from '../../common/weekPicker.vue'
	import list from "../../common/list.vue";
	export default {
		components: {
			list,
			detailCard,
			massageCard,
			success,
			feedback,
			weekPicker,
			vueTouch
		},
		data() {
			return {
				show:true,
				state:1,
				pages: 0,
				total: 0,
				size: 0,
				finish:true,
				fold:false,
				current: 1,
				visiblePick: false,
				reportList: [], //table数据
				factoryId: 0, //当前选择工厂的id
				factory: [], //工厂列表
				rowSelection: {
					type: 'checkbox',
					showCheckedAll:true,
					selectedRowKeys: []
				}, //表格复选框
				success: false, //提示
				report: false, //报工
				orderDetail: false, //任务单工序详情
				detail: {}, //详情内容
				showDetail: false, //展示详情
				is_personal: true, //个人订单/小组订单
				date: '', //今天几号
				value: '', //选择的时间
				weekday:'',//第几周
				type: 2, //1月，2周
				form: {}, //搜索内容
				pickerValue: null,
				orderList: [], //列表
				oldList:[],
				process: [], //任务单工序列表
				visible: false, //列配置显隐
				reportConfig: {
					structureName: true,
					status: true,
					saleOrderNo: true,
					orderNo: true,
					styleName: true,
					colorFeatures: true,
					unitName: true,
					taskAmount: true,
					completeAmount: true,
					timeInterval: true,
					orderRemarks: true,
					sailingDate: true,
				}, //列配置数据
				screenWidth: document.documentElement.clientWidth, //获取屏幕高度
				screenHeight: document.documentElement.clientHeight, //获取屏幕高度
				select: [], //选中的数组
				start: 0,
				moveSize: 0,
				header:[
					{
						lable:"序号",
						width:'80px',
						param:"index",
						filter:false,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"销售订单号",
						width:'150px',
						param:"saleOrderNo",
						filter:false,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"订单编号",
						width:'160px',
						param:"orderNo",
						filter:false,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"款式编号",
						width:'300px',
						param:"styleName",
						filter:false,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"部件",
						width:'150px',
						param:"wayName",
						filter:true,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"任务数",
						width:'150px',
						param:"amount",
						filter:false,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"完工数",
						width:'150px',
						param:"completeCount",
						filter:false,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"时段",
						width:'100px',
						param:"timeInterval",
						filter:true,
						filterIcon:true,
						filterList:[],
						show:true
					},
					// {
					// 	lable:"工序名称",
					// 	width:'160px',
					// 	param:"orderAmount",
					// 	filter:true,
					// 	filterIcon:true,
					// 	filterList:[]
					// },
					{
						lable:"操作",
						width:'100px',
						param:"but",
						filter:false,
						filterIcon:true,
						filterList:[],
						show:true
					},
				],
			};
		},
		mounted: function() {
			this.getFactory();
			// 监听屏幕高度
			var that = this
			window.onresize = () => {
				return (() => {
					window.screenHeight = document.documentElement.clientHeight
					that.screenHeight = window.screenHeight;
				})();
			};
			// 获取初始时间
			this.value = new Date().Format("yyyy-MM-dd")
			var date = new Date().getDate()
			this.weekday = date
			var reportConfig = localStorage.getItem("reportConfig");
			if (reportConfig != null) {
				console.log(JSON.parse(reportConfig));
				this.reportConfig = JSON.parse(reportConfig);
			}else{
				localStorage.setItem("reportConfig",JSON.stringify(this.reportConfig));
			}
			// 获取当前几号
			this.date = date > 9 ? date : '0' + date
			// 获取周列表
			// 获取表格列表
		},
		methods: {
			// 获取工厂信息
			getFactory() {
				getStructure({
					type: 7,
				}).then((res) => {
					this.factory = res.data;
					this.factoryId = res.data[0].structureId;
					this.changeDate(new Date())
				});
			},
			touchstartHandle(e) {
				console.log('touchstartHandle')
				console.log(e)
				this.start = e.changedTouches[0].clientX
			},
			touchmoveHandle(e) {
				console.log('touchmoveHandle')
				console.log(e.changedTouches[0].clientX)
				var end = e.changedTouches[0].clientX
				var move = end - this.start
				this.moveSize = move
			},
			touchendHandle(e) {
				console.log('touchendHandle')
				console.log(e)
				var end = e.changedTouches[0].clientX
				if (end - this.start > 100) {
					this.right()
				}
				if (end - this.start < -100) {
					this.left()
				}
				this.moveSize = 0
			},
			
			touchendHandle1(e){
				console.log('touchendHandle')
				console.log(e)
				var end = e.changedTouches[0].clientX
				if(end-this.start>100){
					this.weekright()
				}
				if(end-this.start<-100){
					this.weekleft()
				}
				this.moveSize = 0
			},
			left() {
				var arr = this.value.split('-')
				var newData = new Date(arr[0], parseInt(arr[1])+1, 1).getTime()
				var last = new Date(newData - 1000 * 60 * 60 * 24).getDate()
					var data = new Date(arr[0], arr[1], 1).getTime()
					var lastDay = new Date(data - 1000 * 60 * 60 * 24).getDate()
					console.log(last)
					console.log(lastDay)
				if (last < parseInt(arr[2])) {
					var nextDay = new Date(this.value).getTime() + last * 24 * 60 * 60 * 1000
					nextDay = new Date(nextDay)
					console.log(nextDay.Format('yyyy-MM-dd'))
					this.value = nextDay.Format('yyyy-MM-dd')
				} else {
					arr[1] = parseInt(arr[1]) +1
					if (arr[1] > 12) {
						arr[0] = parseInt(arr[0]) + 1
						arr[1] = 1
					}
					this.value = arr[0] + '-' + (arr[1] > 9 ? arr[1] : '0' + arr[1]) + '-' + arr[2]
					console.log(this.value)
			
				}
				this.getList()
			},
			right() {
				console.log(this.value)
				var arr = this.value.split('-')
				var newData = new Date(arr[0], parseInt(arr[1])+1, 1)
				var last = new Date(newData - 1000 * 60 * 60 * 24).getDate()
				var lastData = new Date(arr[0], parseInt(arr[1])-1, 1)
				var lastDay = new Date(lastData - 1000 * 60 * 60 * 24).getDate()
				arr[1] = parseInt(arr[1]) - 1
				console.log('最后一天')
				console.log(lastDay)
				console.log(last)
				console.log(arr[2])
				if (lastDay < parseInt(arr[2])) {
					var nextDay = new Date(this.value).getTime() - arr[2] * 24 * 60 * 60 * 1000
					nextDay = new Date(nextDay)
					console.log(nextDay.Format('yyyy-MM-dd'))
					this.value = nextDay.Format('yyyy-MM-dd')
				} else {
					console.log(arr[1])
					if (arr[1] < 1) {
						arr[0] = parseInt(arr[0]) - 1
						arr[1] = 12
					}
					this.value = arr[0] + '-' + (arr[1] > 9 ? arr[1] : '0' + arr[1]) + '-' + arr[2]
				}
				this.getList()
			},
			weekleft() {
				var data = new Date(this.weekday)
				data = data.getTime() + 7 * 24 * 60 * 60 * 1000
				data = new Date(data).Format('yyyy-MM-dd')
				this.weekday = data
				this.value = data
				this.changeDate1(data)
			},
			weekright() {
				var data = new Date(this.weekday)
				data = data.getTime() - 7 * 24 * 60 * 60 * 1000
				data = new Date(data).Format('yyyy-MM-dd')
				this.weekday = data
				this.value = data
				this.changeDate1(data)
			},
			// 获取详情
			openDetail(e, index) {
				if (index.title != '操作') {
					this.$refs.detailCard.getDetail(e.orderId);
					this.showDetail = true;
				}
			},
			changePage(e) {
				console.log(e)
				this.current = e
				this.orderList = [],
					this.getList()
			},
			previous() {
				if (this.current > 1) {
					this.current--
					this.getList()
				}
			},
			next() {
				if (this.current < this.pages) {
					this.current++
					this.getList()
				}
			},
			// 完工数大于未完工数处理数据
			getCount(record) {
				if (record.count > record.amount) {
					record.count = record.amount
				}
			},
			// 
			selectAll(e) {
				console.log(e)
				var select = []
				if (e) {
					for (let i = 0; i < this.orderList.length; i++) {
						console.log(this.orderList[i].state)
						if(this.orderList[i].state==1){
							
						}else{
							select.push(i + 1)
						}
						
					}

				}
				this.select = select
				this.rowSelection.selectedRowKeys = select
			},
			// 获得多选列表的数组
			selectOne(e) {
				for (const item of e) {
					if(item.state == 1) {
						var data = {
							name: '提示',
							msg: '请选择未生产订单报工',
							type: 'Warning'
						}
						this.$refs.massageCard.handleClick(data);
						this.finish = false

						for (const item of this.orderList) {
							item.checked = false
						}
						return
					}
				}
				this.rowSelection.selectedRowKeys = e
				this.select = e
			},
			// 报工
			getOrderTaskList() {

				let arr = []
				this.reportList = []
				for (const item of this.orderList) {
					if(item.checked) {
						arr.push(item)
					}
				}
				if(arr.length < 1) {
					this.$message.warning('请至少选择一条信息');
					
					return
				}else{
					this.report = true
					this.reportList = arr

				}
			},
			// 查工艺
			goWordManShip(name) {
				console.log(name)
				this.$router.push({
					path: '/dashBord/workManShip',
					query: {
						name: name,
					}
				});
			},
			// 获取列表
			getList() {
				var data = this.form
				this.rowSelection.selectedRowKeys = []
				this.select = []
				data.sailingDate = this.value
				data.current = this.current
				data.pieceWay = this.is_personal ? 1 : 0
				data.state = this.state
				data.size = 20
				getOrderList(data).then(res => {

					var orderList = res.data.records
					this.size = res.data.size
					this.total = res.data.total
					this.pages = res.data.pages
					var i = 1
					orderList.forEach(element => {
						element.key = i++
					});
					var list = this.$unique(orderList)

					var index = 0

					for (let a in this.filterable) {
						for (let i = 0; i < list.length; i++) {
							if (a == list[i].name) {
								this.filterable[a].filters = list[i].list
							}

						
						}
					}
					for (const item of orderList) {
						item.checked = false
					}

					this.orderList = orderList
					this.oldList = orderList
					for (const i of this.header) {
						i.filterList = this.filterFun(i.param)
					}
					var loading = document.getElementById("loading")
					loading.style.display = 'none'
				})
			},
			filterFun(name){
				let arr = []
				for (const item of this.orderList) {
					arr.push(item[name])
				}
				arr= new Set(arr);
				let newArr = []
				arr.forEach(ite=>{newArr.push({checked:false,value:ite})})
				return newArr
			},
			changeList(arr) {
				this.orderList = arr
			},
			butClick(item) {
				this.getOrderDetail(item)
			},
			// 获取任务单详情
			getOrderDetail(item) {
				console.log(item)
				var data = {
					pieceWay: item.pieceWay,
					groupId: item.groupId,
					orderId: item.orderId,
					structureId: item.structureId
				}
				getOrderTaskProcess(data).then(res => {

					this.orderDetail = true
					console.log(res)
					this.process = res.data
					// this.orderDetail = true
				})
			},
			// 周 选择日期
			getChangeDate(e) {
				console.log(e)
				this.value = e
				this.getList()
			},
			// 选择日期
			changeDate(e) {
				this.visiblePick = false
				this.$refs.weekPicker1.getList(e,this.value);
				this.$refs.weekPicker2.getList(e,this.value);
				this.$refs.weekPicker3.getList(e,this.value);
				this.getList()
			},
			// 选择日期
			changeDate1(e) {
				this.$refs.weekPicker1.getList(e,this.value);
				this.$refs.weekPicker2.getList(e,this.value);
				this.$refs.weekPicker3.getList(e,this.value);
				// this.getList()
			},
			// 报工提交 
			getMsg() {
				var list = []
				for (let item of this.reportList) {
					var count = 0
					console.log(item.count)
					// 未填写完成数默认未完工数
					if (item.count == null || item.count == '') {
						count = item.amount
					} else {
						count = item.count
					}
					var data = {
						amount: count,
						groupId: item.groupId,
						orderId: item.orderId,
						structureId: item.structureId,
						pieceWay: item.pieceWay
					}
					list.push(data)
				}
				proSet(list).then(res => {
					this.orderList = []
					if (res.code == 0) {
						this.getList()
						this.select = []
						if (res.data == null || res.data[0] == null) {
							this.success = true
							var that = this
							setTimeout(() => {
								that.success = false
							}, 2000);
						} else {
							var data = {
								title: '糟糕~报工失败',
								msg: '我知道了',
								failmsg:res.data[0].msg,
								type: 'fail'
							}
							this.$refs.feedback.handleClick(data);
						}


					} else {
						this.getList()
					}
					this.getList()
				})

			},
			// 一键报工弹出框
			allReport() {
				if (this.select.length > 0) {
					// for (let i = 0; i < this.orderList.length; i++) {
					// 	this.select.push(i)
					// }
					var data = {
						name: '确认报工',
						msg: '是否报工当前选中任务单，共' + this.select.length + '条',
						type: 'Warning'
					}
					this.$refs.massageCard.handleClick(data);
				}
			},
			// getSuccess() {
			// 	this.success = true
			// 	// this.$refs.success1.setMsg('提报成功');
			// 	setTimeout(() => {
			// 		console.log(1211)
			// 		this.success = false
			// 	}, 2000);
			// },
			// 是否确认一键报工点击确认
			handleOk() {
				// var list = []
				// for (let i = 0; i < this.select.length; i++) {
				// 	console.log(this.select[i])
				// 	var data = this.orderList[this.select[i]]
				// 	list.push(data)
				// }
				// console.log(list)
				// this.reportList = list
				// this.getMsg()
				if(this.finish == false){
					this.finish = true
				}else{
					for (let i = 0; i < this.select.length; i++) {
						this.reportList.push(this.orderList[this.select[i] - 1])
					}
					this.getMsg()
				}
				

			},
			// 打开列配置
			handleClick() {
				this.visible = true;
			},
			// 列配置提交
			handleSubmit() {
				console.log(this.reportConfig)
				localStorage.setItem('reportConfig', JSON.stringify(this.reportConfig))
				this.visible = false;
			},
			// 列配置取消
			handleCancel() {
				var reportConfig = localStorage.getItem("reportConfig");
				if (reportConfig != null) {
					console.log(JSON.parse(reportConfig));
					this.reportConfig = JSON.parse(reportConfig);
				}
				this.visible = false;
			},
			changeRadio(e) {
				console.log(e)
				this.state = e
				this.getList()
			}
		},
	};
</script>
<style>
	.arco-trigger-position-top .arco-popover-popup-content {
		background-color: #00000099 !important;

	}

	.arco-trigger-position-top .arco-popover-popup-arrow {
		background-color: #00000099 !important;
	}

	.arco-trigger-position-top .arco-popover-title {
		color: #ffffff !important;
	}

	.task-list {
		position: relative;
	}

	.right {
		position: absolute;
		right: 8px;

	}

	.title .right {
		right: -15px;
		top: 15px;
		font-size: 24px;
	}

	

	.input-box {
		/* border: 1px solid #ffffff; */
		/* width: 100%; */
		border-radius: 50px;
		/* overflow: hidden; */
		margin-right: 0px !important;
		box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.1);
		color: #2189f3;
		text-align: start;
		display: block !important;
	}

	.ellipsis {
		border: none !important;
		color: #616c72 !important;
		/* overflow: hidden; */
		background-color: #FFFFFF00 !important;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: start !important;
		width: 100% !important;
		padding-right: 10px !important;
	}

	.ellipsis:hover {
		background-color: #FFFFFF00;
	}

	.arco-modal-body {
		padding: 0 !important;
	}
</style>
<style scoped lang="less">
	@import url("../../styles/productionTask.less");
	@import url("../../styles/all.less");	
	// 选中颜色
	// /deep/ .arco-icon-hover::before{
	// 	background-color: #2189F300;
	// }
	/deep/ .arco-btn-secondary {
		display: block !important;
		padding: 0;
		height:48px!important;
	}

	/deep/ .arco-picker-header {
		border: none;
	}

	/deep/ .arco-pagination-list span {
		display: none;

	}

	/deep/ .arco-pagination-item {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: #FFFFFF;
		margin: 5px;
	}

	/deep/ .arco-pagination-item-active {
		color: #165DFF;
	}

	/deep/ .arco-pagination-item:not(:last-child) {
		margin: 5px;
	}

	.msg /deep/ .arco-table-tr .arco-table-td {
		border-bottom: 1px solid #e5e6e9 !important;
	}

	.msg /deep/ .arco-table-tr {
		border-left: 1px solid #F5F5F5 !important;
	}

	.msg /deep/ .arco-table-body table {
		border: 1px solid #F5F5F5 !important;
		border-bottom: none !important;
	}

	.msg /deep/ .arco-input-wrapper {
		width: 83px !important;
		height: 40px !important;
		border-radius: 4px !important;

		.arco-input-size-medium {

			text-align: center !important;
		}
	}

	.msg .bottom /deep/ .arco-btn {
		width: 80px !important;
	}

	/deep/ .arco-btn-size-medium{
		height: 32px!important;
	}
	/deep/ .arco-table-cell {
		font-size: 16px;
		font-family: 'SourceHanSansCN';
		font-weight: 400;
		color: #616C72;
		line-height: 19px;
		text-align: start;
		display: flex;
		align-items: flex-start;
	}

	/deep/ .arco-table-th {
		background-color: #FFFFFF00;
		font-size: 16px;
		font-family: 'SourceHanSansCN';
		font-weight: 700;
		color: #000000;
		line-height: 19px;
		height: 56px;
	}

	/deep/ .arco-table-td {
		border: none;
		background-color: #FFFFFF00;
		font-size: 16px;
		font-family: 'SourceHanSansCN';
		font-weight: 400;
		color: #00000080;
		line-height: 19px;
	}

	/deep/ .arco-table-tr {
		height: 56px;

	}

	/deep/ .arco-input-wrapper {
		color: #2189F3;

		@media (max-width: 1400px) {
			width: 160px !important;
		}

		@media (min-width: 1200px) and (max-width: 1300px) {
			width: 140px !important;
		}

		@media (max-width: 1200px) {
			width: 120px !important;
		}
	}

	/deep/ .arco-table-size-mini .arco-table-cell {
		padding: 2px 6px;
		width: auto;
	}

	/deep/ .arco-table-cell .arco-input-wrapper {
		// color: #2189F3;
		width: 83px;
	}

	/deep/ .arco-picker {
		background-color: #FFFFFF00;
		font-size: 24px;
		font-family: Source Han Sans CN-Medium, Source Han Sans CN;
		font-weight: 500;
		color: #3F71F0;
		line-height: 28px;
	}

	/deep/ .arco-picker input {
		font-size: 24px;
		font-family: Source Han Sans CN-Medium, Source Han Sans CN;
		font-weight: 500;
		color: #3F71F0;
		line-height: 28px;
	}

	.date-picker {
		position: relative;
		transform: all 0.5s;
	}

	.date-picker /deep/ .arco-picker-cell {
		background-color: #ffffff00;
	}

	.date-picker /deep/ .arco-picker-date-value {
		width: 28px;
		height: 28px;
		font-size: 14px;
		font-family: Source Han Sans CN-Medium, Source Han Sans CN;
		font-weight: 500;
		line-height: 28px;

		@media (min-width: 1300px) {
			margin: 0 4.3vw;
		}

		@media (min-width: 1200px) and (max-width:1500px) {
			margin: 0 3.5vw;
		}

		@media (min-width: 1024) and (max-width:1200px) {
			margin: 0 2.5vw;
		}

		@media (min-width: 820px) and (max-width:1180px) {
			margin: 0 2vw;
		}

		@media (min-width: 600px) and (max-width:768px) {
			margin: 0 1.5vw;
		}

		@media (max-width:600px) {
			margin: 0;
		}
	}

	.date-picker /deep/ .arco-picker-cell {
		background-color: #ffffff00;
	}

	.date-picker /deep/ .arco-panel-date {
		width: 100%;
	}

	.date-picker /deep/ .arco-panel-date-inner {
		width: 100%;
	}

	.date-picker /deep/ .arco-picker-row {
		width: 100%;
	}

	/deep/ .arco-picker-week-list-item {
		flex: 1;
		height: 32px;
		padding: 0 16px;
		font-size: 18px;
		color: #7d7d7f;
		font-weight: 400;
		line-height: 32px;
		text-align: center;
		margin: 0 4.3vw;
		width: 64px;
		height: 64px;

		@media (min-width: 1300px) {
			margin: 0 4.3vw;
		}

		@media (min-width: 1200px) and (max-width:1500px) {
			margin: 0 3.5vw;
		}

		@media (min-width: 820px) and (max-width:1200px) {
			margin: 0 2.7vw;
		}

		@media (min-width: 600px) and (max-width:768px) {
			margin: 0 1.5vw;
		}

		@media (max-width:600px) {
			margin: 0;
		}
	}

	/deep/ .arco-picker-container,
	.arco-picker-range-container {
		background-color: #ffffff00;
		border: none;
		box-shadow: none;
	}

	/deep/ .arco-picker-footer {
		display: none;
	}

	.refresh {
		height: 50px;
		background-color: #C8D7FF;
		font-size: 18px;
		font-family: Source Han Sans CN-Medium, Source Han Sans CN;
		font-weight: 500;
		color: #2189F3;
	}

	.report {
		width: 520px;
		position: absolute;
		// bottom: 300px;
	}

	.report-width {
		width: 768px;

		@media (min-width: 820px) and (max-width:1180px) {
			width: 560px;
		}

		@media (max-width:768px) {
			width: 460px;
		}
	}

	.title {
		width: 100%;
		height: 56px;
		background: #fff;
		border-radius: 8px 8px 0px 0px;
		font-size: 18px;
	
		font-weight: 500;
		color: #000;
		text-align: start;
		line-height: 56px;
		padding: 0 24px;
	}

	.msg {
		width: 100%;
		padding: 10px 0;
		overflow: auto;
		background-color: #FFFFFF;
	}

	.bottom {
		width: 100%;
		height: 78px;
		background: #FFFFFF;
		box-shadow: 0px -2px 4px 1px #336bef29;
		border-radius: 0px 0px 8px 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 24px;
	}

	.cancel {
		width: 80px;
		height: 48px;
		background: #0000004d;
		border-radius: 8px;
		color: #ffffff;
		margin-right: 15px;
		border: none;
	}

	.primary {
		width: 80px;
		height: 46px;
		border-radius: 8px 8px 8px 8px;
		background-color: #3296FA !important;
	}

	/deep/ .arco-input-focus {
		border: 1px solid #2189F3 !important;
		background-color: #FFFFFF !important;
	}
	/deep/ .arco-btn-secondary{
		border: 1px solid #FFFFFF20 !important;
	}
	/deep/ .arco-input-wrapper{
		border: 1px solid #FFFFFF20 !important;
	}
	/deep/ .arco-input-wrapper:focus-within {
		border: 1px solid #2189F3 !important;
		background-color: #FFFFFF !important;
	}

	/deep/.arco-table-th.arco-table-col-sorted {
		background-color: #00000000;
	}

	/deep/.arco-table-td.arco-table-col-sorted {
		background-color: #00000000;
	}

	/deep/.arco-table-cell-with-sorter:hover {
		background-color: #00000000;
	}

	/deep/ .arco-table-body::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 5px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 1px;
	}

	/deep/ .arco-table-body::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px;
		background-color: #00000060;
		background-image: -webkit-linear-gradient(45deg,
				#ffffff33 25%,
				transparent 25%,
				transparent 50%,
				#ffffff33 50%,
				#ffffff33 75%,
				transparent 75%,
				transparent);
	}

	/deep/ .arco-table-body::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow: inset 0 0 5px #00000033;
		background: #ededed;
		border-radius: 10px;
	}

	.table {
		position: relative;

		.pagination {
			position: absolute;
			right: 0;
			background-color: #00000090;
			z-index: 99;
			border-radius: 90px;
			padding: 10px 5px;
			top: 50px;
		}
	}

	.weekMonth {
		width: 50px;
		height: 4px;
		margin: 0px auto;
		color: rgb(0, 0, 0);
		z-index: 999;
		background-color: whitesmoke;
		border-radius: 50px;
	}

	/deep/ .group {
		@media (max-width: 1400px) {
			width: 140px !important;
		}

		@media (min-width: 1200px) and (max-width: 1300px) {
			width: 120px !important;
		}

	}

	/deep/ .arco-icon-calendar {
		color: #3f71f0;
		padding-top: 2px;
	}

	/deep/ .arco-form-item-label-col {
		display: none;
	}

	.date {
		position: relative;
	}

	.dateCover {
		position: absolute;
		top: 10px;
		left: 12px;
		z-index: 9;
		background-color: #FFFFFF;
		width: 200px;
		height: 40px;
		text-align: start;
		font-size: 20px;
		font-family: Source Han Sans CN-Medium, Source Han Sans CN;
		font-weight: 500;
		color: #3F71F0;
		line-height: 40px;
		cursor: pointer;
	}

	.input-box {
		margin-right: 10px !important;
	}

	/deep/ .arco-spin {
		height: 100%;
	}

	/deep/ .arco-table-container {
		height: 100%;
	}

	/deep/ .arco-table-container::-webkit-scrollbar {
		width: 0px;
	}

	/deep/ .arco-table-body {
		background-color: #FFFFFF00 !important;
		// height: 380px;
		// overflow-x: hidden;
	}

	/deep/ .arco-table-header {
		background-color: #FFFFFF00 !important;
	}

	/deep/ .cancel {
		background: #c9caca;
	}

	/deep/ .arco-table-cell .arco-input-wrapper {
		border-color: #c9caca !important;
		padding: 0 !important;
	}

	.card {
		z-index: 99;
	}

	/deep/ .arco-picker-start-time {
		display: none;
	}

	/deep/ .arco-table-filters {
		position: relative !important;
		display: inline-block !important;
		height: auto !important;
		margin-left: 10px;
	}

	/deep/ .arco-table-cell {
		display: inline-block;
	}

	/deep/ .arco-table-td .arco-table-cell {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.bottom /deep/ .arco-btn-primary {
		height: 40px;
		font-size: 14px;
	}

	.bottom /deep/ .arco-btn-secondary {
		height: 40px;
		font-size: 14px;
	}

	// .date-picker /deep/ .arco-picker-date{
	// 	.arco-picker-date-value{
	// 		color: #ffffff00;
	// 	}
	// 	// display: none;
	// }
	// .date-picker /deep/ .arco-picker-cell-in-view{
	// 	.arco-picker-date-value{
	// 		color: #333333;
	// 	}
	// }
	// .date-picker /deep/ .arco-picker-cell-selected{
	// 	.arco-picker-date-value{
	// 		color: #ffffff;
	// 	}
	// }
	/deep/ .arco-popover-popup-content {
		background-color: #00000099 !important;
	}

	/deep/ .arco-modal-body {
		padding: 0 !important;
	}
	.status{
		margin-top: 0;
	}
	.title /deep/ .arco-icon-close{
		background-color: #FFFFFF00;
	}
	.bottom /deep/ .arco-btn-size-medium{
		padding: 0!important;
	}
	/deep/ .arco-table-border:not(.arco-table-border-cell) .arco-table-container{
		border: none!important;
	}
	/deep/ .arco-table-border .arco-table-scroll-y{
		border: none!important;
	}
	/deep/ .arco-table-border .arco-table-container{
		border: none!important;
	}
	/deep/ .arco-table-cell{
		// line-height: 40px!important;
	}
	/deep/ .arco-picker{
		border: none;
	}
	@media (max-width: 1400px){
		/deep/ .arco-table-tr{
			height: 50px!important;
		}
		/deep/ .arco-table-td{
			height: 50px!important;
		}
		/deep/ .arco-table-cell{
			height: 30px!important;
		}
	}
	.per_header{
		height: 50px;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		margin: 0 12px;
		margin-bottom: 8px;
		font-size: 16px;
		font-weight: bold;
		color: #3B75FD;
		padding-left: 25px;
		position: relative;
		display: flex;
		align-items: center;
		.per_header_box{
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			left: 0;
			right: 0;
			.per_header_boxs{
				margin: 0 40px;
			}
		}
	}
	.search-box{
		height: 58px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 12px 10px 12px;
		line-height: 58px;
		.search-box-left{
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 48%;
			color:#fff;
			font-size: 16px;
			.search-box-l1{
				width: 45%;
				height: 57px;
				background: #4FACFE;
				border-radius: 4px 4px 4px 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				.seeW{
					width: 65px;
					height: 30px;
					background: #96CEFF;
					border-radius: 4px;
					line-height: 30px;
					font-size: 14px;
					text-align: center;
					margin-left: 18px;
				}
			}
			.search-box-l2{
				width: 25%;
				height: 57px;
				background: #4FACFE;
				border-radius: 4px 4px 4px 4px;
				display: flex;
				justify-content: center;
			}
			.search-box-l3{
				width: 24%;
				height: 57px;
				background: #2EC384;
				border-radius: 4px 4px 4px 4px;
				display: flex;
				justify-content: center;
			}
			.num{
				font-size: 24px;
				font-weight: 600;
				margin-left: 20px;
			}
		}
		.search-box-right{
			width: 51%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.search-box-r1{
				width: 20%;
				height: 57px;
				background: #fff;
				border-radius: 4px 4px 4px 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.search-date{
					padding-left: 8px;
					margin: 0 12px;
					width: 100%;
					height: 32px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid var(--color-neutral-3);
					line-height: 32px;
					color: #4E5969;
				}
			}
			.search-box-r2{
				width: 57%;
				height: 57px;
				background: #fff;
				border-radius: 4px 4px 4px 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				input{
					width: 60%;
					height: 32px;
					background: #F2F3F5;
					border-radius: 2px 0px 0px 2px;
					border: 0;
					outline: none;
					padding-left: 15px;
				}
				.but-input{
					width: 69px;
					height: 32px;
					background: #3B75FD;
					opacity: 1;
					line-height: 32px;
					text-align: center;
					color: #fff;
					border-radius: 0 2px 2px 0;
				}
			}
			.search-box-r3{
				width: 18%;
				height: 57px;
				background: #fff;
				border-radius: 4px 4px 4px 4px;
				display: flex;
				align-items: center;
				
				.but{
					width: 83px;
					height: 32px;
					background: #3B75FD;
					border-radius: 2px 2px 2px 2px;
					line-height: 32px;
					text-align: center;
					color: #fff;
					margin: auto;
				}
			}
		}
	}
</style>
