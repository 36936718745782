<template>
  <div class="page" :style="{ height: screenHeight + 'px' }" style="overflow-y: scroll;">
    <div class="task" v-if="factory.length>0&&fold">
      生产任务驾驶舱
      <div
        class="factory flex"
        :class="{ active: item.structureId == factoryId }"
        @click="(factoryId = item.structureId), getList()"
        v-for="item in factory"
        :key="item"
      >
        {{ item.structureName }}
        <div class="factory-active" v-if="item.structureId == factoryId"></div>
      </div>
    </div>
    <div class="calendar" v-show="false">
      <div class="date flex">
        <div class="dateCover" @click="visiblePick = true">
          {{value}}
          <icon-caret-down style="font-size: 24px;margin-left: 10px;" />
        </div>
        <!-- <a-date-picker
          @change="changeDate"
          v-model="value"
          :popup-visible="visiblePick"
          style="width: 180px; position: absolute; left: 20px; top: 10px"
        /> -->
        <div class="tags flex">
          <div :class="[type == 1 ? 'tags1' : 'tags2']" @click="type = 1">
            月
            <div></div>
          </div>
          <div :class="[type == 1 ? 'tags2' : 'tags1']" @click="(type = 2), changeDate1(value)">
            周
            <div></div>
          </div>
        </div>
      </div>
      <div class="date-picker">
        <div
          class="flex"
          style="width: 294%;position: relative;margin-left: -98%;"
          :style="{left:moveSize+'px'}"
          @touchstart="touchstartHandle"
          @touchmove="touchmoveHandle"
          @touchend="touchendHandle"
        >
          <a-date-picker
            v-model="value"
            v-if="type == 1"
            v-model:pickerValue="pickerValue"
            hide-trigger
            @change="getList()"
            style="width: 98%; margin-top: -50px;mar"
          />
          <a-date-picker
            v-model="value"
            v-if="type == 1"
            v-model:pickerValue="pickerValue"
            hide-trigger
            @change="getList()"
            style="width: 98%; margin-top: -50px;mar"
          />
          <a-date-picker
            v-model="value"
            v-if="type == 1"
            v-model:pickerValue="pickerValue"
            hide-trigger
            @change="getList()"
            style="width: 98%; margin-top: -50px;mar"
          />
        </div>

        <div
          v-show="type==2"
          class="flex"
          style="width: 294%;position: relative;margin-left: -98%;"
          :style="{left:moveSize+'px'}"
          @touchstart="touchstartHandle"
          @touchmove="touchmoveHandle"
          @touchend="touchendHandle1"
        >
          <weekPicker ref="weekPicker1" style="width: 98%;" @changeDate="getChangeDate"></weekPicker>
          <weekPicker ref="weekPicker2" style="width: 98%;" @changeDate="getChangeDate"></weekPicker>
        </div>
      </div>
      <div class="weekMonth" @click="type = (type == 2 ? 1 : 2),changeDate1(value)"></div>
    </div>


        
    <div class="per_header">
      生产任务
      <div class="per_header_box">
        <div class="per_header_boxs" v-for="item in factory" :key="item">{{ item.structureName || '生产批次' }}</div>
      </div>
	    <div class="per_right" @click="visiblePick = true">{{value}}</div>
      <a-date-picker v-model="value" style="position: absolute;top: 80px;right:100px"
			:popup-visible="visiblePick" @change="changeDate" />
    </div>

    <div class="search_box">
      <div class="search_left">
        <div class="tit">订单总数</div>
        <div class="num">{{orderList.length}}</div>
      </div>
      <div class="search_right">
        <input
          type="text"
          v-model="form.orderNo"
          placeholder="订单编号"
          @keyup.enter.native="getList()"
        />
        <input
          type="text"
          v-model="form.styleName"
          placeholder="款式名称"
          @keyup.enter.native="getList()"
        />
        <input
          type="text"
          v-model="form.saleOrderNo"
          placeholder="销售订单编号"
          @keyup.enter.native="getList()"
        />
        <a-dropdown>
          <input type="text" v-model="nodeName" placeholder="销售订单编号" />
          <template #content>
            <div v-for="item in nodeList" :key="item">
              <a-doption
                v-if="item.children.length==0"
                @click="form.nodeId = item.nodeId,nodeName = item.nodeName,getList()"
              >{{item.nodeName}}</a-doption>
              <a-dsubmenu v-else :value="item.nodeId">
                <template #default>{{item.nodeName}}</template>
                <template #content>
                  <div v-for="arr in item.children" :key="arr">
                    <a-doption
                      v-if="arr.children==null"
                      @click="form.nodeId = arr.nodeId,nodeName = arr.nodeName,getList()"
                    >{{arr.nodeName}}</a-doption>
                    <a-dsubmenu v-else :value="arr.nodeId" trigger="hover">
                      <template #default>{{arr.nodeName}}</template>
                      <template #content>
                        <a-doption
                          v-for="(obj,index) in arr.children"
                          :key="index"
                          @click="form.nodeId = obj.nodeId,nodeName = obj.nodeName,getList()"
                        >{{obj.nodeName}}</a-doption>
                      </template>
                    </a-dsubmenu>
                  </div>
                </template>
              </a-dsubmenu>
            </div>
          </template>
        </a-dropdown>
      </div>
      <div class="search_pr">
        <div class="left" @click="(form = {}), getList()">重置</div>
        <div class="right" @click="visible = true">列配置</div>
      </div>
    </div>

    <div class="task-list" :style="{ height: screenHeight - 135 + 'px' }">
      <div class="flex">
        <div style="display: flex;align-item: center; flex: 1;justify-content: flex-end; ">
          <a-drawer :visible="visible" @ok="handleOk" @cancel="handleCancel" unmountOnClose :footerStyle='false'>
            <template #title>列显隐</template>
            <div>
              <div v-for="(item,index) in header" :key='index'>
                <a-checkbox default-checked v-model="item.show">{{item.lable}}</a-checkbox>
              </div>
            </div>
         
          </a-drawer>
          
    
        </div>
      </div>


		<list v-if="orderList.length>0" :border='true' @changeList='changeList' @butClick='butClick' :headerData='header' :oldList='oldList' :content='orderList' :tabHight='screenHeight - 130 '/>
      <a-modal :visible="showDetail" class="add-box"  @ok='showDetail = false'  :width="1100">
        <template #title>
          <div class="model-title">生产任务-订单详情</div>
        </template>
        <div class="scrollbar">
          <detailCard
            :type="{ id: 1, name: '生产任务-订单详情' }"
            ref="detailCard"
            class="card"
            @close="showDetail = false"
          ></detailCard>
        </div>
      </a-modal>

      
    </div>
  </div>
  
</template>
<script>
import vueTouch from "vue-touch";
import { getStructure } from "../../api/index.js";
import { getOrderList, getorderNodeList } from "../../api/task.js";
import detailCard from "../../common/detailCard.vue";
import weekPicker from "../../common/weekPicker.vue";
import list from "../../common/list.vue";
export default {
  components: {
    detailCard,
    weekPicker,
	vueTouch,
	list
  },
  data() {
    return {
		value1:new Date().Format('yyyy-MM-dd'),
      header:[
        {
          lable:"序号",
          width:'80px',
          param:"index",
          filter:false,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"状态",
          width:'100px',
          param:"completeState",
          filter:false,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"小组/员工",
          width:'160px',
          param:"groupName",
          filter:true,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"销售订单号",
          width:'200px',
          param:"saleOrderNo",
          filter:true,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"订单编号",
          width:'150px',
          param:"orderNo",
          filter:true,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"生产车间",
          width:'150px',
          param:"productionWorkshop",
          filter:true,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"款式名称",
          width:'300px',
          param:"styleName",
          filter:true,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"部件",
          width:'100px',
          param:"unitName",
          filter:true,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"订单数",
          width:'130px',
          param:"orderAmount",
          filter:true,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"任务数",
          width:'130px',
          param:"taskAmount",
          filter:true,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"完工数",
          width:'130px',
          param:"completeAmount",
          filter:true,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"时段",
          width:'100px',
          param:"timeInterval",
          filter:true,
          filterIcon:true,
          filterList:[],
          show:true
        },
        {
          lable:"详情",
          width:'100px',
          param:"but",
          filter:false,
          filterIcon:true,
          filterList:[],
          show:true
        },
      ],

      fold: false,
      pages: 0,
      total: 0,
      size: 0,
      is_show: true,
      current: 1,
      screenWidth: document.body.clientWidth, //获取屏幕宽度
      screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
      orderId: 0, //详情的id
      orderTotalAmount: 0, //订单数量合计
      factoryId: 0, //当前选择工厂的id
      factory: [], //工厂列表
      weekFirstDays: 0, //选择周的第一天
      weekday: "", //第几周
      showDetail: false, //展示详情
      value: "", //选择的时间
      visiblePick: false,
      visiblePick1:false,
     
      type: 2, //1月，2周
      form: {}, //搜索
      pickerValue: null,
      detail: {}, //订单详情
      orderList: [], //订单列表
      oldList:[],
      visible: false, //列配置
      columnConfig: {
        index: true,
        status: true,
        groupName: true,
        saleOrderNo: true,
        orderNo: true,
        customerName: true,
        productionWorkshop: true,
        styleName: true,
        colorFeatures: true,
        unitName: true,
        orderAmount: true,
        taskAmount: true,
        completeAmount: true,
        timeInterval: true,
        sailingDate: true
      }, //列配置
      start: 0,
      moveSize: 0,
      nodeList: [],
      nodeName: "分单节点"
    };
  },
  mounted: function() {
    var date = new Date().Format("yyyy-MM-dd");
    this.value = date;
    this.weekday = date;
    // var columnConfig = localStorage.getItem("columnConfig");

    // if (columnConfig != null) {
    //   console.log(JSON.parse(columnConfig));
    //   this.columnConfig = JSON.parse(columnConfig);
    // } else {
    //   localStorage.setItem("columnConfig", JSON.stringify(this.columnConfig));
    // }

 
    
    this.getFactory();
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.documentElement.clientHeight;
        this.screenHeight = window.screenHeight;
      })();
    };
    this.getList()
  },
  methods: {

    closeTool() {
      var obj = document.getElementsByClassName("arco-tooltip");
      console.log(obj);
      for (let i = 0; i < obj.length; i++) {
        obj[i].style.display = "none";
      }
    },
    getNodeList() {
      getorderNodeList().then(res => {
        this.nodeList = res.data;
        var nodeId = 0;
        if (res.data[0].children == null) {
          nodeId = res.data[0].nodeId;
          this.nodeName = res.data[0].nodeName;
        } else if (res.data[0].children[0].children == null) {
          nodeId = res.data[0].children[0].nodeId;
          this.nodeName = res.data[0].children[0].nodeName;
        } else {
          nodeId = res.data[0].children[0].children[0].nodeId;
          this.nodeName = res.data[0].children[0].children[0].nodeName;
        }
        this.form.nodeId = nodeId;
        this.changeDate(new Date());
      });
    },
    touchstartHandle(e) {
      this.start = e.changedTouches[0].clientX;
    },
    touchmoveHandle(e) {
      var end = e.changedTouches[0].clientX;
      var move = end - this.start;
      this.moveSize = move;
    },
    touchendHandle1(e) {
      var end = e.changedTouches[0].clientX;
      if (end - this.start > 100) {
        this.weekright();
      }
      if (end - this.start < -100) {
        this.weekleft();
      }
      this.moveSize = 0;
    },
    touchendHandle(e) {
      var end = e.changedTouches[0].clientX;
   
      if (end - this.start > 100) {
        this.right();
      }
      if (end - this.start < -100) {
        this.left();
      }
      this.moveSize = 0;
    },
    left() {
      var arr = this.value.split("-");
      var newData = new Date(arr[0], parseInt(arr[1]) + 1, 1).getTime();
      var last = new Date(newData - 1000 * 60 * 60 * 24).getDate();
      var data = new Date(arr[0], arr[1], 1).getTime();
      var lastDay = new Date(data - 1000 * 60 * 60 * 24).getDate();
      console.log(last);
      console.log(lastDay);
      if (last < parseInt(arr[2])) {
        var nextDay =
          new Date(this.value).getTime() + last * 24 * 60 * 60 * 1000;
        nextDay = new Date(nextDay);
        console.log(nextDay.Format("yyyy-MM-dd"));
        this.value = nextDay.Format("yyyy-MM-dd");
      } else {
        arr[1] = parseInt(arr[1]) + 1;
        if (arr[1] > 12) {
          arr[0] = parseInt(arr[0]) + 1;
          arr[1] = 1;
        }
        this.value =
          arr[0] + "-" + (arr[1] > 9 ? arr[1] : "0" + arr[1]) + "-" + arr[2];
        console.log(this.value);
      }
      this.getList();
    },
    right() {
      console.log(this.value);
      var arr = this.value.split("-");
      var newData = new Date(arr[0], parseInt(arr[1]) + 1, 1);
      var last = new Date(newData - 1000 * 60 * 60 * 24).getDate();
      var lastData = new Date(arr[0], parseInt(arr[1]) - 1, 1);
      var lastDay = new Date(lastData - 1000 * 60 * 60 * 24).getDate();
      arr[1] = parseInt(arr[1]) - 1;
      console.log("最后一天");
      console.log(lastDay);
      console.log(last);
      console.log(arr[2]);
      if (lastDay < parseInt(arr[2])) {
        var nextDay =
          new Date(this.value).getTime() - arr[2] * 24 * 60 * 60 * 1000;
        nextDay = new Date(nextDay);
        console.log(nextDay.Format("yyyy-MM-dd"));
        this.value = nextDay.Format("yyyy-MM-dd");
      } else {
        console.log(arr[1]);
        if (arr[1] < 1) {
          arr[0] = parseInt(arr[0]) - 1;
          arr[1] = 12;
        }
        this.value =
          arr[0] + "-" + (arr[1] > 9 ? arr[1] : "0" + arr[1]) + "-" + arr[2];
      }
      console.log(this.value);
      this.getList();
    },
    weekleft() {
      var data = new Date(this.weekday);
      data = data.getTime() + 7 * 24 * 60 * 60 * 1000;
      data = new Date(data).Format("yyyy-MM-dd");
      this.weekday = data;
      this.value = data;
      this.changeDate1(data);
    },
    weekright() {
      var data = new Date(this.weekday);
      data = data.getTime() - 7 * 24 * 60 * 60 * 1000;
      data = new Date(data).Format("yyyy-MM-dd");
      this.weekday = data;
      this.value = data;
      this.changeDate1(data);
    },
    changePage(e) {
      console.log(e);
      this.current = e;
      (this.orderList = []), this.getList();
    },
    previous() {
      if (this.current > 1) {
        this.current--;
        this.getList();
      }
    },
    next() {
      if (this.current < this.pages) {
        this.current++;
        this.getList();
      }
    },
    // 选择日期
    getChangeDate(e) {
      console.log(e);
      this.value = e;
      this.getList();
    },
    // 获取工厂信息
    getFactory() {
      getStructure({
        type: 7
      }).then(res => {
        console.log(res);
        this.factory = res.data;
        this.factoryId = res.data[0].structureId;
        this.getNodeList();
      });
    },
    // 获取列表
    getList() {

      var data = this.form;
      data.sailingDate = this.value;
      data.current = this.current;
      data.factoryId = this.factoryId;
      data.size = 100;
      getOrderList(data).then(res => {
        let i = 1;
        res.data.orderPage.records.forEach(element => {
          element.key = i++;
        });
        var arr = res.data.orderPage.records;
        var list = this.$unique(arr);
        var index = 0;
        for (let a in this.filterable) {
          for (let i = 0; i < list.length; i++) {
            if (a == list[i].name) {
              this.filterable[a].filters = list[i].list;
            }
          }
        }
        this.orderList = res.data.orderPage.records;
        this.oldList = res.data.orderPage.records;
        for (const i of this.header) {
           i.filterList = this.filterFun(i.param)
        }
        this.size = res.data.orderPage.size;
        this.total = res.data.orderPage.total;
        this.pages = res.data.orderPage.pages;
        this.orderTotalAmount = res.data.orderTotalAmount;
      });
    },
    filterFun(name){
        let arr = []
        for (const item of this.orderList) {
            arr.push(item[name])
        }
        arr= new Set(arr);
        let newArr = []
        arr.forEach(ite=>{newArr.push({checked:false,value:ite})})
        return newArr
    },
    // 获取详情
    getOrderDetail(orderId) {
     
      this.$refs.detailCard.getDetail(orderId);
      this.showDetail = true;
    },
    // 展示周日历
    changeDate(e) {
      this.visiblePick = false;
      this.$refs.weekPicker1.getList(e, this.value);
      this.$refs.weekPicker2.getList(e, this.value);
      this.$refs.weekPicker3.getList(e, this.value);
      this.getList();
    },
    // 展示周日历
    changeDate1(e) {
      this.$refs.weekPicker1.getList(e, this.value);
      this.$refs.weekPicker2.getList(e, this.value);
      this.$refs.weekPicker3.getList(e, this.value);
      this.getList();
    },
    changeList(arr) {
      this.orderList = arr
    },
    // 列配置相关
    handleClick() {
      this.visible = true;
    },
    handleOk() {
      localStorage.setItem("columnConfig", JSON.stringify(this.columnConfig));
      var columnConfig = localStorage.getItem("columnConfig");
      this.columnConfig = JSON.parse(columnConfig);
      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
    butClick(orderId) {
  
      this.getOrderDetail(orderId)
    }
  }
};
</script>
<style scoped>
.arco-dropdown {
  padding: 10px 20px;
}

.input-box {
  /* border: 1px solid #ffffff; */
  width: 100%;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 10px !important;
  box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.1);
  color: #2189f3;
  text-align: start;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1040px) {
  .arco-form-item-content {
    /* border: 1px solid #ffffff; */
    width: 70% !important;
  }
}

[data-v-62a348ee] .arco-table-col-sorted {
  background: none;
}

.arco-modal-body {
  padding: 0 !important;
}

</style>
<style>

.ellipsis {
  border: none !important;
  color: #616c72 !important;
  overflow: hidden;
  background-color: #ffffff00 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start !important;
  width: 100% !important;
  padding-right: 10px !important;
}

.ellipsis:hover {
  background-color: #ffffff00;
}
.arco-modal-footer{
	background: #fff !important;
}
.arco-modal-header{
  background: #fff !important;
}
</style>
<style scoped lang="less">
@import url("../../styles/productionTask.less");
@import url("../../styles/all.less");

.table {
  position: relative;

  .pagination {
    position: absolute;
    right: 0;
    background-color: #00000090;
    z-index: 99;
    border-radius: 90px;
    padding: 10px 5px;
    top: 50px;
  }
}

/deep/ .arco-pagination-list span {
  display: none;
}

/deep/ .arco-pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
  margin: 5px;
}

/deep/ .arco-pagination-item-active {
  color: #165dff;
}

/deep/ .arco-pagination-item:not(:last-child) {
  margin: 5px;
}

/deep/ .arco-form-item {
  display: block;
}

/deep/ .arco-input-focus {
  border: 1px solid #2189f3 !important;
  background-color: #ffffff;
}

/deep/ .arco-input-wrapper {
  @media (max-width: 1300px) {
    width: 140px !important;
  }
}

/deep/ .arco-btn-shape-square {
  @media (max-width: 1300px) {
    width: 100px !important;
  }
}

.table /deep/ .arco-btn-shape-square {
  width: auto !important;
}

/deep/ .arco-table-cell .arco-btn-text {
  @media (max-width: 1300px) {
    width: 60px !important;
    text-align: start;
  }
}

.census {
  font-size: 15px;

  @media (max-width: 1300px) {
    margin-left: 0px !important;
  }
}

/deep/ .arco-btn-secondary {
  border: 1px solid #ffffff20 !important;
}

/deep/ .arco-input-wrapper {
  border: 1px solid #ffffff20 !important;
}

/deep/ .arco-input-wrapper:focus-within {
  border: 1px solid #2189f3 !important;
  background-color: #ffffff;
}

/deep/ .arco-picker input {
  font-size: 24px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #3f71f0;
  line-height: 28px;
}

/deep/ .arco-table-col-sorted {
  background: none;
}

/deep/ .arco-table-cell {
  font-size: 16px;
  font-family: "SourceHanSansCN";
  font-weight: 400;
  color: #616c72;
  line-height: 19px;
}

/deep/ .arco-picker-week-list-item {
  flex: 1;
  height: 28px;
  padding: 0 16px;
  font-size: 14px;
  color: #7d7d7f;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin: 0 4.3vw;
  width: 64px;
  // height: 64px;

  @media (min-width: 1300px) {
    margin: 0 4.3vw;
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    margin: 0 3.5vw;
  }

  @media (min-width: 820px) and (max-width: 1200px) {
    margin: 0 2.7vw;
  }

  @media (min-width: 600px) and (max-width: 768px) {
    margin: 0 1.5vw;
  }

  @media (max-width: 600px) {
    margin: 0;
  }
}

/deep/ .arco-table-th {
  background-color: #ffffff00;
  font-size: 16px;
  font-family: "SourceHanSansCN";
  font-weight: 700;
  color: #000000;
  line-height: 19px;
  height: 56px;
}

/deep/ .arco-table-td {
  border: none;
  background-color: #ffffff00;
  font-size: 16px;
  font-family: "SourceHanSansCN";
  font-weight: 400;
  color: #00000080;
  line-height: 19px;
}

/deep/ .arco-table-tr {
  height: 56px;
}

/deep/ .arco-input-wrapper {
  width: 100%;
  color: #2189f3;
}

/deep/ .arco-table-size-mini .arco-table-cell {
  padding: 2px 6px;
  width: auto;
}

/deep/ .arco-picker {
  background-color: #ffffff00;
  font-size: 24px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #3f71f0;
  line-height: 28px;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  margin: 0 3.5vw;
}

.date-picker {
  margin-top: 30px;
  width: 100%;
}

.date-picker /deep/ .arco-picker-cell {
  background-color: #ffffff00;
}

.date-picker /deep/ .arco-panel-date {
  width: 100%;
}

.date-picker /deep/ .arco-panel-date-inner {
  width: 100%;
}

.date-picker /deep/ .arco-picker-row {
  width: 100%;
}


.date-picker /deep/ .arco-picker-date-value {
  width: 28px;
  height: 28px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  justify-content: center;

  @media (min-width: 1300px) {
    // margin: 0 4.3vw;
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    margin: 0 3.5vw;
  }

  @media (min-width: 820px) and (max-width: 1200px) {
    margin: 0 2.7vw;
  }

  @media (min-width: 600px) and (max-width: 768px) {
    margin: 0 1.5vw;
  }

  @media (max-width: 600px) {
    margin: 0;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  margin: 0 0.4vw;
}

@media (max-width: 600px) {
  margin: 0;
}

/deep/ .arco-picker-container,
.arco-picker-range-container {
  background-color: #ffffff00;
  border: none;
  box-shadow: none;
}

/deep/ .arco-picker-footer {
  display: none;
}

.weekMonth {
  width: 50px;
  height: 4px;
  margin: 0px auto;
  color: rgb(0, 0, 0);
  z-index: 999;
  background-color: whitesmoke;
  border-radius: 50px;
}

/deep/ .arco-btn-size-medium {
  padding: 0 !important;
  // text-align: start!important;
  // height: none!important;
  justify-content: flex-start;
}

/deep/ .arco-icon-calendar {
  color: #3f71f0;
  padding-top: 2px;
}

/deep/ .arco-table-body {
  overflow: auto !important;
}

.date {
  position: relative;
}

.dateCover {
  position: absolute;
  top: 10px;
  left: 12px;
  z-index: 9;
  background-color: #ffffff;
  width: 200px;
  height: 40px;
  text-align: start;
  font-size: 20px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #3f71f0;
  line-height: 40px;
  cursor: pointer;
}

/deep/ .arco-spin {
  height: 100%;
}

/deep/ .arco-table-container {
  height: 100%;
}

/deep/ .arco-spin {
  height: 100%;
}

/deep/ .arco-table-container {
  height: 100%;
}

/deep/ .arco-table-body {
  background-color: #ffffff00 !important;
  max-height: none !important;

}

/deep/ .arco-table-header {
  background-color: #ffffff00 !important;
}

/deep/ .arco-table-content {
  height: 100%;
  // overflow-y: scroll;
}

/deep/ .arco-picker-start-time {
  display: none;
}

// /deep/ .arco-icon-hover::before {
//   background-color: #ffffff00;
// }

// /deep/ .arco-table-tr{
// 	display: flex;
// 	align-items: center;
// }
/deep/ .arco-table-filters {
  position: relative !important;
  display: inline-block !important;
  height: auto !important;
  margin-left: 10px;
}

/deep/ .arco-table-cell {
  display: inline-block;
}

/deep/ .arco-table-td .arco-table-cell {
  // width: 90%;
  display: flex;
  align-items: center;

  .arco-btn-secondary {
    height: 40px;
  }
}

/deep/ .arco-modal-body {
  padding: 0 !important;
  background: #fff !important;
}

/deep/ .arco-select-view-value {
  line-height: 36px !important;
  padding-left: 0 !important;
}

/deep/ .arco-btn-secondary:hover {
  background-color: #ffffff00 !important;
}

/deep/ .arco-btn-secondary:active {
  background-color: #ffffff00 !important;
}

</style>
