<template>
	<div id="app">
		<div id="loading" class="loading" v-show="is_loading">
			<!-- <div id="lottie" style="position: relative;zoom: 0.6;"></div> -->
	
			<div class="loading-box">
				<img class="loading-img" src="./assets/webp.webp" alt="">
			</div>

		</div>
		<router-view></router-view>
		<!-- <keep-alive>
      <router-view class="scrollbar" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view class="scrollbar" v-if="!$route.meta.keepAlive"></router-view> -->
	</div>
</template>

<script>
	import lottie from "lottie-web";
	import animationData from "./assets/loading.json";
	export default {
		data() {
			return {
				is_loading: false,
			};
		},
		watch: {
			//监听路由变化
			$route(path) {
				console.log("app.vue路由");
				console.log(path.fullPath);
				if (path.fullPath == "/") {
					this.is_loading = false;
				} else {
					this.is_loading = true;
				}
			},
		},
		mounted() {
			console.log("this.baseUrl");
			console.log(this.$baseUrl);
			this.svgFun();

		},
		methods: {
			svgFun() {
				let params = {
					container: document.getElementById("lottie"),
					renderer: "svg",
					loop: true,
					autoplay: true,
					animationData: animationData,
				};
				let anim;
				anim = lottie.loadAnimation(params);
			},
			unique(arr) {
				var arr = [{
					name: '1',
					value: '1'
				}, {
					name: '2',
					value: '2'
				}, {
					name: '3',
					value: '3'
				}, {
					name: '4',
					value: '4'
				}]
				for (let obj in arr) {
					console.log(obj)
				}
				return Array.from(new Set(arr))
			}
		},
	};
	Date.prototype.Format = function(fmt) {
		//author: meizz
		var o = {
			"M+": this.getMonth() + 1, //月份
			"d+": this.getDate(), //日
			"h+": this.getHours(), //小时
			"m+": this.getMinutes(), //分
			"s+": this.getSeconds(), //秒
			"q+": Math.floor((this.getMonth() + 3) / 3), //季度
			S: this.getMilliseconds(), //毫秒
		};
		if (/(y+)/.test(fmt))
			fmt = fmt.replace(
				RegExp.$1,
				(this.getFullYear() + "").substr(4 - RegExp.$1.length)
			);
		for (var k in o)
			if (new RegExp("(" + k + ")").test(fmt))
				fmt = fmt.replace(
					RegExp.$1,
					RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
				);
		return fmt;
	};
</script>
<style scoped>
	@import url("styles/filesIcon.css");
</style>
<style lang="less">
	/deep/ .arco-avatar-text {
		font-size: 14px !important;
	}

	img {
		object-fit: cover !important;
	}

	/deep/ .arco-input-focus {
		border: 1px solid #2189F3 !important;
	}

	/deep/ .arco-input-wrapper {
		border: 1px solid #2189F3 !important;
	}

	/deep/ .arco-input-wrapper:focus-within {
		border: 1px solid #2189F3 !important;
	}

	/deep/ .layout-demo {
		border: 0px solid !important;
	}

	* {
		margin: 0;
		padding: 0;
	}

	html,
	body,
	.page {
		z-index: 1;
		background-color: #00000000;
	}
	.loading-img{
		height: 100px;
		width: 100px;
	}
	.loading {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		display: none;
		align-items: center;
		justify-content: center;
		z-index: 1000;
		.loading-box{
			width: 120px;
			height: 120px;
			background-color: var(--color-mask-bg);
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	#lottie {
		width: 119px;
		height: 105px;
	}

	.test-5::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 10px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 1px;
	}

	.test-5::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px;
		background-color: skyblue;
		background-image: -webkit-linear-gradient(45deg,
				rgba(255, 255, 255, 0.2) 25%,
				transparent 25%,
				transparent 50%,
				rgba(255, 255, 255, 0.2) 50%,
				rgba(255, 255, 255, 0.2) 75%,
				transparent 75%,
				transparent);
	}

	.test-5::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: #ededed;
		border-radius: 10px;
	}

	.scrollbar::-webkit-scrollbar {
		width: 4px;
	}

	.scrollbar::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: rgba(0, 0, 0, 0.2);
	}

	.scrollbar::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: rgba(0, 0, 0, 0.1);
	}

	#app {
		-webkit-touch-callout: none;
		/*系统默认菜单被禁用*/
		-webkit-user-select: none;
		/*webkit浏览器*/
		-khtml-user-select: none;
		/*早期浏览器*/
		-moz-user-select: none;
		/*火狐*/
		-ms-user-select: none;
		/*IE10*/
		user-select: none;
		font-family: PingFang SC-Medium, PingFang SC!important;
	}

	input {
		-webkit-user-select: auto;
		/*webkit浏览器*/
	}

	textarea {
		-webkit-user-select: auto;
		/*webkit浏览器*/
	}
</style>
