<template>
	<div class="page">
		<div class="success">
			<img src="../assets/icon/success.png" class="icon" />
			<div class="msg">{{msg}}</div>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				msg: '提报成功'
			}
		},
		methods: {
			setMsg(msg) {
				console.log(msg)
				// this.msg = msg
			}
		}
	};
</script>
<style scoped>
	.page {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #00000090;
	}

	.success {
		width: 120px;
		height: 130px;
		background: #ffffff33;
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		border: 1px solid #ffffff;
		filter: blur(undefinedpx);
	}

	.icon {
		width: 59px;
		height: 59px;
		margin-top: 17px;
	}

	.msg {
		font-size: 16px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #2189F3;
		line-height: 22px;
		text-align: center;
		margin-top: 23px;
	}
</style>
<style scoped lang="less">
</style>
