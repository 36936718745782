//引用第一步创建的axios.js文件
import request from '../axios.js'
// 车间等级人员详情
export function getDetail(params) {
  return request({
    url: '/minapp/personnelLevel/levelPersonnel',
    method: 'get',
    params
  })
}
// 人员异动分析
export function getMonthList(params) {
  return request({
    url: '/minapp/personnelLevel/personnelTurnoverAnalysis',
    method: 'get',
    params
  })
}
// 工龄分析
export function getYearList(params) {
  return request({
    url: '/minapp/personnelLevel/seniorityAnalysis',
    method: 'get',
    params
  })
}
// 年龄分布
export function getAgeList(params) {
  return request({
    url: '/minapp/personnelLevel/ageDistribution',
    method: 'get',
    params
  })
}
// 技能等级人数对比
export function getStaffList(params) {
  return request({
    url: '/minapp/personnelLevel/levelComparison',
    method: 'get',
    params
  })
}
// 技能分布盘点
export function getList(params) {
  return request({
    url: '/minapp/personnelLevel/getLevelDistribution',
    method: 'get',
    params
  })
}
// 技能分布盘点
export function getCharts(params) {
  return request({
    url: '/minapp/personnelLevel/levelComparisonTrend',
    method: 'get',
    params
  })
}