//引用第一步创建的axios.js文件
import request from '../axios.js'
// 获取生产任务
export function getOrderList(params) {
  return request({
    url: 'minapp/proTask/taskOrderDetailsList',
    method: 'get',
    params
  })
}
// 获取 订单详情
export function getOrderDetail(params) {
  return request({
    url: 'minapp/proTask/orderDetails',
    method: 'get',
    params
  })
}
// 获取 节点
export function getorderNodeList(params) {
  return request({
    url: 'order/node/list',
    method: 'get',
    params
  })
}
