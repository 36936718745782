import axios from 'axios'
import qs from 'qs'
import { Message } from '@arco-design/web-vue'

// 创建 axios 实例
const service = axios.create({
  baseURL:'http://119.96.235.187:9980/mock/845377/',
  // baseURL:'http://127.0.0.1:4523/mock/845377/',
  headers: {
    "TENANT-ID":2,
  },
})
// 不需要loading的接口
var list = [
'minapp/report/saveDraft',
'minapp/daily/saveDraft',
'minapp/permission/saveOftenPermission',
'minapp/permission/deleteOftenPermission',
'minapp/permission/getOftenPermission',
'auth/token/qrcode/is_token_login',
'auth/token/qrcode/gen_login_token',
'auth/mobile/token/sms',
'minapp/noticeConfig/save']
//请求拦截
service.interceptors.request.use(
  config => {
	// 展示Loading
    if(list.indexOf(config.url)==-1){
      var loading = document.getElementById("loading")
      loading.style.display = 'flex'
    }
    
    if(config.url=='minapp/noticeConfig/save'){
      config.headers['Content-Type'] = 'application/json'//加请求头
    }
	// 登录接口请求头跟其他接口不一样
    if(config.url=='auth/mobile/token/sms'){
      config.headers['Authorization'] = 'Basic d2lzY2FuOndpc2Nhbg==';//加请求头
    }else{
      var token = localStorage.getItem('token')//获取token
      if(token){
        config.headers['Authorization'] = 'Bearer ' + token;//加请求头
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


//响应拦截
service.interceptors.response.use(
  response => {
    // 关闭loading
    
  const res = response.data
  // 错误直接弹窗
  if(response.data.code==1&&response.data.msg!="已发布"){
    Message.error(response.data.msg)
	var loading = document.getElementById("loading")
	loading.style.display = 'none'
  }else{
	  var loading = document.getElementById("loading")
	  loading.style.display = 'none'
  }
      return res
  },
  error => {
    var loading = document.getElementById("loading")
    loading.style.display = 'none'
    if(error.response.status==424){
      Message.error('登录过期，请重新登录')
      window.location.href = '/'
	  localStorage.removeItem('token');
    // }else if(error.response.status==404){
    //   window.location.href = '/none'
    // }else if(error.response.status==403){
    //   window.location.href = '/noPermission'
    // }else if(error.response.status==500){
    //   window.location.href = '/error'
    }else{
      Message.error(error.response.data.msg)
      console.log(error.response) // for debug
    }
   
    return Promise.reject(error)
   }
)

export default service