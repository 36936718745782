<template>
	<div class="page" style="padding-bottom: 0!important;overflow-y: hidden;overflow-x: hidden;" :style="{ height: screenHeight + 'px' }">
		<div class="return" >
			<div @click="goBack" class="returnPageUp">
				&lt;  返回上一级
			</div>
			<div class="xtb">写提报</div>
			<div>
				<div class="time">{{time}} {{time==''?'':'已保存'}}</div>
			</div>
		</div>
		<div>
		</div>
		<!-- <div style="height: 70px;"></div> -->
		<div class="scrollbar" :style="{ height: screenHeight  + 'px' }" style="overflow-y: scroll;margin-bottom: 16px;">

			<div class="iscontent" :style="{ height: screenHeight - 70 + 'px' }">
				<!-- 表单提交 -->
				<a-form ref="formRef" class="form" :model="form" layout="vertical" style="margin-top: 0!important;">
					<!-- <div class="title">异常提报</div> -->
					<div class="tips">
						<!-- <div>
							自检自查，有问题都可以在这里
						</div> -->
						<!-- <div class="time">{{time}} {{time==''?'':'已保存'}}</div> -->
					</div>
					<!-- <a-form-item field="labelId" label="类型选择" :rules="[{required:true,message:''}]"
						:validate-trigger="['change']"> -->
					<div class="form-item">
						<div class="label blue formlable">类型<span class="red">*</span></div>
						<a-select v-model="form.labelId" :default-value="form.labelId" placeholder="请选择类型 ">
							<a-optgroup :label="item.labelName" v-for="item in typeList" :key="item">
								<a-option :value="arr.labelId" v-for="arr in item.children" :key="arr">{{arr.labelName}}
								</a-option>
							</a-optgroup>
						</a-select>
					</div>
					<div class="form-item">
						<!-- </a-form-item>
					<a-form-item field="structureId" label="车间" :rules="[{required:true,message:'请选择'}]"> -->
						<div class="label">车间<span class="red">*</span></div>
						<a-select v-model="form.structureId" :default-value="form.structureId" placeholder="请选择车间"
							>
							<a-optgroup :label="item.structureName" :value="item.structureId" v-for="item in factory"
								:key="item">
								<a-option :value="arr.structureId" v-for="arr in item.children" :key="arr">{{arr.structureName}}
								</a-option>
							</a-optgroup>
							<!-- <a-option :value="item.structureId" v-for="item in factory" :key="item">{{item.structureName}}
							</a-option> -->
						</a-select>
					</div>
					<div class="form-item">
						<!-- </a-form-item>
					<a-form-item field="location" label="位置" :rules="[{required:true,message:'请输入位置'}]" 
						:validate-trigger="['change','input']">-->
						<div class="label">位置<span class="red">*</span></div>
						<a-input v-model="form.location" :default-value="form.location" :placeholder="'请输入位置'"
							 />
						<!-- </a-form-item> -->
					</div>
					<!-- <a-form-item field="post" label="图片"> -->
					<div class="form-item" style="text-align: start;">
						<div class="label">图片</div>
						<div class="arco-upload-list-picture custom-upload-avatar" @click="$refs.upload.click()" v-show="file!=''&&file!=null">
							<img :src="file" />
						</div>
						<div class="arco-upload-picture-card" @click="$refs.upload.click()" v-show="file==''||file==null" style="margin-left:-10px">
							<div class="arco-upload-picture-card-text">
								<IconPlus />
							</div>
						</div>
						<input ref="upload" v-show="false" class="upload" @change='beforeUpload' type="file">

						<!-- <a-spin :loading="loading">
						<a-upload @success="uploadSuccess" :action="$baseUrl +'/minapp/onlineView/uploadFile/313'" @before-upload="beforeUpload"
							:headers="{'TENANT-ID':'2','Authorization':'Bearer ' + token}" 
							:show-file-list="false" @change="onChange" @progress="onProgress">
							<template #upload-button>
								<div :class="`arco-upload-list-item${
					file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
				}`">
									<div class="arco-upload-list-picture custom-upload-avatar" v-show="file!=''&&file!=null">
										<img :src="file" />

									</div>
									<div class="arco-upload-picture-card" v-show="file==''||file==null">
										<div class="arco-upload-picture-card-text">
											<IconPlus />
										</div>
									</div>
								</div>
							</template>
						</a-upload>
						</a-spin> -->
						<!-- </a-form-item>
					<a-form-item field="problemDesc" label="描述"> -->
					</div>
					<div class="form-item" >
						<div class="label">描述</div>
						<a-textarea v-model="form.problemDesc" autoSize placeholder="" style="padding: 5px 0;" :max-length="200"
							allow-clear show-word-limit />
						<!-- </a-form-item>
					<a-form-item field="responsible" label="责任人"> -->
					</div>
					<div class="form-item">
						<div class="label">责任人</div>
						<a-input v-model="form.responsible" ref="inputText2" @press-enter="$refs.inputText2.blur()"
							placeholder="请输入责任人"  />
						<!-- </a-form-item>
					<a-form-item field="display" label="提报人" style="height:20px;!important"> -->
					</div>
					<div class="form-item" style="width: 100%;">
						<div class="label">提报人</div>
						<div class="switch" style="width: 100%;">
							<div class="right" style="color:#86909C;text-align: end;">
								是否对外显示
								<a-switch v-model="form.display" :default-checked="true" />
							</div>
						</div>
						<!-- </a-form-item>
					<a-form-item field="switch" label="发送到人" v-show="type==0"> -->
					</div>
					<div class="form-item" v-if="type==0">
						<div class="label">发送到人</div>
						<div class="flex" style="align-items: flex-start;">
							<div v-for="item in userList" :key="item">
								<a-avatar class="avatar" :size="40" :style="{ backgroundColor: '#3b75fd' }" shape="square">
									<img :src="$baseUrl + item.avatar" v-if="item.avatar!=null&&item.avatar!=''" />
									{{item.avatar==null||item.avatar==''?item.personnelName.substring(item.personnelName.length-2):''}}
								</a-avatar>
								<!-- <img class="avatar" :src="'https://wis4cloud.wis.ltd'+item.avatar" /> -->
								<div style="color:#000000!important;" class="personnelName">{{item.personnelName}}</div>
							</div>
							<img class="add" src="../../assets/icon/add-blue.png" @click="showLog = true" />
						</div>
					</div>
					<a-form-item>
						<div class="button">
							<a-button @click="goBack" style="color:#000">取消</a-button>
							<a-button type="primary" @click="handleSubmit" style="margin-left:16px !important">提交</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			

			<!-- 发送到人弹出框 -->
			<div class="cover" v-if="showLog">
				<sendLog ref="sendLog" @close="closeSendLog()" class="card"></sendLog>
			</div>

			<!-- 成功或失败的提示页 -->
			<feedback ref="feedback" @goBack="goBack"></feedback>
		</div>
	</div>
</template>
<script>
	import {
		noticeSend,
	} from '../../api/index.js'
	import axios from 'axios'
	import {
		getExceptionProcess,
	} from "../../api/exception.js";
	import IconPlus from '@arco-design/web-vue/es/icon/icon-plus';
	import qs from 'qs'
	import {
		getStructure
	} from '../../api/index.js'
	import {
		getTypeList,
		saveReport,
		submitReport,
		getReport,
		updateReport,
	} from '../../api/exception'
	import {
		getUser
	} from '../../api/daily.js'
	import feedback from '../../common/feedback.vue'
	import sendLog from '../../common/sendLog.vue'
	import * as imageConversion from 'image-conversion';
	export default {
		components: {
			feedback,
			IconPlus,
			sendLog,
		},
		data() {
			return {
				showLog: false, //显示发送到人弹框
				screenHeight: document.documentElement.clientHeight, //获取屏幕高度
				detail: {},
				form: {
					labelId: '',
					attachmentId: '',
					problemDesc: '',
					location: '',
					responsible: '',
					display: '',
					structureId: '',
					reportRecordId: '',
				}, //form表单存放信息
				loading: false,
				type: 0, //0新增，1修改
				factory: [], //车间列表
				typeList: [], //类型列表
				status: null, //定时保存草稿
				time: '', //保存草稿时间
				attachmentId: '', //图片id
				token: '', //图片上传所用
				file: '', //图片地址
				userList: [], //发送到人列表
			};
		},
		beforeRouteLeave: function () {
				clearInterval(this.status);
		},
		mounted: function() {
			this.token = localStorage.getItem('token')
			// 获取发送到人缓存
			var list = localStorage.getItem('userConfig')
			if (list != null && list != "undefined" && list != "") {
				console.log(11111)
				this.userList = JSON.parse(list)
			}
			this.getFactory() //获取车间列表
			this.getType() //获取异常类型列表
			var type = this.$route.query.type //判断新增还是修改
			var detail = this.$route.query.detail //获取需要修改的异常id
			if (type == 2) {
				this.type = 1
				this.getDetail(detail); //获取需要修改的异常详情
			} else {
				this.getReport() //获取基础草稿
				var that = this
				this.status = setInterval(function() {
					console.log('临时保存')
					that.saveAsReport() //保存草稿
				}, 30000) //30s保存一次
			}
			window.onresize = () => {
				return (() => {
					that.screenHeight = document.documentElement.clientHeight;
				})();
			};
		},
		methods: {
			// 上传图片
			beforeUpload(event) {
							  console.log(event)
					var file = event.target.files[0];
							  console.log(file)
					let type = file.type; //文件的类型，判断是否是图片
							  console.log(type)
					let isLt2M = size  < 400*1024 // 判定图片大小是否小于4MB
							  console.log(isLt2M)
					let size = 0.01
							  console.log(size > 400*1024)
					      if (isLt2M) {
					        resolve(file)
					      }else if(size > 400*1024){
							  console.log(111)
						var file1
						size = 0.01
						imageConversion.compress(file, size).then(res => { 
							var uri = ''
							var file1 = new window.File([res], file.name, {type: res.type})
							let form = new FormData();
							form.append('file', file1, file1.name);
							axios({
								url: this.$baseUrl +'/minapp/onlineView/uploadFile/313',
								method: 'post', //get
								data: form,
								headers: {'TENANT-ID':'2','Authorization':'Bearer ' + this.token}
							}).then((res) => {
								this.attachmentId = res.data.data.id
								this.file = this.$baseUrl + res.data.data.url
								this.loading = false
								
								console.log('临时保存')
								this.saveAsReport()
								console.log(res)
							})
						})
					}else{
						var file1
						size = parseFloat(400*1024/file.size)
						imageConversion.compress(file, size).then(res => {
						console.log(file)
							var uri = ''
							var file1 = new window.File([res], file.name, {type: res.type})
							let form = new FormData();
							form.append('file', file1, file1.name);
							axios({
								url: this.$baseUrl +'/minapp/onlineView/uploadFile/313',
								method: 'post', //get
								data: form,
								headers: {'TENANT-ID':'2','Authorization':'Bearer ' + this.token}
							}).then((res) => {
								this.attachmentId = res.data.data.id
								this.file = this.$baseUrl + res.data.data.url
								this.loading = false
								
								console.log('临时保存')
								this.saveAsReport()
								console.log(res)
							})
							
					})
					
				}
			},
			// 获取详情
			getDetail(dailyId) {
				console.log(dailyId);
				getExceptionProcess({
					rid: dailyId,
				}).then((res) => {
					console.log(res.data)
					this.form = res.data
					if (res.data.url != null) {
						this.file = this.$baseUrl + res.data.url
						console.log(this.file)
					}
					console.log(res.data.createPerson != '***')
					if (res.data.createPerson != '***') {
						this.form.display = true
					}
					console.log(this.form.display)
				});
			},
			// 发送到人配置提交
			closeSendLog() {
				this.showLog = false
				var list = localStorage.getItem('userConfig')
				console.log('list')
				console.log(list)
				if (list != null && list != "undefined" && list != "") {
					this.userList = JSON.parse(list)
				}
			},
			// 返回上一页
			goBack() {
				clearInterval(this.status);
				window.clearInterval(this.status);
				var rid = this.form.rid
				this.$router.push({
					path: "/dashBord/workBench/exceptionReport",
					query: {
						attachmentId: rid,
						type: 1
					},
				});
			},
			//   获取发送到人
			getUserList() {
				getUser().then(res => {
					console.log(res)
					this.userList = res.data
				})
			},
			//   上传成功
			// uploadSuccess(e) {
			// 	console.log(e)
			// 	console.log(e.response.data.url)
			// 	this.attachmentId = e.response.data.id
			// 	this.file = this.$baseUrl + e.response.data.url
			// 	this.loading = false

			// 	console.log('临时保存')
			// 	this.saveAsReport()
			// 	console.log(this.file)
			// },
			// 获取车间信息
			getFactory() {
				console.log(112)
				getStructure({
					type: 1
				}).then(res => {
					console.log(res)
					this.factory = res.data
					// this.getList()
				})
			},
			//获取异常类型
			getType() {
				getTypeList().then(res => {
					console.log(res)
					this.typeList = res.data

					// this.getList()
				})
			},
			//获取草稿
			getReport() {
				getReport().then(res => {
					console.log(res)
					this.form = res.data
					if (res.data.url != null) {
						this.file = this.$baseUrl + res.data.url
					}

				})
			},
			// 修改异常
			updateReport() {
				var data = this.form
				data.attachmentId = this.attachmentId
				data.display = data.display ? 1 : 0
				updateReport(data).then(res => {
					console.log(res)
					if (res.code == 1) {
						this.$message.error(res.msg)
					} else {
						var myDate = new Date();
						var mm = myDate.getMinutes()
						mm = mm > 9 ? mm : '0' + mm
						this.time = myDate.getHours() + '时 ' + mm + '分'
					}


				})

			},
			//保存异常
			saveAsReport() {
				var data = {
					labelId: this.form.labelId,
					attachmentId: this.attachmentId,
					problemDesc: this.form.problemDesc,
					location: this.form.location,
					responsible: this.form.responsible,
					display: this.form.display == null || this.form.display ? 1 : 0,
					structureId: this.form.structureId,
					reportRecordId: this.form.rid,
				}
				saveReport(data).then(res => {
					console.log(res)
					var myDate = new Date();
					this.time = myDate.getHours() + '时 ' + myDate.getMinutes() + '分'
					localStorage.setItem('userConfig', null)
				})
			},
			onPreview(e) {

				console.log('预览图片')
				console.log(e)
			},
			//提交异常
			handleSubmit() {
				clearInterval(this.status) //清除定时保存
				window.clearInterval(this.status);
				console.log(this.form.display)
				// 新增
				if (this.type == 0) {

					if (this.form.labelId == '' || this.form.labelId == null) {
						this.$message.error('请选择类型')
						return
					} else if (this.form.structureId == '' || this.form.structureId == null) {
						this.$message.error('请选择车间')
						return
					} else if (this.form.location == '' || this.form.location == null) {
						this.$message.error('请输入位置')
						return
					} else {
						var data = {
							labelId: this.form.labelId,
							attachmentId: this.attachmentId,
							problemDesc: this.form.problemDesc,
							location: this.form.location,
							responsible: this.form.responsible,
							display: this.form.display == undefined || this.form.display ? 1 : 0,
							structureId: this.form.structureId,
							reportRecordId: this.form.rid,
						}
						submitReport(data).then(res => {
							clearInterval(this.status) //清除定时保存
							window.clearInterval(this.status);
							if (res.code == 1) {
								this.$message.error(res.msg)
							} else {
								localStorage.removeItem('userConfig'); //提交成功清除发送到人的配置
								console.log(this.userList)
								var notifications = []
								for (let i = 0; i < this.userList.length; i++) {
									notifications.push({
										receiver:this.userList[i].personnelId,
										// notifier:this.userList[i].personnelName
									})
								}
								var msg = {
									title: '恭喜！提交成功',
									msg: '查看提报',
									type: 'success'
								}
								this.$refs.feedback.handleClick(msg);
								var data1 = {
									type:4,
									outerId:this.form.rid,
									notifications:notifications
								}
								noticeSend(data1).then(res => {
									window.clearInterval(this.status);
									clearInterval(this.status) //清除定时保存
									
								})
								
							}

						})
					}


					// 修改
				} else {
					var data = {
						labelId: this.form.labelId,
						attachmentId: this.attachmentId,
						problemDesc: this.form.problemDesc,
						location: this.form.location,
						responsible: this.form.responsible,
						display: this.form.display == null || this.form.display ? 1 : 0,
						structureId: this.form.structureId,
						reportRecordId: this.form.rid,
					}
					submitReport(data).then(res => {
						clearInterval(this.status)
						console.log(res)
						if (res.code == 1) {
							this.$message.error(res.msg)
						} else {

								var notifications = []
							localStorage.removeItem('userConfig');
							for (let i = 0; i < this.userList.length; i++) {
								notifications.push({
									receiver:this.userList[i].personnelId,
									// notifier:this.userList[i].personnelName
								})
							}
							var data1 = {
								type:4,
								outerId:this.form.rid,
								notifications:notifications
							}
							noticeSend(data1).then(res => {
								window.clearInterval(this.status);
								clearInterval(this.status) //清除定时保存
								var msg = {
									title: '恭喜！提交成功',
									msg: '查看提报',
									type: 'success'
								}
								this.$refs.feedback.handleClick(msg);
							})
						}

					})
				}
			},
		},
	};
</script>
<style scoped lang="less">
	.form{
		width: 50% !important;
		box-shadow:none !important;
		// .arco-form-item-layout-vertical{
		// 	display: none;
		// }
		.button{
			border-top: none !important;
			justify-content:flex-start !important;
			padding-left: 110px;
			button{
				border-radius: 2px 2px 2px 2px;
				opacity: 1;
				width: 68px;
				height: 38px;
			}
		}
		.form-item{
			display: flex;
			width: 90%;
			padding: 0 5%;
		}
		.label{
			font-size: 14px;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #4E5969;
			line-height: 22px;
			width: 80px;
		}
		.tips{
			padding: 0 5%;
			display: flex;
			justify-content: space-between;
			border-bottom: none !important;
			.time{
				position: static !important;
			}
		}
		.right{
			top:-2px !important;
			right: 68px !important;
		}
	}
	.return{
		position: fixed;
		left: 77px;
		right: 13px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.xtb{
			font-size: 18px;
			font-family: Source Han Sans CN-Bold, Source Han Sans CN;
			font-weight: bold;
			color: #3B75FD;
			line-height: 21px;
		}
		.returnPageUp{
			font-size: 14px;
			font-family: PingFang SC-Medium, PingFang SC;
			font-weight: 500;
			color: #4E5969;
			line-height: 22px;
		}
		.time{
			padding-right: 10px;
			font-size: 16px;
			font-family: PingFang SC-Medium, PingFang SC;
			font-weight: 500;
			color: #C4C4C4;
		}
	}
	/deep/ .arco-avatar-text{
		font-size: 14px!important;
	}
	/deep/ .arco-btn-shape-square {
		height: 40px !important;
		font-size: 14px !important;
	}

	/deep/ .arco-input::-webkit-input-placeholder {
		color: #86909C !important;
	}

	/deep/ .arco-input:-moz-placeholder {
		color: #86909C !important;
	}

	/deep/ .arco-input::-moz-placeholder {
		color: #86909C !important;
	}

	/* For the future */
	/deep/ .arco-input:-ms-input-placeholder {
		color: #86909C !important;
	}

	/deep/ .arco-btn-shape-square {
		height: 40px !important;
		font-size: 14px !important;
	}

	/deep/ .arco-select-view-input::-webkit-input-placeholder {
		color: #86909C !important;
	}

	/deep/ .arco-select-view-input:-moz-placeholder {
		color: #86909C !important;
	}

	/deep/ .arco-select-view-input::-moz-placeholder {
		color: #86909C !important;
	}

	/deep/ .arco-form-item-label {
		color: #1F1F1F;
	}

	.label {
		color: #1F1F1F;
		font-size: 14px;
		white-space: normal;
		margin-bottom: 8px;
		padding: 0;
		line-height: 1.5715;
		text-align: left;
		display: flex;
		align-items: center;

		.red {
			color: #F55545;
			margin-left: 8px;
			font-size: 20px;
			line-height: 14px;
		}

	}

	// .blue {
	// 	font-size: 16px;
	// 	font-family: PingFang SC-Medium, PingFang SC;
	// 	font-weight: 500;
	// 	color: #2189F3;
	// }

	.form-item {
		margin-bottom: 20px;
		width: 280px;

		/deep/ .arco-layout-content {
			text-align: start;
		}
	}

	.form-item:hover {
		.label {
			// font-size: 16px;
			font-family: PingFang SC-Medium, PingFang SC;
			font-weight: 500;
			color: #2189F3;
		}
	}
	.upload{
		width: 38px;
		height: 38px;
		background: rgba(196, 196, 196, 0.2);
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
	}
	.scrollbar::-webkit-scrollbar {
		width: 0px;
	}
	.iscontent{
		margin: 0 12px;
		margin-top: 60px;
		background:#fff,
	}
	@import url("../../styles/editExceptionReport.less");
</style>
