<template>
  <div class="page flex" style="box-sizing: border-box">
    <div class="card">
      <div class="logo-bg flex">
        <img class="logo" style="width: 60px" src="../assets/logo/logo.png" />
      </div>
      <div class="code-box">
        <div class="code-bg"></div>
      </div>
      <img
        @click="getQrcode()"
        v-show="loginType == 1 || loginType == 5"
        class="code"
        src="../assets/icon/code.png"
      />
      <img
        @click="(loginType = 1), closeTimer1()"
        v-show="loginType != 1 && loginType != 5"
        class="code pc-code"
        src="../assets/icon/pc.png"
      />
      <div class="title">WISCAN</div>

      <!-- 手机验证码登录 -->
      <div v-show="loginType == 1" style="margin-bottom: 15px">
        <a-form :model="form" class="form">
          <div class="list">
            <div class="label">手机号</div>
            <!-- <a-form-item field="name" label="手机号"> -->
            <input
              class="arco-input-wrapper"
              v-model="form.mobile"
              placeholder="请输入手机号"
            />
            <!-- </a-form-item> -->
          </div>
          <div class="border"></div>
          <!-- <a-form-item field="post" label="验证码"> -->

          <div class="list">
            <div class="label">验证码</div>
            <input
              class="arco-input-wrapper"
              v-model="form.code"
              placeholder="请输入验证码"
            />
            <a-button
              type="primary"
              size="large"
              style="position: absolute; right: 0"
              class="right"
              @click="sendCode"
              >{{ show ? times + "s" : "获取验证码" }}</a-button
            >
            <!-- </a-form-item> -->
          </div>
          <div class="border"></div>
          <div id="box">
            <div class="bgColor"></div>
            <div class="txt">请拖动滑块至最右</div>
            <!--给i标签添加上相应字体图标的类名即可-->
            <div class="slider flex">
              <img class="icon" src="../assets/icon/slider.png" />
            </div>
          </div>
          <a-button
            html-type="submit"
            @click="login"
            type="primary"
            class="primary"
            long
            >登录</a-button
          >
        </a-form>
      </div>

      <!-- 扫码成功 -->
      <div v-show="loginType == 4">
        <icon-check-circle
          :style="{
            fontSize: '120px',
            color: '#00B42A',
            margin: '70px 94px 0',
          }"
          :stroke-width="2"
        />
        <div class="success">扫码成功</div>
      </div>

      <!-- 扫码登录 -->
      <div v-show="loginType != 1 && loginType != 5">
        <div class="small-title">微信小程序扫码登录</div>
        <div class="qrcode-bg">
          <qrcode-vue
            class="qrcode size225"
            :value="value"
            :size="225"
            level="H"
          />
          <qrcode-vue
            class="qrcode size150"
            :value="value"
            :size="150"
            level="H"
          />
          <!-- <img class="qrcode" src="../assets/others/qrcode.jpeg" /> -->
          <div class="qrcode-cover" v-if="loginType == 3">
            <p>二维码已失效</p>
            <a-button
              size="large"
              class="refresh"
              @click="(loginType = 2), getQrcode()"
            >
              <template #icon>
                <icon-refresh :style="{ fontSize: '28px', marginTop: '6px' }" />
              </template>
              <!-- Use the default slot to avoid extra spaces -->
              <template #default> 点击刷新 </template>
            </a-button>
          </div>
        </div>
      </div>

      <!-- 小程序码 -->
      <div v-show="loginType == 5">
        <div class="small-title">微信小程序扫码登录</div>
        <img class="wxcode" :src="wxcode" />
      </div>

      <div class="tips" v-if="loginType == 4">
        请在手机端微信小程序上确认【授权登录】
      </div>
      <div class="tips" v-if="loginType != 4 && loginType != 5">
        没有账号？请前往<span
          class="font12"
          @click="(loginType = 5), closeTimer1()"
          style="color: #3296fa"
          >微信小程序</span
        >端申请
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import axios from "axios";
import QrcodeVue from "qrcode.vue";
import { login, getQrcode } from "../api/index.js";
export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      wxcode: "", //小程序二维码
      num: 0, //二维码存在几秒
      timer: null, //验证码倒计时
      timer1: null, //二维码确认状态
      value: "", //二维码信息
      code: "", //发送的验证码
      show: false, //展示倒计时
      times: 60, //倒计时
      loginType: 1, //1手机验证码登录，2扫码登录，3二维码失效，4扫码成功
      isSuccess: false,
      form: {
        mobile: "",
        code: "",
      },
    };
  },
  methods: {
    // 获取小程序码
    getMiniAppCode() {
      axios({
        url: this.$baseUrl + "/minapp/login/wxCode",
        method: "get", //get
        data: {},
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "*/*",
        },
      }).then((res) => {
        console.log(res);
        this.wxcode = res.data.data;
      });
    },
    // 清除二维码30s倒计时
    closeTimer1() {
      clearInterval(this.timer1);
    },
    // 切换登录方式
    changeLoginType() {
      if (this.loginType == 1) {
        this.loginType = 2;
        this.getQrcode();
      } else {
        this.loginType = 1;
      }
    },
    // 发送验证码
    sendCode() {
      var mobile = this.form.mobile;
      var ab = /^[1][0-9]{10}$/;
      if (ab.test(mobile) == false) {
        this.$message.error("请正确填写手机号码!");
        return;
      } else if (mobile == "" || mobile == null) {
        this.$message.warning("请输入手机号!");
        return;
      }
      axios({
        url: this.$baseUrl + "/minapp/login/mobile/send/" + mobile,
        method: "get", //get
        data: {},
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "*/*",
        },
      }).then((res) => {
        this.code = res.data;
        if (res.data == false) {
          this.$message.error(res.msg);
        } else {
          this.show = true;
          this.$message.success("发送成功！");
          this.timer = setInterval(() => {
            this.times--;
            if (this.times === 0) {
              this.show = false;
              this.times = 60;
              clearInterval(this.timer);
            }
          }, 1000);
        }
      });
    },
    // 获取登录二维码
    getQrcode() {
      this.loginType = 2;
      console.log(this.loginType);
      getQrcode().then((res) => {
        console.log(res);
        this.value = res.data.token;
        console.log(res.data.token);
        var that = this;
        this.timer1 = setInterval(function () {
          that.time(res.data.token);
        }, 500);
      });
    },
    // 定时查询登录状态
    time(token) {
      console.log(token);
      var data = {
        token: token,
      };
      axios({
        url: this.$baseUrl + "/auth/token/qrcode/is_token_login",
        method: "post", //get
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "*/*",
        },
      }).then((res) => {
        console.log(res);
        console.log(res.data.code == 0);
        if (res.data.code == 0) {
          this.qrcodeLogin(token);
          clearInterval(this.timer1);
          return;
        } else {
          this.num++;
          console.log(this.num);
          if (this.num == 30) {
            this.loginType = 3;
            this.num = 0;
            clearInterval(this.timer1);
          }
        }
      });
    },
    // 扫码登录
    qrcodeLogin(token) {
      axios({
        url:
          this.$baseUrl +
          "/auth/mobile/token/QR?mobile=QR@" +
          token +
          "&grant_type=mobile",
        method: "post", //get
        data: {},
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic d2lzY2FuOndpc2Nhbg==",
        },
      }).then((res) => {
        this.$message.success("登录成功！");
        console.log(res);
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("userinfo", JSON.stringify(res.data.user_info));
        this.$router.replace("/dashBord/workBench/index");
      });
    },
    // 手机验证码登录
    login() {
      var ab = /^[1][0-9]{10}$/;
      if (ab.test(this.form.mobile) == false) {
        this.$message.error("请正确填写手机号码!");
        return;
      } else if (this.form.code == "") {
        this.$message.error("验证码不能为空");
        return;
      } else if (!this.isSuccess) {
        this.$message.warning("请拖动滑块至最右!");
        return;
      } else {
        var data = {
          code: this.form.code,
          mobile: "SMS@" + this.form.mobile,
          grant_type: "mobile",
          Authorization: "Basic bWluYXBwOm1pbmFwcA==",
        };
        login(data).then((res) => {
          if (res.code == 1) {
            // this.$message.error(res.msg)
          } else {
            clearInterval(this.timer);
            this.$message.success("登录成功！");
            localStorage.setItem("token", res.access_token);
            localStorage.setItem("userinfo", JSON.stringify(res.user_info));
            this.$router.replace("/dashBord/workBench/index");
          }
        });
      }
    },
  },
  mounted() {
    let backlen = window.history.length - 1;
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (backlen > 1 && this.flag != null) {
      window.history.go(-backlen);
    }
    this.getMiniAppCode();
    var token = localStorage.getItem("token");
    console.log(token);
    if (token != null && token != "") {
      this.$router.replace("/dashBord/workBench/index");
    }
    localStorage.removeItem("tabs");
    // 滑动解锁部分
    var self = this;
    //一、定义了一个获取元素的方法
    function getEle(selector) {
      return document.querySelector(selector);
    }
    //二、获取到需要用到的DOM元素
    var box = getEle("#box"), //容器
      bgColor = getEle(".bgColor"), //背景色
      txt = getEle(".txt"), //文本
      slider = getEle(".slider"), //滑块
      // icon = getEle(".slider>i"),
      successMoveDistance = box.offsetWidth - slider.offsetWidth, //解锁需要滑动的距离
      downX; //用于存放鼠标按下时的位置

    bgColor.style.width = 0 + "px";
    slider.style.left = 0 + "px";
    //三、给滑块添加鼠标按下事件
    console.log("slider");
    console.log(slider);
    slider.onmousedown = mousedownHandler;
    slider.ontouchstart = mousedownHandler; //移动端加touchstart事件
    //3.1鼠标按下事件的方法实现
    function mousedownHandler(e) {
      console.log("按下");
      bgColor.style.transition = "";
      slider.style.transition = "";
      e = e || window.event || e.which;
      downX = e.clientX ? e.clientX : e.changedTouches[0].clientX;
      if (!self.isSuccess) {
        //在鼠标按下时，分别给鼠标添加移动和松开事件
        document.onmousemove = mousemoveHandler;
        document.onmouseup = mouseupHandler;
        //添加移动端对应事件
        document.ontouchmove = mousemoveHandler;
        document.ontouchend = mouseupHandler;
      }
    }
    //四、定义一个获取鼠标当前需要移动多少距离的方法
    function getOffsetX(offset, min, max) {
      if (offset < min) {
        offset = min;
      } else if (offset > max) {
        offset = max;
      }
      return offset;
    }
    //3.1.1鼠标移动事件的方法实现

    function mousemoveHandler(e) {
      console.log(e);
      e = e || window.event || e.which;
      var moveX = e.clientX ? e.clientX : e.changedTouches[0].clientX;
      console.log(moveX);
      var offsetX = getOffsetX(moveX - downX, 0, successMoveDistance);
      bgColor.style.width = offsetX + 30 + "px";
      slider.style.left = offsetX + "px";

      if (offsetX == successMoveDistance) {
        success();
      }
      //如果不设置滑块滑动时会出现问题（目前还不知道为什么）
      e.preventDefault();
    }
    //3.1.2鼠标松开事件的方法实现
    function mouseupHandler() {
      if (!self.isSuccess) {
        bgColor.style.width = 0 + "px";
        slider.style.left = 0 + "px";
        bgColor.style.transition = "width 0.5s linear";
        slider.style.transition = "left 0.5s linear";
      }
      document.onmousemove = null;
      document.onmouseup = null;
      //移除移动端事件
      document.ontouchmove = null;
      document.ontouchend = null;
    }
    //五、定义一个滑块解锁成功的方法
    function success() {
      self.isSuccess = true;
      txt.innerHTML = "解锁成功";
      bgColor.style.backgroundColor = "lightgreen";
      //滑动成功时，移除鼠标按下事件和鼠标移动事件
      slider.onmousedown = null;
      document.onmousemove = null;
      //移除移动端事件
      document.ontouchstart = null;
      document.ontouchmove = null;
    }
  },
};
</script>
<style lang="less" scoped>
@import url("../styles/login.less");
/deep/ .arco-input-wrapper {
  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 12px !important;
  }
}
.list {
  .right {
    @media (min-width: 1200px) and (max-width: 1400px) {
      font-size: 12px !important;
    }
  }
}
.tips {
  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 12px !important;
  }
}

.txt {
  line-height: 32px !important;
}
#box {
  margin: 10px auto 18px !important;
  border-radius: 4px 4px 4px 4px !important;
}
</style>













