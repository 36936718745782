<template>
	<div class="page" :style="{ height: screenHeight  + 'px' }" style="overflow-y: scroll;">
		<div v-show='false'>
			<div class="fold" @click="fold = !fold">
			<icon-double-left class="fold-icon" v-if="fold" />
			<icon-double-right class="fold-icon" v-else />
		</div>
		<div class="task" v-if="factory.length>0&&fold">
			人员信息驾驶舱
			<div class="factory flex" :class="{ active: item.structureId == factoryId }" v-for="item in factory"
				:key="item">
				{{ item.structureName }}
				<div class="factory-active" v-if="item.structureId == factoryId"></div>
			</div>
		</div>
		<div class="workshop" ref="barparent">
			<div style="display: flex;position: relative;" :style="{left:moveSize+'px'}" @mousedown="touchstartHandle"
				@mousemove="touchmoveHandle" @mouseup="touchendHandle">
				<div class="card pointer" :class="{active:shopworkId==item.workshopId}" @click="clickCharts(index,item)"
					v-for="(item,index) in list" :key='index'>
					<div class="card-top">
						<div class="title">{{item.workshopName}}</div>
					</div>
					<ul>
						<li>在线<span>{{item.onLine}}</span></li>
						<li>离线<span>{{item.offLine}}</span></li>
					</ul>
					<div class="chart" :id="'personal'+index"></div>
				</div>
			</div>

			</div>
		</div>

		<div class="per_header">
			人员信息驾驶舱
			<div class="per_header_box">
				<div class="per_header_boxs" v-for="item in factory" :key='item'>{{ item.structureName }}</div>
			</div>
		</div>

		<div class="flex"  :style="{ height: screenHeight -70 + 'px' }" style="background:#F0F1F3; margin: 0 12px;" >
			<div class="rank">
				<div class="rank-BG">
					<div class="title">
						早到排行
						<div style="font-size:12px;margin-left:4px">今日{{new Date().Format("MM-dd")}}</div>
					</div>
					<div v-if="sooner.length>0">
						<div class="rank-list" v-for="(item, i) in sooner" :key="item">
							<div class="icon" :class="'icon' + i" style="text-align: center;"
								:style="{fontSize:i>2?'16px':'12px',marginTop:i>2?'20px':''}">{{i+1}}</div>
							<div class="name">{{item.personnelName}}</div>
							<div class="right">
								{{item.time}}
							</div>
						</div>
					</div>
					<div v-else class="right-empty">
						<img src="../../assets/others/emptyWork.png" style="zoom: 0.6;" />
						<div>
							<p>什么都没有</p>
							<p>换个条件试试吧</p>
						</div>
					</div>
				</div>
				<div class="rank-BG bottom-rank">
					<div class="titles">
						晚到排行
						<div style="font-size:12px;margin-left:4px">今日{{new Date().Format("MM-dd")}}</div>
					</div>
					<div v-if="later.length>0">
						<div class="rank-list flex" v-for="(item, i) in later" :key="item">
							<div class="late-icon" :class="'late-icon' + i"
								:style="{fontSize:i>2?'16px':'12px',marginTop:i>2?'-3px':''}">
								{{i>2?i+1:''}}
							</div>
							<div class="name">{{item.personnelName}}</div>
							<div class="right">
								{{item.time}}
							</div>
						</div>
					</div>
					<div v-else class="right-empty">
						<img src="../../assets/others/emptyWork.png" style="zoom: 0.6;" />
						<div>
							<p>什么都没有</p>
							<p>换个条件试试吧</p>
						</div>
					</div>
				</div>
			</div>

			<div class="table-bgs">
				<div class="title">
					人员状态明细
					<div class="right menu">
						<div class="menu-item pointer" :class="{ selcet: status == 2 }"
							@click="(status = 2), getList()">
							全部({{listData.all==null?'0':listData.all}})
						</div>
						<div class="menu-item pointer" :class="{ selcet: status == 1 }"
							@click="(status = 1), getList()">
							在线({{listData.onLine==null?'0':listData.onLine}})
						</div>
						<div class="menu-item pointer" :class="{ selcet: status == 0 }"
							@click="(status = 0), getList()">
							离线({{listData.offLine==null?'0':listData.offLine}})
						</div>
					</div>
				</div>
				<!-- <template #cell="{ record }">
					<a-tooltip :content="record.personnelNo">
						<a-button class="ellipsis" style="width: 100%;">{{record.personnelNo}}</a-button>
					</a-tooltip>
				</template> -->
				<a-table :data="orderList" :bordered="false" size="mini" @row-click="getDetail" :pagination="false" :style="{ height: screenHeight - 120 + 'px' }"
					v-if="orderList.length > 0" :scroll="{y:620}" style="overflow-x: hidden;" :stripe="true">
					<template #columns>
						<a-table-column width="60" class="column" title="序号" data-index="key">
						</a-table-column>
						<a-table-column class="column" title="员工编号" :filterable="filterable.personnelNo"
							data-index="personnelNo">

						</a-table-column>
						<a-table-column title="姓名" data-index="personnelName" :filterable="filterable.personnelName">
						</a-table-column>
						<a-table-column width="90" title="状态" :filterable="filterable.state">
							<template #cell="{ record }">
								<div class="status" :class="[record.personnelState == 1 ? 'green' : 'orange']"></div>
							</template>
						</a-table-column>
						<a-table-column title="登入时间" :sortable="{sortDirections:['ascend', 'descend']}"
							data-index="loginTime">
						</a-table-column>
						<a-table-column title="车间" :filterable="filterable.shopworkName" data-index="shopworkName">
						</a-table-column>
						<a-table-column width="120" title="小组" :filterable="filterable.structureName"
							data-index="structureName">
							<template #cell="{ record }">
								<a-tooltip :content="record.structureName">
									<a-button class="ellipsis" style="width: 100%;">{{record.structureName}}</a-button>
								</a-tooltip>
							</template>
						</a-table-column>
					</template>
				</a-table>
				<div v-else class="right-empty">
					<img src="../../assets/others/emptyWork.png" style="zoom: 0.6;" />
					<div>
						<p>什么都没有</p>
						<p>换个条件试试吧</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getStructure
	} from "../../api/index.js";
	import {
		workshopInformation,
		soonerAndLater,
		personnelState
	} from "../../api/personnel.js";
	export default {
		data() {
			return {
				screenWidth: document.body.clientWidth, //获取屏幕宽度
				factoryId: 0, //当前选择工厂的id
				factory: [], //工厂列表
				pickerValue: null, //日期
				shopworkId: 0, //选中的车间
				screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
				form: {}, //搜索信息
				visible: false, //列配置
				showDetail: false, //详情
				later: [], //迟到
				index: 0, //当前选中车间下标
				sooner: [], //早到
				list: [], //车间列表
				status: 2, //全部
				moveSize: 0, //顶部图表移动距离
				maxMove: 0, //顶部图表最大移动距离
				move: 0, //上一次移动距离
				start: 0, //初始移动位置
				isclick: false, //是否按下
				isMobile: false, //是否为手机端
				startTime: 0, //按下的时间
				current: 1, //页码
				fold: false, //折叠
				chartsList: [], //刷新圆环用
				listData: {},
				status1: null,
				filterable: {
					personnelName: {
						filters: [],
						filter: (value, row) => row.personnelName.includes(value),
					},
					state: {
						filters: [{
								text: '离线',
								value: '0',
							},
							{
								text: '在线',
								value: '1',
							}
						],
						filter: (value, row) => row.personnelState == value,
					},
					loginTime: {
						filters: [],
						filter: (value, row) => row.loginTime.includes(value),
					},
					personnelNo: {
						filters: [],
						filter: (value, row) => row.personnelNo.includes(value),
					},
					shopworkName: {
						filters: [],
						filter: (value, row) => row.shopworkName.includes(value),
					},
					structureName: {
						filters: [],
						filter: (value, row) => row.structureName.includes(value),
					},
				},
				orderList: Array(100).fill(null).map((_, index) => ({
					key: String(index + 1),
					personnelName: '范祷芳',
					personnelNo: '0023',
					personnelState: index % 2 == 0 ? 1 : 0,
					loginTime: '08:30:26',
					shopworkName: '木工车间',
					structureName: '钉架1组',
				})),
			};
		},
		beforeRouteLeave: function() {
			// this.stopTimer();
			clearInterval(this.status1);
			window.clearInterval(this.status1);

		},
		mounted: function() {
			this.isMobile = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)

			this.getFactory();
			var that = this
			this.status1 = setInterval(function() {
				that.changeCharts(that.index)
			}, 5000)
			window.onresize = () => {
				return (() => {
					window.screenHeight = document.documentElement.clientHeight
					this.screenHeight = window.screenHeight;
				})();
			};
		},
		methods: {
			touchstartHandle(e) {
				// console.log('touchstartHandle')
				// console.log(e)
				this.isclick = true
				this.start = e.clientX
				this.startTime = new Date().getTime()
			},
			touchmoveHandle(e) {
				if (this.isclick) {
					var y = e.clientY
					var end = e.clientX
					if (end < 100 || end > 1800 || y < 130 || y > 300) {
						this.touchendHandle(e)
					}
					var move = end - this.start
					if (!this.isMobile) {
						if (move > 0) {
							this.moveSize = Math.abs(this.move + move) > Math.abs(this.move) ? 0 : this.move + move > 0 ?
								0 : this.move + move

						} else {
							this.moveSize = Math.abs(this.move + move) > this.maxMove ? -this.maxMove : this.move + move
						}
					}
				}
			},
			touchendHandle(e) {
				console.log(this.index)
				var time = new Date().getTime()
				var end = e.clientX
				var move = end - this.start
				// this.move = Math.abs(this.move + move)>this.maxMove?this.maxMove:this.move + move
				if (move > 0) {
					this.move = Math.abs(this.move + move) > Math.abs(this.move) ? 0 : this.move + move > 0 ? 0 : this
						.move + move

				} else {
					this.move = Math.abs(this.move + move) > this.maxMove ? -this.maxMove : this.move + move
				}
				setTimeout(() => {
					this.isclick = false
				}, 500);


			},
			clickCharts(index,item) {
				this.shopworkId = item.workshopId
				this.getRank()
				this.changeCharts(index)
				this.getList()

			},
			// 获取工厂信息
			getFactory() {
				getStructure({
					type: 7,
				}).then((res) => {
					this.factory = res.data;
					this.factoryId = res.data[0].structureId;
					this.getWorkshop();
				});
			},
			// 获取各车间人员在线离线
			getWorkshop() {
				workshopInformation({
					factoryId: this.factoryId,
				}).then((res) => {
					var list = res.data
					const parentClientWidth = this.$refs.barparent.clientWidth;
					console.log('parentClientWidth')
					console.log(parentClientWidth)
					var maxWidth = parseInt(380 * list.length)
					this.maxMove = maxWidth - parentClientWidth
					this.list = list
					this.shopworkId = list[0].workshopId
					this.getRank();
					this.getList()
					setTimeout(() => {
						for (let i = 0; i < list.length; i++) {
							this.getCharts(i, list[i]);
						}
					}, 500);

				});
			},
			// 重新加载echarts
			changeCharts(index) {
				this.index = index
				var list = this.list
				for (let i = 0; i < list.length; i++) {
					this.getCharts(i, list[i]);
				}
			},

			// 早到晚到排行榜
			getRank() {
				soonerAndLater({
					shopworkId: this.shopworkId,
				}).then((res) => {
					console.log(res)
					this.sooner = res.data.sooner
					this.later = res.data.later
				});
			},
			// 列配置相关
			handleClick() {
				this.visible = true;
			},
			handleOk() {
				localStorage.setItem("columnConfig", JSON.stringify(this.columnConfig));
				this.visible = false;
			},
			handleCancel() {
				this.visible = false;
			},
			// 获取人员状态明细
			getList() {
				personnelState({
					shopworkId: this.shopworkId,
					state: this.status
				}).then((res) => {
					console.log('列表')
					console.log(res)
					var list = res.data.page.records
					for (let i = 0; i < list.length; i++) {
						list[i].key = i + 1 > 9 ? i + 1 : '0' + (i + 1)
					}
					this.orderList = list
					var arr = list
					var list = this.$unique(arr)
					var index = 0
					for (let a in this.filterable) {
						for (let i = 0; i < list.length; i++) {
							if (a == list[i].name) {
								this.filterable[a].filters = list[i].list
							}

							// this.filterable[index].filters = list[i].list
						}
					}
					console.log(this.orderList.length)
					this.listData = res.data
				});
			},
			getDetail() {
				this.showDetail = true
			},
			getCharts(i, arr) {
				var myChart = this.chartsList[i]
				if (this.chartsList[i] != null) {
					this.chartsList[i].dispose()
				}
				myChart = this.$echarts.init(
					document.getElementById('personal' + i)
				);
				var color = '#06B1FC'
				if (i == this.index) {
					color = '#4C5EEF'
				}
				// var chartDom = document.getElementById('workshop' + i);
				// var myChart = this.$echarts.init(chartDom);
				var option;
				option = {
					graphic: [{ //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						right: '33%',
						top: '32%',
						textStyle: {
							align: 'right',
						},
						style: {
							text: arr.realCount == null ? '' : arr.realCount,
							textAlign: 'right',
							fill: i == this.index ? '#FFFFFF' : '#03C9FD', //文字的颜色
							fontSize: 20,
							fontWeight: 700,
							width: 200
						}
					}, { //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						left: '67%',
						top: '32%',
						style: {
							text: arr.shouldCount == null ? '' : '/' + arr.shouldCount,
							textAlign: 'center',
							fill: i == this.index ? '#FFFFFF' : '#000000', //文字的颜色
							fontSize: 20,
							fontWeight: 500,
						}
					}, { //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						left: '58%',
						top: '45%',
						style: {
							text: '实到/应到',
							textAlign: 'center',
							fill: i == this.index ? '#FFFFFF' : '#000000', //文字的颜色
							width: 30,
							height: 30,
							fontSize: 16,
						}
					}],
					series: [{
						center: ["68%", "40%"],
						type: 'gauge',
						startAngle: 180,
						endAngle: 0,
						min: 0,
						max: arr.shouldCount,
						splitNumber: 12,
						itemStyle: {
							// color: '#58D9F9',
							color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
								offset: 0,
								color: color

							}, {
								offset: 0.5,
								color: '#0A93FB'

							}]),
							shadowColor: 'rgba(0,138,255,0.45)',
							shadowBlur: 10,
							shadowOffsetX: 2,
							shadowOffsetY: 2
						},
						progress: {
							show: true,
							roundCap: true,
							width: 10
						},
						pointer: {
							length: '0%',
							width: 0,
						},
						axisLine: {
							roundCap: true,
						},
						axisTick: {
							splitNumber: 0,
							lineStyle: {
								width: 0,
							}
						},
						splitLine: {
							lineStyle: {
								width: 0,
							}
						},
						axisLabel: {
							fontSize: 0
						},
						detail: {
							show: false,
						},
						data: [{
							value: arr.realCount
						}]
					}]
				};

				option && myChart.setOption(option);

				this.chartsList[i] = myChart

			}
		},
	};
</script>

<style scoped lang="less">
	@import url("../../styles/personnel.less");
	@import url("../../styles/all.less");

	/deep/ .arco-table-body {
		overflow-x: hidden;
	}

	.right {
		right: 16px;
	}
</style>
