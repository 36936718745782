//引用第一步创建的axios.js文件
import request from '../axios.js'
// 获取各车间完成进度
export function shopworkProgress(params) {
  return request({
    url: 'minapp/schedule/shopworkProgress',
    method: 'get',
    params
  })
}
// 获取生产进度明细
export function getProgressDetails(params) {
  return request({
    url: 'minapp/schedule/getProgressDetails',
    method: 'get',
    params
  })
}
// 获取生产记录详情
export function productionRecord(params) {
  return request({
    url: 'minapp/schedule/productionRecord',
    method: 'get',
    params
  })
}