<template>
	<div class="page">
		<div class="week">
			<div class="width"><div class="day">日</div></div>
			<div class="width"><div class="day">一</div></div>
			<div class="width"><div class="day">二</div></div>
			<div class="width"><div class="day">三</div></div>
			<div class="width"><div class="day">四</div></div>
			<div class="width"><div class="day">五</div></div>
			<div class="width"><div class="day">六</div></div>
		</div>
		<div class="week">
			<div class="width"  v-for="(item,i) in week" :key="item"><div class="day" :class="{choose:item.status}" @click="changeDate(i)">{{item.day}}
			</div></div>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				week: [],
				show: false,
			}
		},
		methods: {
			getList(date,chooseDate) {
				console.log('日期')
				console.log(date)
				var date1 = new Date(date)
				let weekFirstDay = new Date(date1 - date1.getDay() * 86400000)
				let chooseDay = new Date(chooseDate).getDate()
				var week = []
				
				for (let i = 0; i < 7; i++) {
					var nextDate = new Date(weekFirstDay.getTime() + 24 * 60 * 60 * 1000 * i)
					let weekFirstDays = nextDate.getDate();
					let firstMonth = Number(nextDate.getMonth()) + 1
					if (weekFirstDays < 10) {
						weekFirstDays = '0' + weekFirstDays;
					}
					if (firstMonth < 10) {
						firstMonth = '0' + firstMonth
					}
					var data = {
						date: weekFirstDay.getFullYear() + '-' + firstMonth + '-' + weekFirstDays,
						day: weekFirstDays,
						status: chooseDay == weekFirstDays ? true : false
					}
					week.push(data)
				}
				console.log(week)
				this.week = week

			},
			changeDate(index) {
				console.log('下标')
				console.log(index)
				for (var i = 0; i < 7; i++) {
					if (i == index) {
						console.log(i)
						this.week[i].status = true

					} else {
						this.week[i].status = false
					}
					this.show = !this.show
				}
				this.$emit('changeDate', this.week[index].date)
				console.log(this.week)
			}
		}
	};
</script>
<style scoped>

</style>
<style scoped lang="less">
	.week {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #000;
		width: 100%;
		.width{
			width: 20%;
			display: flex;
			align-items: center;
			justify-content: center;
			.day {
			width: 28px;
			height: 28px;
			font-size: 14px;
			font-family: Source Han Sans CN-Medium, Source Han Sans CN;
			font-weight: 500;
			color: #000000cc;
			line-height: 28px;
		}

		.choose {
			background: #3F71F0;
			color: #ffffff;
			border-radius: 50%;
		}
		}
		
	}
</style>
