<template>
	<div class="page" style="overflow: hidden;">
		<!-- <div id="map" style="width:1920px;height: 1080px;"></div>
		<div id="panel"></div> -->
		<iframe v-if="map" style="height: 100vh;position: absolute;top: 0;"
			:style="{left:parseInt(96*screenWidth/1920)+'px',width:parseInt(1824*screenWidth/1920)+'px'}" id="iframeId"
			:src="order.express.trailUrl" frameborder="0" class="pc iframe" scrolling="auto">
		</iframe>
		<div class="index" :style="{zoom:screenWidth/1920}">
			<div class="search">
				<icon-close-circle-fill class="delete" :style="{opacity:number==''?0:1}" @click="number = '',nodata=true" />
				<div class="select" :style="{width:'220px'}">
					<div @click="show = false,select = true">
						{{options[type]}}
						<icon-down />
					</div>
					<div class="options" v-show="select">
						<div class="option" @click="type = 2,select = false">订单编号</div>
						<div class="option" @click="type = 1,select = false">手机号</div>
					</div>
				</div>
				<a-input placeholder="" style="background-color: #F2F3F5;font-size: 24px!important;" v-model="number" />
				<a-button type="primary" style="font-size: 21px!important;" @click="getList()">查询</a-button>
				<div class="content1" v-show="show">
					<div class="title">
						搜索结果
					</div>
					<div class="mobile" v-show="type == 1&&list.length>0">
						<div class="for" v-for="item in list" @click="goDetail(item)">
							<div class="title">
								{{item.orderNo}}
								<div>
									<img class="icon" src="../../assets/icon/right-blue.png"
										style="margin-left: 20px;" />
								</div>
							</div>
							<div class="flex" style="margin-top: 6px;">
								<div class="phone">{{item.phone}}</div>
								<div class="time">{{item.orderTime}}</div>
							</div>

						</div>
					</div>
					<div class="order" v-show="type == 2&&list.length>0">
						<div class="for" v-for="item in list" @click="goDetail(item)">
							<div class="title">{{item.orderNo}}</div>
							<div>
								<img class="icon" src="../../assets/icon/right-blue.png" style="margin-left: 20px;" />
							</div>
						</div>
					</div>
					<div class="nodata" v-show="list.length==null||list.length==0">
						<img class="bg" src="../../assets/bg/wl-nodata.png" />
						<div class="tips">什么都没有</div>
					</div>
				</div>
				<div class="content">
					<div class="nodata" v-show="nodata&&!show">
						<img class="bg" src="../../assets/bg/wl-nodata.png" />
						<div class="tips">重新输入订单编号吧</div>
					</div>
					<div v-show="!nodata&&!show">
						<div @click="getExpressDetail(detail.orderNo)">
							<div class="title">{{detail.orderNo}}</div>
							<div class="orderTime" v-if="order.isExpress==0">
								下单时间
								<span class="blur">{{detail.orderTime}}</span>
							</div>
							<div class="orderTime" v-if="order.isExpress!=0">
								预计送达时间
								<span class="blur">{{order.express==null?'':order.express.arrivalTime}}</span>
							</div>
						</div>


						<div class="step">
							<div class="list">
								<div class="flex ing" v-show="order.isExpress!=0">
									<icon-check-circle-fill :size="28" class="icon"
										style="width: 28px;height: 28px;margin-left: -3px;" />
									运输
									<div class="button">{{order.isExpress==1?'已发货':'已签收'}}</div>
									<div class="right" style="align-items: center;display: flex;width: 120px;"
										@click="logistics = !logistics">
										查看详情
										<icon-down-circle :style="{transform:logistics?'rotate(0deg)':'rotate(180deg)'}"
											:size="24" style="color:#B4B4B4;transition: all 0.3s;margin-left: 5px;" />
									</div>
								</div>
								<div class="flex nostart" v-show="order.isExpress==0">
									<img class="icon" src="../../assets/icon/nostart.png">
									运输
									<div class="button">即将开始</div>
								</div>

								<div class="logistics" v-show="logistics">
									<div class="list" v-for="(item,index) in express">
										<div class="flex" :class="{choose:index==0}">
											<div class="center">
												<div class="i"></div>
											</div>
											{{item.status}}
											<div class="time">{{item.time}}</div>
										</div>
										<div class="desc">
											{{item.context}}
										</div>
									</div>
								</div>
								<div style="height: 40px;"></div>
							</div>
							<div class="list">
								<div class="flex"
									:class="{nostart:order.isInProduction==0,ing:order.isInProduction==1,end:order.isInProduction==2}">
									<icon-check-circle-fill v-show="order.isInProduction!=0" :size="24" class="icon"
										style="width: 28px;height: 28px;margin-left: -3px;" />
									<img v-show="order.isInProduction==0" class="icon"
										src="../../assets/icon/nostart.png">
									生产
									<div class="button">
										{{order.isInProduction==0?'即将开始':order.isInProduction==1?'生产中':'已入库'}}
									</div>
								</div>
							</div>
							<div class="list" style="border-color: #FFFFFF!important;">
								<div class="flex "
									:class="{end:order.isProductionScheduling==2,ing:order.isProductionScheduling!=2}">
									<icon-check-circle-fill :size="28" class="icon"
										style="width: 28px;height: 28px;margin-left: -3px;" />
									排产
									<div class="button">{{order.isProductionScheduling==2?'已排产':'排产中'}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div class="express" v-show="order.isExpress!=0">
				<img class="icon" :src="expressMsg.icon" />
				<span class="name">{{expressMsg.name}}</span>
				<span class="number">{{expressMsg.number}}</span>
			</div>
		</div>
		<a-modal v-model:visible="showDetail" class="add-box add-box1" :footer="false" :width="552">
			<template #title>
				<div class="model-title" style="text-align: center;width: 100%;">订单详情</div>
			</template>
			<div class="msg" v-show="order.isExpress!=0">
				<div class="list">
					<div class="flex">
						<div class="icon">寄</div>
						<div class="address">
							<div class="name">
								{{expressDetail.sender}}
								<div class="phone">{{expressDetail.senderTelephone}}</div>
							</div>
							{{expressDetail.senderAddress}}
						</div>
					</div>
				</div>
				<div class="list" style="border-color: #EFF7FF;">
					<div class="flex" style="top: 0;">
						<div class="icon" style="background-color: #CB363A;">收</div>
						<div class="address">
							<div class="name">
								{{expressDetail.recipient}}
								<div class="phone">{{expressDetail.recipientTelephone}}</div>
							</div>
							{{expressDetail.recipientAddress}}
						</div>
					</div>
				</div>
			</div>
			<div class="flex">
				<div class="module">
					<div class="name">订单编号</div>
					{{expressDetail.orderNo}}
				</div>
				<div class="module">
					<div class="name">产品名称</div>
					{{expressDetail.productName}}
				</div>
			</div>
			<div class="flex">
				<div class="module">
					<div class="name">客户名称</div>
					{{expressDetail.customName}}
				</div>
				<div class="module">
					<div class="name">订单数量</div>
					{{expressDetail.amount}}
				</div>
			</div>
			<div class="flex">
				<div>
					<div class="name">联系电话</div>
					{{expressDetail.phone}}
				</div>
			</div>
			<div class="flex">
				<div>
					<div class="name">销售说明</div>
					{{expressDetail.productName}}
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import axios from 'axios'
	import {
		getOrder,
		getDetail,
		getDetailByNo
	} from "../../api/logistics.js";
	import {
		dataInJs
	} from '../../api/kuaidi.js'
	// import AMapLoader from '@amap/amap-jsapi-loader'
	export default {
		data() {
			return {
				screenWidth: document.documentElement.clientWidth, //获取屏幕宽度
				screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
				type: 0,
				number: '',
				list: [],
				options: ['请选择', '手机号', '订单编号'],
				select: false,
				show: false,
				logistics: false,
				map: false,
				nodata: false,
				showDetail: false,
				order: {},
				detail: {},
				expressMsg: {},
				expressList: dataInJs(),
				express: [],
				expressDetail: {}
			};
		},
		mounted: function() {
			var detail = this.$route.query.detail;
			var data = this.$route.query.data;
			data = JSON.parse(data)
			this.type = data.type
			this.number = data.number
			detail = JSON.parse(detail)
			this.detail = detail
			this.getOrderDetail(detail.id)
		},
		methods: {
			goDetail(item) {
				this.detail = item
				this.getOrderDetail(item.id)
				this.show = false
				this.nodata = false
			},
			getList() {
				if (this.type == 0) {
					this.$message.error('请选择查询方式')
					return
				}
				var data = {
					type: this.type,
					number: this.number
				}
				getOrder().then((res) => {
					console.log(res);
					this.list = res.data
					this.show = true
				});
			},
			getExpressDetail(id) {
				getDetail({
					orderNo: id
				}).then((res) => {
					console.log(res);
					this.expressDetail = res.data
					this.showDetail = true
				});
			},
			getOrderDetail(id) {
				console.log(id)
				let res = {
					data:''
				}
				res.data = {
					"isInProduction": 2,
					"isExpress": 1,
					"express": {
						"com": "shentong",
						"data": [{
								"ftime": "2022-03-22 22:16:46",
								"areaCode": "CN330782000000",
								"areaName": "浙江,金华市,义乌市",
								"context": "浙江义乌公司-已发往-四川南充转运中心",
								"areaPinYin": "yi wu shi",
								"location": "",
								"areaCenter": "120.075058,29.306841",
								"time": "2022-03-22 22:16:46",
								"status": "干线",
								"statusCode": "1002"
							},
							{
								"ftime": "2022-03-22 19:59:52",
								"context": "BC云仓-徐鸿鸿-已收件",
								"location": "",
								"time": "2022-03-22 19:59:52",
								"status": "揽收",
								"statusCode": "1"
							}
						],
						"totalTime": "2天7小时",
						"nu": "773153352519353",
						"trailUrl": "https://api.kuaidi100.com/tools/map/58f21d5393b853dbfefcf4186b366010_106.633212,30.455961_5",
						"remainTime": "2天7小时",
						"isLoop": false,
						"message": "ok",
						"routeInfo": {
							"cur": {
								"number": "CN330782000000",
								"name": "浙江,金华市,义乌市"
							},
							"from": {
								"number": "CN330000000000",
								"name": "浙江"
							},
							"to": {
								"number": "CN511600000000",
								"name": "四川,广安市"
							}
						},
						"ischeck": "0",
						"condition": "00",
						"arrivalTime": "2022-03-25 20",
						"state": "1002",
						"status": "200"
					},
					"isProductionScheduling": 2
				}
				this.order = res.data
				this.express = res.data.express.data
				this.map = true
				
				for (let i = 0; i < this.expressList.length; i++) {
					for (let a in this.expressList[i]) {
						if (this.expressList[i][a] == res.data.express.com) {
							this.expressMsg = this.expressList[i]
							this.expressMsg.number = res.data.express.nu
							return
						}

					}

				}
				// getDetailByNo({
				// 	orderNo: id
				// }).then((res) => {
				// 	this.order = res.data
				// 	this.express = res.data.express.data
				// 	this.map = true
					
				// 	for (let i = 0; i < this.expressList.length; i++) {
				// 		for (let a in this.expressList[i]) {
				// 			if (this.expressList[i][a] == res.data.express.com) {
				// 				this.expressMsg = this.expressList[i]
				// 				this.expressMsg.number = res.data.express.nu
				// 				return
				// 			}

				// 		}

				// 	}
				// });
			}
		},
	};
</script>
<style>

</style>
<style scoped lang="less">
	/deep/ .arco-input-size-medium {
		font-size: 24px !important;
		font-family: PingFang SC-Medium, PingFang SC;
	}

	/deep/ .arco-icon-hover-size-medium {
		width: 16px;
		height: 16px;
		background-color: #1F1F1F;
		color: #FFFFFF;
		border-radius: 50%;
		text-align: center;
		line-height: 16px;

		.arco-icon-close {
			color: #FFFFFF !important;
		}
	}

	.page {
		padding: 0;
	}

	/deep/ .arco-select-view-single {
		outline: none;
		border: none;
		// height: 48px;
		background-color: #F2F3F5;
		border-radius: 4px 0 0 4px;
		margin-left: 11px;
	}

	/deep/ .arco-select-view-value {
		font-size: 21px !important;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #81868C;
		line-height: 48px;
	}

	// @import url("../../styles/all.less");
	.index {
		position: absolute;
		top: 0;
		left: 96px;
		width: 503px;
		right: 0;
		bottom: 0;
		background-color: #FAFBFF;
		z-index: 1;
	}

	// 搜索框
	.search {
		display: flex;
		align-items: center;
		width: 480px;
		height: 84px;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		opacity: 1;
		position: relative;
		box-shadow: 0px 6px 6px 2px rgba(59, 117, 253, 0.05);
		padding-right: 23px;
	}

	.delete {
		position: absolute;
		top: 30px;
		right: 112px;
		font-size: 24px;
		color: #1F1F1F;
	}

	/deep/ .arco-select-view-value {
		padding: 0;
		color: #C4C4C4;
	}

	/deep/ .arco-input-wrapper {
		height: 48px;
		background-color: #FFFFFF;
		border-radius: 0;
		border: none;
		outline: none;
		font-size: 24px!important;
		padding-left: 0;
	}

	/deep/ .arco-btn-primary {
		height: 48px;
		border-radius: 0 3px 3px 0;
	}

	// 搜索结果
	.content1 {
		position: absolute;
		top: 75px;
		left: 0;
		right: 0;
		height: 300px;
		// background-color: #FFFFFF;
		border-radius: 4px;
		overflow: hidden;
		padding: 0 14px 0 17px;

		.title {
			width: 100%;
			height: 30px;
			// background: #FBFCFE;
			border-radius: 4px 4px 4px 4px;
			opacity: 1;
			font-size: 18px;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #B0B6BD;
			line-height: 30px;
			text-align: start;
			padding: 12px 0 0;
		}

		// 手机号搜索
		.mobile {
			.for {
				height: 119px;
				background-color: #FAFBFF;
				padding: 0 20px;

				.right {
					top: 15px;
				}
			}

			.title {
				font-size: 24px;
				font-family: PingFang SC-Heavy, PingFang SC;
				font-weight: 800;
				color: #4E5969;
				line-height: 28px;
				// background-color: #FFFFFF;
				padding-left: 0px;
				margin-top: 15px;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}

			.phone {
				font-size: 24px;
				font-family: PingFang SC-Medium, PingFang SC;
				font-weight: 500;
				color: #3B75FD;
				line-height: 28px;
				margin-top: 18px;
			}

			.time {
				margin-top: 18px;
				font-size: 24px;
				font-family: PingFang SC-Medium, PingFang SC;
				font-weight: 500;
				color: #4E5969;
				line-height: 28px;
				margin-left: 50px;
				min-width:203px;
			}
		}

		// 订单号搜索
		.order {
			background-color: #FBFCFE;

			.for {
				background-color: #FAFBFF;
				height: 60px;
				margin: 6px 20px 0;
				display: flex;
				padding-left: 0;
				align-items: center;
				justify-content: space-between;
				font-family: PingFang SC-Medium, PingFang SC;

				.right {
					top: 17px;

					.icon {
						font-size: 18px;
					}
				}

				.title {
					padding-top: 0;
					font-size: 24px;
					font-family: PingFang SC-Heavy, PingFang SC;
					font-weight: 800;
					color: #0075FF;
					line-height: 28px;
				}
			}
		}

		// 无数据
		.nodata {
			text-align: center;

			.bg {
				width: 159px;
				height: 135px;
				margin-top: -20px;
			}

			.tips {
				font-size: 21px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #B0B6BD;
				line-height: 36px;
				text-align: center;
			}
		}
	}

	// 搜索结果
	.content {
		position: absolute;
		top: 84px;
		left: 0;
		right: 0;
		z-index: -1;
		// background-color: #FFFFFF;
		border-radius: 4px;
		overflow: hidden;
		padding: 22px 22px 0;

		.title {
			font-size: 24px;
			font-family: PingFang SC-Heavy, PingFang SC;
			font-weight: 800;
			color: #4E5969;
			line-height: 28px;
			// border-left: 2px solid #2189F3;
			border-radius: 0;
			// background-color: #FFFFFF;
			color: #252525;
			text-align: start;
		}


		// 无数据
		.nodata {
			text-align: center;
			padding-bottom: 177px;
			padding-top: 177px;

			.bg {
				width: 159px;
				height: 135px;
				margin-top: 47px;
			}

			.tips {
				font-size: 16px;
				font-family: PingFang SC-Medium, PingFang SC;
				font-weight: normal;
				color: rgba(0, 0, 0, 0.30000001192092896);
				line-height: 24px;
				margin-top: 24px;
			}
		}

		.orderTime {
			width: 315px;
			height: 45px;
			background: #FFFFFF;
			border-radius: 6px;
			font-size: 21px;
			font-family: PingFang SC-Medium, PingFang SC;
			font-weight: 500;
			color: #81868C;
			line-height: 45px;
			// padding-left: 11px;
			margin: 10px 0 11px;
			overflow: hidden;

			.blur {
				font-size: 21px;
				font-family: PingFang SC-Medium, PingFang SC;
				font-weight: 500;
				color: #FF5952;
				line-height: 30px;
				margin-left: 12px;
			}
		}

		.step {
			border-top: 1px solid #E1E1E1;
			padding: 24px 0 0;
			font-size: 24px;
			font-family: PingFang SC-Heavy, PingFang SC;
			font-weight: 800;
			line-height: 28px;

			.list {
				border-left: 2px solid #ABB5C2;
				position: relative;
				width: 100%;
				min-height: 80px;
				margin-left: 7px;

				.right {
					position: absolute;
					left: 325px;
					top: 2px;
					font-size: 21px;
					font-weight: normal;
					font-family: PingFang SC-Medium, PingFang SC;
					color: #B4B4B4;
					line-height: 20px;
					text-align: right;
				}

				.flex {
					width: 100%;
					display: flex;
					align-items: center;
					position: absolute;
					left: -12px;
					top: -8px;

					.icon {
						margin-right: 8px;
						background-color: #FFFFFF;
						border-radius: 50%;
						width: 24px;
						height: 24px;
					}

					.button {
						height: 36px;
						border-radius: 6px 6px 6px 6px;
						background: #00000030;
						text-align: center;
						margin-left: 18px;
						padding: 0 10px;
						font-size: 21px;
						font-family: PingFang SC-Medium, PingFang SC;
						font-weight: 500;
						color: #A6A6A6;
						line-height: 36px;
					}
				}

				.end {
					color: #4E5969;

					.button {
						color: #4E5969;
						background-color: #4E596910;
					}
				}

				.ing {
					color: #FF5952;

					.button {
						color: #FF5952;
						background-color: #FF595220;
					}
				}

				.nostart {
					color: #D3D3D3;

					.button {
						color: #A7A7A7;
						background-color: #D3D3D330;
					}

					// .icon {
					// 	width: 15px;
					// 	height: 15px;
					// 	padding: 2px;
					// }
				}
			}
		}

		.logistics {
			padding-top: 20px;
			// padding-left: 20px;
			margin-left: -9px;
			overflow: hidden;
			position: relative;
			top: 30px;

			.list {

				font-weight: 500 !important;

				.choose {
					.i {
						min-width: 11px;
						min-height: 11px;
						background: #FF5952 !important;
						opacity: 1;
						// border: 2px solid #FF5952;
						margin-left: 4px;
					}

					color: #FF5952 !important;

					.time {
						color: #FF5952 !important;
					}

				}

				.flex {
					color: #242424;

					.time {
						font-size: 21px;
						font-family: PingFang SC-Medium, PingFang SC;
						font-weight: normal;
						color: #9D9D9D;
						line-height: 20px;
						margin-left: 8px;
					}
				}

				.center {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 14px;
					height: 14px;
					margin-left: 2px;
					margin-right: 16px;

					.i {
						min-width: 11px;
						min-height: 11px;
						background: #ABB4C2;
						margin-left: 4px;
						opacity: 1;
						border-radius: 50%;
					}
				}

				.desc {
					font-size: 21px;
					font-family: PingFang SC-Medium, PingFang SC;
					font-weight: normal;
					color: #626262;
					line-height: 20px;
					text-align: start;
					padding: 32px 20px 0;
				}
			}

		}

		.logistics::-webkit-scrollbar {
			width: 4px;
		}

		.logistics::-webkit-scrollbar-thumb {
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 3px #7BA6E4;
			// background: rgba(0, 0, 0, 0.2);
		}

		.logistics::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			border-radius: 0;
			background: rgba(0, 0, 0, 0.1);
		}
	}

	.select {
		height: 48px;
		background-color: #F2F3F5;
		text-align: start;
		font-size: 21px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #81868C;
		line-height: 48px;
		position: relative;
		margin-left: 11px;
		padding-left: 12px;
		border-radius: 3px 0 0 3px;
	}

	.options {
		position: absolute;
		top: 72px;
		width: 132px;
		height: 113px;
		background: #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		opacity: 1;
	}

	.option {
		width: 120px;
		height: 45px;
		background-color: #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		opacity: 1;
		font-size: 21px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #81868C;
		line-height: 45px;
		margin: 6px;
	}

	.option:hover {

		background-color: #F0F1F3;
	}

	.add-box {
		.flex {
			font-size: 16px;
			font-weight: normal;
			font-family: PingFang SC-Medium, PingFang SC;
			color: #252525;
			line-height: 22px;
			margin: 24px 58px;

			.module {
				width: 230px;
			}

			.name {
				font-size: 14px;
				font-weight: normal;
				color: #9D9D9D;
				line-height: 20px;
				font-family: PingFang SC-Medium, PingFang SC;
			}
		}

		.msg {
			background-color: #EFF7FF;
			padding: 50px 72px 10px;

			.list {
				border-left: 1px solid #DADADA;
				position: relative;
				min-height: 50px;

				.flex {
					position: absolute;
					top: -14px;
					left: -14px;
					margin: 0;
				}

				.icon {
					width: 28px;
					height: 28px;
					background: #333333;
					opacity: 1;
					border-radius: 50%;
					font-size: 14px;
					font-weight: normal;
					font-family: PingFang SC-Medium, PingFang SC;
					color: #FFFFFF;
					line-height: 28px;
					text-align: center;
					margin-right: 14px;
				}

				.name {
					font-size: 16px;
					font-weight: 700;
					font-family: PingFang SC-Medium, PingFang SC;
					color: #000000;
					line-height: 22px;
					display: flex;

					.phone {
						margin-left: 16px;
					}
				}

				.address {
					font-size: 14px;
					font-family: PingFang SC-Medium, PingFang SC;
					font-weight: normal;
					color: #000000;
					line-height: 20px;
				}
			}
		}

	}

	.express {
		position: fixed;
		top: 50px;
		right: 26px;
		left: 623px;
		height: 81px;
		background: #FFFFFF;
		opacity: 1;
		display: flex;
		align-items: center;
		border-radius: 4px;
		border: 2px solid #FFFFFF;
		// border-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) 2 2;

		.icon {
			width: 56px;
			height: 56px;
			border-radius: 50%;
			margin: 12px 27px 14px;
		}

		.name {
			font-size: 24px;
			font-family: PingFang SC-Heavy, PingFang SC;
			font-weight: 800;
			color: #4E5969;
			// line-height: 81px;
			// line-height: 28px;
			margin-right: 39px;
		}

		.number {
			font-size: 24px;
			font-family: PingFang SC-Medium, PingFang SC;
			font-weight: 500;
			color: #4E5969;
			// line-height: 81px;
		}
	}
</style>
