//引用第一步创建的axios.js文件
import request from '../axios.js'
// 获取生产任务
export function getStaffMsg(params) {
  return request({
    url: 'minapp/wages/basicsInformation',
    method: 'get',
    params
  })
}
// 获取 订单详情
export function getList(params) {
  return request({
    url: 'minapp/wages/wagesRecord',
    method: 'get',
    params
  })
}
export function getChart(params) {
    return request({
      url: 'minapp/wages/chart',
      method: 'get',
      params
    })
  }
