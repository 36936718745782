<template>
	<div class="page" :style="{ height: screenHeight + 'px' }" style="overflow-y: scroll;">
		<!-- <div class="fold" @click="fold = !fold">
			<icon-double-left class="fold-icon" v-if="fold" />
			<icon-double-right class="fold-icon" v-else />
		</div> -->
		<div class="bg pageTitle">
			员工技能驾驶舱
			<div class="factory flex" :class="{ active: item.structureId == factoryId }" v-for="item in factory"
				:key="item">
				{{ item.structureName }}
				<div class="factory-active" v-if="item.structureId == factoryId"></div>
			</div>
		</div>
		<div class="flex" style="align-items: flex-start;">
			<div>
				<div class="charts1 bg">
					<div class="module-list">
						<div class="module" :class="{choose:index==i}" @click="changeList(i)" v-for="(item,i) in list">
							<div class="center">
								<div>{{item.structureName}}</div>
								<div class="flex-bottom">
									<div class="count">{{item.count}}</div>
									<div>人</div>

								</div>
							</div>
						</div>
					</div>
					<div class="chart chart1" id="info"></div>
					<div class="legend-list">
						<div class="flex legend" v-for="(item,i) in levelInfo" @click="getDetailList(item)">
							<div class="legend-icon" :style="{backgroundColor:color[i]}"></div>
							<span class="legend-name">{{item.name}}</span>
							<span class="count">{{item.value}}</span>
							<!-- <span class="legend-desc">人</span>
							<icon-caret-down style="color: #C4C4C4;" /> -->
						</div>
					</div>
				</div>
				<div class="staff">
					<div class="staff-list flex bg" v-for="(item,index) in stafflist">
						<div class="name">{{item.levelDescription}}</div>
						<div class="count">{{item.value}}</div>
						<div class="add">
							当月新增
							<span :class="item.addValue>0?'red':'jiang'">{{item.addValue}}</span>
							<img class="icon" v-show="item.addValue>0" src="../../assets/icon/sheng.png" />
							<img class="icon" v-show="item.addValue<0" src="../../assets/icon/jiang.png" />
						</div>
						<div class="chart chart2" :id="'workshop'+index"></div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="bg" :style="{height:screenWidth>1300?'322px':'214px'}">
					<div class="title">
						人员异动分析
						<div class="right flex">
							<div class="icon" style="background-color: #32F0E5;"></div>
							离职人数
							<div class="icon" style="background-color: #249EFF;"></div>
							入职人数
						</div>
					</div>
					<div class="chart" id="line"></div>
				</div>
				<div class="bg" :style="{height:screenWidth>1300?'326px':'214px'}">
					<div class="title">工龄分析</div>
					<div class="chart" id="pie" ref="container"></div>
					<!-- <Pie ref="Pie"></Pie> -->
					<div class="legend-list">
						<div class="flex legend" v-for="(item,i) in yearList">
							<div class="legend-icon" :style="{backgroundColor:yearColor[i]}"></div>
							<span class="legend-desc" style="width: 90px;">{{item.time}}</span>
							<span class="legend-name">{{item.value}}</span>
						</div>
					</div>
					<div class="tools">
						<div class="count">{{yearList[index1].value}}</div>
						<div>{{yearList[index1].time}}</div>
					</div>
				</div>
				<div class="bg" :style="{height:screenWidth>1300?'316px':'217px'}">
					<div class="title">年龄分布</div>
					<div class="chart" id="category"></div>
				</div>
			</div>
		</div>
		<a-modal v-model:visible="showDetail" class="add-box add-box1" :footer="false" :width="552">
			<template #title>
				<div class="model-title">{{name}}</div>
			</template>
			<a-table style="margin: 0 10px 22px;" :data="detail" :scroll="{y: 280}" :stripe="true" :pagination="false"
				:bordered="false">
				<template #columns>
					<a-table-column title="姓名" data-index="name" width="70"></a-table-column>
					<a-table-column title="小组" data-index="structureName" width="110"
						:filterable="filterable.structureName">
					</a-table-column>
					<a-table-column title="性别" data-index="sex" width="80" :filterable="filterable.Gender">
						<template #cell="{ record }">
							{{record.sex==1?'男':'女'}}
						</template>
					</a-table-column>
					<a-table-column title="年龄" data-index="age" width="70"
						:sortable="{sortDirections: ['ascend', 'descend']}"></a-table-column>
					<a-table-column title="工龄" data-index="workYear" width="70"
						:sortable="{sortDirections: ['ascend', 'descend']}"></a-table-column>
					<a-table-column title="籍贯" data-index="address" width="140" :filterable="filterable.address">
					</a-table-column>
				</template>
			</a-table>
		</a-modal>
	</div>
</template>

<script>
	import HighCharts from 'highcharts'
	import 'echarts-gl'
	import {
		getStructure
	} from "../../api/index.js"
	import {
		getDetail,
		getList,
		getAgeList,
		getMonthList,
		getStaffList,
		getYearList,
		getCharts
	} from "../../api/staff.js";
	import {
		reactive
	} from 'vue';
	export default {
		data() {
			return {
				index1: 0,
				status1: null,
				status2: null,
				status3: null,
				detail: [],
				yearList: [{
					value: 1
				}],
				factory: [], //工厂列表
				factoryId: 0, //当前选择工厂的id
				filterable: {
					Gender: {
						filters: [{
							text: '男',
							value: 1
						}, {
							text: '女',
							value: 0
						}],
						filter: (value, record) => record.sex == value,
					},
					structureName: {
						filters: [],
						filter: (value, record) => record.structureName == value,
					},
					address: {
						filters: [],
						filter: (value, record) => record.address == value,
					},
				},
				stafflist: [],
				month: [],
				list: [],
				levelInfo: [],
				data: reactive(Array(10).fill(null).map((_, index) => ({
					key: String(index),
					name: '于明远',
					address: '黑龙江·哈尔滨',
					group: '封板&打磨组',
					sex: '女',
					age: '26',
					year: '2'
				}))),
				index: 0,
				showDetail: false,
				fold: false,
				name:'',
				yearColor: ['#0FD9CD', '#82CDFF', '#2B73FF', '#009DFF', '#8000FF'],
				color: ['#3685F7', '#5CCCE8', '#5564F6', '#F19A3D', '#F1D94E', '#A2BBD7', '#5CCCE8', '#5564F6', '#2EF867',
					'#F19A3D', '#F1D94E', '#A2BBD7', '#00D1BE', '#F8DFF9',
					'#AFB6FB'
				], //饼状图颜色
				screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
				screenWidth: document.documentElement.clientWidth, //获取屏幕宽度
			};
		},
		mounted: function() {
			window.onresize = () => {
				return (() => {
					window.screenHeight = document.documentElement.clientHeight
					this.screenHeight = window.screenHeight;
					window.screenWidth = document.documentElement.clientWidth
					this.screenWidth = window.screenWidth;
				})();
			};
			// this.initChart()
			this.getList()
			this.getMonth()
			this.getStaff()
			this.getAge()
			this.getYear()
			this.getFactory();
			this.getCharts3()
		},
		beforeRouteLeave: function() {
			clearInterval(this.status1);
			clearInterval(this.status2);
			clearInterval(this.status3);
		},
		methods: {
			// 获取工厂信息
			getFactory() {
				getStructure({
					type: 7,
				}).then((res) => {
					this.factory = res.data;
					this.factoryId = res.data[0].structureId;
					this.getList()
				});
			},
			getYear() {
				getYearList().then((res) => {
					console.log(res)
					var list = res.data
					this.yearList = list
					var arr = []
					for (let i = 0; i < list.length; i++) {
						arr.push({
							name: list[i].time,
							y: list[i].value,
							h: 1,
						})
					}
					arr[0].h = 10
					this.index1 = 0
					this.initChart(arr)
					var index = 0,
						length = list.length
					this.status1 = setInterval(() => {
						if (index < length) {
							index++
						} else {
							index = 0
						}
						if (index == 0) {
							arr[length - 1].h = 1
						} else {
							arr[index - 1].h = 1
						}
						arr[index].h = 10

						this.index1 = index
						this.initChart(arr)
					}, 5000)
				});
			},
			getDetailList(item) {
				var name = this.list[this.index].structureName+'-'+ item.name
				getDetail().then((res) => {
					this.showDetail = true
					this.detail = res.data
					var list = this.$unique(res.data)
					name = name + '('+res.data.length+')人'
					this.name = name
					for (let a in this.filterable) {
						console.log(a)
						for (let i = 0; i < list.length; i++) {
							if (a == list[i].name) {
								this.filterable[a].filters = list[i].list
							}

							// this.filterable[index].filters = list[i].list
						}
					}
				});
			},
			getAge() {
				getAgeList().then((res) => {
					let list = res.data.ageInfo,
						time = [],
						value = []
					for (let i = 0; i < list.length; i++) {
						time.push(list[i].time)
						value.push(list[i].value)
					}
					let data = {
						time: time,
						value: value
					}
					this.getCharts4(data)
				});
			},
			getStaff() {
				getStaffList().then((res) => {
					var stafflist = res.data
					this.stafflist = stafflist
					setTimeout(() => {
						for (let i = 0; i < stafflist.length; i++) {
							this.getChartsMsg(i, stafflist[i].id)
						}
					}, 500);
				});
			},

			getChartsMsg(index, id) {
				// getCharts({
				// 	levelId: id
				// }).then((res) => {
				// 	var list = res.data,
				// 		num = [],
				// 		number = []
				// 	for (let i = 0; i < list.length; i++) {
				// 		num.push(list[i].num)
				// 		number.push(list[i].num - list[i].num / 5)
				// 	}
				// 	var arr = {
				// 		num: num,
				// 		number: number
				// 	}
				// 	this.getCharts(index, arr)
				// });
			},
			getMonth() {
				getMonthList().then((res) => {
					var month = res.data
					let time = [],
						entry = [],
						leave = [],
						max = 0
					for (let i = 0; i < month.length; i++) {
						if (i == 0) {
							max = month[i].entryValue > month[i].leaveValue ? month[i].entryValue : month[i]
								.leaveValue
						} else {
							if (month[i].entryValue > max) {
								max = month[i].entryValue
							}
							if (month[i].leaveValue > max && month[i].leaveValue > month[i].entryValue) {
								max = month[i].leaveValue
							}
						}
						let a = month[i].time
						a = a.split('-')
						time.push(a[1] + '月')

						entry.push(month[i].entryValue)
						leave.push(month[i].leaveValue)
					}
					let maxList = []
					for (let i = 0; i < month.length; i++) {
						maxList.push(max + 1)
					}
					var arr = {
						time: time,
						entry: entry,
						leave: leave,
						list: month,
						maxList: maxList
					}
					this.getCharts2(arr)
				});
			},
			getList() {
				getList().then((res) => {
					var list = res.data
					for (let i = 0; i < list.length; i++) {
						var arr = list[i].levelInfo
						var count = 0
						for (let j = 0; j < arr.length; j++) {
							count += arr[j].value
						}
						list[i].count = count
					}
					this.list = list
					this.levelInfo = list[0].levelInfo
					this.getCharts1(list[0].levelInfo)
					// this.expressDetail = res.data
					// this.showDetail = true
				});
			},
			changeList(index) {
				this.index = index
				var list = this.list[index].levelInfo
				this.levelInfo = list
				this.getCharts1(list)
			},
			getCharts1(arr) {
				var chartDom = document.getElementById('info');
				var myChart = this.$echarts.init(chartDom);

				myChart.dispatchAction({
					type: 'highlight',
					seriesIndex: 0,
					dataIndex: 0
				});
				var option = {
					tooltip: {
						trigger: 'item',
						backgroundColor: 'transparent',
						borderColor: "#ff000000",
						position: ['20%', '40%'],
						extraCssText: 'box-shadow: 0 0 0 #FFFFFF00;', // 额外附加到浮层的 css 样式
						formatter: function(param) { //自定义tooltip内容
							var text = '';
							text +=
								'<div style="font-size: 32px;color: #FFFFFF;">' +
								param.percent +
								'%</div><div style="font-size: 16px;color: #FFFFFF;">' +
								param.name + '</div>'
							return text;
						},
					},
					color: this.color,
					legend: {
						show: false,
					},
					series: [{
						name: 'Access From',
						type: 'pie',
						center: ['35%', '50%'],
						radius: ['3%', '80%'],
						avoidLabelOverlap: false,
						backgroundColor: '#FFFFFF00',
						itemStyle: {
							borderRadius: 10,
							borderWidth: 0
						},
						label: {
							show: false,
							position: 'center'
						},
						labelLine: {
							show: false
						},
						data: arr
					}]
				};
				var index = -1;
				var lastIndex = 0
				this.status2 = setInterval(() => {
					var dataLen = option.series[0].data.length;
					//取消之前高亮的图形
					if (index + 1 == dataLen) {
						index = 0
						lastIndex = dataLen - 1
					} else {
						index++
						lastIndex = index - 1
					}
					for (let i = 0; i < 12; i++) {
						myChart.dispatchAction({
							type: 'downplay',
							seriesIndex: 0,
							dataIndex: i
						});
					}
					//高亮当前图形
					myChart.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: index
					});
					//显示tooltip
					myChart.dispatchAction({
						type: 'showTip',
						seriesIndex: 0,
						dataIndex: index
					})
				}, 5000);
				option && myChart.setOption(option);
				document.getElementById('info').setAttribute('_echarts_instance_', '');

			},
			getCharts2(arr) {
				var list = arr.list
				var aveHistogram = this.$echarts.init(
					document.getElementById("line")
				);
				aveHistogram.on('mouseout', function(params) {
					for (let i = 0; i < arr.length; i++) {
						aveHistogram.dispatchAction({
							type: 'downplay',
							seriesIndex: 0,
							dataIndex: i
						});
					}
				})
				var that = this
				var option = {
					title: {
						show: false,
					},
					grid: {
						top: 50,
						left: 10,
						right: 10,
						bottom: 15,
						containLabel: true
					},
					tooltip: {
						trigger: 'axis',
						extraCssText: 'box-shadow: 0 0 0 #FFFFFF00;', // 额外附加到浮层的 css 样式
						backgroundColor: '#F9F8FB00',
						borderColor: '#FFFFFF00',
						formatter: function(param) { //自定义tooltip内容
							var index = param[1].dataIndex
							var text = '';
							text +=
								'<div style="width: 100px;text-align:start;height: 74px;padding-left: 15px;line-height: 20px;background: #E1EDFB;border-radius: 4px;font-size: 10px;color: #527A99;">' +
								'<div style="padding-top: 6px;">员工总数<span style="font-size: 14px;font-weight: 800;color: #2E5D82;margin-left:6px;">' +
								list[index].totalValue +
								'</span></div><div>入职人数<span style="font-size: 14px;font-weight: 800;color: #2E5D82;margin-left:6px;">' +
								list[index].entryValue +
								'</span></div><div>离职人数<span style="font-size: 14px;font-weight: 800;color: #FFA834;margin-left:6px;">' +
								list[index].leaveValue + '</span></div></div>'
							return text;
						},
					},
					xAxis: {
						data: arr.time,
						// data: ['02-02','02-03','02-04'],
						type: "category",
						axisLabel: {
							interval: 0,
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
							//x轴刻度相关设置
						},
					},
					yAxis: {
						type: 'value',
						max:arr.maxList[0],
						interval:parseInt(arr.maxList[0]/3),
						splitLine: {
							//设置条纹显示 柱状图默认yAxis(true)
							show: true,
							//设置lineStyle即可写入样式即可
							lineStyle: {
								color: '#F7F7F7',
							}
						}
					},
					series: [{
						name: "总数",
						type: "bar",
						data: arr.maxList,
						// data: [5,2,4],
						barWidth: 70,
						itemStyle: {
							normal: {
								// showBackground: false,
								color: "#FFFFFF10"
							},
							emphasis: {
								shadowBlur: 1,
								shadowOffsetX: 0,
								shadowColor: '#1b8ff3'
							}
						},
					}, {
						name: '离职',
						type: 'line',
						smooth: 0.6,
						symbol: 'none',
						lineStyle: {
							color: '#32F0E5',
							width: 3,
						},
						areaStyle: {
							color: '#FFFFFF00'
						},
						data: arr.leave,
					}, {
						name: '入职',
						type: 'line',
						smooth: 0.6,
						symbol: 'none',
						lineStyle: {
							color: '#249EFF',
							width: 3,
						},
						areaStyle: {
							color: '#FFFFFF00'
						},
						data: arr.entry,
					}],
				};
				var dataLen = list.length;
				var index = -1;
				this.status3 = setInterval(() => {
					//取消之前高亮的图形
					if (index + 1 >= dataLen) {
						index = 0
					} else {
						// console.log(2)
						index++
						// console.log(1)
					}
					// app.currentIndex = (app.currentIndex + 1) % dataLen;
					for (let i = 0; i < arr.length; i++) {
						aveHistogram.dispatchAction({
							type: 'downplay',
							seriesIndex: 0,
							dataIndex: i
						});
					}
					//高亮当前图形
					aveHistogram.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: index
					});
					//显示tooltip
					aveHistogram.dispatchAction({
						type: 'showTip',
						seriesIndex: 0,
						dataIndex: index
					})
				}, 5000);
				option && aveHistogram.setOption(option)
				document.getElementById('line').setAttribute('_echarts_instance_', '');
			},
			getCharts3() {

			},
			getCharts4(arr) {
				var that = this
				var histogram = this.$echarts.init(
					document.getElementById("category")
				);
				var option = {
					title: {
						show: false,
					},
					grid: {
						top: 50,
						left: 10,
						right: 10,
						bottom: 15,
						containLabel: true
					},
					xAxis: {
						data: arr.time,
						type: "category",
						axisLabel: {
							interval: 0,
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
							//x轴刻度相关设置
						},
					},
					yAxis: {
						type: 'value',
						splitLine: {
							//设置条纹显示 柱状图默认yAxis(true)
							show: true,
							//设置lineStyle即可写入样式即可
							lineStyle: {
								color: '#F7F7F7',
							}
						}

					},
					series: [{
						name: "人数",
						type: "bar",
						data: arr.value,
						barWidth: this.screenWidth > 1400 ? 15 : 10,
						showBackground: true,
						backgroundStyle: {
							color: '#FAFAFA'
						},
						itemStyle: {
							normal: {
								barBorderRadius: [20, 20, 0, 0],
								color: that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 1,
										color: "#03C9FD",
									},
									{
										offset: 0,
										color: "#0A93FB",
									},
								]),
								label: {
									show: true,
									formatter: "{c}",
									position: "top",
									textStyle: {
										//数值样式
										color: "#2189F3",
										fontSize: 12,
									},
								},
							},
						},
					}, ],
				};
				option && histogram.setOption(option)
			},
			// getCharts(i, arr) {
			// 	var myChart = this.$echarts.init(
			// 		document.getElementById('workshop' + i)
			// 	);

			// 	var option = {
			// 		xAxis: {
			// 			type: 'category',
			// 			data: arr.number,
			// 			show: false,
			// 		},
			// 		yAxis: {
			// 			type: 'value',
			// 			show: false,
			// 		},
			// 		series: [{
			// 			data: arr.number,
			// 			type: 'line',
			// 			smooth: true,
			// 			symbol: "none",
			// 			color: '#6AA1FF',
			// 			itemStyle: {
			// 				normal: {
			// 					lineStyle: {
			// 						type: 'dotted'
			// 					}
			// 				}
			// 			}
			// 		}, {
			// 			data: arr.num,
			// 			type: 'line',
			// 			smooth: true,
			// 			symbol: "none",
			// 			color: '#6B85C0'
			// 		}]
			// 	};

			// 	option && myChart.setOption(option);

			// },
			initChart(arr) {
				// 修改3d饼图绘制过程
				const each = HighCharts.each
				const round = Math.round
				const cos = Math.cos
				const sin = Math.sin
				const deg2rad = Math.deg2rad
				HighCharts.wrap(HighCharts.seriesTypes.pie.prototype, 'translate', function(proceed) {
					proceed.apply(this, [].slice.call(arguments, 1))
					// Do not do this if the chart is not 3D
					if (!this.chart.is3d()) {
						return
					}
					const series = this
					const chart = series.chart
					const options = chart.options
					const seriesOptions = series.options
					const depth = seriesOptions.depth || 0
					const options3d = options.chart.options3d
					const alpha = options3d.alpha
					const beta = options3d.beta
					var z = seriesOptions.stacking ? (seriesOptions.stack || 0) * depth : series._i * depth
					z += depth / 2
					if (seriesOptions.grouping !== false) {
						z = 0
					}
					each(series.data, function(point) {
						const shapeArgs = point.shapeArgs
						var angle
						point.shapeType = 'arc3d'
						var ran = point.options.h
						shapeArgs.z = z
						shapeArgs.depth = depth * 0.75 + ran
						shapeArgs.alpha = alpha
						shapeArgs.beta = beta
						shapeArgs.center = series.center
						shapeArgs.ran = ran
						angle = (shapeArgs.end + shapeArgs.start) / 2
						point.slicedTranslation = {
							translateX: round(cos(angle) * seriesOptions.slicedOffset * cos(alpha *
								deg2rad)),
							translateY: round(sin(angle) * seriesOptions.slicedOffset * cos(alpha *
								deg2rad))
						}
					})
				});
				(function(H) {
					H.wrap(HighCharts.SVGRenderer.prototype, 'arc3dPath', function(proceed) {
						// Run original proceed method
						const ret = proceed.apply(this, [].slice.call(arguments, 1))
						ret.zTop = (ret.zOut + 0.5) / 100
						return ret
					})
				}(HighCharts))
				// 生成不同高度的3d饼图
				const highEcharts = this.$refs.container
				HighCharts.chart(highEcharts, {
					chart: {
						type: 'pie',
						animation: false,
						backgroundColor: 'rgba(0,0,0,0)',

						events: {
							load: function() {
								const each = HighCharts.each
								const points = this.series[0].points
								each(points, function(p, i) {
									p.graphic.attr({
										translateY: -p.shapeArgs.ran
									})
									p.graphic.side1.attr({
										translateY: -p.shapeArgs.ran
									})
									p.graphic.side2.attr({
										translateY: -p.shapeArgs.ran
									})
								})
							}
						},
						options3d: {
							enabled: true,
							alpha: 55
						}
					},
					title: {
						show: 'false',
						text: null
					},
					subtitle: {
						text: null
					},
					credits: {
						enabled: false
					},
					legend: { // 【图例】位置样式
						backgroundColor: 'rgba(0,0,0,0)',
						shadow: false,
						layout: 'vertical',
						align: 'right', // 水平方向位置
						verticalAlign: 'top', // 垂直方向位置
						x: 0, // 距离x轴的距离
						y: 100, // 距离Y轴的距离
						symbolPadding: 10,
						symbolHeight: 14,
						itemStyle: {
							lineHeight: '24px',
							fontSize: '16px',
							color: '#fff'
						},
						labelFormatter: function() {
							/*
							 *  格式化函数可用的变量：this， 可以用 console.log(this) 来查看包含的详细信息
							 *  this 代表当前数据列对象，所以默认的实现是 return this.name
							 */
							return this.name + this.h + '%'
						}
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: 'pointer',
							depth: 10,
							innerSize: this.screenWidth > 1300 ? 180 : 150,
							dataLabels: {
								enabled: false
							},
							// 显示图例
							showInLegend: false
						}
					},
					series: [{
						type: 'pie',
						name: '占比',
						animation: false,
						// h 是高度  y是占的圆环长度
						colorByPoint: true,
						colors: [{ // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
							linearGradient: {
								x1: 0,
								y1: 1,
								x2: 1,
								y2: 0
							},
							stops: [
								[0, '#0FD9CD'],
							]
						}, { // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
							linearGradient: {
								x1: 0,
								y1: 1,
								x2: 1,
								y2: 0
							},
							stops: [
								[0, '#82CDFF'],
							]
						}, { // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
							linearGradient: {
								x1: 0,
								y1: 1,
								x2: 1,
								y2: 0
							},
							stops: [
								[0, '#2B73FF'],
							]
						}, { // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
							linearGradient: {
								x1: 0,
								y1: 1,
								x2: 1,
								y2: 0
							},
							stops: [
								[0, '#009DFF'],
							]
						}, { // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
							linearGradient: {
								x1: 0,
								y1: 1,
								x2: 1,
								y2: 0
							},
							stops: [
								[0, '#8000FF'],
							]
						}],
						data: arr
					}]
				})
			}
		},
	}
</script>
<style>
	.add-box1 .arco-modal-wrapper .arco-modal {
		width: 546px !important;
	}

	/* .add-box1 .arco-icon-hover {
		background-color: #FFFFFF !important;
	} */

	.arco-modal {
		background-color: #F9F9FA !important;
	}


	.arco-modal-title {
		justify-content: flex-start !important;
	}

	.arco-modal-close-btn {
		color: #4E5969 !important;
		font-size: 14px;
		margin-right: 20px;
	}
</style>
<style scoped lang="less">
	@import url("../../styles/all.less");
	@import url("../../styles/third/staff.less");
</style>
