//引用第一步创建的axios.js文件
import request from '../axios.js'
// 获取 质检工序
export function getQCProcessNo(params) {
  return request({
    url: 'minapp/qc/getQCProcessNo',
    method: 'get',
    params
  })
}
// 获取近12天合格率趋势图
export function qualifiedRate(params) {
  return request({
    url: 'minapp/qc/qualifiedRate',
    method: 'get',
    params
  })
}
// 质检总数和平均合格率
export function qualityData(params) {
  return request({
    url: 'minapp/qc/qualityData',
    method: 'get',
    params
  })
}
// TOP 10 疵点分析
export function flawAnalysis(params) {
  return request({
    url: 'minapp/qc/flawAnalysis',
    method: 'get',
    params
  })
}
// 问题部门占比
export function questionDept(params) {
  return request({
    url: 'minapp/qc/questionDept',
    method: 'get',
    params
  })
}
// top 3 疵点小组
export function flawUserGroup(params) {
  return request({
    url: 'minapp/qc/flawUserGroup',
    method: 'get',
    params
  })
}
// 获取 常用疵点 款式
export function commonFlawStyle(params) {
  return request({
    url: 'minapp/qc/commonFlawStyle',
    method: 'get',
    params
  })
}