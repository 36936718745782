import {
	createApp
} from 'vue'
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import router from './router/index.js'
import App from './App.vue';
import * as echarts from 'echarts'; //引入echarts
import '@arco-design/web-vue/dist/arco.css';
import '../src/assets/css/common.css';
import '../src/assets/css/font/iconfont.css';
import 'echarts-liquidfill';
import highcharts from 'highcharts'
import VueHighCharts from 'vue-highcharts'
import './icons'
import SvgIcon from '@/components/SvgIcon'
import highcharts3d from 'highcharts/highcharts-3d'
import Swiper from "swiper"
import 'swiper/dist/css/swiper.css'

highcharts3d(highcharts)
const app = createApp(App);
// app.use(Mui);
app.component('svg-icon', SvgIcon) //注册svg组件

app.config.globalProperties.$echarts = echarts; //全局使用
app.use(ArcoVueIcon);
app.use(router)
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(Swiper);
app.mount('#app');
app.config.globalProperties.$unique = function(arr) {
	var list = []
	var length = 0
	for (let a in arr[0]) {
		var data = {
			name: a,
			list: []
		}
		list.push(data)
		length++
	}
	var index = 0
	for (let i = 0; i < arr.length; i++) {
		var index = 0
		for (let a in arr[i]) {
			if (a == list[index].name) {
				list[index].list.push(arr[i][a])
			}
			index++
		}
	}
	for (var i = 0; i < list.length; i++) {
		list[i].list = Array.from(new Set(list[i].list))
	}
	for (let i = 0; i < list.length; i++) {
		var a = list[i].list
		var filters = []
		for (let j = 0; j < a.length; j++) {
			filters.push({
				text: a[j],
				value: a[j],
			})
		}
		list[i].list = filters
	}
	return list
}
app.config.globalProperties.$baseUrl = 'https://wis4cloud.wis.ltd'
// createApp(App).mount('#app')
