<template>
	<div class="page" :style="{ height: screenHeight + 'px' }" style="overflow-y: scroll;">
		<div class="per_header">
			生产进度驾驶舱
		<div class="per_header_box">
			<div class="per_header_boxs" v-for="item in factory" :key="item">{{ item.structureName}}</div>
		</div>
			<div class="per_right" @click="visiblePick = true">
				<a-date-picker v-model="value"  :popup-visible="visiblePick" @change="changeDate" />
			</div>
		</div>

		<div class="progress">
			<div class="progress-box" v-for="(item,index) in progressList" :key='index'>
				<div class="progress-top">
					<div>{{item.name}}</div>
					<div class="top-right">订单完成率：<span>{{item.percentage}}</span></div>
				</div>
				<div style="margin:0 10px">
					<a-progress status="active" :percent="0.1"  :stroke-width='13'/>
				</div>
				<div class="progress-bottom">
					<div>已完成数/订单数</div>
					<div><span style="color:#FF967E">12</span>/1232</div>
				</div>
			</div>
		</div>

		<div class="list-box">
			<div class="list-top">
				<div>进度详情</div>
				<div style="display:flex"> 
					<div class="list-right">
						<a-input v-model="form.orderNo" placeholder="搜索"
							@press-enter="$refs.inputText1.blur(),$refs.inputText2.blur(),$refs.inputText3.blur(),current = 1,getProgress()"
							ref="inputText1" :allow-clear="true">
							<template #prefix>
								<icon-search />
							</template>
						</a-input>
						<div class="right-but" @click="getProgress">查询</div>
					</div>
					<div class="right-li" @click="visible = true;">
						列配置
					</div>
				</div>
			</div>
			<list :headerData='header'  :content='orderList' :oldList='oldList' :tabHight='screenHeight - 280'></list>
			<!-- <div class="pagination" v-if="pages>1">
				<icon-up @click="previous()" />
				<a-pagination :total="total" @change="changePage" :current="current" :page-size="size"
					:base-size="4" />
				<icon-down @click="next()" />
			</div> -->
		</div>
		<a-drawer :visible="visible" @ok="handleSubmit" @cancel="handleCancel1" unmountOnClose>
			<template #title>
				列显隐
			</template>
			<div>
				<div v-for="(item,index) in header" :key='index'>
					<a-checkbox default-checked v-model="item.show" >{{item.lable}}</a-checkbox><br>
				</div>
			</div>
		</a-drawer>
		<detailTable :data="detail" v-if="showDetail" ref="detailTable" class="card" @close="showDetail = false">
		</detailTable>
	</div>
</template>

<script>
	import {getStructure} from "../../api/index.js";
	import {shopworkProgress,getProgressDetails} from "../../api/schedule.js";
	import detailTable from "../../common/detailTable.vue";
	import list from "../../common/list.vue";
	export default {
		components: {
			detailTable,
			list
		},
		data() {
			return {
				oldList:[],
				header:[
					{
						lable:"序号",
						width:'80px',
						param:"index",
						filter:false,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"状态",
						width:'100px',
						param:"completeState",
						filter:false,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"生产车间",
						width:'150px',
						param:"productionWorkshop",
						filter:true,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"销售订单号",
						width:'200px',
						param:"saleOrderNo",
						filter:true,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"订单编号",
						width:'200px',
						param:"orderNo",
						filter:true,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"时段",
						width:'130px',
						param:"timeInterval",
						filter:true,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"款式名称",
						width:'300px',
						param:"styleName",
						filter:true,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"订单完工日期",
						width:'200px',
						param:"completeDate",
						filter:true,
						filterIcon:true,
						filterList:[],
						show:true
					},
					{
						lable:"工序组",
						width:'130px',
						param:"processGroupName",
						filter:true,
						filterIcon:true,
						filterList:[],
						show:true
					},					
					{
						lable:"部件",
						width:'100px',
						param:"unitName",
						filter:false,
						filterIcon:true,
						filterList:[],
						show:true
					},
				],
				progressList:[
					{
						name:'扪工车间',
						percentage:'94%',
						leftNum:111,
						rightNum:12
					},
					{
						name:'木工车间',
						percentage:'94%',
						leftNum:111,
						rightNum:12
					},
					{
						name:'车工车间',
						percentage:'94%',
						leftNum:111,
						rightNum:12
					},
					{
						name:'裁剪车间',
						percentage:'94%',
						leftNum:111,
						rightNum:12
					},
					{
						name:'发泡车间',
						percentage:'94%',
						leftNum:111,
						rightNum:12
					}
				],
				pages: 0,
				total: 0,
				size: 0,
				current: 1,
				screenWidth: document.body.clientWidth, //获取屏幕宽度
				screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
				factoryId: 0, //当前选择工厂的id
				factory: [], //工厂列表
				pickerValue: new Date().Format('yyyy-MM-dd'), //日期
				active: null, //选中的车间
				form: {}, //搜索信息
				visible: false, //列配置
				showDetail: false, //详情
				list: [], //各车间完成进度列表
				orderList: [], //订单列表
				fold: false,
				moveSize: 0, //顶部图表移动距离
				maxMove: 0, //顶部图表最大移动距离
				move:0,//上一次移动距离
				start: 0, //初始移动位置
				isclick: false, //是否按下
				isMobile: false, //是否为手机端
				detail: {},
				index: 0, //当前选中车间下标
				chartsList: [], //刷新圆环用
				columnConfig: {
					status: true,
					productionWorkshop: true,
					saleOrderNo: true,
					orderNo: true,
					timeInterval: true,
					styleName: true,
					completeDate: true,
					processGroupName: true,
					unitName: true,
					processName: true,
					orderAmount: true,
					kitting: true,
					completeAmount: true,
					orderRemarks: true,
				}, //列配置
				status1: null,
				filterable: {
					status: {
						filters: [{
							text: '未完成',
							value: 0
						}, {
							text: '已完成',
							value: 1
						}],
						filter: (value, record) => record.completeState == value,
					},
					processGroupName: {
						filters: [],
						filter: (value, row) => row.processGroupName.includes(value),
					},
					saleOrderNo: {
						filters: [],
						filter: (value, row) => row.saleOrderNo.includes(value),
					},
					orderNo: {
						filters: [],
						filter: (value, row) => row.orderNo.includes(value),
					},
					processName: {
						filters: [],
						filter: (value, row) => row.processName.includes(value),
					},
					productionWorkshop: {
						filters: [],
						filter: (value, row) => row.productionWorkshop.includes(value),
					},
					styleName: {
						filters: [],
						filter: (value, row) => row.styleName.includes(value),
					},
					orderRemarks: {
						filters: [],
						filter: (value, row) => row.orderRemarks.includes(value),
					},
					unitName: {
						filters: [],
						filter: (value, row) => row.unitName.includes(value),
					},
					orderAmount: {
						filters: [],
						filter: (value, row) => row.orderAmount.includes(value),
					},
					kitting: {
						filters: [],
						filter: (value, row) => row.kitting.includes(value),
					},
					completeAmount: {
						filters: [],
						filter: (value, row) => row.completeAmount.includes(value),
					},
					timeInterval: {
						filters: [],
						filter: (value, row) => row.timeInterval.includes(value),
					},
					completeDate: {
						filters: [],
						filter: (value, row) => row.completeDate.includes(value),
					},
				},
			};
		},
		beforeRouteLeave: function() {
			clearInterval(this.status1);
			window.clearInterval(this.status1);
		},
		mounted: function() {
			this.isMobile = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)

			window.onresize = () => {
				return (() => {
					window.screenHeight = document.documentElement.clientHeight
					this.screenHeight = window.screenHeight;
				})();
			};
			this.getFactory();
			var that = this
			var columnConfig = localStorage.getItem("columnConfig1");
			if (columnConfig != null) {
				console.log(JSON.parse(columnConfig));
				this.columnConfig = JSON.parse(columnConfig);
			} else {
				localStorage.setItem("columnConfig1", JSON.stringify(this.columnConfig));
			}
			that.status1 = setInterval(function() {
				var i = that.index
				that.getCharts(i, that.list[i])
			}, 5000) //30s保存一次
		},
		methods: {
			touchstartHandle(e) {
				this.isclick = true
				this.start = e.clientX
			},
			touchmoveHandle(e) {
				if(this.isclick){
					var end = e.clientX
					var y = e.clientY
					if(end<391||end>1184||y<118||y>478){
						this.touchendHandle(e)
					}
					var move = end - this.start
					if(!this.isMobile){
						if(move>0){
							this.moveSize = Math.abs(this.move + move)>Math.abs(this.move)?0:this.move + move>0?0:this.move + move
							
						}else{
							this.moveSize = Math.abs(this.move + move)>this.maxMove?-this.maxMove:this.move + move
						}
					}
				}
			},
			touchendHandle(e){
				var end = e.clientX
				var move = end - this.start
				// this.move = Math.abs(this.move + move)>this.maxMove?this.maxMove:this.move + move
				if(move>0){
					this.move = Math.abs(this.move + move)>Math.abs(this.move)?0:this.move + move>0?0:this.move + move
					
				}else{
					this.move = Math.abs(this.move + move)>this.maxMove?-this.maxMove:this.move + move
				}
				setTimeout(() => {
					this.isclick = false
				}, 500);
				
				
			},
			changePage(e) {
				console.log(e)
				this.current = e
				this.orderList = [],
					this.getProgress()
			},
			previous() {
				if (this.current > 1) {
					this.current--
					this.getProgress()
				}
			},
			next() {
				if (this.current < this.pages) {
					this.current++
					this.getProgress()
				}
			},
			handleSubmit() {
				localStorage.setItem("columnConfig1", JSON.stringify(this.columnConfig));
				this.visible = false;
			},
			handleCancel1() {
				var columnConfig = localStorage.getItem("columnConfig1");
				if (columnConfig != null) {
					console.log(JSON.parse(columnConfig));
					this.columnConfig = JSON.parse(columnConfig);
				}
				this.visible = false;
			},
			// 选择日期
			getDate(e) {
				console.log(e)
				this.current = 1
				this.pickerValue = e
				this.getProgress()
				this.getShopworkProgress()
			},
			// 获取各车间完成进度
			getShopworkProgress() {
				shopworkProgress({
					factoryId: this.factoryId,
					sailingDate: this.pickerValue
				}).then((res) => {
					var list = res.data

					// this.progressList = list
					const parentClientWidth = document.documentElement.clientWidth-410;
					var maxWidth = parseInt(380*list.length)
					this.maxMove = maxWidth - parentClientWidth
					this.list = list;
					if (this.active == null) {
						this.active = list[0].workshopId
					}
					this.getProgress()
					setTimeout(() => {
						for (let i = 0; i < list.length; i++) {
							this.getCharts(i, list[i]);
						}
					}, 500);
				});
			},
			// 重新加载echarts
			changeCharts(index) {
				this.index = index
				var list = this.list
				for (let i = 0; i < list.length; i++) {
					this.getCharts(i, list[i]);
				}
			},
			// 获取生产进度明细
			getProgress() {
				var data = this.form
				data.factoryId = this.factoryId,
				data.workshopId = this.active,
				data.current = this.current
				data.sailingDate = this.pickerValue,
				getProgressDetails(data).then((res) => {
				
					for (let i = 0; i < res.data.records.length; i++) {
						res.data.records[i].key = i + 1
					}
					var arr = res.data.records
					this.size = res.data.size
					this.total = res.data.total
					this.pages = res.data.pages
					var list = this.$unique(arr)
					var index = 0
					for (let a in this.filterable) {
						for (let i = 0; i < list.length; i++) {
							if (a == list[i].name) {
								this.filterable[a].filters = list[i].list
							}

						}
					}
					this.orderList = res.data.records
					this.oldList = res.data.records;
					for (const i of this.header) {
						i.filterList = this.filterFun(i.param)
					}
				});
			},
			filterFun(name){
				let arr = []
				for (const item of this.orderList) {
					arr.push(item[name])
				}
				arr= new Set(arr);
				let newArr = []
				arr.forEach(ite=>{newArr.push({checked:false,value:ite})})
				return newArr
			},
			// 获取生产记录详情 
			getDetail(e) {
				this.detail = {
					orderNo: e.orderNo,
					orderId: e.orderId,
					processNo: e.processNo,
					styleName: e.styleName,
					processGroupName: e.processGroupName
				}
				this.showDetail = true

			},
			// 获取工厂信息
			getFactory() {
				getStructure({
					type: 7,
				}).then((res) => {
					console.log(res);
					this.factory = res.data;
					this.factoryId = res.data[0].structureId;
					this.getShopworkProgress()
				});
			},
			handleOk() {
				localStorage.setItem("columnConfig", JSON.stringify(this.columnConfig));
				this.visible = false;
			},
			handleCancel() {
				this.visible = false;
			},
			getCharts(i, arr) {
				arr.completeAmount = arr.completeAmount == null ? 0 : arr.completeAmount
				var myChart = this.chartsList[i]
				if (this.chartsList[i] != null) {
					this.chartsList[i].dispose()
				}
				myChart = this.$echarts.init(
					document.getElementById('workshop' + i)
				);
				var color = '#06B1FC'
				if (i == this.index) {
					color = '#4C5EEF'
				}
				// var chartDom = 
				// var myChart = this.$echarts.init(chartDom);
				var option;

				option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						show: false,
						bottom: '5%',
						left: 'center',
						icon: 'circle'
					},
					// color:'#C6C6C6',
					graphic: [{ //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						left: 'center',
						top: '42%',
						style: {
							text: arr.orderAmount == null || arr.orderAmount == '' ? 0 : parseInt(arr
								.completeAmount / arr.orderAmount * 100),
							textAlign: 'center',
							fill: '#2189F3', //文字的颜色
							color: [{
									offset: 1,
									color: "#504DEB"
								},
								{
									offset: 0,
									color: "#4087F9"
								}
							],
							width: 30,
							height: 30,
							fontSize: 30,
							fontWeight: 700,
						}
					}, { //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						left: 'center',
						top: '55%',
						style: {
							text: '完成率',
							textAlign: 'center',
							fill: '#000000', //文字的颜色
							width: 30,
							height: 30,
							fontSize: 16,
						}
					}],
					series: [{
						type: 'pie',
						radius: ['48%', '55%'],
						// center: ['50%', '50%'],
						selectedMode: false,
						hoverAnimation: false,
						data: [{
							value: 1,
							name: ''
						}],
						itemStyle: {
							color: '#C6C6C6'
						},
						label: {
							show: false
						},
						labelLine: {
							show: false
						},
						tooltip: {
							show: false
						},
						animation: false,
						cursor: 'auto',
						// emphasis: {
						// 	itemStyle: {
						// 		color: '#C6C6C6'
						// 	}
						// }
					}, {
						name: arr.workshopName,
						type: 'pie',
						radius: ['48%', '55%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center'
						},
						labelLine: {
							show: false
						},
						data: [{
								value: arr.completeAmount,
								name: '已完成'
							},
							{
								value: arr.incompleteAmount,
								name: '未完成'
							},
						],
						itemStyle: {
							borderRadius: 20,
							normal: {
								borderRadius: 20,
								color: function(params) {
									//自定义颜色
									var colorList = [
										color, '#C6C6C6',
									];
									return colorList[params.dataIndex]
								}
							}
						},
					}, {
						type: 'pie',
						radius: ['48.5%', '54.5%'],
						// center: ['50%', '50%'],
						selectedMode: false,
						hoverAnimation: false,
						data: [{
							value: 2,
							name: ''
						}, {
							value: 99,
							name: ''
						}],
						itemStyle: {
							borderRadius: 20,
							normal: {
								borderRadius: 20,
								color: function(params) {
									//自定义颜色
									var colorList = [
										'#FFFFFF', '#FFFFFF00',
									];
									return colorList[params.dataIndex]
								}
							}
						},
						label: {
							show: false
						},
						labelLine: {
							show: false
						},
						tooltip: {
							show: false
						},
						animation: false,
						cursor: 'auto',
						// emphasis: {
						// 	itemStyle: {
						// 		color: '#FFFFFF'
						// 	}
						// }
					}]
				};

				option && myChart.setOption(option);
				this.chartsList[i] = myChart
			}
		},
	};
</script>

<style scoped lang="less">
	@import url("../../styles/schedule.less");
	@import url("../../styles/all.less");

	/deep/ .arco-table-cell {
		padding-left: 0 !important;
	}

	.table {
		position: relative;
		.pagination {
			position: absolute;
			right: 0;
			background-color: #00000090;
			z-index: 9;
			border-radius: 90px;
			padding: 10px 5px;
			top: 50px;
		}
	}

	/deep/ .arco-pagination-list span {
		display: none;

	}

	/deep/ .arco-pagination-item {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: #FFFFFF;
		margin: 5px;
	}

	/deep/ .arco-pagination-item-active {
		color: #165DFF;
	}

	/deep/ .arco-pagination-item:not(:last-child) {
		margin: 5px;
	}

	.table-bg {
		margin: 0 5px;
	}
	/deep/ .arco-table-body {
		height: 500px !important;
		overflow-y: scroll !important;
	}
	/deep/.arco-progress-line-text{
		display: none;
	}

	.input-box {
		height: 48px !important;
		margin-right: 10px !important;
	}
	.progress{
		display: flex;
		align-items: center;
		padding: 0 12px;
		.progress-box{
			width: 20%;
			height: 86px;
			background: #fff;
			border-radius:4px;
			margin-right: 8px;
			margin-bottom: 8px;
			font-size: 16px;
			color: #4E5969;
			.progress-top{
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px;
				.top-right{
					font-size: 12px;
					span{
						color: #FF967E;
					}
				}
			}
			.progress-bottom{
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 10px 12px 0 12px;
				font-size: 14px;
			}
		}
	}
	.list-box{
		margin: 0 12px;
		font-size: 16px;
		.list-top{
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #fff;
			height: 52px;
			line-height: 52px;
			padding: 0 8px;
			.list-right{
				display: flex;
				align-items: center;
				.right-but{
					width: 50px;
					height: 32px;
					text-align: center;
					line-height: 32px;
					color: #fff;
					background: #3B75FD;
					font-size: 14px;
				}
			}
			.right-li{
				width: 87px;
				height: 32px;
				background: #3B75FD;
				border-radius: 2px 2px 2px 2px;
				text-align: center;
				line-height: 32px;
				color: #fff;
				margin-left: 11px;
				font-size: 14px;
			}
		}
	}
</style>
