<template>
  <div id="calendar">
    <!-- 年份 月份 -->
    <div class="month">
      <div class="flex">
        <b style="margin-right: 36px; margin-left: 20px" class="cncp"
          >成品产能</b
        >
        <!--点击会触发pickpre函数，重新刷新当前日期 @click(vue v-on:click缩写) -->
        <div class="arrow" @click="$emit('pickPre')">
          <icon-left />
        </div>
        <div class="year-month">
          <span class="choose-year">{{ currentYear }}年</span>
          <span class="choose-month">{{ currentMonth }}月</span>
          <a-month-picker @change="getMonth" style="width: 100px !important" />
        </div>
        <div
          class="arrow"
          style="margin-right: 36px"
          @click="$emit('pickNext')"
        >
          <icon-right />
        </div>
        <div class="today" @click="$emit('getToday')">今天</div>
      </div>
    </div>
    <!-- 星期 -->
    <ul class="weekdays">
      <li>周日</li>
      <li>周一</li>
      <li>周二</li>
      <li>周三</li>
      <li>周四</li>
      <li>周五</li>
      <li>周六</li>
    </ul>
    <!-- 日期 -->
    <ul class="days">
      <!-- 核心 v-for循环 每一次循环用<li>标签创建一天 -->
      <li v-for="(dayobject, i) in days" :key="i">
        <!--本月-->
        <!--如果不是本月  改变类名加灰色-->
        <div
          v-if="dayobject.day.getMonth() + 1 != currentMonth"
          class="other-month"
          @click="getDayTime(dayobject.day)"
        >
          <div class="flex" style="position: relative">
            <div class="date">
              {{ dayobject.day.getDate() }}
              <div class="type"></div>
            </div>
            <div class="count">十三</div>
            <div class="right" v-if="list[i - step] != null">
              <div>订单产量</div>
              <div class="num">
                {{
                  list[i - step].orderAomunt == null
                    ? ""
                    : list[i - step].orderAomunt
                }}
              </div>
            </div>
          </div>
          <div
            class="yield"
            :class="{
              orange:
                list[i - step] != null && list[i - step].actualAomunt != null,
            }"
            style="margin-top: 6px"
          >
            {{
              list[i - step] == null
                ? ""
                : list[i - step].actualAomunt == null
                ? ""
                : "实际产量:"
            }}
            <span>{{
              list[i - step] == null
                ? ""
                : list[i - step].actualAomunt == null
                ? ""
                : list[i - step].actualAomunt
            }}</span>
          </div>
          <div
            class="yield"
            :class="{
              blue: list[i - step] != null && list[i - step].planAmount != null,
            }"
          >
            {{
              list[i - step] == null
                ? ""
                : list[i - step].planAmount == null
                ? ""
                : "目标产量:"
            }}
            <span>{{
              list[i - step] == null
                ? ""
                : list[i - step].planAmount == null
                ? ""
                : list[i - step].planAmount
            }}</span>
          </div>
        </div>
        <div v-else class="shadow">
          <div
            @click="getDayTime(dayobject.day)"
            :class="{
              active: dayobject.day.Format('yyyy-MM-dd') == chooseDays,
            }"
            class="boxno"
          >
            <div class="flex" style="position: relative; padding-top: 3px">
              <div class="date">
                {{ dayobject.day.getDate() }}
                <div
                  class="type"
                  :style="{
                    background:
                      list[i - step] == null ||
                      list[i - step].actualAomunt == null ||
                      list[i - step].orderAomunt == null ||
                      list[i - step].actualAomunt <= list[i - step].orderAomunt
                        ? '#FF8A00'
                        : '#6774EA',
                  }"
                  v-if="dayobject.day.Format('yyyy-MM-dd') != chooseDays"
                ></div>
              </div>
              <div class="count">十三</div>
              <div
                class="right"
                v-if="
                  list[i - step] != null &&
                  (dayobject.day.getDate() <= date ||
                    dayobject.day.getMonth() != new Date().getMonth())
                "
              >
                <div>订单产量</div>

                <div class="num">
                  {{
                    list[i - step].orderAomunt == null
                      ? ""
                      : list[i - step].orderAomunt
                  }}
                </div>
              </div>
            </div>

            <div
              class="yield"
              :class="{
                orange:
                  dayobject.day.getTime() < new Date().getTime() &&
                  dayobject.day.Format('yyyy-MM-dd') != chooseDays,
              }"
              style="margin-top: 6px"
            >
              实际产量:
              <span>{{
                list[i - step] == null
                  ? "--"
                  : list[i - step].actualAomunt == null ||
                    list[i - step].actualAomunt == 0
                  ? "--"
                  : list[i - step].actualAomunt == null
                  ? "--"
                  : list[i - step].actualAomunt
              }}</span>
            </div>

            <div
              class="yield"
              :class="{
                blue:
                  dayobject.day.getTime() < new Date().getTime() &&
                  dayobject.day.Format('yyyy-MM-dd') != chooseDays,
              }"
            >
              目标产量:

              <span>{{
                list[i - step] == null
                  ? "--"
                  : list[i - step].planAmount == null ||
                    list[i - step].planAmount <= 0
                  ? "--"
                  : list[i - step].planAmount
              }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { capacityDetail } from "../api/capacity.js";
export default {
  props: {
    factoryId: "",
  },
  data() {
    return {
      currentDay: 1,
      currentMonth: 1,
      currentYear: 1970,
      currentWeek: 1,
      days: [],
      chooseDays: "",
      step: 0,
      list: [],
      date: new Date().getDate(),
    };
  },
  created: function () {
    this.chooseDays = new Date().Format("yyyy-MM-dd");
    // 在vue初始化时调用
    this.initData(null);
    this.getList();
  },
  methods: {
    getToday() {
      this.chooseDays = new Date().Format("yyyy-MM-dd");
      this.initData(null);
      this.getList();
    },
    changeDate(type) {
      if (type == 1) {
        this.pickPre(this.currentYear, this.currentMonth);
      } else {
        this.pickNext(this.currentYear, this.currentMonth);
      }
    },
    getMonth(e) {
      console.log(e);
      var date = e.split("-");
      var month = date[1];
      var year = date[0];
      if (year > new Date().getFullYear()) {
        this.$message.error("不可选中");
        return;
      } else if (
        (year = new Date().getFullYear() && month > new Date().getMonth() + 1)
      ) {
        this.$message.error("不可选中");
        return;
      } else {
        this.currentMonth = date[1];
        this.currentYear = date[0];
      }
    },
    // 获取成品产能
    getList() {
      this.list = [];
      capacityDetail({
        factoryId: this.factoryId,
        month:
          this.currentYear +
          "-" +
          (this.currentMonth > 9 ? this.currentMonth : "0" + this.currentMonth),
      }).then((res) => {
        this.list = res.data;
        for (let i = 0; i < this.days.length; i++) {
          var date = this.days[i].day.getDate();
          if (date == 1) {
            this.step = i;
            break;
          }
        }
      });
    },
    initData: function (cur) {
      var date;
      if (cur) {
        date = new Date(cur);
      } else {
        var now = new Date();
        var d = new Date(this.formatDate(now.getFullYear(), now.getMonth(), 1));
        d.setDate(41);
        date = new Date(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      }
      this.currentDay = date.getDate();
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth() + 1;
      console.log(this.currentMonth);
      this.currentWeek = date.getDay(); // 1...6,0
      if (this.currentWeek === 0) {
        this.currentWeek = 7;
      }
      var str = this.formatDate(
        this.currentYear,
        this.currentMonth,
        this.currentDay
      );
      this.days.length = 0;
      // 今天是周日，放在第一行第7个位置，前面6个
      // 初始化本周
      for (var i = this.currentWeek; i >= 0; i--) {
        var d2 = new Date(str);
        d2.setDate(d2.getDate() - i);
        var dayobjectSelf = {}; // 用一个对象包装Date对象  以便为以后预定功能添加属性
        dayobjectSelf.day = d2;
        this.days.push(dayobjectSelf); // 将日期放入data 中的days数组 供页面渲染使用
      }
      // 其他周
      for (var j = 1; j <= 41 - this.currentWeek; j++) {
        var d3 = new Date(str);
        d3.setDate(d3.getDate() + j);
        var dayobjectOther = {};
        dayobjectOther.day = d3;
        this.days.push(dayobjectOther);
      }
    },
    getDayTime(el) {
      if (new Date(el).getTime() <= new Date().getTime()) {
        this.chooseDays = el.Format("yyyy-MM-dd");
      }
    },
    pickPre: function () {
      // setDate(0); 上月最后一天
      // setDate(-1); 上月倒数第二天
      // setDate(dx) 参数dx为 上月最后一天的前后dx天
      var year = this.currentYear;
      var month = this.currentMonth;
      var d = new Date(this.formatDate(year, month, 1));
      d.setDate(0);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      this.getList();
    },
    pickNext: function () {
      var year = this.currentYear;
      var month = this.currentMonth;
      var d = new Date(this.formatDate(year, month, 1));
      d.setDate(41);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      this.getList();
    },
    // 返回 类似 2016-01-02 格式的字符串
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
  },
};
</script>
<style scoped lang="less">
#calendar {
  font-size: 22px;
  width: 100%;
  margin: 0 auto;
}
.cncp {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #4e5969;
}
.flex {
  display: flex;
  align-items: center;
}
.today {
  width: 56px;
  height: 26px;
  background: #eff1f5;
  border-radius: 2px 2px 2px 2px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #3b75fd !important;
}
b {
  font-size: 16px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  line-height: 19px;
}

.month {
  width: 100%;
  color: #333333;
}

.year-month {
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #1d2129;
  line-height: 28px;
  position: relative;
}
/deep/ .arco-picker {
  width: 80px !important;
}
/deep/ .arco-picker-size-medium {
  position: absolute;
  left: 0;
  opacity: 0 !important;
}
.today {
  width: 52px;
  height: 26px;
  background: #eff1f5;
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1d2129;
  line-height: 26px;
}

.arrow {
  padding: 15px;
  color: #999999;
}

.weekdays {
  margin: 0;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  color: #999;
  justify-content: space-around;
  border-bottom: 1px solid #e5e6e8;
}

.weekdays li {
  display: inline-block;
  width: 13.6%;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #4e5969;
  line-height: 22px;
}

.days {
  // padding: 10px;
  padding-top: 2px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.7%;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: 14.2%;
  text-align: center;
  overflow: hidden;
  font-size: 12px;
  color: #000;
  height: 93px;
  border-radius: 8px;
  margin-top: 8px;
  box-shadow: 0px 0px 1px 1px #e5e6eb;
}
.days li .shadow {
  // box-shadow: 0px -1px 0px 2px #E5E6EB;
  background: rgba(255, 255, 255, 0.4);
  height: 100%;
}
.days li:hover > span > span {
  padding: 6px 10px;
  border-radius: 50%;
  background: #e1e1e1;
  color: #fff;
}

.date {
  width: 34px;
  height: 26px;
  background: #ffffff;
  opacity: 1;
  border: 1px solid #ffffff;
  border-radius: 50%;
  padding-top: 8px;
  font-size: 16px;
  font-family: Nunito Sans-SemiBold, Nunito Sans;
  font-weight: 600;
  color: #1f1f1f;
}

.type {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 auto;
}

.count {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #86909c;
}

.right {
  text-align: right;
  right: 17px;
  font-size: 10px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #6379ab;
}

.num {
  font-size: 14px;
  font-weight: 700;
}

.yield {
  padding: 3px 14px;

  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 7px 4px;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #6379ab;
  background: hsla(0, 0%, 100%, 0.6);
}

.orange {
  background: #feead2 !important;
}

.blue {
  background: #cddcff !important;
}

.active {
  background: linear-gradient(315deg, #8894fe 0%, #fec5ff 100%);
  padding-bottom: 4px;
}

.shadow {
  .active {
    // padding: 8px 6px 4px 5px;
    background: linear-gradient(315deg, #8894fe 0%, #fec5ff 100%) !important;
    box-shadow: 11px 12px 15px 1px rgba(51, 107, 239, 0.16) !important;
  }
  .boxno {
    padding: 8px 6px 4px 5px;
    background: #f3f7ff;
    // box-shadow: inset -1px 0px 0px 1px rgba(99, 121, 171, 0.22);
	.date{
		line-height: 19px;
	}
  }
  .yield{
	  background: #EFF4FF;
	  opacity: 0.8;
  }
  .date {
    width: 25px;
    height: 25px;
    padding-top: 0;
    line-height: 25px;
  }
  .count {
    margin-left: 2px;
  }
  .yield {
    padding: 3px 8px;
    margin: 6px 0;
  }
  .right {
    font-size: 15px;
    zoom: 0.66;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
  }
}
.active .date {
  background-color: #ff8a00;
  color: #ffffff;
  border-color: #ff8a00;
}

.other-month {
  opacity: 0.4;
  background-color: #ffffff50;
  box-shadow: inset 0px 0px 0px 0px #e5e6eb00, inset 0px 0px 0px 0px #e5e6eb00 !important;
}
</style>
