<template>
  <div class="page" style="height: 100vh">
    <div class="return" @click="goBack">
      &lt; 返回上一级
      <massageCard
        style="height: 0px !important"
        @handleOk="handleOk"
        ref="massageCard"
      ></massageCard>
    </div>
    <div style="height: 70px"></div>
    <div
      class="scrollbar"
      :style="{ height: screenHeight - 347 + 'px' }"
      style="overflow-y: scroll"
      id="rightScroll"
    >
      <div class="detail" style="margin-top: 0;">
        <div>
          <div class="title">
            <div class="blod">{{ detail.labelName }}</div>
            <div class="title-border"></div>
            <div>{{ detail.labelSubName }}</div>
            <div
              style="
                margin-left: 25px;
                border-radius: 8px 8px 8px 8px;
                padding: 2px 10px;
                color: #fff;
              "
              class="status"
              :class="[
                { status1: detail.state == 1, status2: detail.state == 2 },
              ]"
            >
              {{ detail.stateCN }}
            </div>
          </div>
          <div class="msg" style="width: 400px">
            <div>
              <icon-location />
              {{ detail.structureName }}
            </div>
            <div>
              <icon-schedule />
              {{ detail.createTime }}
            </div>
          </div>
          <div class="img">
            <a-image
              width="744px"
              height="436px"
              :src="$baseUrl + detail.url"
              v-if="detail.url != null && detail.url != ''"
            />
            <img class="none" src="../../assets/bg/default.png" v-else />
            <div class="tips" style="height: 56px; padding: 0 16px">
              <div>提报人：{{ detail.createPerson }}</div>
              <div>
                责任人：{{
                  detail.responsible == null || detail.responsible == ""
                    ? "--"
                    : detail.responsible
                }}
              </div>
            </div>
          </div>
          <div class="desc" style="word-wrap: break-word">
            {{
              detail.problemDesc == "" || detail.problemDesc == null
                ? "这个家伙很懒,还没有对问题进行描述"
                : detail.problemDesc
            }}
          </div>
          <!-- <div
            class="background flex"
            style="justify-content: space-between"
            v-show="detail.state == 1"
            id="target"
          >
            <input
              class="content input"
              v-model="solve"
              ref="formRef"
              @foucs="setScroll()"
              @keyup.enter="$refs.formRef.blur(), submit()"
              :max-length="200"
              placeholder="提出方案,向优秀更进一步"
              allow-clear
            />
            <div class="icon-bg flex">
              <img
                :src="file"
                class="icon"
                style="background-color: #ffffff00; width: 40px; right: 8px"
                @click="$refs.upload.click()"
                v-show="file != '' && file != null"
              />
              <icon-image
                class="icon"
                :style="{ fontSize: '26px' }"
                v-show="file == '' || file == null"
                @click="$refs.upload.click()"
              />
            </div>
            <input
              ref="upload"
              v-show="false"
              class="upload"
              @change="beforeUpload"
              type="file"
            />
          </div> -->

          <div class="exceptionReport_input" v-if="detail.state == 1">
						<input class="exceptionReport_input_box" v-model="solve" placeholder="提出方案，向优秀更近一步" type="text">
						<div class="exceptionReport_input_right">
							<div @click="$refs.upload.click()">
								<img src="../../assets/icon/img_01.png" alt="">
							</div>
							<div class="exceptionReport_input_border"></div>
							<div @click="submit()">
								<img src="../../assets/icon/img_02.png" alt="">
							</div>
						</div>
						<input ref="upload" v-show="false" class="upload" @change='beforeUpload' type="file">
					</div>
          <div class="contant" v-show="detail.state == 2">
            <a-avatar
              class="avatar"
              :style="{ backgroundColor: '#3b75fd' }"
              :size="40"
              shape="square"
            >
              <img
                :src="$baseUrl + detail.solvePersonnelHeadImage"
                v-if="
                  detail.solvePersonnelHeadImage != null &&
                  detail.solvePersonnelHeadImage != ''
                "
              />
              {{
                detail.solvePersonnelHeadImage == null ||
                detail.solvePersonnelHeadImage == ""
                  ? detail.updatePerson1
                  : ""
              }}
            </a-avatar>
            <div style="margin-left: 12px">
              <div class="name">{{ detail.updatePerson }}</div>
              <div class="comment">{{ detail.solve }}</div>
              <a-image
                width="78"
                style="border-radius: 8px"
                v-if="detail.solveUrl != null && detail.solveUrl != ''"
                :src="$baseUrl + detail.solveUrl"
              />
              <div class="time flex">
                {{ detail.updateTime }}
                <div @click="handleClick()" style="width: 40px; height: 40px">
                  <icon-delete
                    :style="{
                      fontSize: '16px',
                      marginLeft: '18px',
                      marginTop: '12px',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import {
  delReport,
  deleteSolve,
  getExceptionProcess,
  submitReply,
} from "../../api/exception.js";
import massageCard from "../../common/massageCard.vue";
import * as imageConversion from "image-conversion";
export default {
  components: {
    massageCard,
  },
  data() {
    return {
      type: 2,
      solve: "",
      detail: {},
      token: "",
      attachmentId: "", //附件id
      screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
      file: "",
      // screenHeight: document.body.clientHeight, //获取屏幕高度
    };
  },
  watch: {
    /* 监听页面高度 */
    screenHeight(val) {
      console.log(val);
      this.screenHeight = val;
    },
  },
  mounted: function () {
    this.token = localStorage.getItem("token");
    var id = this.$route.query.rid;
    this.getDetail(id);
    var that = this;
    // 获取页面高度
    window.screenHeight = document.body.clientHeight;
    that.screenHeight = window.screenHeight;
    // 监听页面高度 watch有时会监听不到
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.documentElement.clientHeight;
        this.screenHeight = window.screenHeight;
      })();
    };
  },
  methods: {
    // 滚动到指定位置
    setScroll() {
      console.log(111);
      let target = document.getElementById("target");
      let parent = document.getElementById("rightScroll");
      parent.scrollTop = target.offsetTop - parent.offsetTop;
    },
    // 获取详情
    getDetail(dailyId) {
      this.solve = "";
      this.file = "";
      this.attachmentId = "";
      console.log(dailyId);
      getExceptionProcess({
        rid: dailyId,
      }).then((res) => {
        var detail = res.data;
        detail.updatePerson1 = detail.updatePerson.substring(
          detail.updatePerson.length - 2
        );
        this.detail = detail;
      });
    },
    goBack() {
      this.$router.replace({
        path: "/dashBord/workBench/exceptionReportForm",
        query: {
          type: true,
        },
      });
    },
    handleClick() {
      var data = {
        name: "删除回复",
        msg: "是否删除该条回复？",
        type: "Error",
      };
      this.$refs.massageCard.handleClick(data);
    },
    //   提交回复
    submit() {
      var data = {
        reportRecordId: this.detail.rid,
        solve: this.solve,
        attachmentId: this.attachmentId,
      };
      submitReply(data).then((res) => {
        this.file = "";
        this.solve = "";
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.getDetail(this.detail.rid);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 删除异常
    handleOk() {
      if (this.type1 == 1) {
        delReport({
          rid: this.detail.rid,
        }).then((res) => {
          console.log(res);
          if (res.code == 1) {
            // this.$message.error(res.msg);
          } else {
            this.$message.success(res.msg);
            this.getDetail(this.detail.rid);
          }
        });
      } else {
        deleteSolve({
          rid: this.detail.rid,
        }).then((res) => {
          console.log(res);
          if (res.code == 1) {
            // this.$message.error(res.msg);
          } else {
            this.$message.success(res.msg);
            this.getDetail(this.detail.rid);
          }
        });
      }
    },
    // 上传图片
    beforeUpload(event) {
      var file = event.target.files[0];
      let type = file.type; //文件的类型，判断是否是图片
      let isLt2M = size < 400 * 1024; // 判定图片大小是否小于4MB
      let size = 0.01;
      if (isLt2M) {
        resolve(file);
      } else if (size > 400 * 1024) {
        var file1;
        size = 0.01;
        imageConversion.compress(file, size).then((res) => {
          var uri = "";
          var file1 = new window.File([res], file.name, {
            type: res.type,
          });
          let form = new FormData();
          form.append("file", file1, file1.name);
          axios({
            url: this.$baseUrl + "/minapp/onlineView/uploadFile/313",
            method: "post", //get
            data: form,
            headers: {
              "TENANT-ID": "2",
              Authorization: "Bearer " + this.token,
            },
          }).then((res) => {
            this.attachmentId = res.data.data.id;
            this.file = this.$baseUrl + res.data.data.url;
            this.loading = false;
            this.$refs.formRef.focus();
          });
        });
      } else {
        var file1;
        size = parseFloat((400 * 1024) / file.size);
        imageConversion.compress(file, size).then((res) => {
          var uri = "";
          var file1 = new window.File([res], file.name, {
            type: res.type,
          });
          let form = new FormData();
          form.append("file", file1, file1.name);
          axios({
            url: this.$baseUrl + "/minapp/onlineView/uploadFile/311",
            method: "post", //get
            data: form,
            headers: {
              "TENANT-ID": "2",
              Authorization: "Bearer " + this.token,
            },
          }).then((res) => {
            this.attachmentId = res.data.data.id;
            this.file = this.$baseUrl + res.data.data.url;
            this.loading = false;
            this.$refs.formRef.focus();
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
@import url("../../styles/reportFromDetail.less");
@import url("../../styles/exceptionReportFrom.less");
#scrollbar {
  background: rgb(240, 241, 243) !important;
}
.background {
  margin-top: 30px !important;
  width: 735px;
  height: 48px !important;
  box-shadow: 1px 3px 8px 1px rgb(51 107 239 / 5%);
  border-radius: 4px 4px 4px 4px;
  margin: auto;
  border: 1px solid #bbbfc4;
  outline: none;
  padding-left: 10px;
  background: #f0f1f3;
  .content {
    background: none;
    height: 48px;
    line-height: 48px;
    padding-left: 0;
  }
}
.none {
  position: absolute;
  top: 46px;
  left: 164px;
  width: 381px;
  height: 324px;
  padding: 0;
}

/deep/ .arco-avatar-text {
  font-size: 14px !important;
}

.return {
  position: fixed;
  left: 73px;
  right: 12px;
  z-index: 9;
}
.input {
  border: none;
  border-radius: 20px;
  outline: none;
  padding-left: 12px;
  height: 56px;
  width: 80%;
  margin: 8px;
}
.exceptionReport_input {
  position: fixed !important;
  bottom: 10px;
  left: 70px !important;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .exceptionReport_input_box {
    width: 835px;
    height: 48px;
    box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.05);
    border-radius: 4px 4px 4px 4px;
    margin: auto;
    border: 1px solid #bbbfc4;
    outline: none;
    padding-left: 10px;
    background: #f0f1f3;
  }
  .exceptionReport_input_right {
    display: flex;
    align-items: center;
    position: absolute;
    right: 40px;
    z-index: 20;
    .exceptionReport_input_border {
      height: 22px;
      width: 1px;
      background: #bbbfc4;
      margin: 0 14px;
    }
    img {
      width: 19px;
    }
  }
}
.exceptionReport_input{
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
    justify-content: center;
    padding: 0 20px;
		.exceptionReport_input_box{
      width: 100%;

			height: 48px;
			box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.05);
			border-radius: 4px 4px 4px 4px;
			margin: auto;
			border: 1px solid #BBBFC4;
			outline: none;
			padding-left: 10px;
			background: #F0F1F3;
		}
		.exceptionReport_input_right{
			display: flex;
			align-items: center;
			position: absolute;
			right: 40px;
			z-index: 20;
			.exceptionReport_input_border{
				height: 22px;
				width: 1px;
				background: #BBBFC4;
				margin: 0 14px;
			}
			img{
				width: 19px;
				
			}
		}
	}
</style>
