<template>
	<div class="page" v-if="show">
		<div style="position: relative;">
			<div class="card">
				<div class="top">{{data.title}}</div>
				<div class="noData" style="zoom: 0.6;">
					<img class="noData-Bg" src="../assets/bg/none-bg.png" />
					<img class="noData-icon" src="../assets/bg/none.png" v-if="data.type=='fail'" />
					<img class="noData-icon" src="../assets/bg/success.png" v-else />
				</div>
				<div class="tips" v-if="data.type=='fail'">{{data.failmsg}}</div>
				<!-- <div class="button" @click="show=false,$emit('goBack')">{{data.type=='success'?data.msg:'��֪����'}}</div> -->
				<div class="button" @click="show=false,$emit('goBack')">{{data.msg}}</div>

			</div>
			<img class="icon" @click="show=false,$emit('goBack')" src="../assets/icon/close.png" />
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				show: false,
				data: {}
			}
		},
		methods: {
			handleClick(data) {
				console.log(data)
				this.show = true
				this.data = data
			}
		}
	};
</script>
<style scoped>
	.page {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #00000090;
		z-index: 99;
	}

	.tips {
		font-size: 16px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
		line-height: 24px;
	}

	.noData {
		width: 246px;
		height: 209px;
		position: relative;
		margin: 36px 20% 24px;

	}

	.noData-Bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 246px;
		height: 209px;
	}

	.noData-icon {
		width: 140px;
		height: 126px;
		position: absolute;
		top: 55px;
		left: 63px;
	}

	.card {
		width: 240px;
		height: 340px;
		border-radius: 8px;
		overflow: hidden;
		background-color: #ffffff;

	}

	.top {
		background-image: url('../assets/bg/card-bg.png');
		background-size: 100%;
		text-align: center;
		font-size: 20px;
		font-family: PingFang SC-Heavy, PingFang SC;
		font-weight: 800;
		color: #000000;
		height: 78px;
		line-height: 78px;
	}

	.button {
		width: 160px;
		height: 46px;
		background: linear-gradient(47deg, #FFA834, #ff8a14);
		border-radius: 8px;
		font-size: 18px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 46px;
		margin: 24px 41px;
		text-align: center;
	}

	.icon {
		width: 42px;
		height: 42px;
		position: absolute;
		top: 354px;
		left: 102px;
	}
</style>
<style scoped lang="less">
</style>
