import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import HelloWorld from '@/components/HelloWorld.vue'
import login from '@/components/login.vue'
import dashBord from '@/components/dashBord.vue'
import workBench from '@/components/workbench/index.vue'
import index from '@/components/workBench.vue'
import workManShip from '@/components/workbench/workManShip.vue'
import workManShipDetail from '@/components/workbench/workManShipDetail.vue'
import salaryQuery from '@/components/workbench/salaryQuery.vue'
import salaryQuery1 from '@/components/workbench/salaryQuery1.vue'
import productionTask from '@/components/workbench/productionTask.vue'
import productionReport from '@/components/workbench/productionReport.vue'
import productionSchedule from '@/components/workbench/productionSchedule.vue'
import personnel from '@/components/workbench/personnel.vue'
import capacity from '@/components/workbench/capacity.vue'
import exceptionReport from '@/components/workbench/exceptionReport.vue'
import exceptionReportForm from '@/components/workbench/exceptionReportForm.vue'
import reportFormDetail from '@/components/workbench/reportFormDetail.vue'
import editExceptionReport from '@/components/workbench/editExceptionReport.vue'
import productionDaily from '@/components/workbench/productionDaily.vue'
import editProductionDaily from '@/components/workbench/editProductionDaily.vue'
import noPermission from '@/components/noPermission.vue'
import logistics from '@/components/third/logistics.vue'
import logisticsDetail from '@/components/third/logisticsDetail.vue'
import staff from '@/components/third/staff.vue'
import notice from '@/components/third/notice.vue'
import none from '@/components/none.vue'
import error from '@/components/error.vue'
import qcInfo from '@/components/workbench/qcInfo.vue'
import Mui from 'vue-awesome-mui';
const Router = createRouter({
	history: createWebHashHistory(),
	routes: [{
		path: '/',
		name: 'login',
		component: login
	}, {
		path: '/HelloWorld',
		name: 'HelloWorld',
		component: HelloWorld
	}, {
		path: '/403',
		name: 'noPermission',
		component: noPermission
	}, {
		path: '/404',
		name: 'none',
		component: none
	}, {
		path: '/500',
		name: 'error',
		component: error
	}, {
		path: '/dashBord',
		name: 'dashBord',
		component: dashBord,
		children: [{
			path: "workBench",
			component: workBench,
			children: [{
					path: "index",
					component: index,
					meta: {
						title: '工作台',
						icon: 'logo.png',
						type: 1,
						key: '01'
					}
				},
				{
					path: "workManShip",
					component: workManShip,
					meta: {
						title: '工艺查询',
						icon: 'gyzl.png',
						type: 1,
						key: '02',
						keepAlive: true // 需要缓存
					}
				},
				{
					path: "workManShipDetail",
					component: workManShipDetail,
					meta: {
						title: '工艺查询详情',
						icon: 'gyzl.png',
						type: 1,
						key: '02',
						keepAlive: true
					}
				},
				{
					path: "salaryQuery",
					component: salaryQuery,
					meta: {
						title: '工资查询',
						icon: 'scrw.png',
						type: 2,
						key: '03'
					}
				},
				{
					path: "salaryQuery1",
					component: salaryQuery1,
					meta: {
						title: '工资查询',
						icon: 'scrw.png',
						type: 2,
						key: '03'
					}
				}, {
					path: "productionTask",
					component: productionTask,
					meta: {
						title: '生产任务',
						icon: 'scrwblue.png',
						type: 2,
						key: '04'
					}
				}, {
					path: "productionReport",
					component: productionReport,
					meta: {
						title: '生产报工',
						icon: 'scrw.png',
						type: 2,
						key: '05'
					}
				}, {
					path: "productionSchedule",
					component: productionSchedule,
					meta: {
						title: '生产进度',
						icon: 'scrw.png',
						type: 2,
						key: '09'
					}
				}, {
					path: "personnel",
					component: personnel,
					meta: {
						title: '人员信息',
						icon: 'scrw.png',
						type: 2,
						key: '10'
					}
				}, {
					path: "exceptionReport",
					component: exceptionReport,
					meta: {
						title: '异常提报',
						icon: 'yctb.png',
						type: 1,
						key: '06'
					}
				}, {
					path: "exceptionReportForm",
					component: exceptionReportForm,
					meta: {
						title: '异常报表',
						icon: 'scrw.png',
						type: 2,
						key: '08'
					}
				}, {
					path: "reportFormDetail",
					component: reportFormDetail,
					meta: {
						title: '异常报表',
						icon: 'scrw.png',
						type: 2,
						key: '08'
					}
				}, {
					path: "editExceptionReport",
					component: editExceptionReport,
					meta: {
						title: '异常提报',
						icon: 'yctb.png',
						type: 2,
						key: '06'
					}
				}, {
					path: "productionDaily",
					component: productionDaily,
					meta: {
						title: '生产日报',
						icon: 'scrb.png',
						type: 1,
						key: '07'
					}
				}, {
					path: "editProductionDaily",
					component: editProductionDaily,
					meta: {
						title: '生产日报',
						icon: 'scrb.png',
						type: 1,
						key: '07'
					}
				}, {
					path: "qcInfo",
					component: qcInfo,
					meta: {
						title: 'QC信息',
						icon: 'gyzl.png',
						type: 2,
						key: '11'
					}
				}, {
					path: "capacity",
					component: capacity,
					meta: {
						title: '产能报表',
						icon: 'gyzl.png',
						type: 2,
						key: '12'
					}
				}, {
					path: "logistics",
					component: logistics,
					meta: {
						title: '生产物流',
						icon: 'gyzl.png',
						type: 1,
						key: '13'
					}
				}, {
					path: "logisticsDetail",
					component: logisticsDetail,
					meta: {
						title: '生产物流',
						icon: 'gyzl.png',
						type: 1,
						key: '13'
					}
				}, {
					path: "staff",
					component: staff,
					meta: {
						title: '员工技能',
						icon: 'gyzl.png',
						type: 1,
						key: '14'
					}
				}
			],
		}, {
			path: "notice",
			component: notice,
			children: [{
				path: "index",
				component: notice,
				meta: {
					title: '系统通知'
				}
			}],
		}]
	}],
	mode: "history",
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},

})
Router.beforeEach((to, from, next) => {
	var quit = false
	Mui.back = function() { //按下物理返回键
		if (!quit) {
			console.log(from)
			if (to.name == 'home' || to.name == 'about') {
				Mui.toast(from)
				quit = true
				setTimeout(function() {
					quit = false
				}, 2000)
			} else {
				if (to.matched[0].instances.default.showmask) {
					to.matched[0].instances.default.showmask = false
				} else {
					history.go(-1); // 返回上一页
				}
			}
		} else {
			plus.runtime.quit(); //退出app
		}
	}
	next()
})
export default Router
