<template>
	<div class="page" :style="{ height: screenHeight  + 'px' }" style="overflow-y: hidden;overflow-x: hidden;">
		<div class="return pointer"  @click="this.$router.push('/dashBord/workBench/productionDaily')">
			&lt; 返回上一级
		</div>
		<div style="height: 70px;"></div>
		<div class="scrollbar" :style="{ height: screenHeight  + 'px' }"
			style="overflow-y: scroll;margin:0 12px">
			<div class="form" style="margin-top: 0;padding-bottom:100px">
				<div class="title" @click="visiblePickClose">生产日报</div>
				<div class="tips" @click="visiblePickClose">明天又是光芒万丈的一天
					<div class="time">{{time}} {{time==''?'':'已保存'}}</div>
				</div>
				<div hidden>{{click}}</div>
				<div class="label" @click="visiblePickClose">
					今日产量
					<!-- 限制只能填数字 -->
					<input class="count" type="number" ref="input6" @keydown="inputBlur" v-model="count" />
				</div>
				<div class="table">
					<a-table :data="yieldList" :pagination="false">
						<template #columns>
							<a-table-column title="" width="40">
								<template #cell="{ record }">
									<icon-close-circle-fill :style="{fontSize:'20px',color:'#ADADAD'}"
										@click="delList(record.key)" />
								</template>
							</a-table-column>
							<a-table-column title="生产批次" width="160">
								<template #cell="{ record }">
									<div class="datePicker">
										<div class="dateCover" @click="visiblePick[record.key-1] = true"
											:style="{color:record.time==''?'#999999':'#333333'}">
											{{record.time==''?'请选择批次':record.time}}</div>
										<a-date-picker placeholder="请选择批次" :popup-visible="visiblePick[record.key-1]"
											@change="setReport(record.time,record.key)" v-model="record.time"
											:style="{width:'100%'}" />
									</div>

								</template>
							</a-table-column>
							<a-table-column title="计划产量（套）" width="200">
								<template #cell="{ record }">
									<input v-model="record.planAmount " type="number" ref="input" @keydown="inputBlur"
										:precision="2" :style="{width:'100%'}" class="input-demo" />
								</template>
							</a-table-column>
							<a-table-column title="完成产量（套）" width="200">
								<template #cell="{ record }">
									<input v-model="record.actualAmount " type="number" ref="input1"
										@keydown="inputBlur" :precision="2" :style="{width:'100%'}"
										class="input-demo" />
								</template>
							</a-table-column>
							<a-table-column title="备注" width="400">
								<template #cell="{ record }">
									<a-input v-model="record.remarks" ref="input2" @keydown="inputBlur" allow-clear />
								</template>
							</a-table-column>
						</template>
					</a-table>
					<div class="add-table" @click="addYieldList">
						<img class="add-icon" src="../../assets/icon/add.png">
					</div>
				</div>
				<div class="label" style="margin-top:72px;" @click="visiblePickClose">
					员工出勤
				</div>
				<a-table :data="data" :pagination="false" @click="visiblePickClose">
					<template #columns>
						<a-table-column title="车间" data-index="structureName" width="140"></a-table-column>
						<a-table-column title="应到人数" width="258">
							<template #cell="{ record }">
								<input v-model="record.shouldCount" type="number" ref="input3" @keydown="inputBlur"
									:precision="2" :style="{width:'100%'}" class="input-demo" />
							</template>
						</a-table-column>
						<a-table-column title="实到人数" width="258">
							<template #cell="{ record }">
								<input v-model="record.realCount" type="number" ref="input4" @keydown="inputBlur"
									:precision="2" :style="{width:'100%'}" class="input-demo" />
							</template>
						</a-table-column>
						<a-table-column title="产量（套）" width="258">
							<template #cell="{ record }">
								<input type="number" v-model="record.completeAmount" ref="input5" @keydown="inputBlur"
									:precision="2" :style="{width:'100%'}" class="input-demo" />
							</template>
						</a-table-column>
					</template>
				</a-table>
				<div class="label">
					工作简述
				</div>
				<a-textarea v-model="description" placeholder="" ref="inputText3" @press-enter="$refs.inputText3.blur()"
					:max-length="200" allow-clear show-word-limit />
				<div class="label">
					发送到人
				</div>
				<div class="flex" style="align-items: flex-start;">
					<div class="position" v-for="item in userList" :key="item">
						<a-avatar class="avatar" :size="40" :style="{ backgroundColor: '#3b75fd' }" shape="square">
							<img :src="$baseUrl + item.avatar" v-if="item.avatar!=null&&item.avatar!=''" />
							{{item.avatar==null||item.avatar==''?item.personnelName.substring(item.personnelName.length-2):''}}
						</a-avatar>
						<icon-close class="close" @click="delPerson(item.personnelId)" />
						<div class="personnelName">{{item.personnelName}}</div>
					</div>
					<img class="add" src="../../assets/icon/add-blue.png" @click="showLog = true" />
				</div>
			</div>
			<div style="height:80px"></div>
			<div class="button">
				<a-button type="primary" @click="submit">提交</a-button>
				<a-button @click="type=false,goBack()">取消</a-button>
			</div>
			<div class="cover" style="align-items: center;" v-if="showLog">
				<sendLog ref="sendLog" :type="1" @close="showLog=false,getUserList()"></sendLog>
			</div>
			<feedback ref="feedback" @goBack="goBack" @getDetail="getDetail"></feedback>
		</div>
	</div>
</template>
<script>
	import {
		noticeSend,
	} from '../../api/index.js'
	import {
		saveReport,
		submitReport,
		getReport,
		getUser,
		delUser,
		setDailyDetail
	} from '../../api/daily.js'
	import sendLog from '../../common/sendLog.vue'
	import feedback from '../../common/feedback.vue'
	// import success from '../common/success.vue'
	export default {
		components: {
			sendLog,
			feedback,
			// success
		},
		data() {
			return {
				description: '', //工作备注
				count: 0, //产量
				time: '', //保存草稿时间
				showLog: false,
				id:0,
				detail: {},
				userList: [], //发送到人
				screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
				visiblePick: [false],
				yieldList: [{
					key: 1,
					actualAmount: '',
					planAmount: '',
					remarks: "",
					time: ""
				}],
				data: [], //考勤信息
				status: null, //定时保存
				click: false, //刷新页面数据使用
				type: true, //展示详情或统计
				// success:false,
			};
		},

		beforeRouteLeave: function() {
			clearInterval(this.status);
		},
		mounted: function() {

			window.onresize = () => {
				return (() => {
					window.screenHeight = document.documentElement.clientHeight
					this.screenHeight = window.screenHeight;
				})();
			};
			this.getReport()
			this.getUserList()
			var that = this
			// this.status = setInterval(function() {
			// 	console.log('临时保存')
			// 	that.saveAsReport()
			// }, 100000)
		},
		methods: {
			inputBlur(e) {
				console.log(e)
				if (e.keyCode == 13) {
					document.activeElement.blur();
					console.log('关闭软键盘')
					this.$refs.input.blur()
					this.$refs.input1.blur()
					this.$refs.input2.blur()
					this.$refs.input3.blur()
					this.$refs.input4.blur()
					this.$refs.input5.blur()
					this.$refs.input6.blur()
				}

			},
			visiblePickClose() {
				for (let i = 0; i < this.visiblePick.length; i++) {
					this.visiblePick[i] = false
				}
			},
			//删除配置推送人
			delPerson(id) {
				delUser({
					id: id
				}).then(res => {
					this.getUserList()
				})
			},
			goBack() {
				this.$router.push({
					path: "/dashBord/workBench/productionDaily",
					query: {
						dailyId: this.id,
						type: this.type
					},
				});
			},
			// 获取某日产量信息
			setReport(time, key) {
				this.visiblePick[key - 1] = false
				console.log(key)
				setDailyDetail({
					orderBatch: time
				}).then(res => {
					this.yieldList[key - 1] = res.data
					this.yieldList[key - 1].key = key
				})
			},
			//   获取发送到人
			getUserList() {
				getUser().then(res => {
					this.click = !this.click
					this.userList = res.data
				})
			},
			//   删除今日产量某一条
			delList(index) {
				for (let i = 0; i < this.yieldList.length; i++) {
					if (this.yieldList[i].key == index) {
						this.yieldList.splice(i, 1)
						this.visiblePick.splice(i, 1)
					}
				}
			},
			//   新增一条今日产量
			addYieldList() {
				var list = this.yieldList
				if (list[list.length - 1].time != '') {
					var data = {
						key: this.yieldList.length + 1,
						actualAmount: '',
						planAmount: '',
						remarks: "",
						time: ""
					}
					this.yieldList.push(data)
					this.visiblePick.push(false)
				}
				// this.detail.content.production.info.push(data)
			},
			//获取草稿
			getReport() {
				getReport().then(res => {
					var data = res.data
					var content = res.data.content
					content = eval("(" + content + ")")
					data.content = content
					this.detail = data
					this.data = data.content.personnel
					this.count = data.content.production.todayAmount.completeCount
				})
			},
			//保存异常
			saveAsReport() {
				var content = {
					personnel: this.data,
					production: {
						info: this.yieldList,
						todayAmount: {
							completeCount: this.count
						}
					}
				}
				console.log(this.detail)
				var data = {
					content: JSON.stringify(content),
					title: this.detail.title,
					description: this.description,
					dailyId: this.detail.dailyId,
					dailyType: 0,
				}
				saveReport(data).then(res => {
					console.log(res)
					var myDate = new Date();
					this.time = myDate.getHours() + '时 ' + myDate.getMinutes() + '分'
				})
			},
			//提交异常
			submit() {
				console.log(this.detail)
				var content = {
					personnel: this.data,
					production: {
						info: this.yieldList,
						todayAmount: {
							completeCount: this.count
						}
					}
				}
				var data = {
					content: JSON.stringify(content),
					title: this.detail.title,
					description: this.description,
					dailyId: this.detail.dailyId,
					dailyType: 0,
				}
				submitReport(data).then(res => {
					console.log(res.data.dailyId)
					var notifications = []
					for (let i = 0; i < this.userList.length; i++) {
						notifications.push({
							receiver: this.userList[i].personnelId,
							// notifier:this.userList[i].personnelName
						})
					}
					var data1 = {
						type: 3,
						outerId: res.data.dailyId,
						notifications: notifications
					}
					this.id = res.data.dailyId,
					noticeSend(data1).then(res => {
						window.clearInterval(this.status);
						var msg = {
							title: '恭喜！提交成功',
							msg: '查看日报',
							type: 'success'
						}
						this.$refs.feedback.handleClick(msg);
					})
				})

			},
		},
	};
</script>
<style>
	.arco-avatar-image {
		border-radius: 8px !important;
	}
</style>
<style scoped lang="less">
	/deep/ .arco-btn-shape-square {
		height: 40px !important;
		font-size: 14px !important;
	}

	/deep/ .arco-picker-start-time {}

	/deep/ .arco-avatar-text {
		font-size: 14px !important;
	}

	.datePicker {
		position: relative;
	}

	.dateCover {
		position: absolute;
		top: 0;
		left: 0;
		width: 130px;
		height: 32px;
		background-color: #F2F3F5;
		z-index: 9;
		line-height: 32px;
		padding-left: 8px;
		color: #999999;
	}

	.input-demo {
		width: 160px;
		height: 34px;
		background: #F2F3F5;
		border-radius: 2px 2px 2px 2px;
		opacity: 1;
		border: none;
		outline: none;
		padding-left: 8px;
	}

	/deep/ .arco-picker-start-time {
		display: none !important;
	}

	/deep/ .arco-picker-focused,
	.arco-picker-focused:hover {
		background-color: #F2F3F5;
		border-color: #ffffff00;
	}

	/deep/ .arco-picker-suffix {
		display: none;
	}

	.return {
		position: fixed;
		left: 73px;
		right: 12px;
		// z-index: 9;
	}

	.scrollbar::-webkit-scrollbar {
		width: 0px;
	}
	.page::-webkit-scrollbar {
		width: 0px;
	}
	@import url("../../styles/editProductionDaily.less");
</style>
