<template>
	<div style="height: 100vh;overflow-y: hidden;">
		
	
	<div class="page" :style="{zoom:screenWidth/1920}" style="height:100%">
		<!-- 		<img :style="{width:screenWidth>1300?screenWidth-96+'px':screenWidth-60+'rpx'}" class="img-bg"
			src="../../assets/bg/wuliu.png" @click="show = false"> -->
		<div class="search-bg">
			<div class="search">
				<div class="select" :style="{width:'220px'}">
					<div @click="show = false,select = true">
						{{options[type]}}
						<icon-down />
					</div>

				</div>

				
				<a-input class="search-input" placeholder="搜索" v-model="number" allow-clear />
				<a-button type="primary" @click="getList()">查询</a-button>
				
			</div>
			<div class="options" v-show="select">
				<div class="option" :class="{optionChoose:type==2}" @click="type = 2,select = false">订单编号</div>
				<div class="option" :class="{optionChoose:type==1}" @click="type = 1,select = false">手机号</div>
			</div>
			<div class="content" v-show="show">
				<div class="title">
					搜索结果
				</div>
				<div class="mobile" v-show="type == 1&&list.length>0">
					<div class="for" v-for="item in list" :key='item' @click="goDetail(item)">
						<div class="title">
							{{item.orderNo}}
							<div>
								<img class="icon" src="../../assets/icon/right-blue.png"
									style="margin-left: 20px;" />
							</div>
						</div>
						<div class="flex" style="margin-top: 6px;">
							<div class="phone">{{item.phone}}</div>
							<div class="time">下单时间：{{item.orderTime}}</div>
						</div>
			
					</div>
				</div>
				<div class="order" v-show="type == 2&&list.length>0">
					<div class="for" v-for="item in list" :key='item' @click="goDetail(item)">
						<div class="title">{{item.orderNo}}</div>
						<div>
							<img class="icon" src="../../assets/icon/right-blue.png" style="margin-left: 20px;" />
						</div>
					</div>
				</div>
				<div class="nodata" v-show="list.length==null||list.length==0">
					<img class="bg" src="../../assets/bg/wl-nodata.png" />
					<div class="tips">什么都没有</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	import {
		getOrder
	} from "../../api/logistics.js";
	export default {
		data() {
			return {
				screenWidth: document.documentElement.clientWidth, //获取屏幕宽度
				screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
				type: 2,
				number: '',
				options: ['请选择', '手机号', '订单编号'],
				list: [],
				show: false,
				select: false,
			};
		},
		mounted: function() {

		},
		methods: {
			getList() {
				if (this.type == 0) {
					this.$message.error('请选择查询方式')
					return
				}
				var data = {
					type: this.type,
					number: this.number
				}
				this.list = [
					{
						orderNo: "S2022031510244",
						orderTime: "2022-03-23 14:33",
						phone: "13497956096"
					}
				]
				this.show = true
				// getOrder().then((res) => {
				// 	this.list = res.data
				// 	this.show = true
				// });
			},
			goDetail(item) {
				var data = {
					type: this.type,
					number: this.number
				}
				this.$router.push({
					path: '/dashBord/workbench/logisticsDetail',
					query: {
						detail: JSON.stringify(item),
						data: JSON.stringify(data)
					}
				})
			}
		},
	};
</script>
<style>

</style>
<style scoped lang="less">
	/deep/ .arco-icon-hover-size-medium {
		width: 16px;
		height: 16px;
		background-color: #1F1F1F;
		color: #FFFFFF;
		border-radius: 50%;
		text-align: center;
		line-height: 16px;

		.arco-icon-close {
			color: #FFFFFF !important;
		}
	}

	/deep/ .arco-dropdown-option {
		margin: 10px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #81868C;
		width: 80% !important;
	}

	/deep/ .arco-select-view-icon svg {
		font-size: 26px !important;
		font-family: PingFang SC-Medium, PingFang SC;
	}

	/deep/ .arco-btn-size-medium {
		width: 95px;
		height: 69px;
		font-size: 24px !important;
		font-family: PingFang SC-Heavy, PingFang SC;
		font-weight: 800;
		color: #FFFFFF;
		line-height: 33px;
		background: #3B75FD;
		border-radius: 0 6px 6px 0;
	}

	/deep/ .arco-input-size-medium {
		font-size: 21px !important;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #B0B6BD;
		line-height: 33px;
		border: none;
		outline: none;
		box-shadow: none;
		color: #4E5969;
	}

	/deep/ .arco-select-view-input {
		font-size: 21px !important;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #81868C;
		line-height: 33px;
	}

	// @import url("../../styles/all.less");
	.page {
		position: relative;
		background-image: url(../../assets/bg/wuliu.png);
		background-size: 100%;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
		// overflow-y: scroll;
		// height: 100vh;
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.for {
		position: relative;
		padding-left: 17px;

		.right {
			position: absolute;
			top: 0;
			right: 15px;

			.icon {
				font-size: 14px;
				font-family: PingFang SC-Medium, PingFang SC;
				color: #2189F3;
			}
		}
	}

	// 背景图
	.img-bg {
		position: absolute;
		top: 0;
		left: 0;
		height: auto;
		z-index: -1;
	}
	.search-bg{
		position: relative;
		width: 675px;
		height: 69px;

	}
	// 搜索框
	.search {
		filter: drop-shadow(0px 4px 12px rgba(59, 117, 253, 0.16));
		display: flex;
		align-items: center;
		width: 675px;
		height: 69px;
		// box-shadow: 0 0 5px 3px #3B75FD20;
		// background: #3B75FD;
		opacity: 1;
	}

	.select {
		height: 69px;
		background-color: #FFFFFF;
		text-align: center;
		font-size: 21px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #81868C;
		line-height: 69px;
		border-radius: 6px 0 0 6px;
	}

	.options {
		position: absolute;
		filter: drop-shadow(0px 0px 0px #FFFFFFFF);
		top: 72px;
		width: 132px;
		height: 113px;
		background: #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		opacity: 1;
	}

	.option {
		width: 109px;
		height: 45px;
		background-color: #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		opacity: 1;
		font-size: 21px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #81868C;
		padding-left:11px;
		line-height: 45px;
		
		margin: 6px;
	}

	.option:hover {
		background-color: #F0F1F3;
	}

	.optionChoose {
		background-color: #F0F1F3;
	}

	/deep/ .arco-select-view-value {
		padding: 0;
		font-size: 21px !important;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #81868C;
		line-height: 33px;
	}

	/deep/ .arco-select-view-single {
		outline: none;
		border: none;
		min-width: 110px;
		height: 69px;
		margin-left: 1px;
		background-color: #FFFFFF;
		border-radius: 4px 0 0 4px;
	}

	/deep/ .arco-input-wrapper {
		height: 69px;
		background-color: #FFFFFF;
		border-radius: 0;
		border: none;
		outline: none;
	}

	/deep/ .arco-btn-primary {
		height: 69px;
		font-size: 24px !important;
		font-family: PingFang SC-Medium, PingFang SC;
		min-width: 95px;
	}

	// 搜索结果
	.content {
		position: absolute;
		top: 75px;
		left: 0;
		right: 0;
		height: 300px;
		background-color: #FFFFFF;
		border-radius: 4px;
		overflow: hidden;
		padding: 0 14px 0 17px;

		.title {
			width: 100%;
			height: 30px;
			// background: #FBFCFE;
			border-radius: 4px 4px 4px 4px;
			opacity: 1;
			font-size: 18px;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #B0B6BD;
			line-height: 30px;
			text-align: start;
			padding: 12px 0 0;
		}

		// 手机号搜索
		.mobile {
			.for {
				height: 119px;
				background-color: #FAFBFF;
				padding: 0 20px;

				.right {
					top: 15px;
				}
			}

			.title {
				font-size: 24px;
				font-family: PingFang SC-Heavy, PingFang SC;
				font-weight: 800;
				color: #4E5969;
				line-height: 28px;
				// background-color: #FFFFFF;
				padding-left: 0px;
				margin-top: 15px;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}

			.phone {
				font-size: 24px;
				font-family: PingFang SC-Medium, PingFang SC;
				font-weight: 500;
				color: #3B75FD;
				line-height: 28px;
				margin-top: 18px;
			}

			.time {
				margin-top: 18px;
				font-size: 24px;
				font-family: PingFang SC-Medium, PingFang SC;
				font-weight: 500;
				color: #4E5969;
				line-height: 28px;
				margin-left: 78px;
			}
		}

		// 订单号搜索
		.order {
			background-color: #FBFCFE;

			.for {
				background-color: #FAFBFF;
				height: 60px;
				margin: 6px 20px 0;
				display: flex;
				padding-left: 0;
				align-items: center;
				justify-content: space-between;
				font-family: PingFang SC-Medium, PingFang SC;

				.right {
					top: 17px;

					.icon {
						font-size: 18px;
					}
				}

				.title {
					padding-top: 0;
					font-size: 24px;
					font-family: PingFang SC-Heavy, PingFang SC;
					font-weight: 800;
					color: #0075FF;
					line-height: 28px;
				}
			}
		}

		// 无数据
		.nodata {
			text-align: center;

			.bg {
				width: 159px;
				height: 135px;
				margin-top: -20px;
			}

			.tips {
				font-size: 21px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #B0B6BD;
				line-height: 36px;
				text-align: center;
			}
		}
	}
</style>
