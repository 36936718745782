<template>
  <div class="cover flex" style="position: fixed;">
  	<div class="report">
  		<div class="title">
			<span>订单编号：{{data.orderNo}}</span>
			<span>款式名称：{{data.styleName}}</span>
			<span>工序组：{{data.processGroupName}}</span>
			<icon-close class="right"  @click="$emit('close')" />
		</div>
  		<div class="msg" style="margin-bottom: 78px;">
  			<a-table :data="process" :bordered="true" size="mini" :pagination="false" :stripe="true" :scroll="{y:160}">
  				<template #columns>
					<a-table-column width="50" class="column" title="序号" data-index="key">
					</a-table-column>
  					<a-table-column width="90" title="工序编号" data-index="personnelNo">
  						<template #cell="{ record }">
  							<a-tooltip :content="record.processNo">
  								<a-button class="ellipsis" style="width: 100%;">{{record.processNo}}</a-button>
  							</a-tooltip>
  						</template>
  					</a-table-column>
  					<a-table-column width="90" title="工序名称" data-index="personnelNo">
  						<template #cell="{ record }">
  							<a-tooltip :content="record.personnelNo">
  								<a-button class="ellipsis" style="width: 100%;">{{record.processName}}
  								</a-button>
  							</a-tooltip>
  						</template>
  					</a-table-column>
  					<a-table-column width="70" title="部件" data-index="unitName">
  						<template #cell="{ record }">
  							<a-tooltip :content="record.unitName">
  								<a-button class="ellipsis" style="width: 100%;">{{record.unitName}}</a-button>
  							</a-tooltip>
  						</template>
  					</a-table-column>
  					<a-table-column width="50" title="扎号" data-index="bundleNum">
  						<template #cell="{ record }">
  							<a-tooltip :content="record.bundleNum">
  								<a-button class="ellipsis" style="width: 100%;">{{record.bundleNum}}</a-button>
  							</a-tooltip>
  						</template>
  					</a-table-column>
  					<a-table-column width="50" title="数量" data-index="completeAmount">
  						<template #cell="{ record }">
  							<a-tooltip :content="record.completeAmount">
  								<a-button class="ellipsis" style="width: 100%;">{{record.completeAmount}}</a-button>
  							</a-tooltip>
  						</template>
  					</a-table-column>
  					<a-table-column width="110" title="完成时间" data-index="completeDate">
  						<template #cell="{ record }">
  							<a-tooltip :content="record.completeDate">
  								<a-button class="ellipsis" style="width: 100%;">{{record.completeDate}}
  								</a-button>
  							</a-tooltip>
  						</template>
  					</a-table-column>
					<a-table-column width="90" title="员工编号" data-index="personnelNo">
						<template #cell="{ record }">
							<a-tooltip :content="record.personnelNo">
								<a-button class="ellipsis" style="width: 100%;">{{record.personnelNo}}
								</a-button>
							</a-tooltip>
						</template>
					</a-table-column>
					<a-table-column width="70" title="姓名" data-index="personnelName">
						<template #cell="{ record }">
							<a-tooltip :content="record.personnelName">
								<a-button class="ellipsis" style="width: 100%;">{{record.personnelName}}
								</a-button>
							</a-tooltip>
						</template>
					</a-table-column>
					<a-table-column width="70" title="人员组" data-index="structureName">
						<template #cell="{ record }">
							<a-tooltip :content="record.structureName">
								<a-button class="ellipsis" style="width: 100%;">{{record.structureName}}
								</a-button>
							</a-tooltip>
						</template>
					</a-table-column>
  				</template>
  			</a-table>
  		</div>
  		<div class="bottom">
  			<a-button type="primary" @click="$emit('close')">我知道了</a-button>
  		</div>
  	</div>
  </div>
</template>
<script>
// import { getOrderDetail } from "../api/task.js";
import { productionRecord } from "../api/schedule.js";
export default {
  name: "data",
  props: {
    data: {},
  },
  data() {
    return {
      // detail: {},
	  process: Array(10).fill(null).map((_, index) => ({
					key: String(index+1),
					orderNo: '16680907',
					customerName: '钉架',
					productionWorkshop: '一人位',
					orderAmount: '1',
					taskAmount: '1',
					completeAmount: '1',
					sailingDate: '2021-12-26',
					staffNo:'DC002',
					name:'李春梅',
					group:'封板1线'
				  })), //订单列表
    };
  },
  mounted() {
	  this.getReportDetail()
  },
  methods: {
    getReportDetail() {
      console.log("详情");
      productionRecord({
        orderId: this.data.orderId,
		processNo:this.data.processNo
      }).then((res) => {
		  console.log(res.data[0])
		  var process = res.data
		  for (let i = 0; i < process.length; i++) {
		  	process[i].key = i+1>9?i+1:'0'+(i+1)
		  }
        this.process = res.data;
      });
    },
    // getDetail(orderId) {
    //   console.log("详情");
    //   getOrderDetail({
    //     orderId: orderId,
    //   }).then((res) => {
    //     this.detail = res.data[0];
    //   });
    // },
  },
};
</script>
<style scoped lang="less">
	/deep/ .arco-table-cell{
		margin-left: 9px!important;
	}
	/deep/ .arco-table-body{
		overflow-x: hidden;
	}
	/deep/ .arco-btn-primary{
		width: 155px;
		height: 40px;
		background: #2189F3;
		box-shadow: 1px 1px 0px 1px #E5E5E5;
		border-radius: 8px;
		font-size: 18px;
		font-family: Source Han Sans CN-Medium, Source Han Sans CN;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 21px;
		padding-left: 15px!important;
	}
.report {
		width: 1039px;
		position: absolute;
		// bottom: 300px;
		@media (min-width: 1300px) and (max-width:1400px) {
			width: 1000px;
		}
		@media (min-width: 1100px) and (max-width:1300px) {
			width: 900px;
		}
		@media (max-width:1100px) {
			width: 800px;
		}
	}

	.report-width {
		width: 768px;

		@media (min-width: 820px) and (max-width:1180px) {
			width: 560px;
		}

		@media (max-width:768px) {
			width: 460px;
		}
	}

	.title {
		width: 100%;
		height: 56px;
		background: #303C56;
		border-radius: 8px 8px 0px 0px;
		font-size: 18px;
		font-family: Source Han Sans CN-Medium, Source Han Sans CN;
		font-weight: 500;
		color: #FFFFFF;
		text-align: start;
		line-height: 56px;
		padding: 0 24px;
		.right{
			right: -30px;
			font-size: 25px;
			color: #FFFFFF;
			background-color: #FFFFFF00;
		}
		span{
			margin-right: 20px;
		}
	}

	.msg {
		width: 100%;
		padding: 24px;
		overflow: auto;
		background-color: #FFFFFF;
	}

	.bottom {
		width: 100%;
		height: 78px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: #FFFFFF;
		box-shadow: 0px -2px 4px 1px #336bef29;
		border-radius: 0px 0px 8px 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 24px;
		/deep/ .arco-btn-size-medium{
			padding-right: 0!important;
		}
	}
/deep/ .arco-table-cell{
		padding:9px!important;
	}
	/deep/ .arco-btn-size-medium{
		padding: 0 9px!important;
	}
	/deep/ .arco-table-th{
		background-color: #FFFFFF!important;
	}
	/deep/ .arco-table-td{
		background-color: #F4F4F4!important;
	}
	/deep/ .arco-table-stripe .arco-table-tr:nth-child(even) .arco-table-td{
		background-color: #FAFAFB40!important;
	}
</style>
