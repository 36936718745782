export function dataInJs() {
var array = [{
		code: 'shunfeng',
		name: '顺丰',
		icon: 'https://cdn.kuaidi100.com/images/all/56/shunfeng.png'
	},
	{
		code: 'ems',
		name: 'EMS',
		icon: 'https://cdn.kuaidi100.com/images/all/56/ems.png'
	},
	{
		code: 'youzhengguonei',
		name: '邮政包裹',
		icon: 'https://cdn.kuaidi100.com/images/all/56/youzhengguonei.png'
	},
	{
		code: 'huitongkuaidi',
		name: '百世汇通',
		icon: 'https://cdn.kuaidi100.com/images/all/56/huitongkuaidi.png'
	},
	{
		code: 'shentong',
		name: '申通',
		icon: 'https://cdn.kuaidi100.com/images/all/56/shentong.png'
	},
	{
		code: 'zhongtong',
		name: '中通',
		icon: 'https://cdn.kuaidi100.com/images/all/56/zhongtong.png'
	},
	{
		code: 'yuantong',
		name: '圆通',
		icon: 'https://cdn.kuaidi100.com/images/all/56/yuantong.png'
	},
	{
		code: 'guotongkuaidi',
		name: '国通',
		icon: 'https://cdn.kuaidi100.com/images/all/56/guotongkuaidi.png'
	},
	{
		code: 'yunda',
		name: '韵达',
		icon: 'https://cdn.kuaidi100.com/images/all/56/yunda.png'
	},
	{
		code: 'tiantian',
		name: '天天',
		icon: 'https://cdn.kuaidi100.com/images/all/56/tiantian.png'
	},
	{
		code: 'youshuwuliu',
		name: '优速',
		icon: 'https://cdn.kuaidi100.com/images/all/56/youshuwuliu.png'
	},
	{
		code: 'jd',
		name: '京东',
		icon: 'https://cdn.kuaidi100.com/images/all/56/jd.png'
	},
	{
		code: 'zhaijisong',
		name: '宅急送',
		icon: 'https://cdn.kuaidi100.com/images/all/56/zhaijisong.png'
	},
	{
		code: 'annengwuliu',
		name: '安能物流',
		icon: 'https://cdn.kuaidi100.com/images/all/56/annengwuliu.png'
	},
	{
		code: 'annto',
		name: '安得',
		icon: 'https://cdn.kuaidi100.com/images/all/56/annto.png'
	},
	{
		code: 'aramex',
		name: 'Aramex',
		icon: 'https://cdn.kuaidi100.com/images/all/56/aramex.png'
	},
	{
		code: 'huitongkuaidi',
		name: '百世快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/huitongkuaidi.png'
	},
	{
		code: 'baishiwuliu',
		name: '百世快运',
		icon: 'https://cdn.kuaidi100.com/images/all/56/baishiwuliu.png'
	},
	{
		code: 'baishiguoji',
		name: '百世国际',
		icon: 'https://cdn.kuaidi100.com/images/all/56/baishiguoji.png'
	},
	{
		code: 'coe',
		name: 'COE',
		icon: 'https://cdn.kuaidi100.com/images/all/56/coe.png'
	},
	{
		code: 'flyway',
		name: '程光快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/flyway.png'
	},
	{
		code: 'chuanxiwuliu',
		name: '传喜物流',
		icon: 'https://cdn.kuaidi100.com/images/all/56/chuanxiwuliu.png'
	},
	{
		code: 'debangwuliu',
		name: '德邦快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/debangwuliu.png'
	},
	{
		code: 'dhl',
		name: 'DHL',
		icon: 'https://cdn.kuaidi100.com/images/all/56/dhl.png'
	},
	{
		code: 'disifang',
		name: '递四方',
		icon: 'https://cdn.kuaidi100.com/images/all/56/disifang.png'
	},
	{
		code: 'ewe',
		name: 'EWE全球快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/ewe.png'
	},
	{
		code: 'fengwang',
		name: '丰网速运',
		icon: 'https://cdn.kuaidi100.com/images/all/56/fengwang.png'
	},
	{
		code: 'fedex',
		name: 'FedEx',
		icon: 'https://cdn.kuaidi100.com/images/all/56/fedex.png'
	},
	{
		code: 'arkexpress',
		name: '方舟速递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/arkexpress.png'
	},
	{
		code: 'youzhengguonei',
		name: '挂号信',
		icon: 'https://cdn.kuaidi100.com/images/all/56/youzhengguonei.png'
	},
	{
		code: 'guotongkuaidi',
		name: '国通快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/guotongkuaidi.png'
	},
	{
		code: 'huisenky',
		name: '汇森速运',
		icon: 'https://cdn.kuaidi100.com/images/all/56/huisenky.png'
	},
	{
		code: 'huolalawuliu',
		name: '货拉拉物流',
		icon: 'https://cdn.kuaidi100.com/images/all/56/huolalawuliu.png'
	},
	{
		code: 'tcat',
		name: '黑猫宅急便',
		icon: 'https://cdn.kuaidi100.com/images/all/56/tcat.png'
	},
	{
		code: 'jd',
		name: '京东物流',
		icon: 'https://cdn.kuaidi100.com/images/all/56/jd.png'
	},
	{
		code: 'jtexpress',
		name: '极兔速递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/jtexpress.png'
	},
	{
		code: 'jinguangsudikuaijian',
		name: '京广快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/jinguangsudikuaijian.png'
	},
	{
		code: 'kuayue',
		name: '跨越速运',
		icon: 'https://cdn.kuaidi100.com/images/all/56/kuayue.png'
	},
	{
		code: 'xlair',
		name: '快弟来了',
		icon: 'https://cdn.kuaidi100.com/images/all/56/xlair.png'
	},
	{
		code: 'lianhaowuliu',
		name: '联昊通',
		icon: 'https://cdn.kuaidi100.com/images/all/56/lianhaowuliu.png'
	},
	{
		code: 'mexico',
		name: '墨西哥邮政',
		icon: 'https://cdn.kuaidi100.com/images/all/56/mexico.png'
	},
	{
		code: 'minghangkuaidi',
		name: '民航快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/minghangkuaidi.png'
	},
	{
		code: 'meiquick',
		name: '美快国际物流',
		icon: 'https://cdn.kuaidi100.com/images/all/56/meiquick.png'
	},
	{
		code: 'ganzhongnengda',
		name: '能达速递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/ganzhongnengda.png'
	},
	{
		code: 'postennorge',
		name: '挪威邮政',
		icon: 'https://cdn.kuaidi100.com/images/all/56/postennorge.png'
	},
	{
		code: 'southafrican',
		name: '南非邮政',
		icon: 'https://cdn.kuaidi100.com/images/all/56/southafrican.png'
	},
	{
		code: 'ocs',
		name: 'OCS',
		icon: 'https://cdn.kuaidi100.com/images/all/56/ocs.png'
	},
	{
		code: 'euasia',
		name: '欧亚专线',
		icon: 'https://cdn.kuaidi100.com/images/all/56/euasia.png'
	},
	{
		code: 'pingandatengfei',
		name: '平安达腾飞',
		icon: 'https://cdn.kuaidi100.com/images/all/56/pingandatengfei.png'
	},
	{
		code: 'portugalctt',
		name: '葡萄牙邮政',
		icon: 'https://cdn.kuaidi100.com/images/all/56/portugalctt.png'
	},
	{
		code: 'swisspost',
		name: '瑞士邮政',
		icon: 'https://cdn.kuaidi100.com/images/all/56/swisspost.png'
	},
	{
		code: 'quanfengkuaidi',
		name: '全峰快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/quanfengkuaidi.png'
	},
	{
		code: 'quanyikuaidi',
		name: '全一快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/quanyikuaidi.png'
	},
	{
		code: 'rrs',
		name: '日日顺物流',
		icon: 'https://cdn.kuaidi100.com/images/all/56/rrs.png'
	},
	{
		code: 'japanposten',
		name: '日本邮政',
		icon: 'https://cdn.kuaidi100.com/images/all/56/japanposten.png'
	},
	{
		code: 'sxjdfreight',
		name: '顺心捷达',
		icon: 'https://cdn.kuaidi100.com/images/all/56/sxjdfreight.png'
	},
	{
		code: 'subida',
		name: '速必达',
		icon: 'https://cdn.kuaidi100.com/images/all/56/subida.png'
	},
	{
		code: 'suer',
		name: '速尔快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/suer.png'
	},
	{
		code: 'lntjs',
		name: '特急送',
		icon: 'https://cdn.kuaidi100.com/images/all/56/lntjs.png'
	},
	{
		code: 'tiantian',
		name: '天天快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/tiantian.png'
	},
	{
		code: 'tnt',
		name: 'TNT',
		icon: 'https://cdn.kuaidi100.com/images/all/56/tnt.png'
	},
	{
		code: 'ups',
		name: 'UPS',
		icon: 'https://cdn.kuaidi100.com/images/all/56/ups.png'
	},
	{
		code: 'usps',
		name: 'USPS',
		icon: 'https://cdn.kuaidi100.com/images/all/56/usps.png'
	},
	{
		code: 'wherexpess',
		name: '威盛快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/wherexpess.png'
	},
	{
		code: 'ukrpost',
		name: '乌克兰邮政',
		icon: 'https://cdn.kuaidi100.com/images/all/56/ukrpost.png'
	},
	{
		code: 'xyb2b',
		name: '行云物流',
		icon: 'https://cdn.kuaidi100.com/images/all/56/xyb2b.png'
	},
	{
		code: 'xinfengwuliu',
		name: '信丰物流',
		icon: 'https://cdn.kuaidi100.com/images/all/56/xinfengwuliu.png'
	},
	{
		code: 'yuantong',
		name: '圆通速递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/yuantong.png'
	},
	{
		code: 'yuntrack',
		name: 'YunExpress',
		icon: 'https://cdn.kuaidi100.com/images/all/56/yuntrack.png'
	},
	{
		code: 'zhongtong',
		name: '中通快递',
		icon: 'https://cdn.kuaidi100.com/images/all/56/zhongtong.png'
	},
	{
		code: 'zhongtongguoji',
		name: '中通国际',
		icon: 'https://cdn.kuaidi100.com/images/all/56/zhongtongguoji.png'
	},
]
return array;
}
