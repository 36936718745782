//引用第一步创建的axios.js文件
import request from '../axios1.js'
// 根据订单编号或手机号查询订单信息
export function getOrder(params) {
  return request({
    url: '/express/getOrderNo',
    method: 'get',
    params
  })
}
// 订单详情
export function getDetail(params) {
  return request({
    url: '/express/orderInfo',
    method: 'get',
    params
  })
}
// 根据订单编号查询物流详情
export function getDetailByNo(params) {
  return request({
    url: '/express/expressInfoByNo',
    method: 'get',
    params
  })
}
