<template>
	<a-layout>
		<a-layout-sider style="width: 64px;" :style="{zoom:screenWidth/1280}" >
			<div class="logo">
				
				<a-avatar :size="34"
					:style="{ backgroundColor: userinfo.avatar==null||userinfo.avatar==''?'#3b75fd':'#FFFFFF00' }"
					shape="square">
					<img :src="$baseUrl + userinfo.avatar" v-if="userinfo.avatar!=null&&userinfo.avatar!=''" />
					{{userinfo.avatar==null||userinfo.avatar==''?userinfo.nickname1:''}}
				</a-avatar>
			</div>
			<a-menu class="menu-tag" :key="key" @menuItemClick="onClickMenuItem">
				<a-menu-item class="menu-item" key="0" :style="{backgroundColor:key==0?'#ECF2FF':'#FFFFFF'}">
					<!-- <div> -->
					<!-- <IconCalendar style="font-size: 30px; color: #fff" /> -->
					<div class="icon-bg" :style="{backgroundColor:key==0?'#3B75FD':'#FFFFFF'}">
						<span class="iconfont menu-icon " :class="key==0?'home-icon-a-Property1gongzuotai-mian1':'home-icon-a-Property1gongzuotai-xian'"
							style="cursor: pointer;font-size:20px;" :style="{color:key==0?'#ffffff':'#4E5969'}"></span>
					</div>
					<div>工作台</div>
					<!-- </div> -->
				</a-menu-item>
				<a-menu-item class="menu-item" key="1" :style="{backgroundColor:key==1?'#ECF2FF':'#FFFFFF'}">
					<div class="icon-bg" :style="{backgroundColor:key==1?'#3B75FD':'#FFFFFF'}">
						<span class="iconfont menu-icon " :class="key==1?'home-icon-a-Property1xiaoxi-mian1':'home-icon-a-Property1xiaoxi-xian'"
							style="cursor: pointer;font-size:20px;" :style="{color:key==1?'#ffffff':'#4E5969'}"></span>
					</div>
					<div>消息</div>
				</a-menu-item>
				<a-menu-item class="menu-item" key="2" :style="{backgroundColor:key==2?'#ECF2FF':'#FFFFFF'}">
					<div class="icon-bg" :style="{backgroundColor:key==2?'#3B75FD':'#FFFFFF'}">
						<span class="iconfont menu-icon " :class="key==2?'home-icon-a-Property1wode-xian1':'home-icon-a-Property1wode-mian'"
							style="cursor: pointer;font-size:20px;" :style="{color:key==2?'#ffffff':'#4E5969'}"></span>
					</div>
					<div>我的</div>
				</a-menu-item>
				<!-- <a-menu-item class="menu-item" :key="key" :style="{backgroundColor:key==3?'#ECF2FF':'#FFFFFF'}">
					<div class="icon-bg" :style="{backgroundColor:key==3?'#3B75FD':'#FFFFFF'}">
						
							<svg-icon class="icon" style="font-size:26px" icon-class="search"></svg-icon>

					</div>
					<div>搜索</div>
				</a-menu-item> -->
			</a-menu>
			<massageCard style="height: 0px!important;" @handleOk="handleOk" ref="massageCard"></massageCard>
			<div class="bottom">
				<div class="border">
							<svg-icon class="logo" icon-class="logo"></svg-icon>
					<!-- <img class="logo" src="../assets/logo/logo.png" /> -->
				</div>
				<div class="border" @click="layout">
					<span class="iconfont menu-icon home-icon-a-Component59"
						style="cursor: pointer;font-size:20px;" :style="{color:key==3?'#ffffff':'#4E5969'}"></span>
				</div>
			</div>
		</a-layout-sider>
		<a-layout-content>
			<router-view ref="children"></router-view>
		</a-layout-content>
	</a-layout>
	<!-- <a-layout class="layout-demo" :style="{backgroundColor: '#f8f9fa' }">
		左侧
		<a-layout-sider breakpoint="lg" :width="300" :collapsed="true" style=" z-index: 1001;"
			:style="{ height: '100vh',backgroundColor: '#f8f9fa' ,position: 'fixed', top: '40px',left: '0px',width: '80px!important'}">
			
		</a-layout-sider>
		
		
		上方
		<a-layout class="layout-box" :style="{overflowY: 'scroll' }">
			<a-layout-sider style="flex: 1;margin-left:80px;" >
				
				上方搜索部分
				<a-layout-header class="layout-header" style="z-index:1001;"
					:style="{position: 'fixed',top: '0',left: '0',right: '0',height:'50px'}">
					<icon-search style="z-index: 99;" :style="{fontSize:'24px',position:'absolute',left:'31.5%'}" />
					<a-select placeholder="输入需要搜索的功能模块"
						style="width: 38%;height:70%;position: relative;background-color: #748097;" allow-search>
						<a-option v-for="item in children" :key="item">
							<div class="flex" @click="goNewPage(item.webPath,item.type)" style="align-items: center;">
								<div class="search-option"> <span class="iconfont search-icon" :class="item.icon"
										:style="{
                          color: '#FFFFFF',
                          fontSize: '25px',
                        }"></span></div>
								<div>{{item.permissionName}}</div>
							</div>
						</a-option>
					</a-select>
					<div class="user">
						Hi,{{userinfo.nickname}},欢迎登陆
						<a-button type="dashed" @click="layout">注销登录</a-button>
					</div>
				</a-layout-header>
				<a-layout-content class="content-box" style="height: 100%;">
					<router-view ref="children"></router-view>
				</a-layout-content>
			</a-layout-sider>
		</a-layout>
	</a-layout> -->
</template>
<script>
	import {
		getMinPermission,
		logout
	} from '../api/index.js'

	import massageCard from '../common/massageCard.vue'
	export default {
		components: {
			massageCard,
		
		},
		data() {
			return {
				screenWidth: document.documentElement.clientWidth, //获取屏幕宽度
				type: 1, //判断背景颜色
				userinfo: {}, //当前登录的用户信息
				children: [], //所有模块
				key: "", //标签高亮的key
				data: [], //标签列表
				list: ['/dashBord/workBench/index', '/dashBord/notice', '/dashBord/workBench/index',
					'/dashBord/workBench/index'],
			};
		},
		watch: {
			//监听路由变化
			$route(path) {
				// this.key = path.meta.key;
				// if (path.meta.key != null) {
				// 	localStorage.setItem('key', path.meta.key)
				// 	localStorage.setItem('type', path.meta.type)
				this.type = path.meta.type
				// var a = false;//判断当前路由是否加入到标签里面
				// for (let i = 0; i < this.data.length; i++) {
				// 	if (path.meta.key == this.data[i].key) {
				// 		a = true;
				// 	}
				// }
				// if (a == false) {
				// 	var arr = path.meta
				// 	arr.fullPath = path.fullPath
				// 	this.data.push(arr);
				// 	localStorage.setItem('tabs', JSON.stringify(this.data))
				// 	console.log('tabs')
				// 	console.log(this.data)
				// }
				// }

			},
		},
		mounted() {
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.documentElement.clientWidth
					this.screenWidth = window.screenWidth;
					
				})();
			};
			// 存储标签列表
			// var data = localStorage.getItem('tabs')
			// this.key = localStorage.getItem('key')
			// this.type = localStorage.getItem('type')
			// console.log('tabs')
			// console.log(data)
			// 未打开任何页面时默认打开工作台
			// if (data == null || data == '') {
			// 	this.data.push({
			// 		title: '工作台',
			// 		icon: 'logo.png',
			// 		type: 1,
			// 		key: '01',
			// 		fullPath: '/dashBord/workBench'
			// 	});
			// 	localStorage.setItem('tabs', JSON.stringify(this.data))
			// } else {
			// 	this.data = JSON.parse(data)
			// }
			this.type = this.$route.meta.type
			// var menuList = localStorage.getItem('menuList')
			// console.log(menuList)
			// if (menuList == null) {
			// 	this.getMenu()
			// } else {
			// 	this.menuList = JSON.parse(menuList)
			// 	this.children = this.menuList[0].children
			// }
			var userinfo = JSON.parse(localStorage.getItem('userinfo'))
			userinfo.nickname1 = userinfo.nickname.substring(userinfo.nickname.length - 2)
			this.userinfo = userinfo
		},
		methods: {
			// 搜索出来的列表点击跳转页面
			// goNewPage(url, type) {
			// 	console.log(url)
			// 	console.log(url != null)
			// 	if (type != -1 && url != null) {
			// 		this.$router.push('/' + url);
			// 	}
			// },
			// 退出登录
			layout() {
				var data = {
					name: '提示',
					msg: '是否退出登录？',
					type: 'Warning'
				}

				this.$refs.massageCard.handleClick(data);
			},
			// 清除缓存，没有一键清除是因为列配置不能清除
			handleOk() {
				logout().then(res => {
					this.$router.replace('/')
					localStorage.removeItem('token');
					localStorage.removeItem('userinfo');
					localStorage.removeItem('menuList');
					localStorage.removeItem('oftenList');
					localStorage.removeItem('key');
					localStorage.removeItem('tabs');
					localStorage.removeItem('type');
				})
			},
			// 获取全部模块（最上方搜索用到）
			// getMenu() {
			// 	getMinPermission().then(res => {
			// 		var all = []
			// 		for (let i = 0; i < res.data.length; i++) {
			// 			all = all.concat(res.data[i].children)
			// 		}
			// 		this.children = all
			// 	})
			// },
			// 切换 消息/工作台/我的，因为其他两个模块没有所以强制跳转工作台
			onClickMenuItem(e) {
				this.key = e
				var url = this.list[e]
				this.$router.push(url);

			},
			// 点击标签跳转页面
			// goPage(e) {
			// 	this.data.forEach(element => {
			// 		console.log(element.fullPath != null)
			// 		if (element.key == e && element.fullPath != null) {
			// 			this.$router.push(element.fullPath);
			// 			// window.location.href = "#" +element.fullPath
			// 		}
			// 	});
			// },
			// 删除标签，默认打开前一个标签的页面
			// handleDelete(key) {
			// 	this.data = this.data.filter((item) => item.key !== key)
			// 	localStorage.setItem('tabs', JSON.stringify(this.data))
			// 	let push = this.data[this.data.length - 1].fullPath
			// 	this.$router.push(push)
			// },
		},
	};
</script>
<style>
	/* .page {
		padding: 22px;
	}
	.arco-menu-item-tooltip{
		display: none!important;
	} */
</style>
<style scoped lang="less">
	@import url("../styles/dashBord.less");
	/deep/ .arco-menu-inner{
		overflow-x: hidden;
	}
	/deep/ .arco-layout-sider-children{
		overflow-x: hidden;
	}
	// .menu-icon{
	// 	height: 26px!important;
	// 	// font-size: 20px!important;
	// 	margin-left: 2px;
	// }
	// /deep/ .layout-box::-webkit-scrollbar {
	// 	width:0;
	// }
	// /deep/ .arco-avatar-text{
	// 	font-size: 14px!important;
	// }
	// .tabs-list .iconfont{
	// 	color: #2189F3!important;
	// 	margin-right: 10px;
	// }
	// /deep/ .arco-tabs-tab{
	// 	color: #1F1F1F80!important;
	// }
	// /deep/ .arco-tabs-tab-active{
	// 	color: #1F1F1F!important;
	// }
</style>
<style scoped>
	/* .home-icon-pinglun:before{
		width: 24px!important;
	}
	.tab-icon{
		height: 20px;
		    margin-right: 10px;
		    margin-bottom: 6px;
		    position: relative;
		    bottom: -4px;
		
	} */
</style>
