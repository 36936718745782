<template>
	<div class="container-box" >
		<a-row class="grid-demo" :gutter="20">
			<a-col :span="7">
				<div class="left-table" :style="{ height: screenHeight  + 'px' }">
					<!-- <div class="left-input-box">
						<a-input ref="formRef" placeholder="请输入产品型号" @clear="search.productName = null,$refs.formRef.blur(),getProduct()" @press-enter="$refs.formRef.blur(),getProduct()" v-model="search.productName"
							allow-clear>
							<template #prefix>
								<icon-search />
							</template>
						</a-input>
					</div> -->
					<div class="top_input">
						<div class="top_input_a">
							<a-input class="top_input_a" ref="formRef" placeholder="请输入产品型号" @clear="search.productName = null,$refs.formRef.blur(),getProduct()"  v-model="search.productName"
								allow-clear>
							</a-input>
						</div>
						<div class="top_input_but" @click="$refs.formRef.blur(),getProduct()">查询</div>
					</div>
					<div class="top_input1">
						<div class="top_input1_a">
							<a-input ref="formRef1" placeholder="请拍卡" :readonly="readonly" @focus="onFocus" @blur="readonly=true" @press-enter="$refs.formRef1.blur(),getProduct()" v-model="search.cardNo" allow-clear>
								<template #prefix>
									<icon-mobile />
								</template>
							</a-input>
						</div>
					</div>
					<div class="top_title">
						<div style="padding-left:8px">查询结果</div>
					</div>
					<a-table v-if="leftData.length > 0" :columns="leftColumns" :data="leftData" @row-click="getList"  size="middle"
						:pagination="false" :scroll="{ y: screenHeight - 200 }" :bordered="false" class="scroll" />
					<div v-else class="right-empty">
						<img src="../../assets/others/emptyWork.png" style="zoom: 0.6;" />
						<div>
							<p>什么都没有</p>
							<p>换个条件试试吧</p>
						</div>
					</div>
				</div>
			</a-col>
			<a-col :span="17">
				<div class="right-table" :style="{ height: screenHeight - 99 + 'px' }">
					<div class="right-header">
						<div class="list-type">{{ name }}{{screenHeight}}</div>
						<div>
							<a-input placeholder="请输入文件名进行搜索" ref="formRef2" v-model="kewords" @clear="type=1,$refs.formRef2.blur(),getHistoryList()"
								@press-enter="(activeKey = []),$refs.formRef2.blur(), searchTable()" allow-clear>
								<template #prefix>
									<icon-search />
								</template>
							</a-input>
						</div>
					</div>
					<div v-if="type == 1 && historyList.length > 0">
						<a-table class="scrollbar" :bordered="false" :pagination="false" @row-click="goDetail"
							:data="historyList" :scroll="{ y: screenHeight - 212 }" >
							<template #columns>
								<a-table-column width="80" title="序号" :ellipsis="true" data-index="key">
								</a-table-column>
								<a-table-column title="类型" width="80" :ellipsis="true">
									<template #cell="{ record }">
										<img class="icon"
											:src=" require('../../assets/icon/' + record.fileType.replace('.', '') + '.png') " />
									</template>
								</a-table-column>
								<a-table-column title="文件名" :ellipsis="true">
									<template #cell="{ record }">
										<a-tooltip :content="record.fileName">
											<a-button class="ellipsis" style="width: 100%">{{ record.fileName }}
											</a-button>
										</a-tooltip>
									</template>
								</a-table-column>
								<a-table-column width="120" title="上传者" :ellipsis="true" data-index="createPerson">
								</a-table-column>
								<a-table-column width="120" title="更新日期" :ellipsis="true" data-index="createTime">
								</a-table-column>
								<a-table-column width="120" title="大小" :ellipsis="true" data-index="fileSize">
								</a-table-column>
							</template>
						</a-table>
					</div>
					<div class="collapse-table" v-else-if="type == 2 && list.length>0">
						<a-table :columns="rightColumns" :scroll="scrollRight" :bordered="false" :pagination="false"
							class="right-colums-header"></a-table>
						<a-collapse v-if="list" :bordered="false" @change="handleToCollapse" class="collapse"
							:default-active-key="activeKey" :style="{ height: screenHeight - 212 + 'px' }">
							<a-collapse-item v-for="(item, index) in list" :key="index" :header="item.groupName"
								:show-expand-icon="showIcon">
								<template #header>
									<div class="collapse-table-header">
										<img src="../../assets/icon/collapse-right.png"
											v-if="activeKey.indexOf(index) == -1" alt="" />
										<img src="../../assets/icon/collapse-bottom.png" v-else alt="" />
										{{ item.groupName }}
									</div>
								</template>
								<a-table class="table" :bordered="false" :pagination="false"
									@row-click="goDetail" @change="handleChangeRight" :show-header="false"
									:data="item.workmanshipDTOs">
									<template #columns>
										<a-table-column width="80" title="序号" data-index="key"></a-table-column>
										<a-table-column title="类型" width="80">
											<template #cell="{ record }">
												<img class="icon"
													:src=" require('../../assets/icon/' + record.fileType.replace('.', '') + '.png') " />
											</template>
										</a-table-column>
										<a-table-column title="文件名" data-index="fileName" :ellipsis="true">
										</a-table-column>
										<a-table-column width="120" title="上传者" data-index="createPerson">
										</a-table-column>
										<a-table-column width="120" title="更新日期" data-index="createTime">
										</a-table-column>
										<a-table-column width="120" title="大小" data-index="fileSize"></a-table-column>
									</template>
								</a-table>
							</a-collapse-item>
						</a-collapse>
					</div>
					<div v-else class="right-empty">
						<img style="width: 192px;margin-top: 100px;" src="../../assets/others/emptyWork.png" />
						<div>
							<p>什么都没有</p>
							<p>换个条件试试吧</p>
						</div>
					</div>
				</div>
			</a-col>
		</a-row>
	</div>
</template>
<script>
	import {
		getWorkmanship,
		getHistoryList,
		getProduct,
		openFiles,
	} from "../../api/workmanship.js";
	export default {
		data() {
			return {
				productId: "", //产品id
				// activekey: "", //当前展开的面板
				screenHeight: document.documentElement.clientHeight, //获取屏幕宽度.
				// icon: this.iconList,
				kewords: "", //搜索内容
				name: "最近查看", //右侧标题
				historyList: [], //最近查看列表
				search: {}, //搜索信息
				type: 1, //1最近列表，2产品型号列表
				list: [], //右侧列表
				listType: "最新款式",
				scrollRight: {
					x: 200,
					y: 500,
				}, //表格滚动设置
				showIcon: false,
				readonly:true,
				activeKey: [],
				// 左边表格表头
				leftColumns: [{
						title: "序号",
						dataIndex: "index",
						width: "70",
					},
					// {
					// 	title: "产品编号",
					// 	dataIndex: "productNo",
					// 	sortable: {
					// 		sortDirections: ["ascend", "descend"],
					// 	},
					// 	ellipsis:true,
					// },
					{
						title: "产品型号",
						dataIndex: "productModel",
						sortable: {
							sortDirections: ["ascend", "descend"],
						},
						ellipsis: true,
					},
				],
				// 左边表格内容
				leftData: [],
				// 右边表格表头
				rightColumns: [{
						title: "序号",
						dataIndex: "key",
						width: 80,
					},
					{
						title: "类型",
						dataIndex: "fileType",
						width: 80,
					},
					{
						title: "文件名",
						dataIndex: "fileName",
						ellipsis: "true",
					},
					{
						title: "上传者",
						dataIndex: "createPerson",
						width: 120,
					},
					{
						title: "更新日期",
						dataIndex: "createTime",
						width: 120,
					},
					{
						title: "大小",
						dataIndex: "fileSize",
						width: 130,
					},
				],
			};
		},
		// 监听高度
		// watch: {
		// 	screenHeight(val) {
		// 		this.screenHeight = val;
		// 	},
		// },
		mounted() {
			// 监听高度
			var that = this
			that.screenHeight = document.documentElement.clientHeight;
			console.log(that.screenHeight)
			window.onresize = () => {
				return (() => {
					window.screenHeight = document.documentElement.clientHeight
					that.screenHeight = window.screenHeight;
				})();
			};
			this.getHistoryList();
			var name = this.$route.query.name;
			console.log(name);
			if (name != null && name != "") {
				this.search.productName = name;
				this.getProduct();
			} else {
				this.getProduct();
			}
			console.log(
				localStorage.getItem("productId"),
				'localStorage.getItem("productId")'
			);
			if (localStorage.getItem("productId")) {
				console.log(
					localStorage.getItem("productId"),
					'localStorage.getItem("productId")'
				);
				this.productId = localStorage.getItem("productId");
				this.name = localStorage.getItem("name");
				let activeKey = JSON.parse(localStorage.getItem("activeKey"));
				if(activeKey!=null){
					for (let i = 0; i < activeKey.length; i++) {
						activeKey[i] = Number(activeKey[i]);
					}
				}
				this.activeKey = activeKey;
				getWorkmanship({
					productId: localStorage.getItem("productId"),
				}).then((res) => {
					this.type = 2;
					res.data.forEach((element) => {
						var num = 1;
						element.workmanshipDTOs.forEach((item) => {
							// if(item.fileName.indexOf(a)!='-1'){
							item.key = num;
							num++;
							// }
						});
					});
					this.list = res.data;
					localStorage.removeItem("productId");
					localStorage.removeItem("name");
					localStorage.removeItem("activeKey");
				});
			}
		},
		methods: {
			onFocus(){
				setTimeout(() => {
					this.readonly = false
				}, 50);
				
			},
			// 文件名搜索
			searchTable() {
				var list = [];
				var a = this.kewords;
				if (a == "") {
					this.type = 2;
				} else {
					this.list.forEach((element) => {
						element.workmanshipDTOs.forEach((item) => {
							if (item.fileName.indexOf(a) != "-1") {
								list.push(item);
							}
						});
					});
					console.log(list);
					this.historyList = list;
					this.type = 1;
				}
			},
			// 拍卡
			getProduct() {
				getProduct(this.search).then((res) => {
					var leftData = res.data;
					// if (leftData.length == 0) {
					// 	this.$modal.warning({
					// 		title: "提示",
					// 		content: "非扎件卡，内容为空",
					// 		okText: "我知道了",
					// 	});
					// } else {
						for (var i = 0; i < leftData.length; i++) {
							leftData[i].index = i + 1;
						}
						this.leftData = leftData;
					// }
				});
			},
			// 最近查看
			getHistoryList() {
				getHistoryList().then((res) => {
					var list = res.data;
					for (var i = 0; i < list.length; i++) {
						list[i].key = i + 1;
					}
					this.historyList = list;
				});
			},
			// 获取列表
			getList(e) {
				this.name = e.productModel;
				getWorkmanship({
					productId: e.id,
				}).then((res) => {
					this.type = 2;
					this.productId = e.id;
					res.data.forEach((element) => {
						var num = 1;
						element.workmanshipDTOs.forEach((item) => {
							// if(item.fileName.indexOf(a)!='-1'){
							item.key = num;
							num++;
							// }
						});
					});
					this.list = res.data;
				});
			},
			// 预览文件
			goDetail(e) {
				console.log(e.content);
				console.log(e.fileName);
				let that = this;
				var data = {
					attachmentId: e.attachmentId,
					flieName: e.fileName,
				};
				console.log(data);
				openFiles(data).then((res) => {
					if (res.code == 0) {
						this.$router.push({
							path: "/dashBord/workBench/workManShipDetail",
							query: {
								content: e.content,
								name: e.fileName,
								productId: that.productId,
								name: that.name,
								activeKey: that.activeKey,
							},
						});
					}
				});
			},
			handleToCollapse(e) {
				this.activeKey = e;
			},
		},
	};
</script>
<style scoped>
	.right-header{
		height: 50px;
		background: #FFFFFF;
		box-shadow: 0px 4px 4px 1px rgba(59, 117, 253, 0.05);
	}
	.collapse {
		overflow-y: scroll;
	}

	input::-webkit-input-placeholder {
		color: #86909c !important;
	}

	.ellipsis {
		border: none;
		color: #616c72;
		overflow: hidden;
		background-color: #ffffff00;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: start;
		    width: 100% !important;
		    padding-right: 10px!important;
	}

	.ellipsis:hover {
		background-color: #ffffff00;
	}
	.table{
		height: none!important;
	}
</style>
<style lang="less">
	@import url("../../styles/workManShip.less");
	/deep/ .arco-input::-webkit-input-placeholder {
		color: #86909C !important;
	}
	
	/deep/ .arco-input:-moz-placeholder {
		color: #86909C !important;
	}
	
	/deep/ .arco-input::-moz-placeholder {
		color: #86909C !important;
	}
	
	/* For the future */
	/deep/ .arco-input:-ms-input-placeholder {
		color: #86909C !important;
	}
	.arco-table-body{
		// max-height: 999px!important;
	}
	.arco-table-body::-webkit-scrollbar {
	  width: 4px;
	}
	.arco-table-body::-webkit-scrollbar-thumb {
	  border-radius: 10px;
	  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	  background: rgba(0, 0, 0, 0.2);
	}
	.arco-table-body::-webkit-scrollbar-track {
	  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	  border-radius: 0;
	  background: rgba(0, 0, 0, 0.1);
	}
	.collapse::-webkit-scrollbar {
	  width: 4px;
	}
	.collapse::-webkit-scrollbar-thumb {
	  border-radius: 10px;
	  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	  background: rgba(0, 0, 0, 0.2);
	}
	.collapse::-webkit-scrollbar-track {
	  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	  border-radius: 0;
	  background: rgba(0, 0, 0, 0.1);
	}
	.table /deep/ .arco-table-body{
		max-height: 9999px!important;
	}
	.scroll{
		padding: 10px;
	}

</style>
