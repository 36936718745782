<template>
	<div class="page" style="z-index: 99;">
		<!-- <a-modal :visible="visible" @ok="handleOk" @cancel="handleCancel">
			<template #title>
				<icon-close-circle-fill v-if="data.type=='Error'" />
				<icon-exclamation-circle-fill v-if="data.type=='Warning'" />
				{{data.name}}
			</template>
			<div style="margin-left: 20px;">{{data.msg}}</div>
		</a-modal> -->
		<div class="page-content" v-if="visible">
			<div class="page-content-box">
				<div class="page-content-title">
					<!-- <icon-close-circle-fill v-if="data.type=='Error'" />

					<icon-exclamation-circle-fill v-if="data.type=='Warning'" /> -->
					<div class="page-content-icon">!</div>
					<div>{{data.name}}</div>
				</div>
				<div class="page-content-msg">{{data.msg}}</div>
				<div class="page-content-but">
					<div class="page-content-close" @click="handleCancel">取消</div>
					<div class="page-content-ok" @click="handleOk">确定</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				visible: false,
				data: {}
			}
		},
		methods: {
			handleClick(msg) {
				console.log(msg)
				this.data = msg
				this.visible = true;
			},
			handleOk() {
				this.visible = false;
				this.$emit('handleOk')
				// this.$parent.handleOk();
			},
			handleCancel() {
				this.visible = false;
			},
		}
	};
</script>
<style>


	.page .arco-modal-wrapper .arco-modal {
		width: 375px!important;
	}


</style>
<style scoped lang="less">
	.Warning {
		width: 18px;
		height: 18px;
		background: #FF7D00;
		border-radius: 50%;
		color: #FFFFFF;
		line-height: 18px;
		text-align: center;
		font-size: 10px;
		margin-right: 6px;
	}
	.page-content{
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 9000;
		background-color: var(--color-mask-bg);
		display: flex;
		align-items: center;
		justify-content: center;
		.page-content-box{
			width: 374px;
			
			background: #fff;
			border-radius: 4px;
			padding: 24px;
			.page-content-title{
				text-align: center;
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.page-content-icon{
				width: 18px;
				height: 18px;
				text-align: center;
				line-height: 18px;
				border-radius: 50%;
				color: #fff;
				background: #FF7D00;
				margin-right: 5px;

			}
			.page-content-msg{
				margin-top: 24px;
				font-weight: 400;
				color: #1D2129;
				font-size: 14px;
			}
			.page-content-but{
				display: flex;
				align-items: center;
				margin-top: 24px;
				justify-content: center;
				.page-content-close{
					width: 68px;
					height: 36px;
					background: #F2F3F5;
					border-radius: 2px 2px 2px 2px;
					line-height: 36px;
					text-align: center;
					margin: 5px;
				}
				.page-content-ok{
					width: 68px;
					height: 36px;
					background: #165DFF;
					border-radius: 2px 2px 2px 2px;
					line-height: 36px;
					text-align: center;
					color: #fff;
					margin-left: 5px;
				}
			}
		}
	}
	
</style>
