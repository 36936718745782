//引用第一步创建的axios.js文件
import request from '../axios.js'
// 获取异常列表
export function getExceptionList(params) {
  return request({
    url: 'minapp/report/reportListPage',
    method: 'get',
    params
  })
}
// 获取异常详情
export function getExceptionProcess(params) {
  return request({
    url: 'minapp/report/detail',
    method: 'get',
    params
  })
}
// 获取异常类型
export function getTypeList(params) {
  return request({
    url: 'minapp/minLabel/list',
    method: 'get',
    params
  })
}
//保存草稿
export function saveReport(data) {
  return request({
    url: 'minapp/report/saveDraft',
    method: 'post',
    data:data
  })
}
//获取草稿
export function getReport(params) {
  return request({
    url: 'minapp/report/getReportDraft',
    method: 'get',
    params
  })
}
//提交异常
export function submitReport(data) {
  return request({
    url: 'minapp/report/saveReport',
    method: 'post',
    data:data
  })
}
//修改异常
export function updateReport(data) {
  return request({
    url: 'minapp/report/updateReport',
    method: 'post',
    data:data
  })
}
//删除异常
export function delReport(data) {
  return request({
    url: 'minapp/report/deleteReport',
    method: 'delete',
    data:data
  })
}
export function deleteSolve(data) {
  return request({
    url: 'minapp/report/deleteSolve',
    method: 'delete',
    data:data
  })
}
export function submitReply(data) {
  return request({
    url: 'minapp/report/solve',
    method: 'post',
    data:data
  })
}
// 获取生管问题
export function getSelect(params) {
  return request({
    url: 'minapp/minLabel/list',
    method: 'get',
    params
  })
}
// 获取右边echarts
export function getReportCensus(params) {
  return request({
    url: 'minapp/report/getReportCensus',
    method: 'get',
    params
  })
}
// 获取左边 列表
export function reportPage(params) {
  return request({
    url: 'minapp/report/reportPage',
    method: 'get',
    params
  })
}
// 获取报表详情
export function getDetail(params) {
  return request({
    url: 'minapp/report/detail',
    method: 'get',
    params
  })
}