//引用第一步创建的axios.js文件
import request from '../axios.js'
// 获取日报
export function getDailyList(params) {
  return request({
    url: 'minapp/daily/page',
    method: 'get',
    params
  })
}
// 获取日报详情
export function getDailyDetail(params) {
  return request({
    url: 'minapp/daily/details',
    method: 'get',
    params
  })
}
// 写日报
export function setDailyDetail(params) {
  return request({
    url: 'minapp/dailyTemplate/getData/S1637742323460',
    method: 'get',
    params
  })
}
//保存草稿
export function saveReport(data) {
  return request({
    url: 'minapp/daily/saveDraft',
    method: 'post',
    data:data
  })
}
//获取日报配置
export function getReport(params) {
  return request({
    url: 'minapp/daily/chooseDaily',
    method: 'get',
    params
  })
}
//获取配置的推送人
export function getUser(params) {
  return request({
    url: 'minapp/noticeConfig/list',
    method: 'get',
    params
  })
}
//提交日报
export function submitReport(data) {
  return request({
    url: 'minapp/daily/publish',
    method: 'post',
    data:data
  })
}
//删除配置的推送人
export function delUser(data) {
  return request({
    url: 'minapp/noticeConfig/delete/'+data.id,
    method: 'delete',
  })
}
//删除所有配置的推送人
export function delAllUser(params) {
  return request({
    url: 'minapp/noticeConfig/deleteBatch',
    method: 'delete',
	params
  })
}

//新增推送人配置
export function addUser(data) {
  return request({
    url: 'minapp/noticeConfig/save',
    method: 'post',
    data:data
  })
}
// 评论，回复评论
export function setComment(data) {
  return request({
    url: 'minapp/daily/comment',
    method: 'post',
    data:data
  })
}
// 删除评论
export function delComment(data) {
  return request({
    url: 'minapp/daily/deleteComment',
    method: 'delete',
    data:data
  })
}