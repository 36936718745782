//引用第一步创建的axios.js文件
import request from '../axios.js'
// 车间产能趋势
export function capacityTrend(params) {
  return request({
    url: 'minapp/capacity/capacityTrend',
    method: 'get',
    params
  })
}
// 产能人均趋势
export function personAvgTrend(params) {
  return request({
    url: 'minapp/capacity/personAvgTrend',
    method: 'get',
    params
  })
}
// 获取成品产能
export function capacityDetail(params) {
  return request({
    url: 'minapp/capacity/capacityDetail',
    method: 'get',
    params
  })
}
