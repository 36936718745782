<template>
	<div class="page">
		<a-layout>
			<a-layout :style="{height:(screenHeight)+'px'}">
				<a-layout-sider theme="dark" :style="{height:(screenHeight)+'px'}">
					<div>
						<div class="header_tips">
							<div class="tips-box" 
								@click="status=0,current=1,type=1,dailyListl = [],getList()">
								全部
								<div class="selcet" v-if="status == 0"></div>
							</div>
							<div class="tips-box" 
								@click="status=1,current=1,type=1,dailyListl = [],getList()">
								未读（{{unread}}）
								<div class="selcet" v-if="status == 1"></div>
							</div>
							<div class="tips-box" 
								@click="status=2,current=1,type=1,dailyListl = [],getList()">
								我提交的
								<div class="selcet" v-if="status == 2"></div>
							</div>
							<div class="right" v-if="type==2">
								<a-button type="primary" size="large" @click="goEdit">写日报</a-button>
							</div>
						</div>
					</div>

					<div class="sider-width" :style="{height:(screenHeight)+'px'}">
						<div style="height:1PX;background-color:#FFFFFF;background-color: #FFFFFF!important;"></div>
						<div v-for="item in daily" :key="item" style="background-color: #FFFFFF!important;">
							<div class="date">{{item.createDate}}</div>
							<div class="list" :style="{backgroundColor:user.dailyId==dailyId?'#F2F4F5':'#FFFFFF'}"
								v-for="user in item.list" :key="user" @click="user.state=1,getDetail(user.dailyId)">
								<a-avatar :style="{ backgroundColor: '#3b75fd' }" :size="40" shape="square">
									<img :src="'https://wis4cloud.wis.ltd' + user.headImage"
										v-if="user.headImage!=null&&user.headImage!=''" />
									{{user.headImage==null||user.headImage==''?user.personnelName.substring(user.personnelName.length-2):''}}
								</a-avatar>
								<!-- <a-avatar :style="{ backgroundColor: '#3B75FD' }" :size="40" shape="square"
										:src="'https://wis4cloud.wis.ltd/'+user.headImage">{{user.personnelName}}</a-avatar> -->
								<div class="name">{{user.title}}</div>
								<div class="right" style="text-align: center;" v-if="user.isMe==0"
									:class="{read:user.state==0}">
									{{user.state==1?'已读':'未读'}}
								</div>
							</div>
							<div style="height:1PX;background-color:#FFFFFF;"></div>
						</div>
						<div class="bottom">
							<div class="button" @click="current++,getList()">
								{{current==pages||pages==1?'已加载全部':'查看更多...'}}</div>
						</div>
					</div>
				</a-layout-sider>
				<a-layout-content :style="{height:(screenHeight)+'px'}">
					<div class="flex" style="justify-content: center;width: 100%;" v-if="type==1">
						<div class="card" @click="goEdit">
							<div class="card-title flex">
								<img class="daily-icon" src="../../assets/icon/daily.png" />
								日报
							</div>
							<div class="card-label">今日产量</div>
							<a-progress :size="size" :percent="0" :stroke-width="9" :show-text="false" />
							<div class="card-label">员工出勤</div>
							<a-progress :size="size" :percent="0" :stroke-width="9" :show-text="false" />
							<div class="card-label">工作简述</div>
							<a-progress :size="size" :percent="0" :stroke-width="9" :show-text="false" />
						</div>
					</div>
					<div v-if="type==2" class="rightScroll" id="rightScroll">
						<div class="form">
							<div class="tips">
								<a-avatar :style="{ backgroundColor: '#3b75fd' }" :size="40" shape="square">
									<img :src="'https://wis4cloud.wis.ltd' + detail.headImage"
										v-if="detail.headImage!=null&&detail.headImage!=''" />
									{{detail.headImage==null||detail.headImage==''?detail.personnelName.substring(detail.personnelName.length-2):''}}
								</a-avatar>
								<div class="msg">
									<div class="name">{{detail.title}}</div>
									{{detail.date}}
								</div>
							</div>
							<div class="label">
								今日产量
								<input class="count" disabled v-model="count" type="number" />
							</div>
							<div class="table">
								<a-table :data="detail.content.production.info" :pagination="false">
									<template #columns>
										<a-table-column title="序号" width="70" data-index="key"></a-table-column>
										<a-table-column title="生产批次" width="110" data-index="time"></a-table-column>
										<a-table-column title="计划产量" width="105" data-index="planAmount">
										</a-table-column>
										<a-table-column title="完成产量" width="105" data-index="actualAmount">
										</a-table-column>
										<a-table-column title="备注">
											<template #cell="{ record }">
												<a-tooltip :content="record.remarks"
													v-if="record.remarks!=null&&record.remarks!=''">
													<a-button class="ellipsis remarksWidth" style="width: 100%;">
														{{record.remarks}}
													</a-button>
												</a-tooltip>
											</template>
										</a-table-column>
									</template>
								</a-table>
							</div>
							<div class="label" style="margin-top:32px;">
								员工出勤
							</div>
							<a-table class="staff" :data="detail.content.personnel" :pagination="false">
								<template #columns>
									<a-table-column title="车间" data-index="structureName"></a-table-column>
									<a-table-column title="应到人数" data-index="shouldCount"></a-table-column>
									<a-table-column title="实到人数" data-index="realCount"></a-table-column>
									<a-table-column title="产量（套）" data-index="completeAmount"></a-table-column>
									<a-table-column title="人均产量">
										<template #cell="{ record }">
											<!-- {{parseInt(record.completeAmount/record.realCount*100)/100}} -->
											{{record.realCount==0?0:parseInt(record.completeAmount/record.realCount*100)/100}}
										</template>
									</a-table-column>
								</template>
							</a-table>
							<div class="label">
								工作简述
							</div>
							<div class="desc">{{detail.description}}</div>
							<div class="border flex">
								<img class="icon" src="../../assets/icon/comment.png" />
								{{detail.readUserCount}}人已读
							</div>
							<div class="comment flex" v-for="item in detail.commentList" :key="item">
								<a-avatar :style="{ backgroundColor: '#3B75FD' }" :size="40" shape="square">
									{{item.personnelName.substring(item.personnelName.length-2)}}
								</a-avatar>
								<!-- <img class="avatar" src="../assets/others/img1.png" /> -->
								<div class="msg">
									<div class="name" style="height:30px">{{item.personnelName}}</div>
									<div class="contant flex" style="cursor: pointer;display: block!important;">
										<div v-show="item.receiverName!=null&&item.receiverName!=''"
											style="cursor: pointer;">
											回复：
											<span style="color:#2189F3">@{{item.receiverName}}</span>
										</div>
										{{item.content}}
									</div>
									<div class="button flex">
										{{item.date}}
										<div @click="placeholder = '回复：@'+item.personnelName,id = item.commentId,$refs.formRef.focus()"
											style="cursor: pointer;">回复
										</div>
										<div style="cursor: pointer;" @click="delComment(item.commentId)"
											v-if="item.state==1">删除</div>
									</div>
								</div>
							</div>
						</div>
						<div class="send" id="target" @click="setScroll()">
							<a-input ref="formRef" @clear="changeInput" @press-enter="$refs.formRef.blur(),submit()"
								:style="{width:'100%',backgroundColor:'#FAFAFA'}" v-model="content"
								:placeholder="placeholder" allow-clear />
						</div>
					</div>
				</a-layout-content>
			</a-layout>
		</a-layout>
		<massageCard @handleOk="handleOk" ref="massageCard"></massageCard>
	</div>
</template>
<script>
	import {
		getDailyList,
		getDailyDetail,
		setComment,
		delComment
	} from '../../api/daily.js'
	import massageCard from '../../common/massageCard.vue'
	export default {
		components: {
			massageCard
		},
		data() {
			return {
				id: null, //回评论 的comment_id
				content: '', //回复内容
				placeholder: '小小鼓励，让团队更凝聚', //回复框占位符内容
				status: 0, //0全部，1待处理，2我提交
				type: 1, //1写提报，2详情
				detail: {}, //详情
				daily: [], //日报列表
				dailyListl: [], //日报列表原始数据
				unread: 0, //待处理
				current: 1, //页码
				dailyId: 0, //日报id
				count: 0, //总产量
				pages: 1,
				screenHeight: document.documentElement.clientHeight, //获取屏幕高度
			};
		},

		mounted: function() {
			// 监听高度
			var that = this
			that.screenHeight = document.documentElement.clientHeight
			window.onresize = () => {
				return (() => {
					window.screenHeight = document.documentElement.clientHeight
					that.screenHeight = window.screenHeight;
				})();
			};

			// 从写日报页返回过来，获取日报id，展示详情页
			var dailyId = this.$route.query.dailyId
			var type = this.$route.query.type
			console.log('dailyId')
			console.log(dailyId)
			if (dailyId != null && dailyId != '') {
				// this.type = 2
				this.getDetail(dailyId)
			} else {
				this.getList()
			}
		},
		methods: {
			// 滚动到指定位置
			setScroll() {
				console.log(111)
				let target = document.getElementById('target');
				let parent = document.getElementById('rightScroll');
				parent.scrollTop = target.offsetTop - parent.offsetTop;
			},
			// 删除评论
			delComment(id) {
				delComment({
					commentId: id
				}).then(res => {
					console.log(res)
					this.detail.commentList = res.data

				})
			},
			//   提交评论
			submit() {
				var content = this.content
				if (content == null || content == '') {
					this.$message.success('内容不能为空')
				} else {
					var data = {
						dailyId: this.detail.dailyId,
						content: this.content,
						parentId: this.id,
					}
					setComment(data).then(res => {
						console.log(res)
						// this.getList()
						this.content = ''
						this.detail.commentList = res.data
						// this.getDetail(this.detail.dailyId)
						this.$message.success(res.msg)

					})
				}
			},
			//   清空input框时清除回复人
			changeInput() {
				this.placeholder = '小小鼓励，让团队更凝聚'
				this.id = null
			},
			//   写日报
			goEdit() {
				this.$router.replace('/dashBord/workBench/editProductionDaily')
			},
			// 获取列表
			getList() {
				console.log(this.pages)
				console.log(this.current)
				if (this.pages + 1 == this.current) {
					this.current--
				} else {
					getDailyList({
						dailyType: this.status,
						current: this.current
					}).then(res => {
						console.log(res)
						var list = res.data.page.records
						if (list.length == 0) {
							this.current--
						} else {
							this.dailyListl = this.dailyListl.concat(list)
							this.daily = this.mapLoction(this.dailyListl)
							this.unread = res.data.unread
							this.pages = res.data.page.pages
						}

					})
				}
			},
			// 获取详情
			getDetail(dailyId) {
				this.dailyId = dailyId
				getDailyDetail({
					dailyId: dailyId
				}).then(res => {
					var content = res.data.content
					JSON.parse(res.data.content)
					content = JSON.parse(res.data.content)
					this.count = content.production.todayAmount.completeCount
					var list = content.production.info
					for (let i = 0; i < list.length; i++) {
						list[i].key = i + 1
					}
					content.production.todayAmount = list
					res.data.content = content
					this.detail = res.data

					// 匹配回复的人
					var list1 = res.data.commentList
					for (let i = 0; i < list1.length; i++) {
						if (list1[i].parentId != 0) {
							for (let j = 0; j < list1.length; j++) {
								if (list1[i].parentId == list1[j].commentId) {
									list1[i].receiverId = list1[j].commentId
									list1[i].receiverName = list1[j].personnelName
								}
							}
						}
					}
					this.changeInput()
					this.type = 2
					this.current = 1
					this.dailyListl = []
					this.getList()
				})

			},
			// 处理日期
			mapLoction(arr) {
				var newArr = [];
				var index = -1
				for (let i = 0; i < arr.length; i++) {
					if (arr[i + 1] != null && arr[i].createDate.substring(0, 10) === arr[i + 1].createDate.substring(0,
							10)) {

					} else {
						var data = {
							createDate: arr[i].groupDate,
							list: []
						}
						index = index + 1
						newArr.push(data)
						for (let j = 0; j < arr.length; j++) {
							if (arr[i].createDate.substring(0, 10) === arr[j].createDate.substring(
									0, 10)) {
								newArr[index].list.push({
									"createDate": arr[j].createDate,
									"dailyId": arr[j].dailyId,
									"headImage": arr[j].headImage,
									"personnelName": arr[j].personnelName,
									"title": arr[j].title,
									"state": arr[j].state,
									'isMe': arr[j].isMe,
								});
							}
						};
					}
				};
				return newArr;
			}
		},
	};
</script>
<style scoped>
	.sider-width {
		background-color: #ffffff;
		/* height: 400px; */
		position: relative;
		overflow-y: scroll;

	}

	.sider-width::-webkit-scrollbar {
		display: none;
		/* Chrome Safari */
	}

	.rightScroll {
		/* height: 1220px; */
		width: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.rightScroll::-webkit-scrollbar {
		display: none;
		/* Chrome Safari */
	}

	.bottom {
		/* height: 70px; */
		background-color: #FFFFFF;
		width: 100%;

	}

	.bottom .button {
		/* width: 378px !important; */
		height: 48px !important;
		background: #e5e5e580 !important;
		border-radius: 2px !important;
		font-size: 14px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #0000004d;
		margin: 0 5px 0 8px;
		line-height: 48px;
		text-align: start;
		padding-left: 20px;
	}

	@media (width: 1024px) {
		.bottom {
			width: 350px;
		}

		.bottom .button {
			width: 290px !important;
		}
	}

	.ellipsis {
		border: 1px solid #FFFFFF !important;
		color: #616c72;
		overflow: hidden;
		background-color: #FFFFFF00;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100% !important;
		padding-right: 10px !important;
	}

	.ellipsis:hover {
		background-color: #FFFFFF00;
	}

	.count {
		background-color: #FAFAFB !important;
	}

	.count:disabled {
		background-color: #FFFFFF00;
	}
</style>
<style scoped lang="less">
	@import url("../../styles/productionDaily.less");

	/deep/ .arco-avatar-text {
		font-size: 14px !important;
	}

	/deep/ .arco-table-cell {
		padding-left: 16px !important;
	}

	/deep/ .arco-table-th {
		height: 52px !important;
	}

	/deep/ .arco-table-td {
		line-height: 14px;
		height: 52px !important;
	}

	.label {
		height: 52px;
	}

	/deep/ .arco-progress-line {
		border-radius: 0 !important;
	}

	/deep/ .arco-progress-line-bar {
		border-radius: 0 !important;
	}

	/deep/ .arco-table-container::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 0px !important;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 0px !important;
	}

	.date {
		margin-bottom: 16px;
	}
	.header_tips{
		height: 56px;
		background: #FFFFFF;
		box-shadow: 0px 4px 4px 1px rgba(59, 117, 253, 0.05);
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		font-weight: 500;
		color: #4E5969;
		width: 335px;
		position: fixed;
		z-index: 10;
	}
	.tips-box{
		width: 30%;
		text-align: center;
		position: relative;
	}
	.selcet {
		width: 39px;
		height: 4px;
		background: #3B75FD;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -11px;
		margin: auto;
	}
</style>
