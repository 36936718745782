<template>
	<div class="work-place" :style="{ height: screenHeight + 'px' }"
		style="overflow-y: scroll;overflow-x: hidden;">
		<div class="flex" style="text-align:start;align-items:flex-start;">

			<!-- 通知部分 -->
			<div style="width: 34.4%;">
				<a-carousel class="banner-img" :default-current="2" @change="log" :auto-play="{ interval:5000}">
					<a-carousel-item v-for="(item,index) in bannerList">
						<!-- <img src="https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/cd7a1aaea8e1c5e3d26fe2591e561798.png~tplv-uwbnlip3yd-webp.webp" class="tab-right" alt="" srcset=""> -->

						<img :src="item.pictureUrl" class="tab-right banner-img" alt="" srcset="">
						<div class="notice-box">
							<div>{{item.advertTitle}}</div>
							<div>{{item.advertContent}}</div>
							<div class="view-btn" v-show="item.haveButton==1" @click="openUrl(item)">
								{{item.buttonWords}}
							</div>
						</div>
					</a-carousel-item>
				</a-carousel>
			</div>
			<div class="oftenRight" style="width:70%;display: flex;flex-wrap: wrap;overflow-y:hidden;padding: 0 5px;">

				<!-- 常用列表 -->
				<div class="tab-box-bg flex  often-tab1" style="width: 25%;" :key="index"
					v-for="(item,index) in oftenList">
					<div class="tab-box">
						<div class="tab-box-left" @click="goNewPage(item.webPath,item.type)">
							<div class="tab-box-left-bg"
								:style="{background:item.iconBackground,opacity:item.type==-1?0.25:1}">
								<span class="iconfont" :class="item.icon" :style="{
                      color: '#FFFFFF',
                      fontSize: '20px',
                    }"></span>
							</div>
							<div>{{item.permissionName}}</div>
						</div>
						<a-popover trigger="click" position="bottom" style="display: inline-block;">
							<icon-more-vertical style="cursor: pointer;color:rgba(0,0,0,0.3);font-size:24px;" />
							<template #content>
								<div class="tab-canel" @click="permissionId = item.permissionId,delMenu()">
									<icon-minus-circle-fill />
									<div>取消常用</div>
								</div>
							</template>
						</a-popover>
					</div>
					<div class="tab-div" style="min-width: 20px!important;height: 32px;"></div>
				</div>
				<!-- 添加常用 -->
				<div class="tab-box-bg flex" v-if="oftenList.length<length" @click="visible = !visible"
					style="cursor: pointer;width: 25%;">
					<div class="tab-box">
						<div class="tab-box-left">
							<div class="tab-add">
								<icon-plus :style="{color:'#a2a9b7'}" />
							</div>
							<div>添加常用</div>
						</div>
					</div>
					<div class="tab-div" v-if="oftenList.length!=7&&oftenList.length!=3"
						style="min-width: 20px!important;height: 32px;"></div>
				</div>
				<a-modal :visible="visible" @ok="handleOk" @cancel="visible = false" :footer="false"
					class="add-box">
					<template #title>
						<div class="model-title">添加常用</div>
					</template>
					<div>
						<div class="tab-add-search">
							<icon-search />
							<a-select placeholder="搜索" style="width: 100%;height:70%;background-color:#EFEFEF;"
								allow-search>
								<a-option v-for="item in children" :key="item">
									<div class="add-tab-list">
										{{ item.permissionName }}
										<div class="add-btn" style="height:20px;line-height:20px;"
											v-show="indexList.indexOf(item.permissionId)==-1" @click="addMenu(item)">添加
										</div>
										<div class="remove-btn" style="height:20px;line-height:20px;"
											v-show="indexList.indexOf(item.permissionId)!=-1"
											@click="permissionId = item.permissionId,delMenu()">移除
										</div>
									</div>
								</a-option>
							</a-select>
							<!-- <a-input placeholder="搜索"  allow-clear /> -->
						</div>
						<div style="height:2px;"></div>
						<a-layout style="height: 400px; margin-top: 20px;">
							<a-layout>
								<a-layout-sider>
									<a-list :style="{ width: `180px`,margin:'10px 0 0 2px' }" :bordered="false"
										:split="false">
										<a-list-item :key="i" :class=" index == i ? 'list-item-active' : ''"
											v-for="(item,i) in menuList" @click="index=i,children = item.children">
											{{item.permissionName}}
										</a-list-item>
									</a-list>
								</a-layout-sider>
								<a-layout-content style="overflow-y: scroll;height:400px;margin-top: 10px;}"
									class="scrollbar">
									<a-list :bordered="false" :split="false">
										<a-list-item :key="i" v-for="(item,i) in children">
											<a-list-item-meta>
												<template #title>
													<div class="add-tab-list">
														{{ item.permissionName }}
														<div class="add-btn"
															v-show="indexList.indexOf(item.permissionId)==-1"
															@click="addMenu(item,i)">添加</div>
														<div class="remove-btn"
															v-show="indexList.indexOf(item.permissionId)!=-1"
															@click="permissionId = item.permissionId,delMenu()">移除</div>
													</div>
												</template>
												<template #avatar>
													<div class="tab-box-left-bg "
														:style="{background:item.iconBackground,opacity:item.type==-1?0.25:1}">
														<!-- <div class="cover" v-show="item.type==-1"
															style="display: block;"></div> -->
														<div class="tab-box-left-icon">
															<span class="iconfont" :class="item.icon" :style="{
                                        color: '#FFFFFF',
                                        fontSize: '20px',
                                      }"></span>
														</div>
													</div>
												</template>
											</a-list-item-meta>

										</a-list-item>
									</a-list>
								</a-layout-content>
							</a-layout>
						</a-layout>
					</div>
				</a-modal>
			</div>
		</div>
		<div style="width:100%;display: flex;flex-wrap: wrap;margin-top: 12px;">
			<div class="tab-box-bg flex often-tab2" :style="{display:index<length?'none':''}" :key="index"
				v-for="(item,index) in oftenList">
				<div class="tab-box">
					<div class="tab-box-left" @click="goNewPage(item.webPath,item.type)">
						<div class="tab-box-left-bg"
							:style="{background:item.iconBackground,opacity:item.type==-1?0.25:1}">
							<!-- <div class="cover" v-show="item.type==-1" style="display: block;"></div> -->
							<span class="iconfont" :class="item.icon" :style="{
                      color: '#FFFFFF',
                      fontSize: '20px',
                    }"></span>
						</div>
						<div>{{item.permissionName}}</div>
					</div>
					<a-popover trigger="click" position="bottom" style="display: inline-block;">
						<icon-more-vertical style="cursor: pointer;color:rgba(0,0,0,0.3);font-size:24px;" />
						<template #content>
							<div class="tab-canel" @click="permissionId = item.permissionId,delMenu()">
								<icon-minus-circle-fill />
								<div>取消常用</div>
							</div>
						</template>
					</a-popover>
				</div>
				<div class="tab-div" style="min-width: 20px!important;height: 32px;"></div>
			</div>
			<div class="tab-box-bg flex" @click="visible = !visible" v-show="oftenList.length>=length"
				style="cursor: pointer;">
				<div class="tab-box">
					<div class="tab-box-left">
						<div class="tab-add">
							<icon-plus :style="{color:'#a2a9b7'}" />
						</div>
						<div>添加常用</div>
					</div>
				</div>
				<div class="tab-div" v-if="(oftenList.length-7)%6!=0" style="min-width: 20px!important;height: 32px;">
				</div>
			</div>
		</div>
		<div class="tab-all-box">
			<a-tabs @change="changeIndex" :active-key="tabIndex">
				<a-tab-pane :title="item.permissionName" v-for="(item,index) in menuList" :key="index">
					<div style="text-align:start;display: flex;flex-wrap: wrap;">
						<div class="tab-box-bg flex  often-tab3" @click="goNewPage(arr.webPath,arr.type)"
							v-for="arr in item.children" :key="arr">
							<div class="tab-box">
								<div class="tab-box-left">
									<div class="tab-box-left-bg"
										:style="{background:arr.iconBackground,opacity:arr.type==-1?0.25:1}">
										<div class="tab-box-left-icon">
											<span class="iconfont" :class="arr.icon"
												:style="{ color: '#FFFFFF', fontSize: '20px', }"></span>
										</div>
									</div>
									<div>{{arr.permissionName}}</div>
								</div>
							</div>
							<div class="tab-div" style="min-width: 20px!important;height: 32px;"></div>
						</div>
						<!-- <div class="tab-box-bg flex  often-tab3" @click="goNewPage('dashBord/workBench/logistics',1)">
							<div class="tab-box">
								<div class="tab-box-left">
									<div class="tab-box-left-bg"
										:style="{background:'linear-gradient(47deg, rgb(3, 201, 253) 0%, rgb(10, 147, 251) 100%);'}">
										<div class="tab-box-left-icon">
											<span class="iconfont"
												:style="{ color: '#FFFFFF', fontSize: '20px', }"></span>
										</div>
									</div>
									<div>生产物流</div>
								</div>
							</div>
							<div class="tab-div" style="min-width: 20px!important;height: 32px;"></div>
						</div>
						<div class="tab-box-bg flex  often-tab3" @click="goNewPage('dashBord/workBench/staff',1)">
							<div class="tab-box">
								<div class="tab-box-left">
									<div class="tab-box-left-bg"
										:style="{background:'linear-gradient(47deg, rgb(3, 201, 253) 0%, rgb(10, 147, 251) 100%);'}">
										<div class="tab-box-left-icon">
											<span class="iconfont"
												:style="{ color: '#FFFFFF', fontSize: '20px', }"></span>
										</div>
									</div>
									<div>员工技能</div>
								</div>
							</div>
							<div class="tab-div" style="min-width: 20px!important;height: 32px;"></div>
						</div> -->

					</div>
				</a-tab-pane>

			</a-tabs>
		</div>
		<massageCard @handleOk="delSubmit" ref="massageCard"></massageCard>
		<div class="isloding" v-if="loading">
			<div class="isloading-box">
				<img class="loading-img" src="../assets/webp.webp" alt="">
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getMinPermission,
		getOftenPermission,
		addMenu,
		delMenu,
		notice
	} from '../api/index.js'
	import massageCard from "../common/massageCard.vue";
	export default {
		components: {
			massageCard,
		},
		data() {
			return {
				is_click: true,
				loading:false,
				length: 8,
				permissionId: 0,
				bannerList: [],
				children: [], //添加常用列表
				index: 0, //添加常用选中的分类下标
				oftenList: [], //常用菜单
				menuList: [], //菜单
				visible: false,
				tabIndex: 0,
				indexList: [], //常用菜单id集合
				screenWidth: document.documentElement.clientWidth, //获取屏幕宽度
				screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
			};
		},
		watch: {
			/* 监听宽度，常用部分用到*/
			screenWidth(val) {
				this.screenWidth = val;
				// var length = 0
				// console.log(val)
				// if (val > 1279) {
				// 	length = 8
				// } else if (1000 < val && val < 1279) {
				// 	length = 4
				// } else if (val < 1000) {
				// 	length = 2
				// }
				// this.length = length
				this.$forceUpdate()
			},
			screenHeight(val) {
				this.screenHeight = val;
			},
		},
		mounted() {
			this.flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			if (this.flag != null) {
				let backlen = window.history.length - 1;
				console.log('backlen')
				console.log(backlen)

				if (backlen > 1 && this.flag != null) {
					window.history.go(-backlen);
				}
			}


			// 获取屏幕宽度先赋值
			var val = document.body.clientWidth
			// var index = localStorage.getItem('tabIndex')
			// if (index != null) {
			// 	this.tabIndex = index
			// }
			// if (val > 1279) {
			// 	length = 8
			// } else if (1000 < val && val < 1279) {
			// 	length = 4
			// } else if (val < 1000) {
			// 	length = 2
			// }
			// this.length = length
			const that = this
			window.screenHeight = document.documentElement.clientHeight
			that.screenHeight = window.screenHeight
			var menuList = localStorage.getItem('menuList')
			var oftenList = localStorage.getItem('oftenList')
			// 获取模块缓存
			if (menuList == null) {
				this.getMenu()
			} else {
				this.menuList = JSON.parse(menuList)
				this.children = this.menuList[0].children
			}
			if (oftenList == null) {
				this.getOftenMenu()
			} else {
				this.oftenList = JSON.parse(oftenList)
				for (var i = 0; i < this.oftenList.length; i++) {
					this.indexList.push(this.oftenList[i].permissionId)
				}
			}

			// 监听屏幕宽度 
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth
					that.screenWidth = window.screenWidth
					window.screenHeight = document.documentElement.clientHeight
					that.screenHeight = window.screenHeight
					// var length = 0
					var val = window.screenWidth
					// if (val > 1279) {
					// 	length = 8
					// } else if (1000 < val && val < 1279) {
					// 	length = 4
					// } else if (val < 1000) {
					// 	length = 2
					// }
					// this.length = length
				})()
			}
			this.getNotice()
			
		},
		methods: {
			openUrl(item) {
				window.open(item.linkUrl)
			},
			// 获取通知
			getNotice() {
				notice().then(res => {
					this.bannerList = res.data
				})
			},
			// 参加常用
			addMenu(item) {
				this.loading = true
				addMenu({
					permissionId: item.permissionId
				}).then(res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success('添加成功！')
						this.getOftenMenu()
					}
				})
			},
			// 删除常用
			delMenu() {
				var data = {
					name: "确认移除",
					msg: "是否确认移除该常用？",
					type: "Error",
				};
				this.$refs.massageCard.handleClick(data);
			},
			delSubmit() {
				delMenu({
					permissionId: this.permissionId
				}).then(res => {
					this.$message.success('删除成功！')
					this.getOftenMenu()
				})
			},
			// 获取常用
			getOftenMenu() {
				getOftenPermission().then(res => {
					var indexList = []
					res.data.forEach(element => {
						indexList.push(element.permissionId)
					});
					this.indexList = indexList
					localStorage.setItem('oftenList', JSON.stringify(res.data))
					this.oftenList = res.data
				})
			},
			// 获取模块列表
			getMenu() {
				var arr = this.$router.options.routes[5].children
				getMinPermission().then(res => {
					var list = []
					var all = []
					for (let i = 0; i < res.data.length; i++) {
						all = all.concat(res.data[i].children)
					}
					this.children = all
					list.push({
						permissionName: '全部应用',
						children: all
					})
					list = list.concat(res.data)
					var arr2 = list[0].children
					for (let j = 0; j < arr.length; j++) {
						for (let i = 0; i < arr2.length; i++) {
							var url = arr2[i].webPath
							if (url != null) {
								url = url.split('/')
								console.log(url)
								var a = url[url.length-1]
								if (a == arr[j].path) {
									// console.log(arr2[i])
									arr[j].meta.icon = arr2[i].icon
								}
							}

						}
					}
					localStorage.setItem('menuList', JSON.stringify(list))
					this.menuList = list
				})
			},
			changeIndex(e) {
				this.tabIndex = e
				localStorage.setItem('tabIndex', this.tabIndex)
			},
			// 点击模块跳转路由
			goNewPage(url, type) {
				
				if (type != -1 && url != null) {
					this.$router.push('/' + url);
				}
			},
		},
	};
</script>
<style scoped lang="less">
	@import url("../styles/workBench.less");

	.often-tab1:nth-child(4n) {
		.tab-div {
			display: none;
		}
	}

	.often-tab2:nth-child(6n+2) {
		.tab-div {
			display: none;
		}
	}

	.often-tab3:nth-child(6n) {
		.tab-div {
			display: none;
		}
	}

	/deep/ .arco-carousel-indicator-wrapper-bottom {
		display: none;
	}

	.banner-img {
		// width: 29.3vw;
		margin-right: 14px;
		border-radius: 8px;
		overflow: hidden;
		height: 160px !important;
	}

	.tab-box-bg {
		width: 16.6%;
		margin-bottom: 20px;

		.tab-box {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 13px;
			// height: 42px !important;
			width: 100%;
			margin: 0 !important;
			box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.1599999964237213);
			border-radius: 8px 8px 8px 8px;
			// margin: 20px 25px 14px 0;
			// 	@media (min-width:1800px) {
			// 		margin-top: 0px;
			// 		width: 245px;
			// 		height: auto;
			// 	}
			// 	@media (min-width:1322px) and (max-width:1400px) {
			// 		width: 258px;
			// 	}
			// 	@media (min-width:1040px) and (max-width:1322px) {
			// 		width: 236px;
			// 	}

			// 	@media (max-width:1040px) {
			// 		margin-top: 0px;
			// 		width: 172px;
			// 		height: auto;
			// 	}
		}

		/* display: inline-block; */
	}
</style>
<style>
	.arco-dropdown-list-wrapper::-webkit-scrollbar {
		width: 4px;
	}

	.arco-dropdown-list-wrapper::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: rgba(0, 0, 0, 0.2);
	}

	.arco-dropdown-list-wrapper::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: rgba(0, 0, 0, 0.1);
	}

	.arco-tabs-content {
		border: 1px solid #ffffff00 !important;
	}

	.arco-layout {
		/* border: 0px solid !important;
		margin-top: -1px !important;
		margin-left: -1px !important; */
	}

	/* .changyong .arco-layout{
	border: 0px solid!important;
	margin-top: 0px!important;
	margin-left: -1px!important;
} */
	.tab-header {
		width: 100%;
		height: 214px;
	}

	.tab-header .arco-col {
		height: 100%;
	}

	.tab-right .arco-image-img {
		width: 100%;
		height: 214px;
	}


	.notice-box div:first-child {
		font-size: 16px;
		font-weight: 800;
		line-height: 19px;
	}

	.notice-box div:nth-child(2) {
		font-size: 12px;
		line-height: 14px;
		font-weight: 500;
		margin-top: 6px;
		height: 50px;
	}

	.tab-box-left {
		display: flex;
		align-items: center;
		/* width: 100%; */
	}

	.tab-box-left div {
		color: rgba(0, 0, 0, 0.8);
		/* font-size: 18px; */
		font-weight: 500;
		margin-left: 10px;
		font-size: 14px;
		font-family: 'SourceHanSansCN';
		font-weight: 500;
		line-height: 21px;
	}

	.tab-box-left .arco-image {
		width: 52px;
		height: 52px;
		border-radius: 5px;
	}

	.tab-box-left .arco-image-img {
		width: 100%;
		height: 100%;
	}

	.tab-add {
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		background: #EFEFEF;
	}

	/* 添加常用 */
	.arco-modal {
		overflow: hidden;
		width: 800px!important;
	}




	.arco-modal .arco-modal-close-btn {
		color: #ffffff;
		font-size: 20px;
	}

	.tab-add-search {
		display: flex;
		align-items: center;
		background: #EFEFEF;
		border-radius: 5px;
		padding: 5px;
		outline: none;
		border: none;
	}

	.tab-add-search .arco-input-wrapper {
		background: rgba(0, 0, 0, 0.01) !important;
		width: 100%;
	}

	.arco-layout-sider-light {
		box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
		border-right: 1px solid rgba(0, 0, 0, 0.02);
	}

	.arco-list-medium>.arco-list-content.arco-list-virtual .arco-list-item {
		padding: 6px 8px !important;
		cursor: pointer;
	}

	.list-item-active {
		background: #e8edfe !important;
	}

	.list-item-active .arco-list-item-meta-title {
		color: #527ff7 !important;
	}

	.arco-list-item-meta-content {
		flex: 1;
	}

	.add-tab-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 1;
		font-size: 16px;
		font-family: Source Han Sans CN-Regular, Source Han Sans CN;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.800000011920929);
	}

	.add-tab-list .add-btn {
		padding: 4px 10px;
		border-radius: 4px;
		background: #e9f4fd;
		border: 1px solid #2189f3;
		color: #2189f3;
		font-size: 14px;
		cursor: pointer;
	}

	.add-tab-list .remove-btn {
		padding: 4px 10px;
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.3);
		font-size: 14px;
		cursor: pointer;
	}

	.tab-canel {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.tab-canel div {
		margin-left: 10px;
	}

	.tab-all-box {
		margin-top: 20px;
	}

	.tab-all-box .arco-tabs-tab-active {
		font-weight: 800;
		font-size: 16px !important;
	}

	.work-place {
		background-color: #f8f9fa;
		padding: 22px;
	}

	.tab-box-left-bg {
		width: 36px;
		height: 36px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	.tab-box-left-bg .cover {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-color: #ffffff75;
		margin: 0;

	}

	.tab-header {
		width: 100%;
		height: 214px;
	}

	.tab-header .arco-col {
		height: 100%;
	}

	.tab-right .arco-image-img {
		width: 100%;
		height: 214px;
	}

	/* 
	.notice-box div:first-child {
		font-size: 24px;
		font-weight: 800;
		line-height: 34px;
	}

	.notice-box div:nth-child(2) {
		font-size: 16px;
		line-height: 19px;
		font-weight: 500;
		margin-top: 6px;
	} */

	.tab-box-left {
		display: flex;
		align-items: center;
	}

	.tab-box-left div {
		color: rgba(0, 0, 0, 0.8);
		/* font-size: 18px; */
		font-weight: 500;
		margin-left: 10px;
		font-size: 14px;
		font-family: "SourceHanSansCN";
		font-weight: 500;
		line-height: 21px;
	}

	.tab-box-left .arco-image {
		width: 52px;
		height: 52px;
		border-radius: 5px;
	}

	.tab-box-left .arco-image-img {
		width: 100%;
		height: 100%;
	}

	

	.tab-box-left-bg1 {
		background-image: linear-gradient(235deg, #0999fb, #04c6fd);
	}

	.tab-box-left-bg2 {
		background-color: #2189f3;
	}

	.tab-box-left-bg3 {
		background-image: linear-gradient(47deg, #ffa834 0%, #ff8c16 100%);
	}

	.tab-box-left-bg4 {
		background-image: linear-gradient(43deg, #504deb 0%, #4087f9 100%);
	}

	.tab-box-left-bg5 {
		background: linear-gradient(47deg, #00d1be 0%, #2e9ef8 100%);
	}

	.tab-box-left-icon {
		width: 26px;
		height: 24.73px;
		border-radius: 2px;
		margin: 13px;
		margin-left: 13px !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.arco-tabs-nav-size-medium {
		margin-right: 5px;
	}
	.isloding{
		
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99999;
		background-color: var(--color-mask-bg);
		
	}
	.isloading-box{
		width: 120px;
		height: 120px;
		background-color: var(--color-mask-bg);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-items: center;

	}
</style>
