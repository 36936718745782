//引用第一步创建的axios.js文件
import request from '../axios.js'
// 获取生产任务
export function getOrderList(params) {
  return request({
    url: 'minapp/proTask/getTaskOrderList',
    method: 'get',
    params
  })
}
// 获取 订单详情
export function getOrderTaskProcess(params) {
  return request({
    url: 'minapp/proTask/orderTaskProcess',
    method: 'get',
    params
  })
}
// 获取 订单详情
export function proSet(data) {
  return request({
    url: 'minapp/proTask/proSet',
    method: 'post',
    data:data
  })
}
