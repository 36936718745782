//引用第一步创建的axios.js文件
import request from '../axios.js'
// 车间产能趋势
export function getList(params) {
  return request({
    url: '/minapp/notice/list',
    method: 'get',
    params
  })
}
export function getDetailList(params) {
  return request({
    url: '/minapp/notice/typePage',
    method: 'get',
    params
  })
}
export function delNotificationBatch(data) {
  return request({
    url: '/minapp/notice/delNotificationBatch',
    method: 'DELETE',
    data:data
  })
}