
<template>

  <div id="salsryQuery">

<div class="swiper-container">
<div class="swiper-wrapper">
  <div class="swiper-slide" style="height:100%">
    <div class="head">
      <div class="name">工资查询驾驶舱</div>
      <div class="form_screen">
        <div class="condition-box">
          <div
            @click="visible = false"
            class="input-box"
            style="margin-right: 10px !important"
          >
            <a-input
              class="input-number"
              ref="inputText"
              placeholder="卡号"
              type="number"
              style="
                text-align: center;
                height: 32px;
                width: 150px;
                font-weight: 700 !important;
              "
              v-on:keyup.enter="
                $refs.inputText.blur(), $refs.inputText1.blur(), getDetail()
              "
              v-model="search.cardNo"
              allow-clear
            ></a-input>
          </div>
          <div
            @click="visible = false"
            class="input-box employee"
            style="margin-right: 10px !important"
          >
            <a-input
              ref="inputText1"
              placeholder="员工编号"
              style="height: 32px; width: 150px; font-weight: 700 !important"
              @press-enter="$refs.inputText1.blur(), getDetail()"
              v-model="search.personnelNo"
              placeholder-style="color:#FF0000"
              allow-clear
            >
              <template #prefix>
                <icon-search />
              </template>
            </a-input>
          </div>

          <div
            class="input-box"
            style="
              margin-right: 10px !important;
              cursor: pointer;
              position: relative;
            "
          >
            <!-- 覆盖原日期选择框，阻止自动弹出键盘 -->
            <div class="dateCover flex" @click="visible = true">
              <div>{{ datetime[0] == null ? "开始时间" : datetime[0] }}</div>
              -
              <div>{{ datetime[1] == null ? "结束时间" : datetime[1] }}</div>
            </div>
            <a-range-picker
              :popup-visible="visible"
              @change="onChange"
              @select="onSelect"
              :disabledDate="(current) => dayjs(current).isAfter(dayjs())"
              style="
                width: 240px;
                border-radius: 50px;
                border-color: rgba(0, 0, 0, 0);
              "
            />
          </div>
          <div
            class="input-box reset-btn"
            @click="(search = {}), (datetime = [date, date]), (detail = null)"
            style="height: 32px; line-height: 32px; width: 150px"
          >
            <icon-sync />
            重置
          </div>
        </div>
      </div>
    </div>

    <!-- 中间 -->

    <div :style="{ height: screenHeight - 55 + 'px' }">
      <div class="box_1" v-if="detail">
        <div class="left_user_data" @click="visible = false">
          <div style="display: flex">
            <div class="name-box1">
              {{
                detail.personnelName.substring(detail.personnelName.length - 2)
              }}
            </div>
            <div class="name-box2">
              <div class="flex" style="justify-content: flex-start">
                {{ detail.personnelName }}
                <div class="user-years">{{ detail.workingYears }}</div>
              </div>
              <div style="line-height: 28px; margin-top: 5px">
                {{ detail.entryDate }}
              </div>
            </div>
          </div>
          <div class="explain">
            <div class="left_title">员工编号：</div>
            <div class="right_explain">{{ detail.personnelNo }}</div>
          </div>
          <div class="border"></div>
          <div class="explain">
            <div class="left_title">员工卡号：</div>
            <div class="right_explain">{{ detail.cardNo }}</div>
          </div>
          <div class="border"></div>
          <div class="explain">
            <div class="left_title">所属车间：</div>
            <div class="right_explain">{{ detail.workShopName }}</div>
          </div>
          <div class="border"></div>
          <div class="explain">
            <div class="left_title">组别名称：</div>
            <div class="right_explain">{{ detail.structureName }}</div>
          </div>
          <div class="border"></div>
          <div class="explain">
            <div class="left_title">12月平均出勤工时：</div>
            <div class="right_explain">{{ detail.workingHoursOfTheMonth }}</div>
          </div>
          <div class="border"></div>
          <div class="explain">
            <div class="left_title">12月合计收入：</div>
            <div class="right_explain">{{ detail.incomeOfTTheMonth }}</div>
          </div>
        </div>
        <div class="right_echarts" style="padding:9px 11px">
          <div class="echarts">
            <div class="histogram-box">
              <div id="histogram-chart1" :style="{ height: '339px' }"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="box_2" v-if="detail">
        <div class="left_user_data">
          <div class="title">
            <div class="yf">12月本车间工资排行</div>
            <div class="pm">
              当前排名 <span>{{ wagesRanking.currentRanking }}</span>
            </div>
          </div>
          <div class="detail">
            <div v-for="(item, i) in wagesRanking.ranking" :key="item">
              <div class="rank">
                <div class="icon" style="text-align: center;" :class="'icon' + i + ' '">
                  {{ i + 1}}
                </div>
                <div class="name">{{ item.personnelName }}</div>
                <div class="right" style="right: 40px">
                  {{ item.structureName }}
                </div>
              </div>
              <div class="border"></div>
            </div>
          </div>
        </div>
        <div class="right_echarts" style="padding-left:11px;">
          <div id="pie-chart" :style="{ width: '459px', height: '339px' }"></div>
          <div
            class="lengend-box flex jb_c"
            v-if="flaw.length > 0"
            style="margin-right: 20px"
          >
            <div
              class="flex lengend-item"
              v-for="(item, index) in flaw"
              :key="index"
            >
              <div
                class="circle"
                :style="{ backgroundColor: color[index] }"
              ></div>
              <div class="lengend-desc">
                <div>{{ item.flawSubName }}</div>
                <div>
                  出现次数：<span style="color: #d97b62">{{
                    item.flawSubNum
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="swiper-slide" style="height:100%">
    <div class="box_3" v-if="detail">
      <!-- 表格 -->

      <div
        @click="visible = false"
        style="width: 100%; padding-right: 20px; margin-top: 10px"
        v-if="detail != null"
      >
        <div class="title-condition salary"  :style="{height:screenHeight - 40 + 'px'}">
          <div class="title" style="margin-left: 0">
            工资明细
            <div
              class="right"
              @click="changeShow"
              type="outline"
              style="
                margin-top: -21px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              全部{{ show ? "收起" : "展开" }}&nbsp;&nbsp;
              <icon-right-circle v-show="show" :style="{ fontSize: '24px' }" />
              <icon-down-circle v-show="!show" :style="{ fontSize: '24px' }" />
            </div>
          </div>
          <div>
            <a-table
              :style="{height:screenHeight - 100 + 'px'}"
              :scroll="{ y: screenHeight - 100 }"
              :hoverable="true"
              :stripe="true"
              :bordered="false"
              v-if="list.length > 0"
              :pagination="false"
              :columns="columns"
              :data="list"
              :expandable="expanded"
              @expand="expandicon"
              class="salaryTable"
            >
              <template #columns>
                <a-table-column
                  width="70"
                  class="column"
                  title="序号"
                  align="center"
                  data-index="index"
                ></a-table-column>
                <a-table-column title="生产单号">
                  <template #cell="{ record }">
                    <a-tooltip :content="record.orderFormNo">
                      <a-button class="ellipsis" style="width: 100%">{{
                        record.orderFormNo
                      }}</a-button>
                    </a-tooltip>
                  </template>
                </a-table-column>
                <!-- <a-table-column width="122" title="工序名称">
								<template #cell="{ record }">
									<a-tooltip :content="record.processName">
										<a-button class="ellipsis" style="width: 100%">{{record.processName}}</a-button>
									</a-tooltip>
								</template>
							</a-table-column> -->
                <a-table-column
                
                  title="销售订单号"
                  data-index="groupName"
                >
                  <template #cell="{ record }">
                    <a-tooltip :content="record.cusOrderNo">
                      <a-button class="ellipsis" style="width: 100%">{{
                        record.cusOrderNo
                      }}</a-button>
                    </a-tooltip>
                  </template>
                </a-table-column>
                <a-table-column title="款号" data-index="groupName">
                  <template #cell="{ record }">
                    <a-tooltip :content="record.styleName">
                      <a-button class="ellipsis" style="width: 100%">{{
                        record.styleName
                      }}</a-button>
                    </a-tooltip>
                  </template>
                </a-table-column>
                <a-table-column width="130" title="部件">
                  <template #cell="{ record }">
                    <a-tooltip :content="record.unitName">
                      <a-button class="ellipsis" style="width: 100%">{{
                        record.unitName
                      }}</a-button>
                    </a-tooltip>
                  </template>
                </a-table-column>
                <a-table-column
                  width="100"
                  title="工序编号"
                  data-index="processNo"
                >
                  <template #cell="{ record }">
                    <a-tooltip :content="record.processNo">
                      <a-button class="ellipsis" style="width: 100%">{{
                        record.processNo
                      }}</a-button>
                    </a-tooltip>
                  </template>
                </a-table-column>
                <a-table-column
                  width="100"
                  title="工序名称"
                  data-index="groupName"
                >
                  <template #cell="{ record }">
                    <a-tooltip :content="record.processName">
                      <a-button class="ellipsis" style="width: 100%">{{
                        record.processName
                      }}</a-button>
                    </a-tooltip>
                  </template>
                </a-table-column>
                <a-table-column
                  title="完成数量"
                  data-index="groupName"
                >
                  <template #cell="{ record }">
                    <a-tooltip :content="record.num">
                      <a-button class="ellipsis" style="width: 100%">{{
                        record.num
                      }}</a-button>
                    </a-tooltip>
                  </template>
                </a-table-column>
                <a-table-column  title="工资" data-index="groupName">
                  <template #cell="{ record }">
                    <a-tooltip :content="record.price">
                      <a-button class="ellipsis" style="width: 100%">{{
                        record.price
                      }}</a-button>
                    </a-tooltip>
                  </template>
                </a-table-column>
              </template>
            </a-table>
            <div class="none" style="margin-top: 99px" v-else>
              <div>
                <div class="noData">
                  <img class="noData-Bg" src="../../assets/bg/none-bg.png" />
                  <img class="noData-icon" src="../../assets/bg/none.png" />
                </div>
                <div class="tips">
                  <div class="bold">什么都没有</div>
                  换个条件试试吧
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
    

    
    <div id="histogram-chart" :style="{ height: '350px' }" v-show="false"></div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getList, getStaffMsg, getChart } from "../../api/salary.js";
import Swiper from "swiper";
export default {
  setup() {
    return {
      dayjs,
    };
  },
  data() {
    return {
      ref: true,
      show: true, //展开
      screenHeight: document.documentElement.clientHeight, //获取屏幕高度
      screenWidth: document.body.clientWidth, //获取屏幕宽度
      wagesRanking: {}, //排名
      date: "",
      salaryList: [],
      histogram: null,
      flaw: [], //疵点
      search: {personnelNo:'000223'}, //搜索信息
      detail: null, //个人工资信息
      datetime: [],
      color: [
        "#3685F7",
        "#5CCCE8",
        "#5564F6",
        "#2EF867",
        "#F19A3D",
        "#F1D94E",
        "#A2BBD7",
        "#00D1BE",
        "#F8DFF9",
        "#AFB6FB",
      ], //饼状图颜色
      visible: false,
      //表头
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          align: "center",
          width: 90,
        },
        {
          title: "生产单号",
          dataIndex: "orderFormNo",
          width: 130,
        },
        {
          title: "工序名称",
          dataIndex: "processName",
          width: 130,
        },
        {
          title: "销售订单号",
          dataIndex: "cusOrderNo",
          width: 130,
        },
        {
          title: "款号",
          dataIndex: "styleName",
          ellipsis: true,
        },
        {
          title: "部件",
          dataIndex: "unitName",
          ellipsis: true,
        },
        {
          title: "工序编号",
          dataIndex: "processNo",
          width: 130,
        },
        {
          title: "工序名称",
          dataIndex: "processName",
          width: 130,
        },
        {
          title: "完成数量",
          dataIndex: "num",
          width: 200,
          align: "right",
        },
        {
          title: "工资",
          dataIndex: "price",
          width: 200,
          align: "right",
        },
      ],
      // 工资列表信息
      list: [],
      id: 0,
      expanded: {
        expandedRowKeys: [0],
      }, //显示的行
    };
  },
  mounted() {
    var swiper = new Swiper('.swiper-container', {

      direction: 'vertical',
      paginationClickable:true, //分页器点击

      autoplayDisableOnInteraction: false, //点击不会取消自动
    });
    // 系统默认时间
  window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    var date = new Date();
    var year = date.getFullYear();
    var month =
      date.getMonth() + 1 > 10
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    date = year + "/" + month + "/" + day;
    this.date = date;
    this.datetime = [date, date];

    // 获取屏幕高度
    const that = this;
    window.screenHeight = document.documentElement.clientHeight;
    that.screenHeight = window.screenHeight;
    this.getDetail()
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
        window.screenHeight = document.documentElement.clientHeight;
        that.screenHeight = window.screenHeight;
        console.log(window.screenWidth);
      })();
    };
  },
  
  // beforeRouteEnter(to, from, next) {
  // 	next(vm => { //  这里的vm指的就是vue实例，可以用来当做this使用
  // 		if (from.fullPath != '/' ) {
  // 			vm.$router.go(0);
  // 		}
  // 	})
  // },
  methods: {


    handleScroll () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        console.log(scrollTop, '滚动距离')
    },

    changeShow() {
      this.show = !this.show;
      if (this.show) {
        console.log(this.salaryList.length);
        for (let i = 0; i < this.salaryList.length; i++) {
          this.expandicon(i);
        }
      } else {
        this.PutAway();
      }
    },
    // 折叠展开图标点击事件
    expandicon(expanded) {
      console.log(expanded);
      var list = this.expanded.expandedRowKeys;
      console.log("list");
      console.log(list);
      var index = list.indexOf(expanded);
      console.log(index == -1);
      if (index == -1) {
        list.push(expanded);
        this.expanded = {
          expandedRowKeys: list,
        };
      } else if (list.length == 1) {
        this.expanded = {
          expandedRowKeys: [],
        };
      } else {
        list.remove(expanded);
        this.expanded = {
          expandedRowKeys: list,
        };
      }
    },
    PutAway() {
      this.expanded = {
        expandedRowKeys: [],
      };
    },
    // 获取个人工资
    getDetail() {
      this.$refs.inputText.blur();
      var data = this.search;
      data.BeginTime = this.datetime[0].replace(/\//g, "-");
      data.EndTime = this.datetime[1].replace(/\//g, "-");
      getStaffMsg(this.search).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.detail = res.data;
          this.getChart(res.data.personnelId);
          this.id = res.data.personnelId;
          this.getSalaryList(res.data.personnelId);
        }
      });
    },
    // 获取chart数据
    getChart(id) {
      getChart({
        personnelId: id,
      }).then((res) => {
        console.log(res);
        this.wagesRanking = res.data.wagesRanking;
        var attendance = res.data.attendance;
        var date = [];
        var hour = [];
        var b = [];
        for (var i = 0; i < attendance.length; i++) {
          date.push(attendance[i].date);
          hour.push(attendance[i].hour);
        }
        var flaw = res.data.flaw;
        this.flaw = flaw;
        for (var i = 0; i < flaw.length; i++) {
          b.push({
            value: flaw[i].flawSubNum,
            name: flaw[i].flawSubName,
          });
        }
        var a = {
          date,
          hour,
        };
        console.log("chart");
        console.log(a);
        this.drawLine(a, b);
      });
    },
    onChange(e) {
      e[0] = e[0].replace(/-/g, "/");
      e[1] = e[1].replace(/-/g, "/");
      this.datetime = e;
      this.visible = false;
      this.getDetail();
    },
    // 获取工资列表
    getSalaryList(id) {
      getList({
        personnelId: id,
        BeginTime: this.datetime[0].replace(/\//g, "-"),
        EndTime: this.datetime[1].replace(/\//g, "-"),
      }).then((res) => {
        var list = [];
        var salaryList = res.data;
        this.salaryList = salaryList;
        for (var i = 0; i < salaryList.length; i++) {
          for (let j = 0; j < salaryList[i].info.length; j++) {
            salaryList[i].info[j].index = j + 1 > 9 ? j + 1 : "0" + (j + 1);
          }
          list.push({
            key: i,
            orderFormNo: salaryList[i].time,
            num: "累计完成产量：" + salaryList[i].totalNum,
            price: "合计工 资：￥" + salaryList[i].totalPrice,
            children: salaryList[i].info,
          });
        }
        console.log("list");
        console.log(list);
        this.list = list;
      });
    },

    // eacharts
    drawLine(a, flaw) {
      // 基于准备好的dom，初始化echarts实例
      var histogram = this.histogram;
      if (this.histogram != null) {
        this.histogram.dispose();
        histogram.dispose();
      }
      histogram = this.$echarts.init(
        document.getElementById("histogram-chart")
      ); //大分辨率的柱状图
      let histogram1 = this.$echarts.init(
        document.getElementById("histogram-chart1")
      ); //小分辨率柱状图
      let pie = this.$echarts.init(document.getElementById("pie-chart")); //饼状图
      let that = this;
      // 绘制图表
      histogram.setOption({
        title: {
          show: true,
          text: "近15日个人出勤工时",
          textStyle: {
            fontSize: 16,
            fontWeight: "bolder",
            color: "#000", // 主标题文字颜色
          },
        },
        grid: {
          top: 50,
          left: 10,
          right: 10,
          bottom: 15,
          containLabel: true,
        },
        tooltip: {},
        xAxis: {
          data: a.date,
          type: "category",
          axisLabel: {
            interval: 0,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            //x轴刻度相关设置
            alignWithLabel: true,
          },
        },
        yAxis: {
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "工时",
            type: "pie",
            data: a.hour,
            barWidth: 8,
            radius: [20, 250],
            itemStyle: {
              normal: {
                barBorderRadius: [20, 20, 0, 0],
                color: that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 1,
                    color: "#03C9FD",
                  },
                  {
                    offset: 0,
                    color: "#0A93FB",
                  },
                ]),
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    //数值样式
                    color: "#2189F3",
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      });
      this.histogram = histogram;
      document
        .getElementById("histogram-chart")
        .setAttribute("_echarts_instance_", "");
      histogram1.setOption({
        title: {
          text: "近15日个人出勤工时",
          textStyle: {
            fontSize: 14,
            fontWeight: "bolder",
            color: "#3B75FD", // 主标题文字颜色
          },
        },
        grid: {
          top: 50,
          left: 30,
          right: 30,
          bottom: 30,
          containLabel: true,
        },
        tooltip: {},
        xAxis: {
          data: a.date,
          type: "category",
          axisLabel: {
            interval: 0,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            //x轴刻度相关设置
            alignWithLabel: true,
          },
        },
        yAxis: {
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "工时",
            type: "bar",
            data: a.hour,
            barWidth: 8,
            itemStyle: {
              normal: {
                barBorderRadius: [20, 20, 0, 0],
                color: that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 1,
                    color: "#03C9FD",
                  },
                  {
                    offset: 0,
                    color: "#0A93FB",
                  },
                ]),
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    //数值样式
                    color: "#2189F3",
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      });
      document
        .getElementById("histogram-chart1")
        .setAttribute("_echarts_instance_", "");
      pie.setOption({
        title: {
          show: true,
          text: "近7日个人瑕疵点分析",
          textStyle: {
            fontSize: 14,
            fontWeight: "bolder",
            color: "#3B75FD", // 主标题文字颜色
          },
        },
        color: this.color,
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} %",
        },
        series: [
          {
            name: "瑕疵点分析",
            type: "pie",
            barWidth: 5,
            center: ["60%", "50%"],
            radius: ["20%", "80%"], //  第一个为环形图的内半径 ，第二个值为环形图的外半径
            data: flaw,
            itemStyle: {
              borderRadius: 18,
            },
            label: {
              normal: {
                show: false,
                position: "center", // 设置居中
                formatter: function (data) {
                  // 设置圆饼图中间文字排版
                  return (
                    "{value|" +
                    data.value +
                    "%}" +
                    "\n" +
                    "{name|" +
                    data.name +
                    "}"
                  ); // 对应的名字和值
                },
                textStyle: {
                  fontSize: "0",
                },
              },
              emphasis: {
                show: false, // 文字至于中间时，这里需为true
                textStyle: {
                  // 设置文字样式
                  fontSize: "18",
                  color: "#333",
                  fontWeight: 700,
                },
                rich: {
                  value: {
                    fontSize: "18",
                    color: "#333",
                    fontWeight: 700,
                  },
                  name: {
                    fontSize: "14",
                    color: "rgba(0,0,0,0.3)",
                    lineHeight: 28,
                  },
                },
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="less">
@import url("../../styles/productionTask.less");

// @import url("../styles/all.less");
.table-bg {
  margin: 5px;
  // width: 95%;
  padding: 0 16px 10px;
  // height: 722px;
  overflow: hidden;
  background: #ffffff66;
  box-shadow: 1px 3px 8px 1px #336bef29;
  border-radius: 18px 18px 18px 18px;
  border-image: linear-gradient(180deg, #ffffff00, #ffffff) 2 2;
  padding-top: 10px;
}

/deep/ .arco-table-header {
  background-color: #ffffff00 !important;
}

/deep/ .arco-table-body {
  background-color: #ffffff00 !important;
  min-height: 280px;
}

/deep/ .arco-table-body::-webkit-scrollbar {
  width: 0;
}

/deep/ .arco-table-stripe .arco-table-tr:nth-child(even) .arco-table-td {
  background-color: #fafafa99 !important;
}

/deep/ .arco-table-td {
  background-color: #fafafa00 !important;
}

/deep/ .arco-table-th {
  background-color: #ffffff00 !important;
}

/deep/ .arco-table-cell {
  padding: 9px 0 9px 8px !important;
  font-weight: 500;
  font-size: 16px;
  height: 38px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #00000080;
  line-height: 38px;
  display: flex;
  align-items: center;

  span {
    padding-left: 0 !important;
  }

  .arco-table-th-item-title {
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 700;
    color: #000000;
    line-height: 19px;
    text-align: center;
  }
}

/deep/ .arco-btn-size-medium {
  padding: 0 15px 0 0 !important;
}

.arco-form-size-medium {
  position: relative;
  width: 100%;

  .right {
    position: absolute;
    // right: 200px;
    display: flex;
    align-items: center;
    bottom: 0;
  }
}

.arco-input-wrapper {
  width: 206px;
  height: 48px;
  border-radius: 99px;
  background-color: #2189f300 !important;
  border: 1px solid #ffffff20;
  filter: blur(undefinedpx);
  color: #2189f3;
}

/deep/ .arco-icon-hover::before {
  background-color: #2189f3;
}

/deep/ .arco-icon-hover {
  background-color: #2189f3;
}

/deep/ .arco-icon-close {
  color: #ffffff;
  background-color: #2189f3;
  border-radius: 50%;
}

/deep/ .arco-btn-size-large {
  height: 48px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  opacity: 1;
  filter: blur(undefinedpx);
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #2189f3;
}

/deep/ .arco-btn-outline:hover {
  background-color: #00000000 !important;
  background: #00000000 !important;
}

/deep/ .arco-btn-outline:active {
  background-color: #00000000 !important;
  background: #00000000 !important;
}

/deep/ .arco-picker-input input {
  display: none !important;
}

/deep/ .arco-picker {
  background-color: #ffffff00;
  border: 1px solid #ffffff20 !important;
}

.dateCover {
  position: absolute;
  top: 3px;
  right: 24px;
  width: 220px;
  z-index: 999;
  /* background-color: #00B42A; */
  color: #000000;
  height: 40px;
  line-height: 40px;
  text-align: end;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #2189f3;
  line-height: 19px;

  div {
    width: 100px;
    text-align: center;
  }
}

/deep/ .arco-table-content {
  margin-left: -40px;
}

/deep/.salaryTable
  .arco-spin
  .arco-table-container
  .arco-table-content
  .arco-table-body::-webkit-scrollbar {
  width: 4px !important;
}

/deep/.salaryTable
  .arco-spin
  .arco-table-container
  .arco-table-content
  .arco-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

/deep/.salaryTable
  .arco-spin
  .arco-table-container
  .arco-table-content
  .arco-table-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.1) !important;
}

/deep/.salaryTable
  .arco-spin
  .arco-table-container
  .arco-table-content
  .arco-table-header {
  background-color: rgba(0, 0, 0, 0) !important;
}

.arco-picker-separator {
  display: none !important;
}

/deep/ .arco-input::-webkit-input-placeholder {
  color: #1f1f1f40 !important;
}

/deep/ .arco-input:-moz-placeholder {
  color: #1f1f1f40 !important;
}

/deep/ .arco-input::-moz-placeholder {
  color: #1f1f1f40 !important;
}

/* For the future */
/deep/ .arco-input:-ms-input-placeholder {
  color: #1f1f1f40 !important;
}

/deep/ input::-webkit-input-placeholder {
  color: #1f1f1f40 !important;
}

/deep/ input:-moz-placeholder {
  color: #1f1f1f40 !important;
}

/deep/ input::-moz-placeholder {
  color: #1f1f1f40 !important;
}

/* For the future */
/deep/ input:-ms-input-placeholder {
  color: #1f1f1f40 !important;
}

/deep/ .arco-btn-secondary {
  display: block !important;
}

/deep/ .arco-icon-plus {
  background-image: url(../../assets/icon/collapse-bottom.png);
  background-size: 100% 100%;
  width: 20px !important;
  height: 20px !important;
  color: #ffffff00;
}

/deep/ .arco-icon-minus {
  background-image: url(../../assets/icon/collapse-right.png);

  background-size: 100% 100%;
  width: 20px !important;
  height: 20px !important;
  color: #ffffff00;
}

/deep/ .arco-table-expand-btn {
  background-color: #ffffff00 !important;
  // background-size: 100% 100%;
}

/deep/ .arco-input-focus {
  border: 1px solid #2189f3 !important;
  background-color: #ffffff !important;
}

/deep/ .arco-input-wrapper:focus-within {
  border: 1px solid #2189f3 !important;
  background-color: #ffffff !important;
}

/deep/ .arco-input-size-medium {
  font-weight: 700 !important;
}

/deep/ .arco-input-size-medium::-webkit-input-placeholder {
  font-weight: 500;
}

/deep/ .arco-input-size-medium:-moz-placeholder {
  font-weight: 500;
}

/deep/ .arco-input-size-medium::-moz-placeholder {
  font-weight: 500;
}

/* For the future */
/deep/ .arco-input-size-medium:-ms-input-placeholder {
  font-weight: 500;
}

.container-box {
  // height: 100%;
  width: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.container-box::-webkit-scrollbar {
  width: 4px;
}

.container-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.arco-dropdown-list-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

/deep/ .arco-table-tr-expand {
  display: none;
}

.title /deep/ .arco-btn-size-medium {
  width: 141px;
  height: 50px;
  background: rgba(255, 255, 255, 0.20000000298023224);
  box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.1599999964237213);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 2px solid #ffffff;
  color: #2189f3;
  position: absolute;
  right: 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: -20px;
}

/deep/ tbody .arco-table-tr {
  background-color: #0746cc10;
}

/deep/ .arco-table-th {
  background-color: #ffffff00;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 700;
  color: #000000;
  line-height: 19px;
  height: 56px;
}

/deep/ .arco-table-td {
  border: none;
  background-color: #ffffff00;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #00000080;
  line-height: 19px;
}

/deep/ .arco-table-tr {
  height: 56px;
}

/deep/ .arco-input-wrapper {
  color: #2189f3;
  height: 48px !important;
}

/deep/ .arco-table-size-mini .arco-table-cell {
  padding: 2px 6px;
  width: auto;
}

.none {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.noData {
  width: 246px;
  height: 209px;
  position: relative;

  .noData-Bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 246px;
    height: 209px;
  }

  .noData-icon {
    width: 140px;
    height: 126px;
    position: absolute;
    top: 55px;
    left: 63px;
  }
}

.flaw {
  width: 100%;
  height: 350px;

  .noData {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    width: 100%;
  }
}

.tips {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
  line-height: 24px;
  margin-top: 30px;
  text-align: center;

  .bold {
    font-weight: 800;
  }
}

.salary {
  .title {
    margin-left: 16px;
    margin-bottom: 24px;
    position: relative;

    .right {
      width: 141px;
      height: 50px;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.16);
      border-radius: 20px 20px 20px 20px;
      opacity: 1;
      border: 2px solid #ffffff;
      font-size: 18px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #2189f3;
      right: 0px;
      line-height: 50px;
      text-align: center;
    }
  }
}

/deep/ .arco-table-expand {
  width: 10px !important;
}
</style>
<style scoped="">
.ellipsis {
  border: none !important;
  color: #616c72 !important;
  overflow: hidden;
  background-color: #ffffff00 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start !important;
  width: 100% !important;
  padding-right: 10px !important;
}

.ellipsis:hover {
  background-color: #ffffff00;
}

.flex {
  display: flex;
  align-items: center;
}

/* 头部标题-导出 */
.header-box {
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.4000000059604645);
  box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.1599999964237213);
  border-radius: 18px;
  padding: 24px 16px;
  margin-bottom: 20px;
}

.header-box div:first-child {
  color: rgba(0, 0, 0, 0.4000000059604645);
  font-size: 24px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
}

.header-box div:nth-child(2) {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #2189f3;
  padding: 10px 15px;
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.1599999964237213);
}

.title-condition .title {
  color: #3b75fd;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  padding-left: 16px;
}

.condition-box {
  display: flex;
  align-items: center;
}

.input-box {
  width: auto !important;
  /* border: 1px solid #ffffff; */
  border-radius: 50px;
  overflow: hidden;
  margin-right: 20px;
  box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.1);
  color: #2189f3;
  text-align: start;
}

.input-box .arco-input-wrapper {
  border-radius: 50px;
  /* background: #FFF!important;
		border-color: #2189f3; */
}

.input-box .arco-input-wrapper:hover {
  border-color: #2189f3;
  background: #fff !important;
}

.input-box .arco-input-wrapper .arco-input::placeholder {
  color: #888888 !important;
  font-size: 16px;
  font-weight: 400;
  font-family: "SourceHanSansCN";
  text-align: left;
}

.reset-btn {
  display: flex;
  align-items: center;
  color: #2189f3;
  padding: 8px 15px;
  background: rgba(0, 0, 0, 0.03);
}

/* 基本信息 */
.user-box {
  margin-top: 20px;
}

.user-info {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 11px 12px 15px 1px rgba(51, 107, 239, 0.15);
  border-radius: 20px;
  height: 114px;
  padding: 10px 20px;
  border-image: linear-gradient(
      134deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    )
    2 2;
}

.user-name {
  width: 20%;
  justify-content: flex-start;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 1400px) {
  .user-name {
    width: 200px;
    justify-content: flex-start;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.user-name .name-box1 {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background: #3b75fd;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
  margin-right: 10px;
}

.user-name .name-box2 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.user-name .user-years {
  background: linear-gradient(47deg, #ffa834 0%, #ff8c16 100%);
  border-radius: 10px 10px 10px 2px;
  padding: 2px 14px;
  color: #ffffff;
  margin-left: 10px;
}

.user-company {
  flex: 1;
  justify-content: flex-end;
  color: #000;
}

.company-box {
  width: 31%;
}

@media (max-width: 1400px) {
  .company-box {
    width: 45%;
  }
}

.company-box .bold {
  font-weight: 700;
}

.company-box div {
  text-align: start;
  font-size: 16px;
  line-height: 19px;
  margin: 20px 0;
  font-weight: 500;
  white-space: nowrap;
}

.company-box div span {
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 400;
}

.income-box {
  color: #2189f3;
  justify-content: flex-start;
}

.income-number div:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 10px;
  text-align: left;
}

.income-number div:nth-child(2) {
  color: #2189f3;
  font-size: 36px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  text-align: left;
}

/* echarts */
.histogram-box {
  padding: 20px 10px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 11px 12px 15px 1px rgba(51, 107, 239, 0.15);
  border-radius: 20px;
  position: relative;
}

.lengend-box {
  width: 320px !important;
  flex-wrap: wrap;
  justify-content: flex-end;
  overflow-y: scroll;
  height: 220px;
  position: absolute;
  right: 0;
  bottom: 47px;
}

.lengend-box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0;
  /*高宽分别对应横竖滚动条的尺寸*/
}

.lengend-item {
  margin-bottom: 40px !important;
  text-align: left;
  margin: 5px 0;
  align-items: flex-start;
}

.lengend-item .circle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #2189f3;
  margin-right: 10px;
  margin-top: 5px;
}

.lengend-desc {
  width: 140px;
}

.lengend-desc div:first-child {
  font-size: 16px;
  color: #333;
}

.lengend-desc div:nth-child(2) {
  color: #999;
  margin-top: 5px;
  font-size: 14px;
}

/* 工资排行 */
.salary-ranking .title {
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 700;
  color: #3b75fd;
  line-height: 19px;
  text-align: start;
}

.person-rank {
  /* width: 324px; */
  height: 68px;
  background: linear-gradient(47deg, #ffa834, #ff8c16);
  box-shadow: 0px 5px 8px 1px #3b75fd0d;
  border-radius: 20px;
  color: #ffffff;
  text-align: start;
  padding: 11px 0 0 24px;
  margin-top: 13px;
}

.person-rank img {
  width: 76px;
  height: auto;
}

.person-rank div {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #fefefe;
  line-height: 16px;
}

.rank {
  width: 348px;
  height: 56px;
  background: rgba(255, 255, 255, 0.20000000298023224);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid #ffffff20;
  filter: blur(undefinedpx);
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.rank .icon0 {
  width: 34px;
  height: 41px;
  background-image: url("../../assets/icon/rank.png");
  background-size: 100%;
  margin: 0 16px;
  font-size: 12px;
  line-height: 22px;
  margin-top: 10px;

}

.rank .icon1 {
  width: 34px;
  height: 41px;
  background-image: url("../../assets/icon/rank2.png");
  background-size: 100%;
  margin: 0 16px;
  font-size: 12px;
  line-height: 22px;
  margin-top: 10px;
}

.rank .icon2 {
  width: 34px;
  height: 41px;
  background-image: url("../../assets/icon/rank3.png");
  background-size: 100%;
  margin: 0 16px;
  font-size: 12px;
  line-height: 22px;
  margin-top: 10px;
}

.rank .name {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 19px;
  width: 180px;
  text-align: start;
}

.rank .right {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 19px;
  width: 80px;
  right: 0 !important;
}

.salary {
  /* width: 1720px; */
  height: 379px;
  background: rgba(255, 255, 255, 0.20000000298023224);
  box-shadow: 1px 3px 8px 1px rgba(51, 107, 239, 0.1599999964237213);
  border-radius: 20px 20px 20px 20px;
  text-align: start;
  padding: 24px 10px 24px 16px;
}

.input-box .arco-input-wrapper {
  height: 32px;
}

@media (max-width: 1400px) {
  .company-box3 {
    display: none;
  }

  .none {
    padding: 0 !important;
  }
}

@media (min-width: 1400px) {
  .blod1280 {
    display: none;
  }

  .none {
    display: none;
  }
}

.input-number {
  width: 150px;
  height: 44px;
  background: rgba(255, 255, 255, 0.20000000298023224);
  opacity: 1;
  border: 1px solid #ffffff20;
  filter: blur(undefinedpx);
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #2189f3;
  border-radius: 22px;
  outline: none;
}
.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
</style>

<style scoped lang="less">
@import url("../../styles/productionTask.less");
#salsryQuery {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  .head {
    position: fixed;
    top: 0;
    z-index: 10;
    height: 50px;
    background: #ffffff;
    display: flex;
    width: calc(100% - 60px);
    margin-left: 12px;
    padding: 0 7px 0 27px;
    border-radius: 4px 4px 4px 4px;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #3b75fd;
    }
    .form_screen {
      .condition-box {
        display: flex;
        border-radius: 0 !important;
        height: 32px !important;
        align-items: center;
        .input-box {
          height: 32px !important;
          line-height: 32px !important;
          border-radius: 0 !important;
          .arco-input-wrapper {
            height: 100% !important;
            background: #f2f3f5 !important;
            border: none !important;
            border-radius: 0 !important;
          }
          .dateCover {
            height: 32px !important;
            background: #f2f3f5 !important;
            line-height: 32px !important;
            border-radius: 0 !important;
            top: 0;
            width: 100% !important;
            right: 0;
          }
          /deep/ .arco-picker-size-medium {
            height: 32px !important;
            background-color: #ffffff00 !important;
            padding: 0 !important;
            margin-top: -5px;
            .arco-picker-suffix {
              //   display: none;
            }
          }
        }
        .reset-btn {
          padding: 0 14px !important;
        }
      }
    }
  }

  .box_1 {
    margin: 58px 12px 0 12px;
    display: flex;
    height: 47%;
  }
  .left_user_data {
    width: 257px;
    height: 100%;
    padding: 9px 11px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;

    .name-box1 {
      background: #3b75fd;
      border-radius: 8px 8px 8px 8px;
      width: 59px;
      height: 59px;
      line-height: 59px;
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
    }
    .name-box2 {
      margin-top: 6px;
      margin-left: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      .user-years {
        background: linear-gradient(47deg, #ffa834 0%, #ff8c16 100%);
        border-radius: 10px 10px 10px 2px;
        padding: 2px 14px;
        color: #ffffff;
        margin-left: 10px;
      }
    }

    .explain {
      display: flex;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #4e5969;
      padding: 15px 5px 12px 1px;
      justify-content: space-between;
      .left_title {
        text-align: left;
      }
      .right_explain {
        text-align: right;
      }
    }
    .border {
      width: 100%;
      height: 1px;
      background: #4e5969;
      opacity: 0.04;
    }
  }

  .right_echarts {
    margin-left: 8px;
    width: calc(100% - 297px);
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    height: 100%;
    .echerts_title {
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #4e5969;
    }
    .echarts {
      width: 100%;
      height: 100%;
      .histogram-box {
        box-shadow: none;
        width: 100%;
        padding: 0px !important;
      }
      .chart {
        width: 100%;
        height: 100%;
      }
    }
  }

  .box_2 {
    margin: 8px 12px 0 12px;
    display: flex;
    height: 47%;
    margin-top: 2.5%;
    .left_user_data {
      width: 282px;
      height: 100%;
      border-radius: 4px 4px 4px 4px;
      padding: 0;
      .title {
        padding: 0 11px;
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(180deg, #ffebce 0%, #ffffff 100%);
        .yf {
          color: #ff9243;
          font-size: 16px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 600;
        }
        .pm {
          font-size: 16px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #4e5969;
          span {
            font-size: 20px;
            font-family: PangMenZhengDao-Regular, PangMenZhengDao;
            font-weight: 400;
            color: #ff9243;
            margin-top: 4px;
          }
        }
      }
      .detail {
        .rank {
          width: 100%;
          display: flex;
          height: 48px;
          align-items: center;
          line-height: 48px;
          margin: 5px;
          //   justify-content: space-between;
        }
        .name {
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          width: auto !important;
        }
        .icon {
          font-size: 16px;
          font-family: PangMenZhengDao-Regular, PangMenZhengDao;
          font-weight: 400;
          color: #a9a9a9;
        }
        .right {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
      }
    }
    .right_echarts {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lengend-box {
        position: static;
      }
    }
  }

  .box_3 {
    margin: 8px 12px 12px 12px;
    /deep/.arco-table-expand-btn {
      width: 23px !important;
    }
    /deep/ .arco-table-th-title {
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #4e5969;
    }
    .title-condition {
      padding: 0;
      border-radius: 4px 4px 4px 4px !important;
      .title {
        padding: 0 8px;
        font-size: 16px;
        margin-bottom: 0;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #4e5969;
        background: #ffffff;
        height: 51px;
        line-height: 51px;
        .right {
          margin-top: -7px !important;
          margin-right: 10px;
          border: none;
          width: 98px;
          height: 36px;
          background: #3b75fd;
          border-radius: 2px 2px 2px 2px;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  
  }
}
// @import url("../styles/all.less");
.table-bg {
  margin: 5px;
  // width: 95%;
  padding: 0 16px 10px;
  // height: 722px;
  overflow: hidden;
  background: #ffffff66;
  box-shadow: 1px 3px 8px 1px #336bef29;
  border-radius: 18px 18px 18px 18px;
  border-image: linear-gradient(180deg, #ffffff00, #ffffff) 2 2;
  padding-top: 10px;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
</style>

