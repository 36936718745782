<template>
	<div class="page">
		<div class="title">
			选择接收人
			<icon-close class="right" @click="$emit('close')" />
		</div>
		<div class="flex">
			<div class="choose">
				<a-input :style="{width:'320px'}" v-model="personnelName" ref="formRef"
					@press-enter="$refs.formRef.blur(),getUserList({structureId:null},1)" placeholder="请输入名字搜索"
					allow-clear>
					<template #prefix>
						<icon-search />
						<div class="search scrollbar" v-show="is_search">
							<div class="flex" :value="item" v-for="(item,index) in searchList" ::key="index"
								@click="$refs.formRef.blur(),putSearchChoose(item)">
								<a-avatar class="avatar" :size="40" :style="{ backgroundColor: '#3b75fd' }"
									shape="square">
									<img :src="'https://wis4cloud.wis.ltd' + item.avatar"
										v-if="item.avatar!=null&&item.avatar!=''" />
									{{item.avatar==null||item.avatar==''?item.personnelName.substring(item.personnelName.length-2):''}}
								</a-avatar>
								<div style="margin-left: 10px;">
									<div style="color: #333333;">{{item.personnelName}}</div>
									{{item.structureName}}
								</div>
							</div>
						</div>
					</template>
				</a-input>
				<div class="tagList">
					<a-tag closable v-for="item in chooseList" @close="closeTag(item,1)" class="tag" :key="item">
						{{item.personnelName}}
					</a-tag>
					<a-tag closable v-for="item in tagList" @close="closeTag(item,2)" class="tag" :key="item">
						{{item.personnelName}}
					</a-tag>
				</div>
				<div class="button">
					<a-button type="primary" @click="submit">提交{{count==0?'':'('+count+')'}}</a-button>
					<a-button @click="$emit('close')">取消</a-button>
				</div>
			</div>
			<div style="padding:24px 16px;width:45%;">
				<div class="flex" style="width:100%">
					<a-select @change="changeSelect" default-value="0" :placeholder="placeholder" style="width:120px;"
						v-if="is_select">
						<a-option v-for="(item,index) in factory" :value="index" :key="item">{{item.structureName}}
						</a-option>
					</a-select>
					<a-breadcrumb v-else>
						<a-breadcrumb-item v-for="(item,index) in breadcrumb" @click="changeBreadCrumb(item,index)"
							:key="item">{{item.name}}
						</a-breadcrumb-item>
					</a-breadcrumb>
				</div>
				<div style="margin-top:20px;height:300px;overflow-y: scroll;width:300px;overflow-x: hidden;"
					class="scrollbar">
					<!-- <a-checkbox-group direction="vertical" v-model="tagList"> -->
					<a-checkbox-group direction="vertical" v-show="is_item">
						<div class="flex checkbox" v-for="item in checkbox" :key="item" @click="getCheckbox(item)">
							<div style="width: 20px;">
								<!-- <a-checkbox :value="item"></a-checkbox> -->
							</div>
							<div>{{item.structureName}}</div>

						</div>
						<!-- <a-checkbox :value="item.structureId" v-for="item in checkbox" :key="item"
							>{{item.structureName}}
						</a-checkbox> -->
					</a-checkbox-group>
					<a-checkbox-group direction="vertical" @change="putSatffByItemChoose" v-model="tagList"
						v-show="!is_item">
						<div class="flex checkbox" v-for="item in checkbox" :key="item">
							<div style="width: 20px;">
								<a-checkbox :value="item" v-if="item.checkbox!=false" @click="item.checkbox=false">
								</a-checkbox>
							</div>
							<div>{{item.personnelName}}</div>

						</div>

					</a-checkbox-group>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getStructure,
		getPersonnel
	} from '../api/index.js'
	import {
		delAllUser,
		delUser,
		addUser,
		getUser
	} from '../api/daily.js'
	export default {
		name: 'data',
		props: {
			data: {},
			type: {}
		},
		data() {
			return {
				tagList: [], //通过分组获取人员列表选中的人
				breadcrumb: [], //面包屑列表
				checkbox: [], //复选框列表
				addList: [], //初始选中的人
				searchList: [], //搜索出来的人员列表
				factory: [], //部门列表
				placeholder: '',
				count: 0,
				is_item: true, //判断右侧复选框是否为部门
				personnelName: null, //名字搜索
				is_search: false, //是否搜索
				chooseList: [], //全部选中的人（不包含与复选框绑定的人）
				chooseFactory: true,
				is_select: true,
			};
		},
		mounted: function() {
			this.getFactory()
			console.log(this.type)
			// type 1 日报，2异常
			if (this.type == 1) {
				this.count = parseInt((this.chooseList == null ? 0 : this.chooseList.length) + (this.tagList == null ?
					0 : this.tagList.length))
				this.getUser()
			} else {
				var list = localStorage.getItem('userConfig')
				console.log(list)
				if (list != null && list != "undefined" && list != "") {
					this.chooseList = JSON.parse(list)
					console.log(this.chooseList)
					console.log(this.tagList)
					this.count = parseInt((this.chooseList == null ? 0 : this.chooseList.length) + (this.tagList ==
						null ? 0 : this.tagList.length))
				}
			}
		},
		methods: {
			// 搜索出来的人放入chooseList
			putSearchChoose(item) {
				this.is_search = false
				var a = false
				var b = false
				// 判断chooseList是否有该人员
				console.log(this.chooseList)
				if (this.chooseList!= null) {


					for (let i = 0; i < this.chooseList.length; i++) {
						if (this.chooseList[i].personnelId == item.personnelId) {
							return
						} else if (i + 1 == this.chooseList.length) {
							a = true
						}
					}
				}else{
					this.chooseList = []
				}
				// 判断tagList是否有该人员（右侧复选框绑定人员）''
				if (this.tagList!= null) {
					for (let i = 0; i < this.tagList.length; i++) {
						console.log(this.tagList[i].personnelId == item.personnelId)
						if (this.tagList[i].personnelId == item.personnelId) {
							return
						} else if (i + 1 == this.tagList.length) {
							b = true
						}
					}
				}

				// 两个列表都不存在
				if (a == true && b == true) {
					this.chooseList.push(item)
				}
				// tagList没有该人员且chooseList没有任何人员
				if (b == true && this.chooseList.length == 0) {
					this.chooseList.push(item)
				}
				// chooseList没有该人员且tagList没有任何人员
				if (a == true && this.tagList.length == 0) {
					this.chooseList.push(item)
				}
				//两个列表都为空
				if (this.chooseList.length == 0 && this.tagList.length == 0) {
					this.chooseList.push(item)
				}

				this.count = parseInt((this.chooseList == null ? 0 : this.chooseList.length) + (this.tagList == null ? 0 :
					this.tagList.length))
			},
			// 右侧列表放入chooseList
			putSatffByItemChoose(e) {
				console.log(this.chooseList)
				this.is_select = false
				this.tagList = []
				console.log(e)
				this.count = parseInt((this.chooseList == null ? 0 : this.chooseList.length) + e.length)
				for (let i = 0; i < e.length; i++) {
					// chooseList为空表示没有选中任何人员
					// if(this.chooseList.length==0){

					this.tagList.push(e[i])
					// }else{
					// 判断选中的人是否在chooseList内
					// 	for (let j = 0; j < this.chooseList.length; j++) {
					// 		console.log(this.chooseList[j].personnelId)
					// 		if (this.chooseList[j].personnelId == e[i].personnelId) {
					// 			return
					// 		} else if (j+1 == this.chooseList.length) {
					// 			this.tagList.push(e[i])
					// 		}
					// 	}
					// }

				}


			},
			// 从左侧删除人员
			closeTag(e, type) {
				// 右侧复选框删除
				if (type == 2) {
					var index = this.tagList.indexOf(e)
					console.log(index)
					this.tagList.splice(index, 1)

				}
				// 全部人员删除
				if (type == 1) {
					var index1 = this.chooseList.indexOf(e)
					console.log(this.chooseList.splice(index1, 1))
					// this.chooseList.splice(index1, 1)
					console.log(this.chooseList)
				}
				this.count = parseInt((this.chooseList == null ? 0 : this.chooseList.length) + (this.tagList == null ? 0 :
					this.tagList.length))
				var item = this.breadcrumb[this.breadcrumb.length - 1]
				this.getUserList(item.item, item.type)
			},
			changeBreadCrumb(item, index) {
				var list = []

				for (let i = 0; i < index + 1; i++) {
					list.push(this.breadcrumb[i])
				}
				this.breadcrumb = list
				if (index == 0) {
					this.is_select = true
					this.is_item = true
					this.checkbox = item.children
				} else if (item.type == 1) {
					this.checkbox = item.children
					this.is_item = true
				} else {
					this.getUserList(item.item, item.type)
					this.is_item = false
				}
			},
			// 选择部门
			getCheckbox(item) {
				console.log(item)
				this.is_select = false
				if (item.children == null) {
					var data = {
						name: item.structureName,
						item: item,
						type: 2,
					}
					this.breadcrumb.push(data)
					this.is_item = false
					console.log(item)
					this.getUserList(item, 2)
				} else {
					var data = {
						name: item.structureName,
						children: item.children,
						type: 1,
					}
					this.breadcrumb.push(data)
					console.log(this.breadcrumb)
					this.checkbox = item.children
				}
			},
			// 选择工厂（沙发厂）
			changeSelect(e) {
				console.log(this.factory[e])
				this.is_item = true
				this.is_select = false
				this.chooseList = this.chooseList.concat(this.tagList)
				this.tagList = []
				this.checkbox = this.factory[e].children
				this.breadcrumb = []
				this.breadcrumb.push(this.factory[e])
				this.count = parseInt((this.chooseList == null ? 0 : this.chooseList.length) + (this.tagList == null ? 0 :
					this.tagList.length))
			},
			// 提交发送的人配置
			submit() {
				console.log(this.chooseList)
				var list = this.chooseList
				console.log(list)
				console.log(this.tagList)
				if (list != null) {
					list = list.concat(this.tagList)
				} else {
					list = []
					list = list.concat(this.tagList)
				}
				// 日志
				if (this.type == 1) {
					// 先删除初始配置的人
					// this.delConfigUser()
					// 保存选中的所有人
					// setTimeout(() => {
						this.addConfigUser(list)
					// }, 1000);
				} else {
					// 日常 直接将所有人加入缓存
					console.log(list)
					localStorage.setItem('userConfig', JSON.stringify(list))
					setTimeout(() => {
						this.$emit('close')
					}, 500);
				}
			},
			//添加配置推送人
			addConfigUser(list) {
				console.log(list)
				var ids = []
				for (let i = 0; i < list.length; i++) {
					console.log(list[i].personnelId)
					ids.push({
						receiver: list[i].personnelId
					})
				}
				console.log(ids)
				addUser(JSON.stringify(ids)).then(res => {
					console.log(res)
					this.$emit('close')
				})
			},
			//删除配置推送人
			delConfigUser() {
				console.log('删除循环')
				console.log(JSON.parse(this.addList))
				var list = JSON.parse(this.addList)
				var id = []
				list.forEach(element => {
					console.log(element)
					id.push(element.personnelId+'')
					
				});
				delAllUser(JSON.stringify(id)).then(res => {
					console.log(res)
				})
			},
			//   获取发送到人
			getUser() {
				getUser().then(res => {
					console.log(res)
					this.chooseList = res.data
					var list = JSON.stringify(res.data)
					this.addList = list
				})
			},
			// 获取部门列表
			getFactory() {
				this.breadcrumb = []
				getStructure({
					type: 8
				}).then(res => {
					console.log(res)
					this.factory = res.data
					// this.breadcrumb[0] = res.data[0]
					this.placeholder = res.data[0].structureName
					var data = {
						name: res.data[0].structureName,
						children: res.data[0].children,
						type: 1
					}
					this.breadcrumb.push(data)
					console.log('this.breadcrumb')
					console.log(this.breadcrumb)
					this.checkbox = res.data[0].children
					// this.getUserList(res.data[0])
				})
			},
			getUserList(arr, type) {
				getPersonnel({
					structureId: arr.structureId,
					personnelName: this.personnelName
				}).then(res => {
					console.log(res)
					this.personnelName = ''
					if (type == 1) {
						this.searchList = res.data
						this.is_search = true
					} else {
						this.is_item = false
						for (let i = 0; i < res.data.length; i++) {
							if (this.chooseList != [] && this.chooseList != null) {
								for (let j = 0; j < this.chooseList.length; j++) {
									if (this.chooseList[j].personnelId == res.data[i].personnelId) {
										res.data[i].checkbox = false

									}
								}
							}
							if (this.tagList.length != null) {
								for (let j = 0; j < this.tagList.length; j++) {
									if (this.tagList[j].personnelId == res.data[i].personnelId) {
										res.data[i].checkbox = false
									}
								}
							}
						}
						console.log(res.data)
						this.checkbox = res.data
					}

				})
			}
		}
	};
</script>
<style scoped lang="less">
	//   @import url("../styles/productionTask.less");
	.page {
		text-align: start;
		// overflow-x: hidden;
		// width: 1090px;
	}
	/deep/ .arco-avatar-text{
		font-size: 14px!important;
	}
	.title {
		width: 660px;
		height: 56px;
		background: #303C56;
		border-radius: 8px 8px 0px 0px;
		font-size: 18px;
		font-family: Source Han Sans CN-Medium, Source Han Sans CN;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 56px;
		padding-left: 24px;
		position: relative;

		.right {
			position: absolute;
			right: 18px;
			top: 18px;

		}
	}

	.choose {
		width: 340px;
		padding: 16px;
		height: 360px;
		overflow: auto;
		background-color: #FFFFFF;
		position: relative;
		border-right: 1px solid #00000010;

		.tagList {
			padding: 16px 0;

			.tag {
				margin-right: 10px;
				margin-top: 5px;
			}
		}

		.button {
			position: absolute;
			bottom: 0;
			left: 16px;
			right: 0;
			height: 78px;
		}
	}

	.flex {
		display: flex;
		align-items: flex-start;
		background-color: #FFFFFF;
		width: 684px;
		border-radius: 0px 0px 8px 8px;
	}

	.list {
		text-align: start;
		color: #000;

		.name {
			font-size: 16px;
			font-family: Source Han Sans CN-Medium, Source Han Sans CN;
			font-weight: 700;
			color: #000000;
			line-height: 19px;
			height: 56px;
			line-height: 56px;
		}

		.contant {
			font-size: 16px;
			font-family: Source Han Sans CN-Normal, Source Han Sans CN;
			font-weight: 400;
			color: #1F1F1F;
			line-height: 19px;
			height: 56px;
		}

		.desc {
			width: 1004px;
			background: #F8F9FA;
			border-radius: 8px 8px 8px 8px;
			font-family: Source Han Sans CN-Normal, Source Han Sans CN;
			font-weight: 400;
			color: #000000cc;
			line-height: 19px;
			border: 1px dashed #E5E5E5;
			padding: 24px 18px;
		}
	}

	.search {
		position: absolute;
		top: 55px;
		left: 18px;
		z-index: 99;
		background-color: #FFFFFF;
		height: 300px;
		overflow-y: scroll;
		width: 317px;
		overflow-x: hidden;
		box-shadow: 0 0 5px #00000030;

		.flex {
			line-height: 25px;
			margin: 10px 20px;
			align-items: center;
			color: #999;
		}
	}

	/deep/ .arco-breadcrumb {
		height: 32px;
	}

	.checkbox {
		font-size: 14px;
		font-family: Nunito Sans-Regular, Nunito Sans;
		font-weight: 400;
		color: #1D2129;
		line-height: 22px;
		align-items: center;
		width: 311px;
		height: 32px;
		box-shadow: 0px 1px 0px 0.5px rgba(229, 229, 229, 0.5);
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		margin-top: 2px;

		div {
			margin-left: 8px;
		}
	}

	/deep/ .arco-btn-primary {
		width: 178px;
		height: 48px;
		background: #2189F3;
		border-radius: 8px;
		font-size: 18px;
		font-family: Source Han Sans CN-Medium, Source Han Sans CN;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 21px;
	}

	/deep/ .arco-btn-secondary {
		width: 102px !important;
		height: 48px;
		background: rgba(0, 0, 0, 0.30000001192092896);
		border-radius: 8px;
		font-size: 18px;
		font-family: Source Han Sans CN-Medium, Source Han Sans CN;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 21px;
		margin-left: 16px;
	}

	/deep/ .arco-breadcrumb-item {
		font-size: 14px;
		font-family: Nunito Sans-Regular, Nunito Sans;
		font-weight: 400;
		color: #86909C;
		line-height: 22px;
	}

	/deep/ .arco-btn-shape-square {
		height: 40px !important;
		font-size: 14px !important;
	}
</style>
