<template>
	<div class="page" :style="{ height: screenHeight   + 'px' }" style="overflow-y: scroll;">
		<!-- <div class="task" v-if="factory.length>0&&fold">
			QC数据驾驶舱
			<div class="factory flex" :class="{ active: item.structureId == factoryId }" v-for="item in factory"
				:key="item">
				{{ item.structureName }}
				<div class="factory-active" v-if="item.structureId == factoryId"></div>
			</div>
			<div class="right flex">
				<a-select @click="visible = false" v-model="processNo" placeholder="全部质检工序" @change="change()">
					<a-option :value="item.processNo" v-for="(item,index) in list1" :key='index'>{{item.processName}}</a-option>
				</a-select>
				<div class="menu" @click="visible = false">
					<div class="menu-item pointer" :class="{ selcet: status == 0 }"
						@click="(status = 0), changeDate(0)">
						日
					</div>
					<div class="menu-item pointer" :class="{ selcet: status == 2 }"
						@click="(status = 2), changeDate(7)">
						周
					</div>
					<div class="menu-item pointer" :class="{ selcet: status == 1 }"
						@click="(status = 1), changeDate(30)">
						月
					</div>
				</div>
			
				<div class="dateCover flex" @click="visible = true">
					<div>{{ startDate == null ? "开始时间" : startDate }}</div>
					-
					<div>{{ endDate == null ? "结束时间" : endDate}}</div>
				</div>
				<a-range-picker :popup-visible="visible" @change="onChange" @select="onSelect" :disabledDate="(current) => dayjs(current).isAfter(dayjs())"  style="
				    width: 230px!important;
				    border-radius: 50px;height: 38px;
				  " />
			</div>
		</div> -->
		<div class="per_header" >
			QC数据驾驶舱
			<div class="per_header_box">
				<div class="per_header_boxs" v-for="item in factory" :key='item'>{{ item.structureName }}</div>
			</div>
			<div class="per-right-po">
				<a-select @click="visible = false" v-model="processNo" placeholder="全部质检工序" @change="change()">
					<a-option :value="item.processNo" v-for="(item,index) in list1" :key='index'>{{item.processName}}</a-option>
				</a-select>

				<div class="per-right-poright">
					<div class="per-but" :class="status == 0 ? 'per-but1' : ''  " @click="(status = 0), changeDate(0)">日</div>
					<div class="per-but" :class="status == 1 ? 'per-but1' : ''  " @click="(status = 1), changeDate(30)">月</div>
					<div class="per-but" :class="status == 2 ? 'per-but1' : ''  " @click="(status = 2), changeDate(7)">周</div>
				</div>
			</div>
		</div>
		
		<div class="qc-box">
			<div class="qc-left">
				<div class="left-top">
					<div class="left-top-title">质检总数</div>
					<div class="chart" style="height:80%" id="quality"></div>
				</div>
				<div class="left-bottom">
					<div class="left-bottom-title">平均合格率</div>
					<div class="chart"  style="height:150px" id="average"></div>
				</div>
			</div>
			<div class="qc-center">
				<div class="chart" id="info" ></div>
				<div class="tips-box-chart">
					<div style="height:200px;overflow: auto;">
						<div class="tips-list" style="width:150px" v-for="(item,i) in flawList" :key='i'>
							<div class="tips-border" :style="{backgroundColor:color[i]}">
							</div>
							<div class="tips-name">{{item.flawName}}</div>
							<div class="tips-num">
								{{item.amount}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="qc-right">
				<div class="right-top" style="position: relative;">
					<div class="right-top-title">问题部门占比</div>
					<div class="chart" style="height:150px" id="proportion"></div>
					<div class="tips-box-chart">
						<div >
							<div class="tips-list" v-for="(item,i) in proportion" :key='i'>
								<div class="tips-border" :style="{backgroundColor:color[i]==null?color[i-10]:color[i]}">
								</div>
								<div class="tips-name">{{item.structureName}}</div>
								<div class="tips-num">
									{{item.amount}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right-bottom">
					<div class="right-bottom-title">疵点小组排行</div>
					<div class="rank-list flex" v-for="(item, i) in flawRank" :key="item">
						<div class="icon" :class="'late-icon' + i"></div>
						<div class="name">{{item.structureName}}</div>
						<div class="right">
							{{item.amount}}
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="qc-bottom" :style="{ height: screenHeight -480  + 'px' }">
			<div class="qc-bottom-left">
				<div class="qc-bottom-left-title">近12天合格率趋势图</div>
				<div class="chart" style="height:90%" id="histogram"></div>
			</div>
			<div class="qc-bottom-right">
				<div class="qc-bottom-right-title">常见疵点款式</div>
				<div class="flex" style="padding-left:10px">
					<div class="tab">
						<div class="tab-pane pointer" :class="{active:index == i}"
							@click="index = i,orderList = item.info,changeTable(item.info)" v-for="(item,i) in flawStyle" :key='i'>
							<div>{{item.productModel}}({{item.flawAmount}})</div>
						</div>
					</div>
					<a-table class="table" style="width:60%" :data="orderList" :bordered="false" size="mini" @row-click="getDetail"
						:pagination="false" v-if="orderList.length > 0" :scroll="{y:200}" :stripe="true">
						<template #columns>
							<a-table-column width="130" title="疵点原因" :filterable="filterable.flawName">
								<template #cell="{ record }">
									<a-tooltip :content="record.flawName">
										<a-button class="ellipsis" style="width: 100px;">{{record.flawName}}</a-button>
									</a-tooltip>
								</template>
							</a-table-column>
							<a-table-column width="130" title="疵点工序" data-index="processName"
								:filterable="filterable.processName">
							</a-table-column>
					
						</template>
					</a-table>
				</div>
			</div>
		</div>

		<!-- <div class="charts-top" @click="visible = false">
			<div class="charts charts1">
				<div class="flex">
					<div>
						<div class="charts charts2">
							<div class="title border">质检总数</div>
							<div class="chart" id="quality"></div>
						</div>
						<div class="charts charts2">
							<div class="title border">平均合格率</div>
							<div class="chart" id="average"></div>
						</div>
					</div>
					<div style="width: 500px;">
						<div class="chart" id="info" ></div>
						<div class="legend-list" >
							<div class="flex legend" style="float: left;" v-for="(item,i) in flawList" :key='i'>
								<div class="legend-icon" :style="{backgroundColor:color[i]}"></div>
								<div class="legend-desc">
									<div class="legend-name">{{item.flawName}}</div>
									<div class="flex">
										出现次数：
										<span class="count">{{item.amount}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="charts-right">
				<div class="charts charts3">
					<div class="title">问题部门占比</div>
					<div class="legend-list">
						<div class="flex legend" v-for="(item,i) in proportion" :key='i'>
							<div class="legend-icon" :style="{backgroundColor:color[i]==null?color[i-10]:color[i]}">
							</div>
							<div class="legend-name">{{item.structureName}}</div>
							<div class="count">
								{{item.amount}}
							</div>
						</div>
					</div>
					<div class="chart" style="width: 440px;" id="proportion"></div>
				</div>
				<div class="charts charts4">
					<div class="title">疵点小组排行</div>
					<div class="rank-list flex" v-for="(item, i) in flawRank" :key="item">
						<div class="icon" :class="'late-icon' + i"></div>
						<div class="name">{{item.structureName}}</div>
						<div class="right">
							{{item.amount}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex" @click="visible = false">
			<div class="charts charts5">
				<div class="title">近12天合格率趋势图</div>
				<div class="chart" id="histogram"></div>
			</div>
			<div class="charts charts6">
				<div class="title">常见疵点款式</div>
				<div class="tab">
					<div class="tab-pane pointer" :class="{active:index == i}"
						@click="index = i,orderList = item.info,changeTable(item.info)" v-for="(item,i) in flawStyle" :key='i'>
						<div class="title">{{item.flawAmount}}</div>
						<div>{{item.productModel}}</div>
					</div>
				</div>
				<a-table class="table" :data="orderList" :bordered="false" size="mini" @row-click="getDetail"
					:pagination="false" v-if="orderList.length > 0" :scroll="{y:200}" :stripe="true">
					<template #columns>
						<a-table-column width="230" title="疵点原因" :filterable="filterable.flawName">
							<template #cell="{ record }">
								<a-tooltip :content="record.flawName">
									<a-button class="ellipsis" style="width: 100px;">{{record.flawName}}</a-button>
								</a-tooltip>
							</template>
						</a-table-column>
						<a-table-column width="130" title="疵点工序" data-index="processName"
							:filterable="filterable.processName">
						</a-table-column>
				
					</template>
				</a-table>
			</div>
		</div> -->
	</div>
</template>

<script>
	import dayjs from 'dayjs';
	import {
		getStructure
	} from "../../api/index.js";
	import {
		getQCProcessNo,
		qualifiedRate,
		qualityData,
		flawAnalysis,
		questionDept,
		flawUserGroup,
		commonFlawStyle
	} from "../../api/charts.js";
	export default {
		setup() {
		    return {
		      dayjs,
		    }
		  },
		data() {
			return {
				screenWidth: document.body.clientWidth, //获取屏幕宽度
				factoryId: 0, //当前选择工厂的id
				charts1Data: [],
				charts2Data: [],
				factory: [], //工厂列表
				status: 0, //日
				list1: [],
				index: 0,
				arr: {},
				myChart1: null,
				status1: null,
				status2: null,
				status3: null,
				visible: false,
				dataIndex: 0,
				// datetime: [],
				processNo: 0, //工序编号
				list: [], //质检工序列表
				flawList: [], //TOP 10 疵点分析列表
				color: ['#3685F7', '#5CCCE8', '#5564F6', '#2EF867', '#F19A3D', '#F1D94E', '#A2BBD7', '#00D1BE', '#F8DFF9',
					'#AFB6FB'
				], //饼状图颜色
				startDate: new Date().Format('yyyy-MM-dd'), //开始时间
				endDate: new Date().Format('yyyy-MM-dd'), //结束时间
				flawRank: [], //top 3 疵点小组
				fold: true,
				charts4data: [],
				myChart: null,
				charts4max: [],
				flawStyle: [], //常用疵点 款式
				proportion: [], //问题部门占比列表
				screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
				checkName: '',
				orderList: Array(10).fill(null).map((_, index) => ({
					groupName: '未按工艺生产未按工...',
					styleName: '钉架',
				})),
				filterable: {
					flawName: {
						filters: [],
						filter: (value, row) => row.flawName.includes(value),
					},
					processName: {
						filters: [],
						filter: (value, row) => row.processName.includes(value),
					},
				}
			};
		},
		beforeRouteLeave: function() {
			clearInterval(this.status1);
			clearInterval(this.status2);
			clearInterval(this.status3);
		},
		mounted: function() {
			window.onresize = () => {
				return (() => {
					window.screenHeight = document.documentElement.clientHeight
					this.screenHeight = window.screenHeight;
				})();
			};
			var that = this
			that.$forceUpdate();
			// that.getCharts5()
			var index = -1
			setTimeout(() => {
				// that.getCharts1(that.charts1Data)
				// that.getCharts5()
				that.getFactory();
				// that.getCharts4(that.charts4data, that.charts4max)
			}, 500);
			this.status1 = setInterval(function() {
				// console.log(that.arr.time)
				// var length = that.arr.time.length
				// console.log(that.arr.time[0])
				// if (index + 1 == length) {
				// 	index = 0
				// 	that.checkName = that.arr.time[index]
				// } else {
				// 	index++
				// 	that.checkName = that.arr.time[index]
				// }
				// if (that.arr.time != null) {
				// 	// setTimeout(() => {
				// 		that.setCharts5(that.arr, index)
				// 	// }, 1000);

				// }

				// that.getWorkshop() //保存草稿
				that.getCharts1(that.charts1Data)
				that.getCharts2(that.charts2Data)
			}, 5000) //30s保存一次
		},
		// beforeRouteEnter(to, from, next) {
		// 	next(vm => { //  这里的vm指的就是vue实例，可以用来当做this使用
		// 		if (from.fullPath != '/' ) {
		// 			vm.$router.go(0);
		// 		}
		// 	})
		// },
		methods: {
			changeTable(arr) {
				// var arr = res.data[0].info
				var list = this.$unique(arr)
				var index = 0
				for (let a in this.filterable) {
					for (let i = 0; i < list.length; i++) {
						if (a == list[i].name) {
							this.filterable[a].filters = list[i].list
						}

						// this.filterable[index].filters = list[i].list
					}
				}
			},
			onChange(e) {
				this.startDate = e[0]
				this.endDate = e[1]
				// this.datetime = e;
				this.visible = false;
				this.change()
			},
			// 切换日期
			changeDate(num) {
				var date = new Date().getTime() - 1000 * 60 * 60 * 24 * num
				this.startDate = new Date(date).Format('yyyy-MM-dd')
				this.change()
			},
			// 重新获取页面信息
			change() {
				this.getQualityData()
				this.getFlawAnalysis()
				this.grtQuestionDept()
				this.getFlawRank()
				this.getCommonFlawStyle()
				// this.getCharts5()
			},
			// 获取 常用疵点 款式
			getCommonFlawStyle() {
				var data = {
					factoryId: this.factoryId,
					processNo: this.processNo,
					startDate: this.startDate,
					endDate: this.endDate
				}
				commonFlawStyle(data).then((res) => {
					console.log(res);
					this.flawStyle = res.data
					if (res.data.length > 0) {
						this.orderList = res.data[0].info
						this.changeTable(res.data[0].info)
					}
					console.log(this.filterable)
				});
			},
			// top 3 疵点小组
			getFlawRank() {
				var data = {
					factoryId: this.factoryId,
					processNo: this.processNo,
					startDate: this.startDate,
					endDate: this.endDate
				}
				flawUserGroup(data).then((res) => {
					console.log(res);
					this.flawRank = res.data
				});
			},
			// 获取工厂信息
			getFactory() {
				getStructure({
					type: 7,
				}).then((res) => {
					console.log(res);
					this.factory = res.data;
					this.factoryId = res.data[0].structureId;
					this.getList()
				});
			},
			// 获取 质检工序
			getList() {
				getQCProcessNo().then((res) => {
					console.log(res);
					this.list1 = res.data
					this.processNo = res.data[0].processNo
					this.change()
					this.getCharts5()
					// this.getCharts5()
				});
			},
			// 质检总数和平均合格率
			getQualityData() {
				var data = {
					factoryId: this.factoryId,
					processNo: this.processNo,
					startDate: this.startDate,
					endDate: this.endDate
				}
				qualityData(data).then((res) => {
					console.log(res);
					this.charts2Data = res.data
					this.getCharts2(res.data)
					this.getCharts3(res.data.rate)
				});
			},
			// TOP 10 疵点分析
			getFlawAnalysis() {
				var data = {
					factoryId: this.factoryId,
					processNo: this.processNo,
					startDate: this.startDate,
					endDate: this.endDate
				}
				flawAnalysis(data).then((res) => {
					console.log(res);
					var list = res.data
					this.flawList = list
					var data = []
					for (let i = 0; i < list.length; i++) {
						data.push({
							value: list[i].amount,
							name: list[i].flawName
						}, )
					}
					this.charts1Data = data
					this.getCharts1(data)
				});
			},
			// 问题部门占比
			grtQuestionDept() {
				var data = {
					factoryId: this.factoryId,
					processNo: this.processNo,
					startDate: this.startDate,
					endDate: this.endDate
				}
				questionDept(data).then((res) => {
				
					var list = res.data
					this.proportion = list
					var data = [],
						max = 0
					for (let i = 0; i < list.length; i++) {
						max += list[i].amount
						data.push({
							type: "bar",
							data: [0, 0, list[i].amount],
							coordinateSystem: "polar",
							barMaxWidth: 10, //圆环的宽度
							roundCap: true,
							name: list[i].structureName,
							color: this.color[i],
							stack: "a"
						})
					}
					this.charts4data = data
					this.charts4max = max
					this.getCharts4(data, max)
				});
			},
			getCharts2(arr) {
				var chartDom = document.getElementById('quality');
				var myChart = this.myChart2
				if (this.myChart2 != null) {
					this.myChart2.dispose()
				}
				myChart = this.$echarts.init(chartDom);
				var option;
		
				option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						show: false,
					},
					// color:'#C6C6C6',
					graphic: [{ //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						left: 'center',
						top: '40%',
						style: {
							text: '' + arr.totalAmount == null ? 0 : arr.totalAmount,
							textAlign: 'center',
							fill: '#2189F3', //文字的颜色
							color: [{
									offset: 1,
									color: "#504DEB"
								},
								{
									offset: 0,
									color: "#4087F9"
								}
							],
							width: 30,
							height: 30,
							fontSize: 25,
							fontWeight: 600,
						}
					}, { //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						left: 'center',
						top: '60%',
						style: {
							text: '质检总数',
							textAlign: 'center',
							fill: 'rgba(0, 0, 0, 0.4)', //文字的颜色
							width: 30,
							height: 30,
							fontSize: 12,
						}
					}],
					series: [{
						type: 'pie',
						radius: ['72%', '80%'],
						// center: ['50%', '50%'],
						selectedMode: false,
						hoverAnimation: false,
						data: [{
							value: 1,
							name: ''
						}],
						itemStyle: {
							color: '#F2F2F2'
						},
						label: {
							show: false
						},
						labelLine: {
							show: false
						},
						tooltip: {
							show: false
						},
						animation: false,
						cursor: 'auto',
						emphasis: {
							itemStyle: {
								color: '#F2F2F2'
							}
						}
					}, {
						// name: '扪工车间',
						type: 'pie',
						radius: ['72%', '80%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center'
						},
						labelLine: {
							show: false
						},
						data: [{
								value: arr.qualifiedAmount,
								name: '合格数'
							},
							{
								value: arr.totalAmount - arr.qualifiedAmount,
								name: '未合格数'
							},
						],
						itemStyle: {
							borderRadius: 20,
							normal: {
								borderRadius: 20,
								color: function(params) {
									//自定义颜色
									var colorList = [
										'#06B1FC', '#F2F2F2',
									];
									return colorList[params.dataIndex]
								}
							}
						},
					}, {
						type: 'pie',
						radius: ['72%', '80%'],
						// center: ['50%', '50%'],
						selectedMode: false,
						hoverAnimation: false,
						data: [{
							value: 1.8,
							name: ''
						}, {
							value: 99,
							name: ''
						}],
						itemStyle: {
							borderRadius: 20,
							normal: {
								borderRadius: 20,
								color: function(params) {
									//自定义颜色
									var colorList = [
										'#FFFFFF', '#FFFFFF00',
									];
									return colorList[params.dataIndex]
								}
							}
						},
						label: {
							show: false
						},
						labelLine: {
							show: false
						},
						tooltip: {
							show: false
						},
						animation: false,
						cursor: 'auto',
						// emphasis: {
						// 	itemStyle: {
						// 		color: '#FFFFFF'
						// 	}
						// }
					}]
				};

				option && myChart.setOption(option);
				this.myChart2 = myChart
			},
			getCharts5() {
				qualifiedRate({
					factoryId: this.factoryId,
					processNo: this.processNo,
				}).then((res) => {
					console.log(res);
					var list = res.data
					var time = [],
						data = [],
						index = 0,
						value = 0,
						indexList = []
					for (let i = 0; i < list.length; i++) {
						time.push(list[i].time)
						var num = parseInt(list[i].qualifiedAmount / list[i].totalAmount * 100)
						data.push(num)
						// if (i == 0) {
						// 	value = num
						// } else {
						// 	if (data[i] > value) {
						// 		index = i
						// 		value = data[i]
						// 	} else if (data[i] == value) {
						// 		indexList.push(index)
						// 		index = i
						// 	}
						// }

					}
					var arr = {
						time: time,
						data: data
					}
					this.checkName = time[-1]
					this.arr = arr
					this.list = list
					this.setCharts5(arr, 0)
					// for (let i = 0; i < indexList.length; i++) {
					// 	data[indexList[i]] = {
					// 		value: value,
					// 		itemStyle: {
					// 			color: "#FFA834",
					// 		}
					// 	}
					// }
					// data[index] = {
					// 	value: value,
					// 	itemStyle: {
					// 		color: "#FFA834",
					// 	}
					// }
				});
			},
			setCharts5(arr, index) {
				console.log(arr)
				var time = arr.time
				var data = arr.data
				var max = 0,
					maxList = [],
					bgList = []
				// data = []
				for (let i = 0; i < data.length; i++) {
					if (i == 0) {
						max = data[i]
					} else {
						if (data[i] > max) {
							max = data[i]
						}
					}
				}
				for (let i = 0; i < data.length; i++) {
					maxList.push(max)
					bgList.push(100)
				}
				var list = this.list
				var checkName = this.checkName; //点击柱子的名字//添加在代码最上面
				// let histogram = this.myChart1
				// if (this.myChart1 != null) {
				// 	histogram.dispose()
				// }
				var histogram = this.$echarts.init(
					document.getElementById("histogram")
				);
				histogram.on('click', function(params) {
					console.log(params)
					this.dataIndex = params.dataIndex
					console.log(this.dataIndex)
					for (let i = 0; i < 12; i++) {
						histogram.dispatchAction({
							type: 'downplay',
							seriesIndex: 0,
							dataIndex: i
						});
					}

					histogram.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: params.dataIndex
					});
					//点击的柱子的名称
					// checkName = params.name;
					// //柱形图重构
					// histogram.setOption(option);
				})
				var that = this
				var option = {
					title: {
						show: false,
					},
					grid: {
						top: 50,
						left: 10,
						right: 10,
						bottom: 15,
						containLabel: true
					},
					tooltip: {
						position: function(params) {
							var x = 30,
								y = 150
							// x = parseInt(30+params.dataIndex*20)
							return params;
						},
						backgroundColor: '#F1EAF580',
						borderColor: '#FFFFFF',
						formatter: function(param) { //自定义tooltip内容 
							var text = '';
							text +=
								'<div style="text-align:start;"><div style="font-size: 14px;color: #00000080;">' +
								param.name +
								'</div><div style="font-size: 16px;color: #2189F3;font-weight: 800;margin:10px 0 5px">合格率：' +
								param.value +
								'%</div><div style="font-size: 14px;color: #00000030;">质检套数：' +
								list[param.dataIndex].qualifiedAmount +
								'</div></div>'
							return text;
						}
					},
					xAxis: {
						data: time,
						type: "category",
						axisLabel: {
							interval: 0,
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
							//x轴刻度相关设置
						},
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						name: "合格率",
						type: "bar",
						data: data,
						barWidth: 20,
						showBackground: false,
						itemStyle: {
							normal: {
								// showBackground: false,
								color: "#FFFFFF10"
							},
							emphasis: {
								shadowBlur: 1,
								shadowOffsetX: 0,
								shadowColor: '#1b8ff3'
							}
						},
						itemStyle: {
							normal: {
								barBorderRadius: [20, 20, 0, 0],
								// color: that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								// 		offset: 1,
								// 		color: "#03C9FD",
								// 	},
								// 	{
								// 		offset: 0,
								// 		color: "#0A93FB",
								// 	},
								// ]),
								color: function(params) {
									//通过判断选中的名字改变柱子的颜色样式
									if (checkName === params.name) {
										return '#FFA834'; //点击后的颜色
									} else {
										var color = that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
												offset: 1,
												color: "#03C9FD",
											},
											{
												offset: 0,
												color: "#0A93FB",
											},
										])
										return color; //默认颜色
									}
								},
								label: {
									show: true,
									formatter: "{c}%",
									position: "top",
									textStyle: {
										//数值样式
										color: "#2189F3",
										fontSize: 12,
									},
								},
							},
							emphasis: {
								color: '#FFA834'
							}
						},
					}, {
						data: maxList,
						name: '背景',
						type: 'bar',
						barWidth: '20',
						barGap: '-100%',
						itemStyle: {
							normal: {
								color: '#C4C4C440'
							}
						},
						zlevel: 9
					}, {
						data: bgList,
						name: '背景',
						type: 'bar',
						barWidth: '20',
						barGap: '-100%',
						itemStyle: {
							normal: {
								color: '#FFFFFF00'
							}
						},
						zlevel: 9
					}],
				};
				index = -1;
				var lastIndex = 0
				this.status2 = setInterval(() => {
					console.log('5s动效')
					var dataLen = option.series[0].data.length;
					//取消之前高亮的图形
					// that.checkName = that.arr.time[app.currentIndex]
					// that.getCharts5()
					if (index + 1 == dataLen) {
						index = 0
						lastIndex = dataLen - 1
					} else {
						index++
						lastIndex = index - 1
					}
					for (let i = 0; i < 12; i++) {
						histogram.dispatchAction({
							type: 'downplay',
							seriesIndex: 0,
							dataIndex: i
						});
					}
					// histogram.dispatchAction({
					// 	type: 'downplay',
					// 	seriesIndex: 0,
					// 	dataIndex: lastIndex
					// });
					// console.log(this.dataIndex)
					// histogram.dispatchAction({
					// 	type: 'downplay',
					// 	seriesIndex: 0,
					// 	dataIndex: this.dataIndex
					// });
					//高亮当前图形
					histogram.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: index
					});
					//显示tooltip
					histogram.dispatchAction({
						type: 'showTip',
						seriesIndex: 0,
						dataIndex: index
					})
				}, 5000);
				option && histogram.setOption(option)
				document.getElementById('histogram').setAttribute('_echarts_instance_', '');
				// this.myChart1 = histogram
				window.addEventListener("resize", function() {
					histogram.resize();
				});
			},
			getCharts4(arr, max) {
				let myChart = this.myChart
				if (this.myChart != null) {
					myChart.dispose()
				}
				myChart = this.$echarts.init(
					document.getElementById("proportion")
				);
				var that = this
				var option
				
				myChart.dispatchAction({
					type: 'highlight',
					seriesIndex: 0,
					dataIndex: 1
				});
				option = {
					title: {
						show: false,
					},
					tooltip: {
						show: true,
						position: ['13%', '36%'],
						backgroundColor: '#F9F8FB00',
						borderColor: '#FFFFFF00',
						formatter: function(param) { //自定义tooltip内容
							var text = '';
							text +=
								'<div style="font-size: 28px;font-weight: 600;color: #050505;text-align:center;width:100px;">' +
								param.value +
								'</div><div style="font-size: 14px;color: rgba(0, 0, 0, 0.4);text-align:center;">' +
								param.seriesName + '</div>'
							return text;
						},
						extraCssText: 'box-shadow: 0 0 0 #FFFFFF00;', // 额外附加到浮层的 css 样式
					},
					angleAxis: {
						axisLine: {
							show: false
						},
						axisLabel: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						min: 0,
						max: max,
						startAngle: 180 //初始角度
					},
					radiusAxis: {
						type: "category",
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							show: false
						},
						data: ["1", "2", "3", "4"], //代表层级
						z: 10
					},
					polar: {
						center: ["30%", "50%"], //圆环-图形位置
						radius: "100%" //图形大小 值可设置超过100%
					},
					series: arr,
					legend: {
						show: false,
						icon: "rect",
						itemWidth: 14,
						itemHeight: 14,
						top: 45,
						right: 50,
						orient: 'vertical',
						itemGap: 30,
					}
				};
				// this.status3 = setInterval(() => {
				console.log('param')
				console.log(option)
				setTimeout(() => {
					myChart.dispatchAction({
						type: 'highlight',
						seriesIndex: 1,
						dataIndex: 2
					});
					myChart.dispatchAction({
						type: 'showTip',
						seriesIndex: 0,
						dataIndex: 2
					})
				}, 500);
				
				// }, 5000);
				
				document.getElementById('proportion').setAttribute('_echarts_instance_', '');
				option && myChart.setOption(option);
				this.myChart = myChart
			},
			getCharts1(arr) {
				if(arr==null||arr==[]){
					return
				}
				var chartDom = document.getElementById('info');
				var myChart = this.myChart1
				if (this.myChart1 != null) {
					this.myChart1.dispose()
				}
				myChart = this.$echarts.init(chartDom);
				var option;
				myChart.dispatchAction({
					type: 'highlight',
					seriesIndex: 0,
					dataIndex: 0
				});
				var option = {
					tooltip: {
						trigger: 'item',
						backgroundColor: 'transparent',
						borderColor: "#ff000000",
						position: ['20%', '40%'],
						extraCssText: 'box-shadow: 0 0 0 #FFFFFF00;', // 额外附加到浮层的 css 样式
						formatter: function(param) { //自定义tooltip内容
							var text = '';
							text +=
								'<div style="font-size: 32px;color: #FFFFFF;">' +
								param.percent +
								'%</div><div style="font-size: 16px;color: #FFFFFF;">' +
								param.name + '</div>'
							return text;
						},
					},
					color: this.color,
					legend: {
						show: false,
					},
					series: [{
						name: 'Access From',
						type: 'pie',
						center: ['35%', '50%'],
						radius: ['3%', '80%'],
						avoidLabelOverlap: false,
						backgroundColor: '#FFFFFF00',
						itemStyle: {
							borderRadius: 10,
							borderWidth: 0
						},
						label: {
							show: false,
							position: 'center'
						},
						labelLine: {
							show: false
						},
						data: arr
					}]
				};
				var index = -1;
				var lastIndex = 0
				this.status2 = setInterval(() => {
					var dataLen = option.series[0].data.length;
					//取消之前高亮的图形
					if (index + 1 == dataLen) {
						index = 0
						lastIndex = dataLen - 1
					} else {
						index++
						lastIndex = index - 1
					}
					for (let i = 0; i < 12; i++) {
						myChart.dispatchAction({
							type: 'downplay',
							seriesIndex: 0,
							dataIndex: i
						});
					}
					//高亮当前图形
					myChart.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: index
					});
					//显示tooltip
					myChart.dispatchAction({
						type: 'showTip',
						seriesIndex: 0,
						dataIndex: index
					})
				}, 5000);
				option && myChart.setOption(option);
				document.getElementById('info').setAttribute('_echarts_instance_', '');

			},
			getCharts3(arr) {
				var chartDom = document.getElementById('average');
				var myChart = this.$echarts.init(chartDom);
				var option;

				option = {
					title: {
						text: '平均合格率',
						left: "center",
						top: "90",
						textStyle: {
							fontWeight: "500",
							fontSize: 15,
							color: "#A8DDFF"
						},
					},
					tooltip: {
						show: false
					},
					itemStyle: {
						normal: {
							shadowBlur: 0
						},
					},
					series: [{
						type: 'liquidFill',
						radius: this.screenWidth > 1100 ? '150px' : '100px',
						data: arr == null ? [0, 0, 0] : [arr / 100, (arr / 100) - 0.05, arr / 100 - 0.1],
						backgroundStyle: {
							color: '#B2EAFC',
							shadowBlur: 0
						},
						outline: {
							itemStyle: {
								shadowBlur: 0,
								opacity: 0
							}
						},
						shadowBlur: 0,
						label: {
							shadowBlur: 0,
							normal: {
								shadowBlur: 0,
								color: '#27e5f1',
								insideColor: '#fff',
								textStyle: {
									fontSize: 20,
									fontWeight: 'bold',
								}
							}
						},
						color: [{
							type: 'linear',
							x: 0,
							y: 1,
							x2: 0,
							y2: 0,
							shadowBlur: 0,
							backgroundStyle: {
								normal: {
									shadowBlur: 0,
								}
							},
							colorStops: [{
								offset: 1,
								color: ['#6BC9FD'], // 0% 处的颜色
							}, {
								offset: 0,
								color: ['#6BC9FD'], // 100% 处的颜色
							}],
							global: false // 缺省为 false
						}, {
							type: 'linear',
							x: 0,
							y: 1,
							x2: 0,
							y2: 0,
							backgroundStyle: {
								normal: {
									shadowBlur: 0,
								}
							},
							colorStops: [{
								offset: 1,
								color: ['#40B6FE'], // 0% 处的颜色
							}, {
								offset: 0,
								color: ['#40B6FE'], // 100% 处的颜色
							}],
							global: false // 缺省为 false
						}, {
							type: 'linear',
							x: 0,
							y: 1,
							x2: 0,
							y2: 0,
							backgroundStyle: {
								normal: {
									shadowBlur: 0,
								}
							},
							colorStops: [{
								offset: 1,
								color: ['#0099FF'], // 0% 处的颜色
							}, {
								offset: 0,
								color: ['#0099FF'], // 100% 处的颜色
							}],
							global: false // 缺省为 false
						}],
					}]
				}
				option && myChart.setOption(option);

			},
		},
	}
</script>

<style scoped lang="less">
	@import url("../../styles/qcInfo.less");
	@import url("../../styles/all.less");

	.dateCover {
		position: absolute;
		top: 10px;
		right: 0px;
		width: 220px;
		z-index: 999;
		/* background-color: #00B42A; */
		color: #000000;
		height: 40px;
		line-height: 40px;
		text-align: end;
		font-size: 16px;
		font-family: Source Han Sans CN-Regular, Source Han Sans CN;
		font-weight: 400;
		color: #2189F3;
		line-height: 19px;

		div {
			width: 100px;
			text-align: center;
		}
	}

	/deep/ .arco-picker-input input {
		display: none;
	}

	/deep/ .arco-picker-separator {
		display: none;
	}

	/deep/ .arco-picker-suffix-icon {
		display: none;
	}

	/deep/ .arco-picker {
		border: 1px solid #ffffff20;
		;
	}
</style>
